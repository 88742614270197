import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { E } from 'chart.js/dist/chunks/helpers.core';
import { liveQuery } from 'dexie';
import { Subscription } from 'rxjs';
import { db, TowerRatesList } from 'src/db';
import { ApiService } from '../api.service';
import { DataUpdateService } from '../data-update.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-provider-types',
  templateUrl: './provider-types.component.html',
  styleUrls: ['./provider-types.component.scss']
})
export class ProviderTypesComponent implements OnInit, OnDestroy {
  constructor(private dataUpdateService: DataUpdateService, private apiService: ApiService, private ref: ChangeDetectorRef, private dataService: DataService, private loaderService: LoaderService) { }
  providers: any;
  selected = null;
  liveQuerySubscription = new Subscription();

  ngOnInit(): void {
    this.liveQuerySubscription.add(liveQuery(() => this.getProviderTypesFromDb()).subscribe(providers => {
      this.providers = providers;
    }));
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  async getProviderTypesFromDb() {
    return await this.dataService.getDataFromDb('providerTypes');
  }

  async changeProvider(provider) {
    if (this.providers.filter(e => e.isSelected).length == 1 && provider.isSelected == true) {
      Swal.fire({ icon: 'info', text: "Please select atleast one provider type" });
      return Promise.reject();
    }
    this.loaderService.openDialog()
    provider.isSelected = !provider.isSelected;
    if (provider.isSelected) {
      await this.dataService.updateRecord('providerTypes', provider);
      // this.apiService.getAllRatesAsPerSelections();
    }
    else {
      await db.selection_providerTypes.delete(provider.id);
    }
    (await this.apiService.getAllRatesAsPerSelections()).subscribe(data => {
      this.dataUpdateService.emitDataChangeEvent('providerChanged')
      this.loaderService.closeDialog();
    })
    return Promise.resolve();
  }

}
