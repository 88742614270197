import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { liveQuery } from 'dexie';
import { Subscription } from 'rxjs';
import { db } from 'src/db';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
declare let d3: any;
@Component({
  selector: 'app-target-spend-role',
  templateUrl: './target-spend-role.component.html',
  styleUrls: ['./target-spend-role.component.scss']
})
export class TargetSpendRoleComponent implements OnInit, OnDestroy {

  @Input() spendBy: string;
  benchmarkPoint: string = '50';
  byLocation = 'tower';
  spt: number;
  skillFactor: number = 1;
  targetSpend: number = 0;
  liveQuerySubscription = new Subscription();

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this._getRolesList();
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  drawSunburst(nodeData, selector) {
    d3.select(`#${selector}`).empty();
    var Tooltip = d3.select("#div_customContent")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")
    var width = 150;
    var height = 150;
    var radius = Math.min(width, height) / 2;
    var color = d3.scaleOrdinal().domain(nodeData).range(environment.chartColorSchemaAltered);

    nodeData.children = nodeData.children.filter(E => {
      E.children = E.children.filter(e => e.size != 0);
      return E.children.length > 0;
    });

    var g = d3.select(`#${selector}`)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    var partition = d3.partition()
      .size([2 * Math.PI, radius]);

    var root = d3.hierarchy(nodeData)
      .sum(function (d) { return d.size });

    partition(root);
    var arc = d3.arc()
      .startAngle(function (d) { return d.x0 })
      .endAngle(function (d) { return d.x1 })
      .innerRadius(function (d) { return d.y0 })
      .outerRadius(function (d) { return d.y1 });

    g.selectAll('g')
      .data(root.descendants())
      .enter().append('g').attr("class", "node").append('path')
      .attr("display", function (d) { return d.depth ? null : "none"; })
      .attr("d", arc)
      .style('stroke', '#fff')
      .style("fill", function (d) { return color(d.data.name); })
      .on('mouseover', function (d) {
        Tooltip
          .html("The exact value of<br>this cell is: " + d.size)
          .style("left", (d3.mouse(this)[0] + 70) + "px")
          .style("top", (d3.mouse(this)[1]) + "px")
      })

    g.selectAll(".node")
      .append("text")
      .attr("transform", function (d) {
        return "translate(" + arc.centroid(d) + ")rotate(" + computeTextRotation(d) + ")";
      })
      .style('font-size', 9)
      .attr('font-family', 'Open Sans', 'Inter','Roboto')
      .style('font-weight', 'bold')
      .style('fill', '#fff')
      .attr("dx", "-20")
      .attr("dy", ".5em")
      .text(function (d) { return d.parent ? d.data.name : "" })
      .on('mouseover', function (d) {
        var value = 0;
        if (d.data.children) {
          d.data.children.forEach(x => value = value + x.size)
        }
        else
          value = d.data.size;
        Tooltip
          .html(value)
          .style("left", (d3.mouse(this)[0] + 70) + "px")
          .style("top", (d3.mouse(this)[1]) + "px")
      })

    function computeTextRotation(d) {
      var angle = (d.x0 + d.x1) / Math.PI * 90;
      return (angle < 120 || angle > 270) ? angle : angle + 180;
    }

  }

  _getRolesList() {
    this.liveQuerySubscription.add(liveQuery(() => this.getRolesFromDB()).subscribe((roles) => {
      this.drawChart();
    }))
  }

  async drawChart() {
    let result = await this.getSelectedTowers();
    if (result.children.length > 0)
      this.drawSunburst(result, 'towerSpend');
  }

  async getRolesFromDB() {
    let allRoles = await this.dataService.getRolesInputs();

    this.skillFactor = await this.dataService.getSkillFactor();
    await this.getRoleFiltersFromDb();

    return allRoles.filter(role => role.isSelected == true);
  }

  async getRoleFiltersFromDb() {
    let filters = await db.role_filter.toArray();
    filters.forEach(f => {
      if (f.type == "spt")
        this.spt = f.value;
    })
    return filters;
  }

  async getSelectedTowers() {
    let locations =  await this.dataService.getDataFromDb('locations', {}, true);
    let inputs = await db.inputs.toArray();
    let roles = await (await db.selection_roles.toArray()).filter(x => x.isSelected == true);
    let rolesIds = roles.map(role => { return role['id'] });
    let rates = await db.rateList.where('role').anyOf(rolesIds).toArray();
    let towers = await (await db.selection_towers.toArray()).filter(x => x.isSelected == true)
    if (this.byLocation == 'tower') {
      let result = this.calculateTargetSpendByTower(locations, roles, towers, rates, inputs, this.benchmarkPoint);
      return result;
    }
    else {
      let result = this.calculateTargetSpendByLocation(locations, roles, towers, rates, inputs, this.benchmarkPoint);
      return result;
    }
  }

  calculateTargetSpendByTower(locations: any[], roles: any[], towers: any[], rates: any[], inputs: any[], benchmarkPoint: string) {
    let returnData = {
      name: "Towers",
      children: []
    };
    let targetSpend = 0;
    towers.forEach(tower => {
      let graphObj = {
        name: tower.tower,
        children: []
      };
      locations.forEach(location => {
        let value = 0;
        roles.filter(x => x.tower == tower.id).forEach(role => {
          let input = inputs.filter(x => x.role == role.id && x.location == location.id)[0];
          let rate = rates.filter(x => x.role == role.id && x.location == location.id && x.serviceProviderType == this.spt && x.benchmarkPoint == benchmarkPoint)[0].value * this.skillFactor;
          if (input != undefined)
            value = value + (input.headcount * rate * 1920);
        })
        targetSpend = targetSpend + value;
        if (value != 0)
          graphObj.children.push({
            name: location.locationName,
            size: value
          })
      })
      if (graphObj.children.length > 0)
        returnData.children.push(graphObj)
    });
    this.targetSpend = targetSpend;
    return returnData;
  }

  calculateTargetSpendByLocation(locations: any[], roles: any[], towers: any[], rates: any[], inputs: any[], benchmarkPoint: string) {
    let returnData = {
      name: "Locations",
      children: []
    };

    let targetSpend = 0;
    locations.forEach(location => {
      let graphObj = {
        name: location.locationName,
        children: []
      };
      towers.forEach(tower => {
        let value = 0;
        roles.filter(x => x.tower == tower.id).forEach(role => {
          let input = inputs.filter(x => x.role == role.id && x.location == location.id)[0];
          let rate = rates.filter(x => x.role == role.id && x.location == location.id && x.serviceProviderType == this.spt && x.benchmarkPoint == benchmarkPoint)[0]['intValue'] * this.skillFactor;
          if (input != undefined)
            value = value + (input.headcount * rate * 1920);
        })

        targetSpend = targetSpend + value;
        if (value != 0)
          graphObj.children.push({
            name: tower.tower,
            size: value
          })
      })
      if (graphObj.children.length > 0)
        returnData.children.push(graphObj)
    });

    this.targetSpend = targetSpend;
    return returnData;
  }

}
