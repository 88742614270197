<div  *ngIf="rowData$ | async as rowData; else dummyGrid">
    <div class="df-jcsb mb-8">
        <div class="headings">Recent Download(s) <span class="no-list-items"
               >{{rowData.length}}</span></div>
    </div>
    <ag-grid-angular class="ag-theme-alpine saved-scenario-table recent-download-table" 
        [rowData]="rowData"
        [columnDefs]="columnDefs" 
        [domLayout]="domLayout" 
        [rowHeight]="rowHeight" 
        [headerHeight]="headerHeight"
        (cellClicked)="onCellClicked($event)" 
        (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>

<ng-template #dummyGrid>
    <div class="df-jcsb mb-8 mt-4">
        <div class="headings">Recent Download(s) <span class="no-list-items"
               >0</span></div>
    </div>
    <ag-grid-angular class="ag-theme-alpine saved-scenario-table recent-download-table" 
        [columnDefs]="columnDefs" 
        [domLayout]="domLayout" 
        [rowHeight]="rowHeight" 
        [headerHeight]="headerHeight">
    </ag-grid-angular>
</ng-template>