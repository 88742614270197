<div mat-dialog-title>
    <div class="bg-heading bg-heading-blue">
        <span>Tower Parameters</span>
        <div>
            <!-- <button mat-button mat-dialog-close>
                <i class="icons-image icon-cancel-white" aria-hidden="true"></i>
            </button> -->
            <button class="mat-button mat-button-base" mat-button (click)="resetSelections()"><i _ngcontent-ndf-c220=""
                    class="icons-image icon-reset" matTooltip="Reset"
                    style="filter: invert(10) brightness(10); background-position: -532px -4px;"></i></button>
        </div>
    </div>
</div>
<mat-dialog-content class="mat-typography scroll-window pt-3" style="padding: 10px;">
    <div class="disp-flex scroll-window">
        <table width="100%" class="table table-sm table-bordered rounded" style="table-layout: fixed;">
            <thead>
                <tr>
                    <th style="position: sticky;left:0;z-index: 1;" width="196">Tower</th>
                    <th style="position: sticky;left:196px;z-index: 1;" width="196">Sub-Tower</th>
                    <th style="position: sticky;left:392px;z-index: 1;" width="196">Resource Unit</th>
                    <th width="196" *ngFor="let item of params?.uniqueParams">{{item}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tower of params?.grouped | keyvalue">
                    <td style="background-color: #f1f1f1;position: sticky;left:0;z-index: 1;">{{tower.key}}</td>
                    <td colspan="5" style="padding: 0;">
                        <table width="100%" class="table table-sm" style="table-layout: fixed;">
                            <tbody>
                                <tr *ngFor="let subtower of tower.value | keyvalue">
                                    <td style="border-right:1px solid #dee2e6;background-color: #f1f1f1;position: sticky;left:196px;z-index: 1;"
                                        width="200">{{subtower.key}}</td>
                                    <td style="border-bottom: none;padding: 0;">
                                        <table width="100%" class="table table-sm" style="table-layout: fixed;">
                                            <tbody>
                                                <tr *ngFor="let role of subtower.value | keyvalue">
                                                    <td width="196"
                                                        style="border-right:1px solid #dee2e6;background-color: #f1f1f1;position: sticky;left:392px;z-index: 1;">
                                                        {{role.key}}</td>

                                                    <td width="196"
                                                        style="border-right:1px solid #dee2e6;padding-bottom:0;padding-top: 0;padding-left: 0;"
                                                        *ngFor="let parameter of params?.uniqueParams">
                                                        <div class="custom-select"
                                                            *ngIf="checkIfParamExist(role.value[parameter], parameter)">
                                                            <mat-form-field>
                                                                <mat-label>{{parameter}}</mat-label>
                                                                <mat-select
                                                                    [formControl]="getControl(role.key, parameter)"
                                                                    (selectionChange)="onSelectionChanged($event)">
                                                                    <mat-option
                                                                        *ngFor="let param of role.value[parameter]"
                                                                        [value]="param">
                                                                        {{param.displayText}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="saveSenariobtn" align="end">
    <button class="themebg" mat-button mat-raised-button (click)="performOperations()"
        [disabled]="userSelections.length ==0" cdkFocusInitial>Submit</button>
    <!-- <button class="mat-button mat-button-base"  mat-button (click)="resetSelections()">Reset</button> -->
    <button mat-button mat-dialog-close>
        Cancel
    </button>

</mat-dialog-actions>