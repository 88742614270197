<div mat-dialog-title>
    <div class="bg-heading bg-heading-blue">
        <span>Benchmark Spend Analysis</span>
        <button mat-button mat-dialog-close>
            <i class="icons-image icon-cancel-white" aria-hidden="true"></i>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography scroll-window" style="padding: 10px;" *ngIf="type=='role'">
    <app-savings-analysis-role [benchmarkPoint]="benchmarkPoint" [isModal]="true"
        [annualHours]="annualHours"></app-savings-analysis-role>
</mat-dialog-content>
<mat-dialog-content class="mat-typography scroll-window" style="padding: 10px;" *ngIf="type=='tower'">
    <app-savings-analysis-tower [benchmarkPoint]="benchmarkPoint" [isModal]="true"></app-savings-analysis-tower>
</mat-dialog-content>