import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-boxillustration',
  templateUrl: './boxillustration.component.html',
  styleUrls: ['./boxillustration.component.scss']
})
export class BoxillustrationComponent implements OnInit {

  constructor() { }
  @Input() data: any;
  ngOnInit(): void {
  }

  formatNumber(value) {
    return value.toFixed(0);
  }

}
