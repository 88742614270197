import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ApiService } from '../api.service';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { db } from 'src/db';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { liveQuery } from 'dexie';
import { MillionPipe } from '../million.pipe';
import { draw } from 'patternomaly'

import { Subscription } from 'rxjs/internal/Subscription';
import { SavingsAnalysisService } from '../services/savings-analysis.service';
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
  providers: [MillionPipe]
})

export class BarChartComponent implements OnInit, OnChanges {
  @Input() towerId: number;
  @Input() savingsData: number;
  @Input() pdfExport: boolean = false;
  @Output() graphOptions = new EventEmitter<any>();

  chart: Chart;
  chartId: string;
  showOverlay: boolean = true;

  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private millionPipe: MillionPipe) {
    Chart.register(...registerables, annotationPlugin, ChartDataLabels);
    Chart.defaults.font.family = "'Open Sans', 'Inter','Roboto'";
  }

  ngOnInit(): void {
    this.chartId = "DetailedTowerChart-" + this.towerId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['savingsData'] && changes['savingsData'].currentValue)
      this.plotChart(changes['savingsData'].currentValue)
  }

  createChart(dataset: any[], annotation, annotation1) {
    let chartOptions = {
      type: 'bar', //this denotes the type of chart
      data: {
        labels: ["Your Spend", "Shoring", "Pyramid", "Rates", "Benchmark Spend"],
        datasets: dataset,
      },

      options: {
        aspectRatio: 4,
        plugins: {
          annotation: {
            annotations: {
              //annotation,
              annotation1
            }
          },
          legend: {
            display: true,
            labels: { filter: (item, chart) => !item.text.includes('Extra') }
          },
          datalabels: {
            color: '#555555',
            align: 'end',
            offset: -5,
            anchor: 'end',
            display: function (context) {
              if (context.dataset.label != "Extra")
                return Number(context.dataset.data[context.dataIndex]) > 0 ? true : false;
              return false;
            },
            font: {
              weight: 'bold'
            },
            formatter: (value, ctx) => {
              let val = this.millionPipe.transform(value);
              if (["Negative Savings (Already better than Benchmark)"].includes(ctx.dataset.label))
                return `$(${this.millionPipe.transform(value).substring(1)})`
              return val;
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            }
          },
          y: {
            display: true,
            stacked: true,
            type: 'linear',
            ticks: {
              callback: (value) => this.millionPipe.transform(value)
            },
            offset: true
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    };

    if (this.chart != undefined) {
      this.chart.data.datasets = dataset;
      this.chart.update();
    }
    else {
      this.chart = new Chart(this.chartId,
        {
          type: 'bar', //this denotes the type of chart
          data: {
            labels: ["Your Spend", "Shoring", "Pyramid", "Rates", "Benchmark Spend"],
            datasets: dataset,
          },

          options: {
            aspectRatio: 4,
            plugins: {
              annotation: {
                annotations: {
                  //annotation,
                  annotation1
                }
              },
              legend: {
                display: true,
                labels: { filter: (item, chart) => !item.text.includes('Extra') }
              },
              datalabels: {
                color: '#555555',
                align: 'end',
                offset: -5,
                anchor: 'end',
                display: function (context) {
                  if (context.dataset.label != "Extra")
                    return Number(context.dataset.data[context.dataIndex]) > 0 ? true : false;
                  return false;
                },
                font: {
                  weight: 'bold'
                },
                formatter: (value, ctx) => {
                  let val = this.millionPipe.transform(value);
                  if (["Negative Savings (Already better than Benchmark)"].includes(ctx.dataset.label))
                    return `$(${this.millionPipe.transform(value).substring(1)})`
                  return val;
                }
              }
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                }
              },
              y: {
                display: true,
                stacked: true,
                type: 'linear',
                ticks: {
                  callback: (value) => this.millionPipe.transform(value)
                },
                offset: true
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }
        });
    }
    this.graphOptions.emit(chartOptions)
  }

  plotChart({ currentSpend, shoringSavings, socSavings, rateSavings, targetSpend }) {

    const annotation1 = {
      type: 'line',
      scaleID: 'y',
      borderWidth: 1,
      borderColor: '#000',
      value: 0,
    };
    const annotation2 = {
      type: 'line',
      scaleID: 'y',
      borderWidth: 2,
      borderColor: '#C5AC2E',
      borderDash: [3, 3],
      value: targetSpend.total,
    };

    let spendDataset: any[] = [
      {
        label: 'Benchmark Spend ',
        backgroundColor: "#C5AC2E",
        maxBarThickness: 100,
        data: [0, 0, 0, 0, targetSpend.total]
      }
    ];

    if (currentSpend.total != 0) {
      let img = new Image();
      img.src = 'assets/img/detailed-chart-negative-pattern.png';
      let canvasElement: any = document.getElementById(this.chartId);
      let fillPattern = canvasElement.getContext('2d').createPattern(img, 'repeat');

      spendDataset = [
        {
          label: 'Extra',
          backgroundColor: "#FFF",
          maxBarThickness: 0,
          data: [
            0,
            (currentSpend.total - Math.max(shoringSavings.total, 0)),
            (currentSpend.total - shoringSavings.total - Math.max(socSavings.total, 0)),
            (currentSpend.total - shoringSavings.total - socSavings.total - Math.max(rateSavings.total, 0)),
            0
          ]
        },
        {
          label: 'Your Spend',
          backgroundColor: "#000",
          maxBarThickness: 100,
          data: [currentSpend.total, 0, 0, 0, 0],
        },
        {
          label: 'Lower than the benchmark',
          backgroundColor: [draw('diagonal', '#737373', '#FFF')],
          maxBarThickness: 100,
          data: [
            0,
            shoringSavings.total < 0 ? Math.abs(shoringSavings.total) : 0,
            socSavings.total < 0 ? Math.abs(socSavings.total) : 0,
            rateSavings.total < 0 ? Math.abs(rateSavings.total) : 0,
            0
          ],
        },
        {
          label: 'Higher than the benchmark',
          backgroundColor: "#A0A0A0",
          maxBarThickness: 100,
          data: [
            0,
            shoringSavings.total >= 0 ? shoringSavings.total : 0,
            socSavings.total >= 0 ? socSavings.total : 0,
            rateSavings.total >= 0 ? rateSavings.total : 0,
            0
          ]
        },
        ...spendDataset]
    }

    this.createChart(spendDataset, annotation1, annotation2);
  }

}


