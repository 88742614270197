<table style="width: 100%;" class="table table-striped-columns" *ngIf="rows.length > 0 || (rows.length==0 && showOnlySelected)">
    <tbody>
      <tr class="loc-row">
        <td class="search-content fixed-col" style="background-color: #F5F5F5; color: #565656">
          <span class="towerNameHead">Roles Name</span>         
        </td>
        <td *ngFor="let locat of locationSingle;let i = index"
          [ngClass]="selectedServiceProviders.length == 1 ? (i%2==0 ? 'loc-gray' : 'loc-white') : ''">
          <table>
            <tr style="background-color: #F5F5F5; color: #2B2B2B">
              <td class="sub-header-td" *ngFor="let sp of selectedServiceProviders" style="width: 25%;"
                [ngClass]="selectedServiceProviders.length > 1 ? 'color-' + sp.spt : ''">{{sp.spt}}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngFor="let row of rows | generalPriceBar:searchText">
        <td class="search-content fixed-col">
          <ng-container *ngIf="loadedComponentType == 'role'">
            
              <span class="role-label"> <span [matTooltip]="row.label">{{ row.label }}</span>
              
              </span>
              <span *ngIf="loadedComponentType == 'role'">({{row.subtowerShortcode}})</span>
              <span> {{row['roleData']['level'] }}: {{row.label}} {{row['roleData']['experience'] }} years</span>
          </ng-container>
          <ng-container *ngIf="loadedComponentType != 'role'">
            <div class="d-flex justify-content-start align-items-center" (click)="openBenchmarkDetail(row)">
              <div class="align-self-start" style="line-height: 0;">
                <i class="icons-image icon-expand" title="Blended Benchmark across Levels"></i>
              </div>
              <div class=" px-1 text-truncate align-self-center">{{row.label}}</div>

            </div>
            <div style="padding-left:30px !important" class=" px-1 d-flex  justify-content-start align-items-center"
              [matTooltip]="getTooltipText(row)">
              <span class="badge rounded-pill text-light text-bg-secondary">{{ row.selectedSubtowers[0]['shortCode']
                }}</span>
              <span class="badge rounded-pill text-light text-bg-secondary" *ngIf="row.selectedSubtowers.length > 1">{{
                row.selectedSubtowers[1]['shortCode'] }}</span>
              <span class="badge rounded-pill text-light text-bg-secondary"
                *ngIf="row.selectedSubtowers.length > 2">+{{row.selectedSubtowers.length - 2}}</span>
            </div>
          </ng-container>
        </td>
        <td *ngFor="let locat of locationSingle;let i = index"
          [ngClass]="selectedServiceProviders.length == 1 ? (i%2==0 ? 'loc-gray' : 'loc-white') : ''">
          <table>
            <tr class="table-inline">
              <td *ngFor="let sp of selectedServiceProviders"
                style="width: 25%;">
                <ng-container>
                  <div *ngIf="row && minMaxRoles">
                      <app-boxillustration [data]="row['rateSPT'][locat['id']][sp['id']]" style="padding: 0px;"></app-boxillustration>
                    <!-- <app-box-plot-container-ru *ngIf="this.typeNameSelector == 'Services'" [minMaxRoles]="minMaxRoles"
                      [width]="getWidthAsPerSelections()" [role]="row" [locat]="locat" [spt]="sp">
                    </app-box-plot-container-ru>
                    <app-box-plot-container *ngIf="this.typeNameSelector != 'Services'" [minMaxRoles]="minMaxRoles"
                      [width]="getWidthAsPerSelections()" [role]="row" [locat]="locat" [spt]="sp">
                    </app-box-plot-container> -->
                    <!-- <app-box-plot-container-ru *ngIf="loadedComponentType != 'role'"  [minMaxRoles]="minMaxRoles" [width]="getWidthAsPerSelections()" [role]="row" [locat]="locat" [spt]="sp">
                    </app-box-plot-container-ru>  -->
                    <!-- <app-box-plot-container *ngIf="this.typeNameSelector != 'Services'"  [minMaxRoles]="minMaxRoles" [width]="selectedServiceProviders.length == 1 ? 400:250" [role]="row" [locat]="locat" [spt]="sp">
                    </app-box-plot-container>  -->
                    <!-- <app-ru-box-container *ngIf="this.typeNameSelector == 'Services'" [minMaxRoles]="minMaxRoles" [width]="selectedServiceProviders.length == 1 ? 400:250" [role]="row" [locat]="locat" [spt]="sp"></app-ru-box-container>   -->
                  </div>
                </ng-container>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </tbody>

  </table>