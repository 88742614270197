<div mat-dialog-title>
    <div class="bg-heading bg-heading-blue">
        <span>Select Locations</span>
        <button mat-button mat-dialog-close>
            <i class="icons-image icon-cancel-white" aria-hidden="true"></i>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography scroll-window">
    <app-region-chart [isModal]="true"></app-region-chart>
</mat-dialog-content>
