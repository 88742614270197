<div class="plot-wrap" *ngIf="checkIfDataAvailable()">

  <div class="d-flex">
    <div class="price-bar" [ngStyle]="{'width.px': width}">
      <div class="bar">
        <div class="first"
          [ngStyle]="{
            'left.px': getMedian()-getWidth(),
            'width.px': getWidth()}">
        </div>
        <div class="second"
          [ngStyle]="{
            'left.px': getMedian(),
            'width.px': getWidth()}">
        </div>
        <div class="priceValue">
        <span class="numbers"
          [ngStyle]="{'left.px': (getMedian()-getWidth())-getLeftLabelOffset()}">
          {{formatNumber(getRate(25))}}
        </span>
        <span class="numbers-top"
          [ngStyle]="{'left.px': (getMedian())-getLabelOffset()}">
          {{formatNumber(getRate(50))}}
        </span>
        <span class="numbers"
          [ngStyle]="{'left.px': (getMedian()+getWidth())-getRightLabelOffset()}">
          {{formatNumber(getRate(75))}}
        </span>
        </div>
        <div>
          <span class="numbers cp" *ngIf="role.inputPlots[locat.id] &&role.inputPlots[locat.id][spt.id] "
          [ngStyle]="{'left.px': calculateScatterPosition()}" [matTooltip]="role.inputPlots[locat.id][spt.id]"
            [ngClass]="getClassForUserValue(role, locat,spt)">
          </span>
        </div>
      </div>
    </div>
  </div>
</div>