import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-savings-modal',
  templateUrl: './savings-modal.component.html',
  styleUrls: ['./savings-modal.component.scss']
})
export class SavingsModalComponent implements OnInit {

  benchmarkPoint: string;
  type: string;
  annualHours: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { benchmarkPoint: string, type: string, annualHours: number }) { }

  ngOnInit(): void {
    this.benchmarkPoint = this.data.benchmarkPoint;
    this.type = this.data.type;
    this.annualHours = this.data.annualHours;
  }

}
