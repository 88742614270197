import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { liveQuery } from 'dexie';
import { AreaList, db, TowerRatesList } from 'src/db';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';
import { MessageService } from 'src/app/services/Message.service';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataUpdateService } from 'src/app/data-update.service';
import { LoaderService } from 'src/app/loader.service';
import { EncyptDecryptService } from 'src/app/encypt-decrypt.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';

@Component({
  selector: 'app-select-countries',
  templateUrl: './select-countries.component.html',
  styleUrls: ['./select-countries.component.scss'],
  providers: [
    // { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions }
  ]
})
export class SelectCountriesComponent implements OnInit, OnChanges, OnDestroy {

  //MANISH
  constructor(
    private apiService: ApiService, private dataService: DataService,
    private messageService: MessageService, private route: ActivatedRoute,
    private dataUpdate: DataUpdateService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private encDecService: EncyptDecryptService) { }
  locations: any[] = [];
  regionName: any = "Select Location(s)";
  selectionCountriesForm: FormGroup;
  //SWASTIKA
  @Input() countries: any;
  @Input() finalData: any;
  @Input() isModal?: boolean = false;
  @Input() variant?: string = '';
  @Input() benchmarkType?: string = '';

  selectedRegion: number;
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deselectRegionEvent: EventEmitter<boolean> = new EventEmitter<any>();
  @Output() selectLocation: EventEmitter<object> = new EventEmitter<any>();
  @Output() selectMapRegion: EventEmitter<object> = new EventEmitter<any>();
  locationLimitEnabled = environment.locationLimitEnabled;
  countryList = [];
  selectedCountries: any[] = [];
  selectedOffShore: any[] = [];
  selectedOnShore: any[] = [];
  liveQuerySubscription = new Subscription();
  title: any;
  panelOpenState = false;
  maxLocationLimitWarning = 6;
  displayWarningMessage = false;


  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.benchmarkType = params['benchmarkType']
    })
    this.selectionCountriesForm = this.fb.group({
      countries: this.fb.array([])
    })
    this.getLocations().then(locations => {
      this.countryList = locations
    })

    this.liveQuerySubscription.add(liveQuery(() => this.getSelectedCountries()).subscribe(result => {
      const cities = (<FormArray>(this.selectionCountriesForm.get("countries"))) as FormArray;
      result.forEach(loc => {
        cities.push(new FormControl(loc.id));
      })
      this.selectedCountries = result;
      if (this.selectedCountries.length >= this.maxLocationLimitWarning) {
        this.displayWarningMessage = true;
      }
      else {
        this.displayWarningMessage = false;
      }
    }));
    // liveQuery(() => this.getLocations()).subscribe(locations => {
    //   if(this.selectedRegion){
    //     this.countryList = locations.filter(loc => { return loc.regionId == this.selectedRegion });
    //   }
    //   else
    //     this.countryList = locations;
    // });
    //this.checkOffshoreRatio()
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  async deselectRegion() {
    this.countryList = await this.getLocations();
    this.regionName = "Select Location(s)";
    this.deselectRegionEvent.emit(null);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['countries'].currentValue != null && changes['countries'].currentValue != changes['countries'].previousValue) {

      if ('locations' in changes['countries'].currentValue) {

        this.countryList = changes['countries'].currentValue['locations']
          .map(cnt => ({ ...cnt, isSelected: this.selectedCountries.map(e => e.id).includes(cnt.id) }));

        this.regionName = "Select Location(s) from the highlighted region on the map";
        this.selectedRegion = changes['countries'].currentValue.id;
      }
    }
  }

  async getLocations() {
    let area: AreaList[] = await this.dataService.getDataFromDb('areas', {}, true);
    if (area.length > 0) {
      return (await this.dataService.getDataFromDb('locations')).filter(e => area[0].locationMappings.includes(e.id));
    }
    return [];
  }

  async getSelectedCountries() {
    let area: AreaList[] = await this.dataService.getDataFromDb('areas', {}, true);
    if (area.length > 0) {
      return (await this.dataService.getDataFromDb('locations', {}, true)).filter(e => area[0].locationMappings.includes(e.id));
    }
    return [];
  }

  async markAsOffshoreLocation(event, location) {
    let loc = await db.locations.where({ id: location.id }).first();
    loc['offshore'] = event.checked;
    await this.dataService.updateRecord('locations', loc);
  }

  //SWASTIKA
  closeModal() {
    let data = this.countries.locations.filter(country => { return country.isChecked == true }).map(location => { return { id: location.id, name: location.locationName } })
    this.modalClosed.emit(false);
  }

  async updateSelection(event, location) {
    let loc = await db.locations.where({ id: location.id }).first();
    loc['isSelected'] = event.checked;
    location.isOffshore = false;
    if (event.checked == false) {
      await this.dataService.deleteRecordFromSelection('locations', [location.id])
    }
    else {
      if (!environment.locationLimitEnabled) {
        // 4 counties limit check has been disabled
        await this.dataService.updateRecord('locations', loc);
      }
      else {
        if (this.selectedCountries.length < 4) {
          await this.dataService.updateRecord('locations', loc);
          /*let blendedObj = await this.apiService.getBlendedObject();
          blendedObj['locations'].push(loc.id)
            this.apiService.getBlendedRates(blendedObj).subscribe(async(data: TowerRatesList[]) => {
              await db.towerRates.clear();
              await db.towerRates.bulkAdd(data)
              await this.dataService.updateRecord('locations', loc);
            });*/
          // await this.apiService.getAllRatesAsPerSelections();
        }
        else {
          this.countryList = this.countryList.map(e => ({ ...e, isSelected: ((e.id == location.id) ? false : this.selectedCountries.map(e => e.id).includes(e.id)) }))
          //alert("Maximum 4 locations allowed.");
          Swal.fire({
            //title: 'Error!',
            text: 'Maximum 4 locations allowed.',
            icon: 'info',
            confirmButtonText: 'Close'
          });
        }
      }
    }
    this.selectLocation.emit({ event, location });
  }

  async updateSelectionCheckbox(selectedOption, id, location) {
    const cities = (<FormArray>(this.selectionCountriesForm.get("countries"))) as FormArray;

    if (selectedOption.checked == false && cities.value.length == 1) {
      Swal.fire({
        icon: 'info',
        text: 'Please select at least one location'

      })
      await Promise.resolve();
    }
    if (selectedOption.checked) {
      cities.push(new FormControl(id));
      this.selectedCountries.push(location)
    } else {
      const i = cities.controls.findIndex(
        x => x.value === id
      );
      cities.removeAt(i);
      let index = this.selectedCountries.findIndex(sc => sc.id == id)
      this.selectedCountries.splice(index, 1);
    }
    this.displayWarningMessage = cities.value.length > 5
    this.selectMapRegion.emit({ selectedOption, location });
    return Promise.resolve();
  }

  async submitLocations() {
    this.loaderService.openDialog();
    await db.selection_locations.clear();
    let locations = this.selectionCountriesForm.get('countries').value;
    let loc = await db.locations.where('id').anyOf(locations).toArray();
    loc.forEach(async (l) => {
      l['isSelected'] = true;
      await this.dataService.updateRecord('locations', l)
    });
    let blendedObj = await this.apiService.getBlendedObject();
    await this.apiService.getBlendedRates(blendedObj).subscribe(async (data: TowerRatesList[]) => {
      await db.towerRates.clear();
      let towerRatesData = data;
      towerRatesData.forEach(rate => {
        rate['locationData'] = this.encDecService.encryptObject(rate['locationData']);
      })
      await db.towerRates.bulkAdd(towerRatesData);
      //await db.towerRates.bulkAdd(data)
    });
    (await this.apiService.getRatesAsPerSelections()).subscribe(async (data: any) => {
      await db.table('rateList').clear()
      await db.table('rateList').bulkPut(data);
      this.dataUpdate.emitDataChangeEvent('locationSelectionsChanged')
    });
  }

  location(location) {
  }

  //MANISH
  onNoClick() {
  }

  updateLocation(location, event) {
    db.locations.update(location.id, { isSelected: event.checked })
  }

  async remove(location) {
    location.isSelected = false;
    location.isOffshore = false;
    delete location.offshoreRatio;
    await this.dataService.updateRecord('locations', location)
  }

  async saveOffshoreRatio(location, event) {
    location.offshoreRatio = parseInt(event.target.value)
    await this.dataService.updateRecord('locations', location);
    this.checkOffshoreRatio()
  }

  async checkOffshoreRatio() {
    let locations = await this.dataService.getDataFromDb('locations', {}, true);
    locations = locations.filter(loc => { return loc.isOffshore == true });
    if (locations.length == 0) {

    } else {
      let offShoreRatio = _.reduce(locations, function (sum, n) {
        return sum + n.offshoreRatio;
      }, 0);
      this.messageService.setStepAsDisabled(offShoreRatio < 100);
    }
  }

  checkIfLimitEnabled() {
    if (!environment.locationLimitEnabled) {
      return false;
    }
    if (this.selectionCountriesForm.get('countries').value.length == 0) {
      return true;
    }

    return this.selectionCountriesForm.get('countries').value.length > 4
  }
}
