import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Shepherd from 'shepherd.js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor(private route: Router) { }

  production = environment.production||false;
  
  commonSteps = [    
    {
      page: 'interactive',
      id: 'step4',
      title: 'Options for FTE Benchmarking',
      text: 'Select the relevant benchmarking option (Discrete/Blended)',
      attachTo: {
        element: '.tour-options',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    { 
      page: 'interactive',
      id: 'step18',
      title: 'Category',
      text: 'Select ITO or BPO for your benchmarking requirements',
      attachTo: {
        element: '.tour-category',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    { 
      page: 'interactive',
      id: 'step18',
      title: 'Area',
      text: 'Select the relevant area (i.e. ADM/Infra/Consulting for ITO and F&A/HRO/Contact Centres for BPO)',
      attachTo: {
        element: '.tour-area',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      page: 'interactive',
      id: 'step5',
      title: 'Skill factor',
      text: 'Identify relevant skill category (Standard, Premium and Niche - Click here for detailed list of skills by category)',
      attachTo: {
        element: '.tour-skillfactor',
        on: 'right'
      },
      classes: 'example-step-extra-class'
    },
    { 
      page: 'interactive',
      id: 'step18',
      title: 'Towers/Sub-towers',
      text: 'Select relevant functional towers and sub-towers (refer to list of towers and sub-towers)',
      attachTo: {
        element: '.custom-tree',
        on: 'right'
      },
      classes: 'example-step-extra-class'
    },
    { 
      page: 'interactive',
      id: 'step18',
      title: 'Location',
      text: 'Select the relevant delivery location',
      attachTo: {
        element: '.region-wrap',
        on: 'right'
      },
      classes: 'example-step-extra-class'
    },
    {
      
      page: 'interactive',
      id: 'step18',
      title: 'Provider Type',
      text: 'Select the provider category (Indian and Global SI players)',
      attachTo: {
        element: '.tour-provider',
        on: 'top'
      },
      classes: 'example-step-extra-class'
    },
    {
      page: 'interactive',
      id: 'step3',
      text: 'Download your benchmarking analysis and relevant data in Excel/PDF format',
      attachTo: {
        element: '.download-tour',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    
    {
      page: 'interactive',
      id: 'step15',
      title: 'Save Scenario',
      text: 'Save this scenario',
      attachTo: {
        element: '.save-btn',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      page: 'interactive',
      id: 'step15',
      title: 'Reset',
      text: 'Reset Selections',
      attachTo: {
        element: '.reset-btn',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    }
  ]

  roleSteps = [
    {
      title: 'Variance Spread',
      text: 'Click to open Variance Spread',
      attachTo: {
        element: '.tour-variance',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Rates',
      text: 'Input your Rates',
      attachTo: {
        element: '.icon-table-edit',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Reset',
      text: 'Clear input rates',
      attachTo: {
        element: '.clear-btn',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Location',
      text: 'Select the relevant delivery location',
      attachTo: {
        element: '.tour-boxplotlocation',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Benchmarking',
      text: 'Benchmark your rates',
      attachTo: {
        element: '.tour-boxplot',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    
    {
      title: 'Select Location',
      text: 'Select the relevant delivery location',
      attachTo: {
        element: '.tour-locationselect',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Select Spend Type',
      text: 'Select the spend type',
      attachTo: {
        element: '.tour-spendtype',
        on: 'bottom'
      },
      classes: 'example-step-extra-class',
      // showOn:() => {
      //   return document.querySelector('.tour-spendtype').getBoundingClientRect().x > 0;
      // },
    },
    {
      title: 'Provider Category',
      text: 'Select the provider category (Indian SI including Indian heritage players like Infosys, Wipro, etc. and Global SI includes players like Accenture, Capgemini, etc.',
      attachTo: {
        element: '.tour-roleprovider',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
  ];

  blendedSteps = [
    {
      title: 'Span Of Control',
      text: 'Ratios summarizing breakup of the Delivery Team across different levels of the hierarchy (e.g., L1 : L2 : Lead : Manager)',
      attachTo: {
        element: '.custom-slider',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    { 
      title: 'Tower Benchmarking',
      text: 'Optimizing requirements of each component at Tower level.',
      attachTo: {
        element: '.tower-analysis-tab',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Selections',
      text: 'Use this section to modify selections',
      attachTo: {
        element: '.tour-selections',
        on: 'left'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Opportunity Sizing',
      text: 'The assessment for the extent of savings that can be done with the benchmarked prices',
      attachTo: {
        element: '.tour-opportunity',
        on: 'left'
      },
      classes: 'example-step-extra-class'
    }
  ];

  stepsList = [
    {
      title: 'Category',
      text: 'Select ITO or BPO for your benchmarking requirements',
      attachTo: {
        element: '#mat-tab-label-0-0',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Area',
      text: 'Select the relevant area (i.e. ADM/Infra/Consulting for ITO and F&A/HRO/Contact Centres for BPO)',
      attachTo: {
        element: '#mat-tab-label-0-1',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Tower(s) & Sub-tower(s)',
      text: 'Select relevant functional towers and sub-towers (refer to list of towers and sub-towers)',
      attachTo: {
        element: '#mat-tab-label-0-2',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Locations',
      text: 'Select the relevant delivery location',
      attachTo: {
        element: '#mat-tab-label-0-3',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Provider Type',
      text: 'Select the provider category (Indian SI including Indian heritage players like Infosys, Wipro, etc. and Global SI includes players like Accenture, Capgemini, etc.',
      attachTo: {
        element: '#mat-tab-label-0-4',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    }
  ];

  dashboardSteps = [
    {
      title:'Dashboard',
      text: 'Click here to return to the home screen',
      attachTo: {
        element: '.tour-dashboard',
        on: 'right'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'FTE',
      text: 'Benchmark your hourly FTE rates across ITO/BPO towers and delivery locations across the globe',
      attachTo: {
        element: '.fte-tour',
        on: 'right'
      },
      classes: 'example-step-extra-class'
    }, 
    {
      title: 'RU',
      text: 'Benchmark your resource unit rates across ITO/BPO towers and delivery locations across the globe' + (this.production?' (Coming Soon)':''),
      attachTo: {
        element: '.ru-tour',
        on: 'right'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'FTE Bechmarking',
      text: 'Benchmark your hourly FTE rates across ITO/BPO towers and delivery locations across the globe',
      attachTo: {
        element: '.tour-ftebox',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'RU Benchmarking',
      text: 'Benchmark your resource unit rates across ITO/BPO towers and delivery locations across the globe' + (this.production?' (Coming Soon)':''),
      attachTo: {
        element: '.tour-rubox',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
    {
      title: 'Download Reports',
      text: 'Download your benchmarking analysis and relevant data in Excel/PDF format',
      attachTo: {
        element: '.recent-download-table',
        on: 'bottom'
      },
      classes: 'example-step-extra-class'
    },
  ];

  custom = {
    variance: [{
      title: 'Variance Spread',
      text: 'The range of FTE rates as compared with the median prices',
      attachTo: {
        element: '#scatterChart',
        on: 'top'
      },
      classes: 'example-step-extra-class',
    }],
    towerAnanlysis:[{
      title: 'Detailed Tower Analysis',
      text: 'Complete analysis to get the comprehensive view at Tower level ',
      attachTo: {
        element: '.tour-detailed-analysis',
        on: 'top'
      },
      classes: 'example-step-extra-class',
    }]
  }

  private _generateSteps(type = null) {
    let tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shadow-md bg-purple-dark',
        scrollTo: true,      
      }
    });
  
    let defaultButtons = [
      {text:'Skip', action: tour.cancel, classes:'cbtn-primary cbtn-secondary-2'},
      {text:'Prev', action: tour.back, classes:'cbtn-primary cbtn-secondary-2'},
      {
        text: 'Next',
        action: tour.next, classes:'cbtn-primary cbtn-secondary-2'
      }
    ]
    let steps = []
    if(type){
      steps = this.custom[type]
    }
    else{
      steps = [...this.commonSteps, ...this.stepsList, ...this.dashboardSteps, ...this.roleSteps, ...this.blendedSteps]
    }
    if(steps.length == 1){
      defaultButtons = [
        {text:'Skip', action: tour.cancel, classes:'cbtn-primary cbtn-secondary-2'},        
      ]
    }
    steps.forEach(step => {
      step['showOn'] = function(){
        return (document.querySelector(step.attachTo.element))? document.querySelector(step.attachTo.element).getBoundingClientRect().x > 0 : false;
      }      
      step['buttons'] = defaultButtons;
    })
    tour.addSteps(steps);
    tour.start()
  }

  startTour(page = null, type=null) {    
    this._generateSteps();
  }

  startCustomTour(type){
    this._generateSteps(type);
  }
}
