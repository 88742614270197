<div mat-dialog-title>
    <div class="bg-heading bg-heading-blue">
        <span>Tower Based Benchmark Spend Analysis</span>
        <div>
            <button mat-button (click)="startTour()"><i class="fa-solid fa-question"></i></button>
            <button mat-button mat-dialog-close>
                <i class="icons-image icon-cancel-white" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>
<mat-dialog-content class="mat-typography scroll-window">
    <div *ngIf="displayLoader" class="loader">
        <img src="../../assets/img/dark-loader.gif" style="width: 80%;" alt="" >
        Loading..
    </div>
    <div *ngIf="!displayLoader" class="row bg-heading bg-heading-yellow">
        <div class="col-10"><div class="bg-heading bg-heading-yellow ps-0">{{data.data.tower}} </div></div>
        <div class="col-2">
            <a *ngIf="isValueChanged" style="color: #333333; display: flex;" href="javascript:void(0)" class="btn-reset" (click)="reset()">
                <span matTooltip="Reset">Reset</span>
                <i class="icons-image icon-reset" aria-hidden="true"></i>                
            </a>
        </div>
    </div>
    
    
    <div *ngIf="!displayLoader" class="main-content-wrap tour-detailed-analysis">
        <ag-grid-angular #agGrid class="full-width-grid ag-theme-alpine" [columnDefs]="colDefs"
            [defaultColDef]="defaultColDef" [rowData]="rowData" (gridReady)="onGridReady($event)"
            [rowHeight]="rowHeight" [headerHeight]="headerHeight" [domLayout]="domLayout">
        </ag-grid-angular>
        <app-bar-chart [towerId]="data.data.towerId" [savingsData]="data.savingsData"></app-bar-chart>
    </div>
</mat-dialog-content>