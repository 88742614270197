<div class="plot-wrap" style="" *ngIf="checkIfDataAvailable()">

  <div class="d-flex">
    <div class="price-bar" [ngStyle]="{'width.px': (max - min) * (width/(max - min))}">
      <div class="bar">
        <div class="first"
          [ngStyle]="{
            'left.px': (getRate(25) - min) * (width/(max - min)),
            'width.px': (getRate(50)! - getRate(25)!) * (width/(max - min))}">
        </div>
        <div class="second"
          [ngStyle]="{
            'left.px': (getRate(50) - min) * (width/(max - min)),
            'width.px': (getRate(75)! - getRate(50)!) * (width/(max - min))}">
        </div>
        <div class="priceValue">
        <span class="numbers"
          [ngStyle]="{'left.px': ((getRate(25) - min) * (width/(max - min)))-12}">
          {{formatNumber(getRate(25))}}
        </span>
        <span class="numbers-top"
          [ngStyle]="{'left.px': ((getRate(50) - min) * (width/(max - min)))-5}">
          {{formatNumber(getRate(50))}}
        </span>
        <span class="numbers"
          [ngStyle]="{'right.px': ((max - getRate(75)) * (width/(max - min)))-12}">
          {{formatNumber(getRate(75))}}
        </span>
        </div>
        <div *ngIf="role.inputPlots[locat.id] && role.inputPlots[locat.id][spt.id] " style="width: 100%;height: 25px; position: absolute; z-index: 999;" [title]="role.inputPlots[locat.id][spt.id]"></div>
        <mat-slider
        [title]="role.inputPlots[locat.id][spt.id]"
          *ngIf="role.inputPlots[locat.id] && role.inputPlots[locat.id][spt.id] "
          [color]="getColorForInput(role, locat, spt)"
          [max]="minMaxRoles[locat.id][spt.id]['max']"
          [min]="minMaxRoles[locat.id][spt.id]['min']"
          step="1"       
          [(ngModel)]="role.inputPlots[locat.id][spt.id]"
          aria-labelledby="example-name-label">
      </mat-slider>
      </div>
    </div>
  </div>
</div>