import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataService } from '../data.service';
import * as ApexCharts from 'apexcharts';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { MillionPipe } from '../million.pipe';
import { LoaderService } from '../loader.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SavingsModalComponent } from '../savings-modal/savings-modal.component';
import { environment } from 'src/environments/environment';
import { EncyptDecryptService } from '../encypt-decrypt.service';
import { SavingsAnalysisService } from '../services/savings-analysis.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { formatNumber } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

declare let d3: any;
@Component({
  selector: 'app-savings-analysis-tower',
  templateUrl: './savings-analysis-tower.component.html',
  styleUrls: ['./savings-analysis-tower.component.scss'],
  providers: [MillionPipe]
})
export class SavingsAnalysisTowerComponent implements OnInit, OnDestroy {

  constructor(private dataService: DataService,
    public dialog: MatDialog,
    private millionPipe: MillionPipe,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private encDecService: EncyptDecryptService,
    private savingsService: SavingsAnalysisService) {
  }

  @Input() benchmarkPoint: string = '50';
  @Input() isModal: boolean = false;
  byLocation = 'tower';
  type: string; //"discrete","role";
  spt: number;
  skillFactor: number = 1;
  public chartTower: ApexCharts;
  public chartLocation: ApexCharts;
  towers: any[] = [];
  annualHours: number = 1920;
  locations = [];
  allTowers = [];
  totalSpend = 0;
  totalSavings = 0;
  targetSpend: number = 0;
  liveQuerySubscription = new Subscription();
  ratesMissing = false;
  isCurrent: boolean = false;
  displayLoader: boolean = false;
  errorMessage: string = '';
  loadedPage: string = '';
  tooltipMessage: any = '';
  @Output() calculations = new EventEmitter<any>();


  ngOnInit(): void {
    this.route.params.subscribe(param => {
      this.loadedPage = param['type']
    })
    this.savingsService.getBenchmarkPoint().subscribe(res => {
      this.benchmarkPoint = res;
      this.getCalculatedData();
    })
    this.liveQuerySubscription.add(liveQuery(() => this.getDataFromDB()).subscribe(async (data) => {
      this.towers = data;
      await this.getCalculatedData();
    }));

    this.liveQuerySubscription.add(liveQuery(() => { db.spends.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))

    this.liveQuerySubscription.add(liveQuery(() => { db.input_plot.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))

    this.liveQuerySubscription.add(liveQuery(() => { db.inputs.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))

    this.liveQuerySubscription.add(liveQuery(() => { db.role_filter.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  setBenchmark() {
    this.savingsService.setBenchmarkPoint(this.benchmarkPoint);
  }

  async getCalculatedData() {
    this.displayLoader = true;
    let result = {
      categories: [],
      series: []
    };
    let color = [];
    let targetSpendChartData = await this.getSelectedTowers();
    if (this.type == 'tower' && this.byLocation == 'tower') {
      result = await this.getTowerSpendChartData(targetSpendChartData);
      color = this.getColorArray(this.locations.length, false);
    }
    else if (this.type == 'discrete' && this.byLocation == 'tower') {
      result = await this.getDiscreteData(targetSpendChartData);
      color = this.getColorArray(this.locations.length, true);
    }
    else if (this.type == 'discrete' && this.byLocation == 'location') {
      result = await this.getDiscreteDataByLocation(targetSpendChartData);
      color = this.getColorArray(this.allTowers.length, true);
    }
    else {
      result = await targetSpendChartData;
      color = [...environment.newColorScheme];
    }
    this._checkIfCurrentPresent(result)
    this.calculateTotalSavings();

    if (this.isModal)
      this.createOptions(result, color);
    this.displayLoader = false;
    //this.calculations.emit({targetSpend: this.targetSpend, totalSpend: this.totalSpend, totalSavings: this.totalSavings })
    this.calculations.emit({
      raw: {
        totalSpend: this.totalSpend,
        targetSpend: this.targetSpend,
        totalSavings: this.totalSavings
      },
      totalSpend: this.millionPipe.transform(this.totalSpend), targetSpend: this.millionPipe.transform(this.targetSpend), totalSavings: this.millionPipe.transform(this.totalSavings)
    })
  }

  private _checkIfCurrentPresent(result) {
    let currentGroupPresent = result.series.filter(ser => { return ser.group == 'current' })
    this.isCurrent = (currentGroupPresent.length == 0) ? false : _.flatten(currentGroupPresent.map(cg => { return cg.data })).some(el => el > 0);
  }

  calculateTotalSavings() {
    this.totalSavings = this.totalSpend - this.targetSpend
    //this.totalSavings = this.totalSpend > this.targetSpend ? this.totalSpend - this.targetSpend : 0;
  }

  async getDiscreteData(targetSpendChartData) {
    return await this._getCurrentSpendBreakup(targetSpendChartData)
  }

  private async _getCurrentSpendBreakupNew(targetSpendChartData, breakBy = 'tower') {
    let categoriesMaster = targetSpendChartData.categories;

    let totalSpendArray = [];
    let inputs = (await db.inputs.toArray()).filter(ip => { return ip.tower != null });
    let annualHours = this.annualHours;
    let inputPlots = (await db.input_plot.toArray()).filter(ip => { return ip.tower != null && ip.serviceProvider == this.spt });
    let towers = await db.towers.toArray();
    let locations = await db.locations.toArray();
    towers = _.groupBy(towers, _.iteratee('id'))
    locations = _.groupBy(locations, _.iteratee('id'))
    inputs.forEach((ip: any) => {
      ip['tower'] = towers[ip['tower']][0]['tower']
      ip['locationName'] = locations[ip.location][0]['locationName']
    })
    inputPlots.forEach((ip: any) => {
      ip['tower'] = towers[ip['tower']][0]['tower']
      ip['locationName'] = locations[ip.location][0]['locationName']
    })
    let groupByArray = ['locationName', 'tower'];
    if (breakBy == 'tower') {
      groupByArray = ['tower', 'locationName']
    }
    let inputGrouped = this._deepGroup(inputs, groupByArray)
    let inputPlotrouped = this._deepGroup(inputPlots, groupByArray)
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    _.forEach(inputGrouped, function (value, key) {
      let totalSpend = 0;
      _.forEach(value, function (value2, key2) {
        _.forEach(value2, function (input, key3) {
          try {

            let inputPlot = inputPlotrouped[key][key2][0]['inputValue']
            totalSpend = input.headcount * inputPlot
          } catch (e) { }
        })
        value['totalSpend'] = totalSpend * annualHours
      })

      let currentSpend = { name: `${key} (Your Spend)`, group: 'current', data: range(0, categoriesMaster.length - 1, 1).fill(0) };
      currentSpend.data[categoriesMaster.indexOf(key)] = value.totalSpend;
      targetSpendChartData.series.unshift(currentSpend);
      totalSpendArray.push(value['totalSpend'])
    })

    this.totalSpend = _.sum(totalSpendArray)

    return targetSpendChartData;
  }

  private async _getCurrentSpendBreakup(targetSpendChartData, breakBy = 'tower') {
    let categoriesMaster = targetSpendChartData.categories;
    let discreteTotalSpend = 0;
    let locations = await this.dataService.getDataFromDb('locations', {}, true);
    this.ratesMissing = false;
    let currentBreakup = {};
    let currentlocation = [];
    this.towers.forEach(tower => {
      locations.forEach(location => {
        let parentKey = location.locationName;
        let nestedKey = tower.tower;
        if (breakBy == 'location') {
          parentKey = tower.tower;
          nestedKey = location.locationName;
        }
        if (typeof currentBreakup[parentKey] === 'undefined') {
          currentBreakup[parentKey] = {};

          currentlocation.push(parentKey);
        }
        currentBreakup[parentKey][nestedKey] = { tower: tower.id, location: location.id, location_name: location.locationName, totalSpend: 0 }

        let totalSpend = 0;
        let inputs = tower.inputs.filter(x => x.location == location.id);
        let inputPlots = tower.inputPlots.filter(x => x.serviceProvider == this.spt && x.location == location.id);
        if (inputs.length > 0 && inputPlots.length > 0) {
          inputs = inputs[0];
          inputPlots = inputPlots[0]
          let inputRate = inputPlots.inputValue == '' ? 0 : inputPlots.inputValue;
          totalSpend = totalSpend + (inputs.headcount * inputRate)
          currentBreakup[parentKey][nestedKey]['totalSpend'] = totalSpend * this.annualHours;
          if (inputRate == 0 || inputPlots.length < locations.length)
            this.ratesMissing = true;
        }
        else if (inputPlots.length == 0)
          this.ratesMissing = true;
        let finalspend = totalSpend * this.annualHours;
        discreteTotalSpend += finalspend;
      })
    })
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    currentlocation.forEach(key => {
      let currentSpend = { name: `${key} (Your Spend)`, group: 'current', data: range(0, categoriesMaster.length - 1, 1).fill(0) };
      _.forEach(currentBreakup[key], (spends, key) => {
        currentSpend.data[categoriesMaster.indexOf(key)] = spends.totalSpend
      })
      targetSpendChartData.series.push(currentSpend);
    })
    this.totalSpend = discreteTotalSpend;
    return targetSpendChartData;
  }

  async getDiscreteDataByLocation(targetSpendChartData) {
    return await this._getCurrentSpendBreakup(targetSpendChartData, 'location')
  }

  async getTowerSpendChartData(targetSpendChartData) {
    this.totalSpend = 0;
    this.ratesMissing = false;
    let towerTotalSpend = 0;
    let currentSpend = {
      name: 'Your Spend',
      group: 'current',
      data: []
    };
    this.towers.forEach(tower => {
      let spend = tower.spend;
      if (!spend || spend == 0)
        this.ratesMissing = true;
      currentSpend.data.push(spend);
      this.totalSpend += spend;
    });
    if (currentSpend.data.some(x => x > 0))
      targetSpendChartData.series.splice(0, 0, currentSpend);

    return targetSpendChartData;

  }

  createOptions(data, color) {
    data.series = _.sortBy(data.series, ['group'])
    var optimalColumnWidthPercent = 60;
    if (!this.isCurrent) {
      optimalColumnWidthPercent = 30;
    }
    var options = {
      zoom: {
        enabled: true,
        type: "xy"
      },
      series: data.series,
      chart: {
        type: 'bar',
        height: 365,
        stacked: true,
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        formatter: (val) => this.millionPipe.transform(val)
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: optimalColumnWidthPercent + '%'
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return this.millionPipe.transform(val)
          }
        }
      },
      xaxis: {
        labels: {
          offsetY: 5,
        },
        categories: data.categories
      },
      fill: {
        opacity: 1,
      },
      colors: color,
      legend: this.getLegends(),
      annotations: {
        points: this._getAnnotations(data)
      },
      grid: {
        padding: {
          bottom: 40
        }
      }
    };
    if (this.byLocation == 'tower') {
      if (this.chartTower != undefined)
        this.chartTower.destroy()
      this.chartTower = new ApexCharts(document.querySelector("#savingsTower"), options);
      this.chartTower.render();
    }
    else {
      if (this.chartLocation != undefined)
        this.chartLocation.destroy()
      this.chartLocation = new ApexCharts(document.querySelector("#savingsLocation"), options);
      this.chartLocation.render();
    }
  }


  getColorArray(noOfColorRequired, isCurrentSplit = false) {
    let color = [...environment.newColorScheme].slice(0, noOfColorRequired);
    let newColor = [];
    if (isCurrentSplit) {
      //for discrete we have to duplicate colors for current and benchmark data series
      newColor = [...color, ...color];
    }
    else {
      //for role level we use black color for current data series
      newColor = ['#000', ...color]
    }
    return newColor;

    /*Old Approach*/

    // let colorScheme = color;
    // if (this.isCurrent)
    //   colorScheme.splice(0, 0, '#000');
    // return colorScheme;
  }

  getLegends() {
    let arr = [];
    if (this.byLocation == 'tower')
      arr = this.locations.map(x => x.locationName)
    else
      arr = this.allTowers.map(x => x.tower);
    let color = [...environment.newColorScheme];

    let legend = {
      position: 'top',
      horizontalAlign: 'left',
      showForSingleSeries: true,
      customLegendItems: arr,
      markers: {
        fillColors: [...color.slice(0, arr.length)]
      }
    }
    return legend;
  }

  private _getAnnotationConfig(category, type = 'Your Spend') {
    let options = {
      x: category,
      y: 0,
      marker: {
        size: 0,
        fillColor: "transparent",
        strokeColor: "transparent",
        radius: 0
      },
      label: {
        borderColor: "transparent",
        offsetY: 25,
        offsetX: -50,
        style: {
          color: "#000000DE",
          background: "transparent"
        },
        text: type
      }
    }
    if (type != 'Benchmark Spend') {
      options.label.offsetX = (this.isCurrent) ? 50 : 0;
    }
    return options;
  }

  private _getAnnotations(data) {
    let annotations = [];

    data.categories.forEach(category => {
      if (this.isCurrent)
        annotations.push(this._getAnnotationConfig(category))
      annotations.push(this._getAnnotationConfig(category, 'Benchmark Spend'))
    })
    return annotations;
  }

  async getDataFromDB() {

    this.skillFactor = await this.dataService.getSkillFactor();
    let filter = await this.getFiltersFromDB();
    this.totalSpend = this.type == 'total' ? await this.dataService.getTotalSpend('tower') : 0;

    let allData = await this.dataService.getTowersInputs();
    return allData.filter(tower => tower.isSelected == true);
  }

  async getFiltersFromDB() {
    let filters = await db.role_filter.toArray();
    filters.forEach(f => {
      if (f.type == "blended spend")
        this.type = f.value;
      else if (f.type == "spt")
        this['spt'] = f.value;
    })
    if (this.type == undefined)
      this.type = 'discrete';
    return filters;
  }

  async getSelectedTowers() {
    this.locations = await this.dataService.getDataFromDb('locations', {}, true);
    let inputs = await db.inputs.toArray();
    this.allTowers = await (await db.selection_towers.toArray()).filter(x => x.isSelected == true);
    let towersIds = this.allTowers.map(tower => { return tower['id'] });
    // let rates = await db.towerRates.where('towerId').anyOf(towersIds).toArray();
    let data = await this.dataService.getTowerWithRatesInputPlot();
    ;

    if (this.byLocation == 'tower') {
      let result = await this.calculateTargetSpendByTower(this.locations, this.allTowers, data, inputs, this.benchmarkPoint);
      return result;
    }
    else {
      let result = await this.calculateTargetSpendByLocation(this.locations, this.allTowers, data, inputs, this.benchmarkPoint);
      return result;
    }
  }

  async calculateTargetSpendByTower(locations: any[], towers: any[], rates: any[], inputs: any[], benchmarkPoint: string) {
    let returnData = {
      categories: [],
      series: []
    };
    let series = {};
    let targetSpend = 0;
    await Promise.all(towers.map(async (tower) => {
      let soc = await this.getBenchmarkSOCs(tower.id);
      returnData.categories.push(tower.tower);
      locations.forEach(location => {
        let value = 0;
        let input = inputs.filter(x => x.tower == tower.id && x.location == location.id)[0];
        let towerRates = rates.filter(x => x.id == tower.id)[0]['rateSPT'];

        let locationRate = towerRates[location.id][this.spt][benchmarkPoint];
        if (input != undefined)
          value = value + (input.headcount * locationRate * this.annualHours);

        targetSpend = targetSpend + value;
        if (!series.hasOwnProperty(location.locationName))
          series[location.locationName] = [];
        series[location.locationName].push(Math.round(value));
      })
    }));
    this.targetSpend = targetSpend;

    Object.keys(series).forEach(key => {
      returnData.series.push({ name: `${key} (Benchmark)`, group: 'benchmark', data: series[key] });
    })

    return returnData;

  }

  async calculateTargetSpendByLocation(locations: any[], towers: any[], rates: any[], inputs: any[], benchmarkPoint: string) {
    let returnData = {
      categories: [],
      series: []
    };
    let series = {};
    let targetSpend = 0;
    await Promise.all(locations.map(async (location) => {

      returnData.categories.push(location.locationName);
      await Promise.all(towers.map(async (tower) => {
        let value = 0;
        let soc = await this.getBenchmarkSOCs(tower.id);
        let input = inputs.filter(x => x.tower == tower.id && x.location == location.id)[0];
        let towerRates = rates.filter(x => x.id == tower.id)[0]['rateSPT'];
        let locationRate = towerRates[location.id][this.spt][benchmarkPoint];
        if (input != undefined)
          value = value + (input.headcount * locationRate * this.annualHours);
        targetSpend = targetSpend + value;
        if (!series.hasOwnProperty(tower.tower))
          series[tower.tower] = [];
        series[tower.tower].push(value);
      }))
    }));

    this.targetSpend = targetSpend;

    Object.keys(series).forEach(key => {
      returnData.series.push({ name: `${key} (Benchmark)`, group: 'benchmark', data: series[key] });
    })
    return returnData;
  }

  openSavingsAnalysis() {

    const dialogRef = this.dialog.open(SavingsModalComponent, {
      width: '80%',
      panelClass: 'detail-tower-modal',
      data: {
        benchmarkPoint: this.benchmarkPoint,
        type: 'tower'
      }
    });

    // this.liveQuerySubscription.add(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // );
  }

  async getBenchmarkSOCs(towerId: number) {
    let soc = await db.socs.where('towerid').equals(towerId).first()
    return soc['soc'];
  }

  getRateBySOC(soc: any, locationRate: any, benchmarkPoint: string) {
    let level1 = (soc.level1 * locationRate.level1[benchmarkPoint]) / 100;
    let level2 = (soc.level2 * locationRate.level2[benchmarkPoint]) / 100;
    let level3 = (soc.level3 * locationRate.level3[benchmarkPoint]) / 100;
    let level4 = (soc.level4 * locationRate.level4[benchmarkPoint]) / 100;
    let level5 = (soc.level5 * locationRate.level5[benchmarkPoint]) / 100;

    let rate = (level1 + level2 + level3 + level4 + level5) * this.skillFactor;
    return rate;
  }

  getErrorMessages() {
    setTimeout(() => {
      this._parseResultAndDeriveMessage()
    }, 500)

    // liveQuery(() => this._getFilters()).subscribe(result => {
    //   //this._parseResultAndDeriveMessage(result)
    // })
  }

  private async _parseResultAndDeriveMessage() {
    this.errorMessage = '';
    let result = await db.role_filter.toArray();
    let data = result.filter(r => { return r.type == 'blended spend' })[0]
    let spendData = await db.spends.toArray();
    let inputsData = await db.inputs.toArray();
    let inputGroup = _.groupBy(inputsData, _.iteratee('tower'))
    let inputPlotData = await db.input_plot.toArray();
    let inputPlotGroup = _.groupBy(inputPlotData, _.iteratee('tower'))

    let identifier = '';
    switch (this.loadedPage) {
      case 'role-level':
        identifier = 'roles';
        break;
      case 'blended-rate':
        identifier = 'towers'
        break;
      case 'ru':
        identifier = 'RUs';
        break;
    }

    switch (data.value) {
      case 'total':
        let sp = spendData.filter(sp => { return sp.tower == 0 && sp['spendSelection'] == 'total' });
        if (spendData.length == 0 || sp.length == 0 || sp[0]['spend'] == 0)
          this.errorMessage = 'Please provide total spend.';
        break;

      case 'tower':
        let sprole = spendData.filter(sp => { return sp.role != 0 && sp['spendSelection'] == 'tower' });
        if (spendData.length == 0 || !sprole.every(sp => sp.spend > 0))
          this.errorMessage = `Please provide tower spend for all Towers that have an input headcount.`;
        break;

      case 'discrete':
        if (spendData.length == 0 || Object.keys(inputGroup).length != Object.keys(inputPlotGroup).length || !inputsData.every(inp => inp['headcount'] > 0))
          this.errorMessage = `Please provide your rate for all Towers that have an input headcount.`;
        break;
    }

  }

  calculatePercentage(total, value) {
    let percentage: any = ((value - total) / total) * 100;
    percentage = formatNumber(percentage, "en-US", "2.1-2").replace(',', '');
    if (value > total)
      this.tooltipMessage = `You spend is ${percentage}% higher than the benchmark spend`;
    else
      this.tooltipMessage = `Your spend is ${formatNumber(Math.abs(percentage), "en-US", "2.1-2")}% lower than the benchmark spend`
    return percentage;
    //return (value/ total) *100;
  }

  private _deepGroup = function (seq, keys) {
    if (!keys.length) return seq;
    var first = keys[0];
    var rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => { return this._deepGroup(value, rest) });
  };
}