<h1 class="saveSenarioTitle" mat-dialog-title>Save Scenario</h1>
<form [formGroup]="scenarioForm" (ngSubmit)="saveScenario()">
    <div class="saveSenarioContent" mat-dialog-content>        
        <label class="saveSenarioSelectLabel d-none">Select an option</label>
        <div class="saveSenarioSelection">
            <div class="saveSenarioRadio">
                <mat-radio-group (change)="changeCurrentSelection($event)" formControlName="selectionType">
                    <mat-radio-button value="new">Create new scenario</mat-radio-button>
                    <mat-radio-button value="update">Update exisiting scenario</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="saveSenarioInput">
                <ng-container *ngIf="scenarioForm.get('selectionType').value == 'new'">
                    <mat-form-field   class="saveScenarioInputField" appearance="outline">
                        <mat-label>Scenario name</mat-label>
                        <input matInput formControlName="name">
                        <mat-error *ngIf="(scenarioForm.get('name').errors ? scenarioForm.get('name').errors['required'] : false)"> Provide a scenario name</mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="scenarioForm.get('selectionType').value == 'update'">
                    <mat-form-field  class="saveScenarioSelectField" appearance="outline">
                    <!-- <mat-label>--Select One--</mat-label> -->
                    <mat-select placeholder="Select exisitng scenario" formControlName="scenario" >
                        <mat-option *ngFor=" let scenario of rowData" [value]="scenario">
                            {{scenario.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="(scenarioForm.get('scenario').errors ? scenarioForm.get('scenario').errors['required'] : false)">
                        Please select one scenario</mat-error>
                </mat-form-field>
            </ng-container>
            </div>
            <!-- <p class="saveSenarioInfoText mt-2"  *ngIf="scenarioForm.get('selectionType').value == 'new'"> <img src="../../../assets/image/info.svg">
                 Please enter something that will be meaningful
                </p> -->
            <div mat-dialog-actions class="saveSenariobtn">
                
                <button class="themebg" mat-button color="primary" type="submit" [disabled]="submitButton()">Save</button>
                <button type="button" mat-button (click)="onNoClick()">Cancel</button>
            </div>
        </div>        
    </div>    
</form>