<div class="saved-scenario-section mb-4" *ngIf="rowData$ | async as rowData; else dummyGrid">
    <div class="df-jcsb mb-8">
        <div class="headings">Saved Benchmark Scenario(s) <span class="no-list-items" >{{rowData.length}}</span></div>  
    </div>
    <ag-grid-angular
        class="ag-theme-alpine saved-scenario-table"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        (cellClicked)='onCellClicked($event)'
        [domLayout]="domLayout"
        [rowHeight]="rowHeight"
        [headerHeight]="headerHeight"
        (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>

<ng-template #dummyGrid>
    <div class="df-jcsb mb-8">
        <div class="headings">Saved Benchmark Scenario(s) <span class="no-list-items" >0</span></div>  
    </div>
    <ag-grid-angular
        class="ag-theme-alpine saved-scenario-table"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        [rowHeight]="rowHeight"
        [headerHeight]="headerHeight">
    </ag-grid-angular>
</ng-template>