import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'million' })

export class MillionPipe implements PipeTransform {
    transform(value, decimals = 2): string {
        value = value?.toString().replaceAll(',', '') ?? 0;
        if (value != '' && !isNaN(value)) {
            value = parseFloat(value)
        }
        else
            return '$0.00';

        let isDecimal = value.toString().indexOf('.') > -1;
        let digits = isDecimal ? value.toString().indexOf('.') : value.toString().length;

        if (digits < 4)
            return '$' + (isDecimal ? value.toFixed(decimals) : value);
        else if (digits >= 4 && digits < 7) {
            value = value / 1000;
            return '$' + (value.toFixed(decimals)) + 'K';
        }
        else if (digits >= 7 && digits < 10) {
            value = value / 1000000;
            return '$' + (value.toFixed(decimals)) + 'M';
        }
        else {
            value = value / 1000000000;
            return '$' + (value.toFixed(decimals)) + 'B';
        }

        // if (digits < 4)
        //     return '$' + (isDecimal ? value.toFixed(decimals) : value);
        // else if (digits >= 4 && digits < 7) {
        //     value = value / 1000;
        //     return '$' + (value.toString().indexOf('.') > -1 ? value.toFixed(1) : value) + 'K';
        // }
        // else {
        //     value = value / 1000000;
        //     return '$' + (value.toString().indexOf('.') > -1 ? value.toFixed(1) : value) + 'M';
        // }
    }
}