import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import * as _ from 'lodash';
import { thresholdScott } from 'd3-array';
import { Observable, of, Subscription } from 'rxjs';
import { MessageService } from '../services/Message.service';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { DataService } from '../data.service';

@Component({
  selector: 'app-roles-selector',
  templateUrl: './roles-selector.component.html',
  styleUrls: ['./roles-selector.component.css']
})
export class RolesSelectorComponent implements OnInit, OnDestroy {
  selectedRoles = [];
  constructor(private apiService: ApiService, private ref: ChangeDetectorRef, private messageService: MessageService, private dataService: DataService) { }
  selectedRolesObservable: Subscription;
  selectedLocations = [];
  @Input() finalData: any;
  liveQuerySubscription = new Subscription();
  displayedColumns: string[] = ['role'];

  async getRolesFromDB() {    
    let roles = await this.dataService.getDataFromDb('roles',{},false);
    this.selectedRoles = roles
  }

  async getSelectedLocations() {
    return await db.locations.filter(loc => { return loc.isSelected }).toArray()
  }

  ngOnInit(): void {
    this.getRolesFromDB();
    this.liveQuerySubscription.add(liveQuery(() => this.getSelectedLocations()).subscribe(result => {
      this.selectedLocations = result;
      result.forEach(location => {
        this.displayedColumns.push(location.locationName)
      })
    }));

    this.liveQuerySubscription.add(liveQuery(() => this.getRolesFromDB()).subscribe(roles => {}))
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
    this.selectedRolesObservable.unsubscribe();
  }

  changeSelection(role) {
    this.dataService.updateRecord('roles',role)
  }

}
