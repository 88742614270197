import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { db } from 'src/db';


export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-discrete-filters',
  templateUrl: './discrete-filters.component.html',
  styleUrls: ['./discrete-filters.component.scss']
})
export class DiscreteFiltersComponent implements OnInit {

  constructor() { }
  @Input() filters: any = [];
  @Output() filtersOut = new EventEmitter<any[]>();
  selectedFilters: any = [];
  allComplete: boolean = false;

  ngOnInit(): void {
    this._setExistingFilters();
  }

  private async _setExistingFilters(){
    let result = await db.experience_filter.toArray();
    if(result.length > 0){
      this.selectedFilters = result[0]['experience'];
      this.filtersOut.emit(this.selectedFilters)
    }
  }

  updateAllComplete(e) {
    this._setFilter(e.checked, e.source.value)
    this.allComplete = this.selectedFilters.length == this.filters.length;
  }

  private async _setFilter(checked, value, setAll = false){
    let existingFilters = await db.experience_filter.toArray();
    let records = [];
    if(existingFilters.length > 0){
      records = existingFilters[0]['experience'];
    }
    if(checked){
      records.push(value)
    }
    if(setAll && checked){
      records = this.filters;
    }
    if(setAll && !checked){
      records = [];
    }
    if(!checked){
      records.splice(records.indexOf(value), 1)
    }
    this.selectedFilters = records;
    this.filtersOut.emit(records)
    if(existingFilters.length > 0){
      await db.experience_filter.update(existingFilters[0]['id'], {experience: records})
    }
    else{
      await db.experience_filter.add({experience: records})
    }
  }

  someComplete(): boolean {
    if(this.selectedFilters.length == 0)
      return false;
    return this.selectedFilters.length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    this._setFilter(completed, [], true)
  }

  

}
