<div class="tower-selection">
  <div class="switch switch--horizontal" *ngIf="topCategories.length > 0">
        <input *ngFor="let topcategory of topCategories" id="category_{{topcategory.id}}" type="radio" name="categories" 
        [checked]="topcategory.isChecked" (change)="handleChange(topcategory)">
        <label *ngFor="let topcategory of topCategories" for="category_{{topcategory.id}}">
          {{topcategory.category}} 
        </label>
    <span class="toggle-outside">
      <span class="toggle-inside"></span>
    </span>
  </div>
  <select name="" id="" *ngIf="area" class="form-control">
    <option value="" *ngFor="let item of area">{{ item }}</option>
  </select>
</div>
        <!-- {{topcategory |json}} -->

