<div class="category-main">
  <div class="tower-selection" *ngIf="displayCategoryAndArea">
    <div class="switch switch--horizontal tour-category">
      <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="categoryModel">
        <mat-button-toggle [disableRipple]="true" (click)="selectCategory(c)" *ngFor="let c of category" [value]="c.id"
          [disabled]="!c.isEnabled">
          {{c.category}}</mat-button-toggle>
        <span class="toggle-outside">
          <span class="toggle-inside"></span>
        </span>
      </mat-button-toggle-group>
    </div>
    <div class="right-selections" [ngClass]="isTwoColumn? 'two-cols': ''">
      <div class="custom-select tour-area">
        <mat-form-field *ngIf="areas$ | async as areas">
          <mat-label>Area</mat-label>
          <mat-select (selectionChange)="changeAreaSelection($event)" [(value)]="selectedAreaId" [disabled]="">
            <mat-option *ngFor="let area of areas" [value]="area.id" [disabled]="!area.isEnabled">
              {{area.area}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="skillFactors.length>0" class="custom-toggle-btn tour-skillfactor">
        <mat-button-toggle-group [(ngModel)]="scoreFactor" (change)="onSkillValChange($event.value)"
          aria-label="Font Style">
          <mat-button-toggle *ngFor="let factor of skillFactors" [value]="factor['Value']"
            [matTooltip]="factor['Text'] + ' skill set'">
            {{factor['Text']}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div class="row m0 donut-content scroll-window">
    <div class="col-8 chart-wrap p0">
      <app-tower-sunburst [isModal]="isModal" [size]="donutSize" [variant]="variant"
        *ngIf="donutSize"></app-tower-sunburst>
    </div>
    <div class="col-4 ts-list-wrap p0">
      <app-tower-subtower-list [isModal]="isModal" [isStepCaller]="isStepCaller"
        [variant]="variant"></app-tower-subtower-list>
    </div>
  </div>
</div>