<ng-template #box let-boxdata>
    <div class="row box-wrapper">
        <span class="median">{{formatNumber(role['rateSPT'][locat['id']][spt['id']][50])}}</span>
        <div class="col-6 first">&nbsp;</div>
        <div class="col-6 second">&nbsp;</div>
        <span class="quartile quartile1">{{formatNumber(role['rateSPT'][locat['id']][spt['id']][25])}}</span>
        <span class="quartile quartile2">{{formatNumber(role['rateSPT'][locat['id']][spt['id']][75])}}</span>
    </div>
 </ng-template>

<div class="row">
    <!-- <mat-slider *ngIf="role.inputPlots[locat.id] && role.inputPlots[locat.id][spt.id]"
        max="528"
        min="-5"
        title="{{role.inputPlots[locat.id][spt.id]}}"
        disabled
        [(ngModel)]="role.inputPlots[locat.id][spt.id]"
        step="1">
    </mat-slider>     -->
    <div class="col-4">
        <div *ngIf="canBeDisplayed(1)">
            <ng-template *ngTemplateOutlet="box; context: { $implicit: role }"></ng-template>
        </div>
    </div>
    <div class="col-4">
        <div *ngIf="canBeDisplayed(2)">
            <ng-template *ngTemplateOutlet="box; context: { $implicit: role }"></ng-template>
        </div>
    </div>
    <div class="col-4">
        <div *ngIf="canBeDisplayed(3)">
            <ng-template *ngTemplateOutlet="box; context: { $implicit: role }"></ng-template>
        </div>
    </div>
</div>