import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DataService } from '../data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { db } from 'src/db';
import { DataUpdateService } from '../data-update.service';

@Component({
  selector: 'app-tower-params',
  templateUrl: './tower-params.component.html',
  host: { 'class': 'tower-params-component' },
  styleUrls: ['./tower-params.component.scss']
})
export class TowerParamsComponent implements OnInit {
  params: any;
  towerParamFormGroup: FormGroup;
  userSelections = [];
  constructor(private dataService: DataService, private dataUpdate: DataUpdateService) { }

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.dataService.getTowerParams().then(result => {

      this._transformData(result)
    })
  }

  private async _transformData(params) {
    this.towerParamFormGroup = this.toFormGroup(params)
    this.params = params;
  }

  checkIfParamExist(role, param) {
    return typeof role !== 'undefined';
  }

  toFormGroup(params: any[]): FormGroup {
    const group: any = {};
    _.forEach(params['roleParamGrouped'], (values, key) => {
      let selections = values.filter(x => x.isSelected)
      if (selections.length == 0) {
        selections = values.filter(x => x.isDefault)
      }
      group[this.getFormControlKey(key)] = new FormControl(selections[0]);
    })
    return new FormGroup(group);
  }

  getFormControlKey(strKey) {
    return strKey.toLowerCase().replace(" ", "")
  }

  getControl(roleName, param) {
    let controlName = this.getFormControlKey([roleName, param].join("-"));
    return this.towerParamFormGroup.controls[controlName] as FormControl;
  }

  async onSelectionChanged(event) {
    this.userSelections.push(event)
  }

  async performOperations() {
    this.userSelections.forEach(async (event) => {
      let existingRecord = await db.selection_tower_params.filter(i => {
        return i.parameter == event.value.parameter && i.resourceUnit == event.value.resourceUnit
      }).toArray()
      if (existingRecord.length > 0) {
        return await db.selection_tower_params.delete(existingRecord[0]['id']);
      }
      let record = Object.assign(event.value, { isSelected: true })
      return await db.selection_tower_params.add(record);
    })
    this.dataUpdate.emitDataChangeEvent('towerParamsUpdated')
  }

  async resetSelections() {
    await db.selection_tower_params.clear();
    this.getData();
  }

  getControls() {
    return Object.keys(this.towerParamFormGroup.controls);
  }
}
