import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataUpdateService {
  dataChange: EventEmitter<any> = new EventEmitter();
  constructor() { }

  emitDataChangeEvent(type){
    this.dataChange.emit(type)
  }

  subscribeDataChangeEvent(){
    return this.dataChange;
  }
}