<div class="login-main">
    <div class="form-div">
      <div class="loginpage-logo">
        <img src="assets/img/hex-logo.png" alt="logo">
      </div>
      <!-- loginpage-logo-end -->
  
      <div class="login-form">
        <h2>Forgot Password?</h2>
        <form class="example-form">
            <div class="form-email">
            <mat-label class="label">Email</mat-label>
            <mat-form-field class="example-full-width" appearance="fill">
              <input matInput placeholder="Enter you email" aria-autocomplete="off">
            </mat-form-field>
            </div>
             <!-- form-email-end -->
                   <div class="form-email login-btn res-btn">
                    <a a routerLink="/resetpassword" mat-flat-button>Reset Password</a>
                   </div>
                   <!-- form-email-end -->
          </form>
  
      </div>
      <!-- login-form-end -->
  
      <footer>
        <p>© 2022 Hex Advisory Group</p>
      </footer>
    </div>
    <!-- form-div-end -->
  
  
    <div class="blue-bg-div">
  
    </div>
    <!-- blue-bg-div -->
  </div>
  <!-- login-main-end -->


