import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InteractiveDashboardComponent } from './interactive-dashboard/interactive-dashboard.component';
import { LoginComponent } from './login/login.component';
import { RolesListComponent } from './roles-list/roles-list.component';
import { TowerParamsComponent } from './tower-params/tower-params.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PdfExportViewComponent } from './pdf-export-view/pdf-export-view.component';

const routes: Routes = [
  {path:'', component: DashboardComponent},
  //{ path: 'roles-list', component: RolesListComponent },
  { 
    path:':benchmarkType/step/:type', 
    loadChildren: () => import('./step-wizard/step-wizard.module').then(m => m.StepWizardModule),
    canLoad: [AuthGuard]
  },
  //{path:'step/:type', loadChildren: () => import('./step-wizard/step-wizard.module').then(m => m.StepWizardModule) },
  {path: 'fte/:type', component: InteractiveDashboardComponent, canActivate: [AuthGuard], data: {interactive: true}},
  {path: 'ru/:type', component: InteractiveDashboardComponent, canActivate: [AuthGuard], data: {interactive: true}},
  {path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard]},
  {path: 'tower-params', component: TowerParamsComponent, canActivate: [AuthGuard]},
  {path: 'access-denied', component: AccessDeniedComponent},
  {path: 'pdf_export/:loadedPageType/:type', component: PdfExportViewComponent, canActivate: [AuthGuard], data: {interactive: true}}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
