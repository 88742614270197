import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { ErrorStateMatcher } from '@angular/material/core';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  file: File = null;
  isSubmitted: boolean = false;
  isFileChosen: boolean = false;
  @Output() toggleFeedback = new EventEmitter();
  @ViewChild('fileInput', {static: false})
  InputVar: ElementRef;

  subjects: any[] = [
    "Get in touch with an advisor",
    "Feedback",
    "Technical Issue",
    "Request access for User"
  ]

  constructor(private fb: FormBuilder, private apiService: ApiService, private loaderService: LoaderService) {
    this.feedbackForm = this.fb.group({
      subject: ["", [Validators.required]],
      body: [null, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  toggle(){
    this.toggleFeedback.emit(true)
  }

  submitFeedback() {
    this.isSubmitted = true;    
    this.apiService.sendFeedback({file: this.file, formData: this.feedbackForm.value}).subscribe(data => {
      Swal.fire({
        text: 'Feedback Submitted Successfully',
        timer: 5000,
        icon:'success'
      });
      this.isSubmitted = false;
      this.feedbackForm.setValue({
        subject: '',
        body: null
      })
      this.InputVar.nativeElement.value  = ''
    })
  }


  onFilechange(event:any){
    if(event.target.files[0]['size'] > 1024 * 1024){ //file size is 1mb
      Swal.fire({
         // title: 'Warning!',
          text: 'Please upload file size lesser than 1Mb',
          icon: 'info',
          confirmButtonText: 'Close'
      });
      event.target.value = null
      this.isFileChosen = false;
    }
    else{
      this.file = event.target.files[0];
      this.isFileChosen = true;
    }
  }

  resetFileSelection(){
    this.InputVar.nativeElement.value  = '';
    this.isFileChosen = false;
  }
}
