import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { liveQuery } from 'dexie';
import { DataService } from 'src/app/data.service';
import { db } from 'src/db';
import * as _ from 'lodash';
import { SelectionModel } from '@angular/cdk/collections';
import { thresholdScott } from 'd3';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { LoaderService } from 'src/app/loader.service';
import { DataUpdateService } from 'src/app/data-update.service';
import { Subscription } from 'rxjs';
import { F } from 'chart.js/dist/chunks/helpers.core';
import { MessageService } from 'src/app/services/Message.service';
import Swal from 'sweetalert2';

interface TreeNode {
  name: string;
  id: number;
  children?: TreeNode[];
  isSelected: boolean;
  parentId: number;
  isEnabled: boolean;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  isSelected: boolean;
  parentId: number;
}

@Component({
  selector: 'app-tower-subtower-list',
  templateUrl: './tower-subtower-list.component.html',
  styleUrls: ['./tower-subtower-list.component.scss']
})
export class TowerSubtowerListComponent implements OnInit, OnDestroy {
  @Input() variant?: string;
  @Input() isStepCaller?: boolean = false;
  @Input() isModal?: boolean = false;

  liveQuerySubscription = new Subscription();
  allTowersSelected: boolean = false;

  constructor(private DataUpdate: DataUpdateService, private messageService: MessageService, private loaderService: LoaderService, private apiService: ApiService, private dataService: DataService, private changeDetectorRef: ChangeDetectorRef, private route: ActivatedRoute) {

  }
  getLevel = (node: ExampleFlatNode) => node.level;


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['type'] == 'blended-rate')
        this.getTreeForTowerSubTower(false);
      else
        this.getTreeForTowerSubTower(false);
    })
    this.liveQuerySubscription.add(liveQuery(() => this.getSelectedArea()).subscribe(data => {
      this.getTreeForTowerSubTower();
    }))
    this.DataUpdate.subscribeDataChangeEvent().subscribe(type => {
      if (type == 'towerSubtowerUpdateInternal') {
        this.getTreeForTowerSubTower(false);
      }
    });

    this.messageService.getMessage().subscribe(event => {
      if (event.data == "sunburstSelectionChanged")
        this.updateSelectionAsPerSunburst(event)
    })

  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  async getSelectedArea() {
    return await db.selection_areas.toArray();
  }

  checkTowerSelections() {
    let filteredList = this.treeControl?.dataNodes
    let result = filteredList?.some(node => this.checklistSelection?.isSelected(node)) && !filteredList.every(node => this.checklistSelection?.isSelected(node))
    return result
  }

  setAllTowerAsSelected(event) {
    this.allTowersSelected = event;
    let filteredList = this.treeControl.dataNodes.filter(node => {
      return node.level == 0 && node['isEnabled']
    })
    filteredList.forEach(node => {
      this.setAll(event, node);
    })
  }

  async getTreeForTowerSubTower(getSubTowers = true) {
    var TowerTree = [];
    let category = await this.dataService.getDataFromDb('categories', {}, true);
    let area = await this.dataService.getDataFromDb('areas', { categoryId: category[0]['id'] }, true);
    if (area.length < 1)
      return;

    let towers = await this.dataService.getDataFromDb('towers', { areaId: area[0]['id'] });
    let subtowers = [];
    subtowers = await this.dataService.getDataFromDb('subtowers', {});

    towers.forEach(tower => {
      TowerTree.push({
        name: (this.variant == 'mini') ? `${tower.tower} (${tower.shortCode})` : tower.tower,
        id: tower.id,
        isSelected: tower.isSelected,
        isEnabled: tower.isEnabled,
        parentId: -1,
        children: subtowers.filter(subtower => { return subtower.towerId == tower.id }).map(subt => {
          return {
            name: (this.variant == 'mini') ? `${subt.subTower} (${subt.shortCode})` : subt.subTower,
            id: subt.id,
            isSelected: subt.isSelected,
            parentId: tower.id,
            isEnabled: subt.isEnabled
          }
        })
      });
    })
    this.dataSource.data = TowerTree;
    this._checkFromDb();
    this.DataUpdate.emitDataChangeEvent('towerSubtowerListRefreshed')
  }

  _checkFromDb() {
    this.treeControl.dataNodes.forEach(node => {
      if (node.isSelected) {
        this.checklistSelection.select(node)
      }
    })
    let filteredList = this.treeControl.dataNodes.filter(node => {
      return node.level == 0 && node['isEnabled']
    })
    this.treeControl.expand(filteredList[0])
    this.allTowersSelected = filteredList.every(node => this.checklistSelection.isSelected(node))
  }

  updateSelectionAsPerSunburst(nodeData) {
    let nodeId = nodeData.params.data.id;
    let elemNode = this.treeControl.dataNodes.filter(node => {
      return node['id'] == nodeId
    })
    if (nodeData.params.data.type == 'tower') {
      this.todoItemSelectionToggle(elemNode[0])
      this.setAll(nodeData.params.data.isSelected, elemNode[0])
    }
    if (nodeData.params.data.type == 'subtower') {
      this.updateSelection(nodeData.params.data.isSelected, elemNode[0])
    }
  }

  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      isSelected: node.isSelected,
      children: node.children,
      parentId: node.parentId,
      isEnabled: node.isEnabled
    };
  };

  isNodeDisbled(node) {
    if (this.getParentNode(node)) {
      return !this.getParentNode(node)['isEnabled'];
    }
    return !node.isEnabled
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  checklistSelection = new SelectionModel<ExampleFlatNode>(true);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


  someComplete(node) {
    return false;
  }

  async setAll(completed: boolean, node) {
    let tower = await db.towers.where({ id: node.id }).first();
    let subtowers = await db.subtowers.where({ towerId: node.id }).toArray();
    const descendants = this.treeControl.getDescendants(node);
    if (tower) {
      node.isDeterminate = completed;
      if (completed == false) {
        this.checklistSelection.deselect(...descendants);
        await this.dataService.deleteRecordFromSelection('towers', [tower.id]);
        await this.dataService.deleteRecordFromSelection('subtowers', subtowers.map(st => { return st.id }));
        //this.loaderService.closeDialog()
      } else {
        this.checklistSelection.select(...descendants)
        tower['isSelected'] = completed;
        await this.dataService.updateRecord('towers', tower);
        subtowers.forEach(element => {
          element.isSelected = completed
        });
        await this.dataService.updateBulkRecords('subtowers', subtowers);
        if (!this.isStepCaller) {
          (await this.apiService.getAllRatesAsPerSelections()).subscribe(data => {
            if (this.isModal == false)
              this.DataUpdate.emitDataChangeEvent('towerSubtowerUpdate')
          })
        }
      }
      this.selectUnselectChildElements(node);
      if (this.isModal == false)
        this.DataUpdate.emitDataChangeEvent('towerSubtowerUpdate');
      if (this.isModal)
        this.DataUpdate.emitDataChangeEvent('towerSubtowerUpdateInternal')
    }
    this.checkIfAllNodesSelected()
  }

  selectUnselectChildElements(node) {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  descendantsAllSelected(node): boolean {
    try {
      const descendants = this.treeControl.getDescendants(node);
      const descAllSelected =
        descendants.length > 0 &&
        descendants.every(child => {
          return this.checklistSelection.isSelected(child);
        });
      return descAllSelected;
    } catch {
      return false;
    }

  }

  descendantsPartiallySelected(node): boolean {

    try {
      const descendants = this.treeControl.getDescendants(node);
      const result = descendants.some(child => this.checklistSelection.isSelected(child));
      return result && !this.descendantsAllSelected(node);
    }
    catch (e) {
      return null;
    }
  }

  checkIfSelected(node) {
    return node.isSelected;
  }

  async updateSelection(event, node) {

    let subTower = await db.subtowers.where({ id: node.id }).first()
    let tower = await db.towers.where({ id: node.parentId }).first();
    subTower['isSelected'] = event;
    tower['isSelected'] = event;
    if (event) {
      await this.dataService.updateRecord('towers', tower);
      await this.dataService.updateRecord('subtowers', subTower);
      if (this.variant == 'mini') {
        (await this.apiService.getAllRatesAsPerSelections()).subscribe(data => {
          if (this.isModal == false)
            this.DataUpdate.emitDataChangeEvent('towerSubtowerUpdate')
        })
      }
    } else {
      this.checkForMinimumSelectionCriteria();
      const descendants = this.treeControl.getDescendants(this.getParentNode(node));
      let selectedCount = descendants.filter(child => {
        return child['id'] != node['id'] && this.checklistSelection.isSelected(child);
      });
      if (selectedCount.length == 0)
        await db.selection_towers.delete(tower['id']);
      await db.selection_subtowers.delete(subTower['id']);
      if (this.variant == 'mini')
        (await this.apiService.getAllRatesAsPerSelections()).subscribe(data => {
          if (this.isModal == false)
            this.DataUpdate.emitDataChangeEvent('towerSubtowerUpdate')
        })

      if (this.variant == 'mini' && this.isModal == false)
        this.DataUpdate.emitDataChangeEvent('towerSubtowerUpdate');
    }
    this.todoLeafItemSelectionToggle(node);
    this.checkIfAllNodesSelected();
  }

  checkIfAllNodesSelected() {
    let filteredList = this.treeControl.dataNodes.filter(node => {
      return node.level == 0 && node['isEnabled']
    })
    this.allTowersSelected = filteredList.every(node => this.checklistSelection.isSelected(node))
  }

  todoLeafItemSelectionToggle(node): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  todoItemSelectionToggle(node): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node): void {
    let parent = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node) {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  checkForMinimumSelectionCriteria() {

    if (this.checklistSelection.selected.length == 3) {
      Swal.fire({ icon: 'info', text: 'Please select at least one tower/ Sub-tower' });
      return Promise.reject();
    }
    return Promise.resolve();
  }


}
