
import { HttpMethod } from "@auth0/auth0-angular";
import { commonConfig } from './config';

let apiEndpoint = 'https://hexindexapi.azurewebsites.net/';


export const environment : any = {
  isRUEnabled: true,
  production: false,
  pdfExportService: apiEndpoint + 'api/pdf-service',
  roleRequired: 'HexIndexAdmins',
  trialExpiredMessage: '',
  apiEndpoint: apiEndpoint + 'api/',
  locationLimitEnabled: false,
  ...commonConfig,
  auth0: {
    domain: "iam.syven.in",
    clientId: "23Fqq4l20wL3zdGN7OQOJzGeuOpcElpY",
    audience: "hexindexapi",
    appUri: "http://localhost:4200",
    errorPath: "/error",

    apiUri: apiEndpoint,
    scopes: {
      readuser: 'read:user',
      readadmin: 'read:admin'
    },
    httpInterceptor: { allowedList: commonConfig.getAllowedList(apiEndpoint) },
  }
};
