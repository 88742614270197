class ShoringData {

    /**
     *
     */
    constructor(onShore, offShore) {
        this.onShore = onShore;
        this.offShore = offShore;
    }

    onShore: number;
    offShore: number;
    get total() { return this.onShore + this.offShore }
}


export default ShoringData;