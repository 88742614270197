<div class="access-denied-wrap" *ngIf="show">
  <div class="access-denied-wrap-flex">
    <div class="denied-image" style="width: 144px; height: 144px;">
      <img src="assets/image/access_denied_icon.svg" alt="image" style="width:100%;height: 100%; ">
    </div>
    <div class="denied-content">
      <h2
        style="font-size: 28px; line-height: 40px; font-weight: 800; text-transform: uppercase; color: #2B2B2B; letter-spacing: 0.56px;">
        ACCESS DENIED</h2>
      <p style="font-size: 18px; line-height: 32px; font-weight: 400; color: #555555;" *ngIf="isDemoEnvironment; else prodAccessDenied">
        Your access to HEX Index demo has expired. <span style="display: block;">Contact our <a href="mailto:info@hexadvisory.com?Subject=Request for subscription to HEX Index"
            style="color: #C5AC2E; text-decoration: underline!important;">support team</a> to request for subscription.</span> </p>
      
      <button class="back-button" (click)="goBack()">Go back to Login</button>
    </div>

  </div>
</div>


<ng-template #prodAccessDenied>
  <p style="font-size: 18px; line-height: 32px; font-weight: 400; color: #555555;">
        Your subscription has expired. <span style="display: block;">Contact our <a href="mailto:info@hexadvisory.com?Subject=Request to renew subscription to HEX Index"
            style="color: #C5AC2E; text-decoration: underline!important;">support team</a> to renew your subscription.</span> </p>
</ng-template>