import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-plot-container-ru',
  templateUrl: './box-plot-container-ru.component.html',
  styleUrls: ['./box-plot-container-ru.component.scss']
})
export class BoxPlotContainerRUComponent implements OnInit {

  constructor() { }
  @Input() locat: any;
  @Input() width: any;
  @Input() role: any;
  @Input() spt: any;
  @Input() minMaxRoles = {};
  scale;
  realMin;
  realMax;
  min;
  max;
  ngOnInit(): void {
    this.realMin = this.minMaxRoles[this.locat['id']][this.spt['id']]['min'];
    this.realMax = this.minMaxRoles[this.locat['id']][this.spt['id']]['max'];
    this.min = Math.log10(this.realMin + 5);
    this.max = Math.log10(this.realMax - 5);
    this.scale = this.max - this.min;
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
  }

  getClassForUserValue(role, location, serviceProviderType) {
    let className = '';
    var value = role['rateSPT'][location['id']][serviceProviderType['id']];
    if ('inputPlots' in role) {
      if (role.inputPlots[location.id] != undefined && role.inputPlots[location.id][serviceProviderType.id] != undefined) {
        let userInputValue = role.inputPlots[location.id][serviceProviderType.id];
        // debugger;
        if (userInputValue >= 0 && userInputValue < (((value[50]) * 95) / 100)) {
          className = 'bg-green';
        }
        if (userInputValue >= (((value[50]) * 95) / 100) && userInputValue <= (((value[50]) * 105) / 100)) {
          className = 'bg-amber';
        }
        if (userInputValue > (((value[50]) * 105) / 100)) {
          className = 'bg-red';
        }
      }
    }
    return className;
  }

  checkIfDataAvailable(): any {
    if (this.role != undefined
      && this.locat != undefined
      && this.role['rateSPT']
      && this.locat['id']
      && this.role['rateSPT'][this.locat['id']] != undefined
      && this.role['rateSPT'][this.locat['id']][this.spt['id']])
      return true;
    return false;
  }

  formatNumber(value) {
    if (value.toString().indexOf('.') > -1) {
      if (value < 10) {
        return value.toFixed(1);
      }
      return value.toFixed(0);
    }
    else
      return value;
  }

  calculateScatterPositionOld(): any {
    let input = this.role.inputPlots[this.locat.id][this.spt.id]
    let unitSize = (this.width / (this.max - this.min));

    if (input > this.max)
      input = this.max;
    else if (input < this.min)
      input = this.min;

    let val = (this.max - input) * unitSize;
    if (val >= this.width) val -= 5;
    return val;
  }

  calculateScatterPosition(): any {
    let input = this.role.inputPlots[this.locat.id][this.spt.id]
    let rate25 = this.formatNumber(this.getRate(25));
    let rate50 = this.formatNumber(this.getRate(50));
    let rate75 = this.formatNumber(this.getRate(75));
    let medianposition = this.getMedian();
    let barWidth = this.getWidth();
    let position = -4;
    let originalPosition = (Math.log10(input) - this.min) * (this.width / this.scale);
    if (input <= this.realMin)
      position += 2;
    else if (input >= this.realMax)
      position += this.width + this.getWidth();
    else if (input == rate50)
      position += medianposition;
    else if (input == rate25)
      position += medianposition - barWidth;
    else if (input == rate75)
      position += medianposition + barWidth;
    else if (input > rate25 && input < rate50) {
      position += medianposition - (barWidth / 2);
    }
    else if (input > rate50 && input < rate75) {
      position += medianposition + (barWidth / 2);
    }
    else if (input < rate25) {
      if (originalPosition > (medianposition - barWidth))
        position += originalPosition - barWidth;
      else
        position += originalPosition;
    }
    else if (input > rate75) {
      if (originalPosition < (medianposition + barWidth))
        position += originalPosition + barWidth;
      else
        position += originalPosition;
    }
    return position;
  }

  getRate(benchmarkPoint) {
    return this.role['rateSPT'][this.locat['id']][this.spt['id']][benchmarkPoint];
  }

  getLogRate(benchmarkPoint) {
    let rate = this.role['rateSPT'][this.locat['id']][this.spt['id']][benchmarkPoint];
    return Math.log10(rate);
  }

  getWidth() {
    let rate50 = this.getRate(50);
    if (rate50 < 10)
      return 10;
    else if (rate50 < 100)
      return 12;
    else if (rate50 < 1000)
      return 14;
    else
      return 16;
    // (getLogRate(50)! - getLogRate(25)!) * (width/scale)
  }

  getMedian() {
    let medianValue = (this.getLogRate(50) - this.min) * (this.width / this.scale);
    // let width = this.getWidth();
    // if (medianValue - width > 0 && medianValue + width < this.width+5)
    //   return medianValue;
    // if (medianValue + width+5 >= this.width)
    //   return this.width - width - 5;
    // return width + 5;
    return medianValue;
  }

  getLabelOffset() {
    let rate50 = this.getRate(50);
    if (rate50 < 10)
      return 10;
    else if (rate50 < 100)
      return 10;
    else if (rate50 < 1000)
      return 12;
    else
      return 15;
  }

  getLeftLabelOffset() {
    let rate25 = this.getRate(25);
    if (rate25 < 10)
      return 8;
    else if (rate25 < 100)
      return 10;
    else if (rate25 < 1000)
      return 12;
    else
      return 15;
  }

  getRightLabelOffset() {
    let rate75 = this.getRate(75);
    if (rate75 < 10)
      return 5;
    else if (rate75 < 100)
      return 7;
    else if (rate75 < 1000)
      return 10;
    else
      return 15;
  }

}
