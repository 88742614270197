import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-tower-selections',
  templateUrl: './tower-selections.component.html',
  styleUrls: ['./tower-selections.component.css']
})
export class TowerSelectionsComponent implements OnInit {
  @Input() finalData: any;
  checkModel = {};
  topCategories = [];
  category: number;
  area: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.getRoles().subscribe(roles => {
      this.manipulateData(roles)
    })
  }

  manipulateData(dataFromAPI) {
    this.topCategories = dataFromAPI.map(d => { return { id: d.id, category: d.category, isChecked: d.isSelected == 1 ? true : false } })
  }

  handleChange(e) {
    this.area = e.area;
  }

}
