import { Injectable } from '@angular/core';
import { db, Spends } from 'src/db';
import { Inputs } from 'src/db';
import { DetailedAnalysis } from 'src/db';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})


export class InputService {

  constructor() { }

  async getRoles() {
    return await db.inputs.filter(inputs => {
      return inputs.tower == null;
    });
  }

  async getTowers() {
    return await db.inputs.filter(inputs => {
      return inputs.role == null;
    });
  }

  async getDetailedAnalysis(towerId) {
    return await db.detailed_analysis.where('tower').equals(towerId).toArray()
  }

  private _validateInputData(data) {
    if ('role' in data === false && 'tower' in data === false)
      throw new Error('Either tower or location should be passed');

    if ('role' in data && 'tower' in data)
      throw new Error('Only role either tower can be passed');

    if ('location' in data === false)
      throw new Error('Location should be provided');
  }

  private _validateSpendData(data) {
    if ('role' in data === false && 'tower' in data === false)
      throw new Error('Either tower or location should be passed');

    if ('role' in data && 'tower' in data)
      throw new Error('Only role either tower can be passed');

    if ('spend' in data === false)
      throw new Error('Spend should be provided');
  }

  async _replaceAlreadyPresentItemsAsPerRole(data) {
    let selectedLocations = await db.selection_locations.toArray()

    let role = await db.selection_roles.where('id').equals(data.role).first();
    let offshoreData = await db.offshore.where('tower').equals(role['tower']).toArray();
    let offshoreCount = selectedLocations.filter(x => x['offshore'] == true).length;
    let onshoreCount = selectedLocations.filter(x => x['offshore'] == false).length;
    if (offshoreCount == 0)
      offshoreData[0].onShore = 100;
    else if (onshoreCount == 0)
      offshoreData[0].offShore = 100;

    let records = await db.inputs.where('role').equals(data.role).toArray();

    selectedLocations.forEach(async loc => {
      let isOffshore = loc['offshore'];
      let headCountMultiplier = isOffshore ?
        (offshoreData[0].offShore / (100 * offshoreCount)) :
        (offshoreData[0].onShore / (100 * onshoreCount));
      let rec = records.filter(r => r.location == loc.id);
      let inputObj: any;
      let headcount = Math.round(((data.headcount * headCountMultiplier) + Number.EPSILON) * 100) / 100;
      if (rec.length > 0) {
        inputObj = rec[0];
        await db.inputs.update(inputObj.id, { headcount: headcount, locationSelection: 'split' })
      }
      else {
        inputObj = {
          location: loc.id,
          role: data.role,
          headcount: headcount,
          locationSelection: 'split'
        }
        await db.inputs.add(inputObj);
      }
    })
  }

  async _replaceAlreadyPresentItemsAsPerTower(data) {
    if (isNaN(data.headcount)) {
      return;
    }
    let selectedLocations = await db.selection_locations.toArray()

    let offshoreData = await db.offshore.where('tower').equals(data.tower).toArray();
    let offshoreCount = selectedLocations.filter(x => x['offshore'] == true).length;
    let onshoreCount = selectedLocations.filter(x => x['offshore'] == false).length;
    if (offshoreCount == 0)
      offshoreData[0].onShore = 100;
    else if (onshoreCount == 0)
      offshoreData[0].offShore = 100;

    let records = await db.inputs.where('tower').equals(data.tower).toArray();

    selectedLocations.forEach(async loc => {
      let isOffshore = loc['offshore'];
      let headCountMultiplier = isOffshore ?
        (offshoreData[0].offShore / (100 * offshoreCount)) :
        (offshoreData[0].onShore / (100 * onshoreCount));
      let rec = records.filter(r => r.location == loc.id);
      let inputObj: any;
      let headcount = Math.round(((data.headcount * headCountMultiplier) + Number.EPSILON) * 100) / 100;
      if (rec.length > 0) {
        inputObj = rec[0];
        await db.inputs.update(inputObj.id, { headcount: headcount, locationSelection: 'split' })
      }
      else {
        inputObj = {
          location: loc.id,
          tower: data.tower,
          headcount: headcount,
          locationSelection: 'split'
        }
        await db.inputs.add(inputObj);
      }
      await this.insertDetailedDataAsPerTotal(data.tower, headcount, loc.id);

    })
  }

  async resetDetailedDataByTower(tower: number) {
    let existingAnalysis = await db.detailed_analysis.where('tower').equals(tower).toArray();
    await db.detailed_analysis.bulkDelete(existingAnalysis.map(a => { return a.id }))
    let headcounts = await db.inputs.where('tower').equals(tower).toArray();
    headcounts.forEach(async (headcount) => {
      await this.insertDetailedDataAsPerTotal(headcount.tower, headcount.headcount, headcount.location)
    });
  }

  async insertDetailedDataAsPerTotal(tower: number, headcount: number, location: number) {

    let record = await (await db.detailed_analysis.where('tower').equals(tower).toArray()).filter(x => x.location == location);
    if (record.length == 0 && (!isNaN(headcount) || headcount != 0)) {
      let soc = await db.socs.where('towerid').equals(tower).toArray();
      let newrecord: DetailedAnalysis = { tower: tower, location: location };
      if (soc.length > 0) {
        newrecord.level1 = Math.round((headcount * soc[0]['soc'].level1 * 100 + Number.EPSILON) / 100) / 100;
        newrecord.level2 = Math.round((headcount * soc[0]['soc'].level2 * 100 + Number.EPSILON) / 100) / 100;
        newrecord.level3 = Math.round((headcount * soc[0]['soc'].level3 * 100 + Number.EPSILON) / 100) / 100;
        newrecord.level4 = Math.round((headcount * soc[0]['soc'].level4 * 100 + Number.EPSILON) / 100) / 100;
        newrecord.level5 = Math.round((headcount * soc[0]['soc'].level5 * 100 + Number.EPSILON) / 100) / 100;
        newrecord.total = headcount;
        newrecord.spend = 0;
      }
      await this._upsertValue(newrecord, {}, 'detailed_analysis');
    }
    else if (record.length > 0) {
      if (isNaN(headcount) || headcount == 0)
        await db.detailed_analysis.delete(record[0]['id']);
    }
  }

  async setInputValue(data: Inputs) {
    if (data['location'] == 0) {
      if ('role' in data)
        await this._replaceAlreadyPresentItemsAsPerRole(data)
      else
        await this._replaceAlreadyPresentItemsAsPerTower(data)
    }
    try {
      let headcount = Math.round(((data.headcount) + Number.EPSILON) * 100) / 100;
      data.headcount = headcount;
      if ('tower' in data) {
        await this.insertDetailedDataAsPerTotal(data.tower, data.headcount, data.location);
      }
      await this.insertValuesInInput(data);
    } catch (e) {
      console.error(e)
    }
  }

  async setSpendValue(data: Spends) {
    try {
      await this.insertValuesInSpend(data);
    } catch (e) {
      console.error(e)
    }
  }

  async insertValuesInSpend(data) {
    this._validateSpendData(data);
    if ('id' in data) {
      await this._upsertValue(data, [{ id: data['id'] }], 'spends')
    }
    if ('role' in data && 'id' in data === false) { //role wise operations
      let existingSpends = await (await db.spends.toArray()).filter(input => {
        return input.role == data.role && input.location == data.location;
      })
      await this._upsertValue(data, existingSpends, 'spends')
    }

    if ('tower' in data && 'id' in data === false) { //tower wise operations
      let existingSpends = await (await db.spends.toArray()).filter(input => {
        return input.tower == data.tower;
      })
      await this._upsertValue(data, existingSpends, 'spends')
    }
  }

  async insertValuesInInput(data) {
    this._validateInputData(data);
    if ('id' in data) {
      await this._upsertValue(data, [{ id: data['id'] }])
    }
    if ('role' in data && 'id' in data === false) { //role wise operations
      let existingInputs = await (await db.inputs.where('location').equals(data.location).toArray()).filter(input => {
        return input.role == data.role;
      })
      await this._upsertValue(data, existingInputs)
    }
    if ('tower' in data && 'id' in data === false) { //tower wise operations
      let existingInputs = await (await db.inputs.where('location').equals(data.location).toArray()).filter(input => {
        return input.tower == data.tower;
      })
      await this._upsertValue(data, existingInputs)
    }
  }

  async setDetailedAnalysisValue(data: DetailedAnalysis) {
    try {
      Object.keys(data).forEach(k => {
        let val = data[k].toString();
        if (!isNaN(data[k])) {
          data[k] = Math.round(data[k] * 100) / 100;
        }
        else {
          if (k != 'name')
            data[k] = 0;
        }
        //[+-]?([0-9]*[.])?[0-9]+
      });
      this._validateInputData(data);
      if ('id' in data) {
        await this._upsertValue(data, [{ id: data['id'] }], 'detailed_analysis')
      }
      if ('tower' in data && 'id' in data === false) {
        let existingInputs = await (await db.detailed_analysis.where('tower').equals(data['tower']).toArray()).filter(input => {
          return input.location == data.location;
        })
        await this._upsertValue(data, existingInputs, 'detailed_analysis');
      }
    } catch (e) {
      console.error(e)
    }
  }

  private async _upsertValue(data, existingInputs, table = 'inputs') {
    if (existingInputs.length > 0) { // update record
      try {
        let updatedData = _.merge(existingInputs[0], data);
        await db.table(table).update(existingInputs[0]['id'], updatedData);
      }
      catch (e) { }
    }
    else {
      try {
        await db.table(table).add(data)
      }
      catch (e) { }
    }
  }

  async getInputValuesAsPerSelection() {

  }

  async updateFilterSelection(type, value) {
    let record = (await db.role_filter.toArray()).filter(rf => {
      return rf.type == type
    })
    if (record.length > 0) {
      await db.role_filter.update(record[0].id, { value: value })
    }
    else {
      await db.role_filter.add({ type: type, value: value })
    }
  }

  async updateInputPlotValue(data) {
    try {
      this._validateInputData(data)
      let filter = { location: data.location };
      if ('role' in data)
        filter['role'] = data.role;
      if ('tower' in data)
        filter['tower'] = data.tower;
      filter['serviceProvider'] = data.serviceProvider;
      let record = await db.input_plot.where(filter).toArray();
      if (record.length > 0) {
        if (isNaN(data.inputValue) || data.inputValue == 0)
          await db.input_plot.delete(record[0]['id']);
        else
          await db.input_plot.update(record[0]['id'], data)
      }
      else {
        if (!isNaN(data.inputValue) || data.inputValue != 0)
          await db.input_plot.add(data)
      }
    }
    catch (e) {
      console.error(e)
    }
  }
}
