<!-- <div class="modal-header">
    <h4 class="modal-title pull-left">Select SubTower</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let subtower of subTowers; let i = index;">
            <input class="form-check-input me-1" [(ngModel)]="subtower.isChecked" type="checkbox">
            <label class="form-check-label" for="firstCheckbox">{{subtower.subTower}}</label>
        </li>
    </ul> -->
    <div class="selection" *ngIf="subTowers">
      <div class="bg-heading">
        <div class="title">Select Subtower</div>
        <i class="icon icon-close" aria-hidden="true" (click)="clickButton()"></i>
      </div>
      <ul class="select-options">
        <li *ngFor="let subtower of subTowers; let i = index;">
          <label for="subtower{{subtower.subTower}}">
              <input type="checkbox" id="subtower{{subtower.subTower}}" [(ngModel)]="subtower.isChecked">{{subtower.subTower}}
          </label>
      </li>
      </ul>
    </div>
<!-- </div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">Apply</button>
</div> -->

