import { Component, Input, OnInit } from '@angular/core';
import { db } from 'src/db';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-blended-rate',
  templateUrl: './blended-rate.component.html',
  styleUrls: ['./blended-rate.component.css']
})
export class BlendedRateComponent implements OnInit {
  
  constructor(private apiService: ApiService) { }
  @Input() finalData: any;
  @Input() soc: any;
  data: any;
  apiData: any;
  @Input() onshore: any;

  ngOnInit(): void {
  }
  
}
