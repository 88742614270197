<!-- <div *ngFor="let tower of selectedTowers">
    <app-pyramid-chart [soc]="tower" ></app-pyramid-chart>
</div> -->

<div class="section mt-0">
    <div class="card border-light text-center m-3 align-middle" *ngIf="noData"
        style="height: 100%;align-items: center;display: flex;justify-content: center;">
        <div class="error-text">&#9432; Please select atleast one Tower/Sub-Tower</div>
    </div>
    <mat-tab-group class="tower-analysis-tab" *ngIf="!modal && !pdfExport">
        <mat-tab *ngFor="let tower of selectedTowers" [label]="tower.tower">
            <ng-template matTabContent>
                <app-pyramid-chart-new-1 [soc]="tower"></app-pyramid-chart-new-1>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <app-pyramid-chart-new-1 [modal]="modal" *ngIf="modal" [soc]="selectedTowers[0]"></app-pyramid-chart-new-1>
</div>