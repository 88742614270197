import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TourService } from '../tour.service';

@Component({
  selector: 'app-variance-spread',
  templateUrl: './variance-spread.component.html',
  styleUrls: ['./variance-spread.component.scss']
})
export class VarianceSpreadComponent implements OnInit {

  type: string;
  displayLoader: boolean = true;
  constructor(private tourService: TourService, @Inject(MAT_DIALOG_DATA) public data: { type: string }) { }

  ngOnInit(): void {
    this.type = this.data.type;
  }

  startTour(){
    this.tourService.startCustomTour('variance');

  }

}
