import Dexie, { Table } from 'dexie';
import { environment } from './environments/environment';
import * as CryptoJS from 'crypto-js';
import { NumberFilter } from 'ag-grid-community';
export interface RoleList {
    id?: number;
    role: string;
    subTowerId: number;
    isSelected: boolean;
    type: number;
    data: object;
    area: number;
    tower: number;
    providerType: number;
}

export interface AreaList {
    id?: number;
    area: string;
    categoryId: number;
    locationMappings: number[];
    isSelected: boolean;
    isEnabled: boolean;
    isDefault: boolean;
    type: number;
}

export interface TowerList {
    id?: number;
    tower: string;
    areaId: number;
    isSelected: boolean;
    subtowerCount: number;
    type: number;
}

export interface SubTowerList {
    id?: number;
    subTower: string;
    towerId: number;
    isSelected: boolean;
    type: number;
}

export interface SOCList {
    id?: number;
    towerid: number;
    tower: string;
}

export interface ProviderTypeList {
    id?: number;
    spt: string;
    isSelected: boolean;
}

export interface RegionList {
    id?: number;
    regionName: string;
}

export interface LocationList {
    id?: number;
    locationName: string;
    isSelected: boolean;
    regionId: number;
}

export interface DataSync {
    id?: number;
    isConstantDataSynced: boolean;
}

export interface CategoryList {
    id?: number;
    category: string;
    desc: string;
    isSelected: boolean;
    type: number;
}

export interface OffShoreList {
    id?: number;
    offShore: number;
    onShore: number;
    tower: number;
}

export interface TowerRatesList {
    id?: number;
    towerId: number;
}

export interface SelectionLocationList {
    id?: number;
    locationName: string;
    isSelected: boolean;
    regionId: number;
    isOffshore: boolean;
    offshoreRatio: number;
}

export interface SelectionBenchmarkType {
    id?: number;
    type: number;
    currentBenchmarkData: object;
}

export interface RateList {
    id?: number;
    tower: number;
    subTower: number;
    location: number;
    role: number;
    experience: number;
    serviceProviderType: number;
    benchmarkPoint: number;
    value: string;
    type: number;
    category: number;
    area: number;
}

export interface Inputs {
    id?: number;
    tower?: number;
    role?: number;
    location: number;
    headcount?: number;
}

export interface InputPlot {
    id?: number;
    tower?: number;
    role?: number;
    location: number;
    inputValue: number;
    serviceProvider: number;
}

export interface Spends {
    id?: number;
    tower?: number;
    role?: number;
    spend: number;
    location?: number;
}

export interface DetailedAnalysis {
    id?: number;
    tower: number;
    location: number;
    level1?: number;
    level2?: number;
    level3?: number;
    level4?: number;
    level5?: number;
    total?: number;
    spend?: number;
}

export interface DetailedAnalysisReset {
    id?: number;
    tower: number;
    location: number;
    level1?: number;
    level2?: number;
    level3?: number;
    level4?: number;
    level5?: number;
    total?: number;
    spend?: number;
}

export interface RoleFilter {
    id?: number;
    type: string;
    value: any;
}

export interface DealTowerParams {
    paramId: number;
    paramName: string;
    type: string; // deal or tower
}

export interface DealTowerParamsValues {
    id: number;
    key: string;
    value: number;
    isDefault: boolean;
    dealParamId: number;
}

export interface DealParams{
    id?: number;
    category: number;
    displayText: string;
    parameter: string;
    value: number;
}

export interface TowerParams{
    id?: number;
    category: number;
    displayText: string;
    parameter: string;
    value: number;
    resourceUnit: number;
}

export interface SelectionDealTowerParamsValues {
    id: number;
    key: string;
    value: number;
    isDefault: boolean;
    dealParamId: number;
    isSelected: boolean;

}

export interface ResetScenarioStat {
    id?: number;
    scenarioId: number;
    created: number;
    data: object;
}

export interface StepSelectionStat {
    id?: number;
    data: object;
    created: number;
}

export interface pdfDownload{
    isDownloaded: boolean;
}

export interface experienceFilter{
    experience: string[];
}

export class AppDB extends Dexie {

    roles!: Table<RoleList, number>;
    areas!: Table<AreaList, number>;
    towers!: Table<TowerList, number>;
    subtowers!: Table<SubTowerList, number>;
    socs!: Table<SOCList, number>;
    providerTypes!: Table<ProviderTypeList, number>;
    regions!: Table<RegionList, number>;
    locations!: Table<LocationList, number>;
    dataSync!: Table<DataSync, number>;
    categories!: Table<CategoryList, number>;
    offshore!: Table<OffShoreList, number>;
    towerRates!: Table<TowerRatesList, number>;
    rateList!: Table<RateList, number>;
    inputs!: Table<Inputs, number>;
    input_plot!: Table<InputPlot, number>;
    spends!: Table<Spends, number>;
    detailed_analysis: Table<DetailedAnalysis, number>;
    detailed_analysis_reset: Table<DetailedAnalysisReset, number>; 
    role_filter: Table<RoleFilter, number>;
    deal_tower_params: Table<DealTowerParams, number>;
    deal_tower_params_values: Table<DealTowerParamsValues, number>;
    reset_scenario_stat: Table<ResetScenarioStat, number>;
    step_selection_stat: Table<StepSelectionStat, number>;
    deal_params: Table<DealParams, number>;
    tower_params: Table<TowerParams, number>;
    file_download: Table<pdfDownload, number>;
    experience_filter: Table<experienceFilter, number>;

    /* Update tables */
    selection_roles!: Table<RoleList, number>;
    selection_areas!: Table<AreaList, number>;
    selection_towers!: Table<TowerList, number>;
    selection_subtowers!: Table<SubTowerList, number>;
    selection_socs!: Table<SOCList, number>;
    selection_providerTypes!: Table<ProviderTypeList, number>;
    selection_regions!: Table<RegionList, number>;
    selection_locations!: Table<SelectionLocationList, number>;
    selection_categories!: Table<CategoryList, number>;
    selection_offshore!: Table<OffShoreList, number>;
    selection_towerRates!: Table<TowerRatesList, number>;
    selection_benchmarkType!: Table<SelectionBenchmarkType, number>;
    selection_rateList!: Table<RateList, number>;
    selection_deal_tower_params_values!: Table<SelectionDealTowerParamsValues, number>;
    selection_deal_params: Table<DealParams, number>;
    selection_tower_params: Table<TowerParams, number>;
    /* Ends */

    constructor() {
        super(environment.dbName);
        this.version(environment.indexDbVersion).stores({
            roles: '++id, subTowerId, type, area, tower',
            areas: '++id, categoryId, type, isDefault',
            towers: '++id, areaId, type',
            subtowers: '++id, towerId, type, subTower',
            socs: '++id, towerid, tower',
            providerTypes: '++id',
            regions: '++id',
            locations: '++id, regionId, locationName',
            dataSync: '++id',
            categories: '++id, type',
            offshore: '++id, tower',
            towerRates: '++id, towerId',
            rateList: '++id, role, subTower, location, tower, serviceProviderType',
            inputs: '++id, tower, role, category, location',
            input_plot: '++id, [tower+location+serviceProvider], [role+location+serviceProvider]',
            spends: '++id, tower, role, spend',
            detailed_analysis: '++id, tower, location',
            detailed_analysis_reset: '++id',
            role_filter: '++id, type',
            deal_tower_params: '++paramId, type',
            deal_tower_params_values: '++id, dealParamId',
            reset_scenario_stat: '++id',
            step_selection_stat: '++id',
            deal_params: '++id, category, parameter',
            tower_params: '++id, category, parameter, resourceUnit',
            file_download: '++id',
            experience_filter: '++id',

            selection_roles: '++id, subTowerId, type, area, tower',
            selection_areas: '++id, categoryId, type, isDefault',
            selection_towers: '++id, areaId, type',
            selection_subtowers: '++id, towerId, type',
            selection_socs: '++id, towerid',
            selection_providerTypes: '++id',
            selection_regions: '++id',
            selection_locations: '++id, regionId, locationName',
            selection_categories: '++id, type',
            selection_offshore: '++id, tower',
            selection_towerRates: '++id, towerId',
            selection_benchmarkType: '++id',
            selection_rateList: '++id, role, subTower, location, tower, serviceProviderType',
            selection_deal_tower_params_values: '++id, isSelected, dealParamId',
            selection_deal_params: '++id, category, parameter, displayText',
            selection_tower_params: '++id, category, parameter, resourceUnit'
        })
        .upgrade(tx => {
            return tx.table("categories").toCollection().modify(friend => {
                friend.desc = "";
            });
        });

    }

    populate() {

    }
}

const db = new AppDB();

const decryptData = (data) => {
    try {
        const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
        if (bytes.toString()) {
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return data;
    } catch (e) {
        console.error(e);
    }
}


const decryptObject = (data) => {
    try {
        const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
        if (bytes.toString()) {
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return data;
    } catch (e) {
        console.error(e);
    }
}

db['rateList'].hook('reading', (obj) => {
    obj['intValue'] = parseFloat(decryptData(obj['value']));
    return obj
});

db['towerRates'].hook('reading', (obj) => {
    obj['locationData'] = decryptObject(obj['locationData']);
    return obj;
})

db['socs'].hook('reading', (obj) => {
    obj['soc'] = decryptObject(obj['soc']);
    return obj;
});

// db['deal_tower_params_values'].hook('reading', (obj) => {
//     obj['value'] = decryptData(obj['value']);
//     return obj;
// })

export { db };
