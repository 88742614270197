import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { liveQuery } from 'dexie';
declare let d3v3: any;
declare let topojson: any;
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { AreaList } from 'src/db';

@Component({
  selector: 'app-region-chart',
  templateUrl: './region-chart.component.html',
  styleUrls: ['./region-chart.component.css']
})
export class RegionChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isModal: boolean = false;
  @Input() dashboardView?: boolean = false;
  @Input() variant?: string = '';
  @Input() loadedPageType?: string = '';
  @Input() benchmarkType?: string = '';
  @Input() pdfExport?: boolean = false;
  
  bsModalRef?: BsModalRef;
  regions = [];
  countries = [];
  showCountries: boolean = false;
  svgParent = null;
  chartSelector = 'map';
  regionMapping = {
    'North America': ['CAN', 'MEX', 'USA'],
    'Latin America': ['BRA'],
    'Eastern Europe': ['HUN', 'POL', 'ROU'],
    'Continental Europe': ['FIN', 'FRA', 'DEU', 'NLD', 'NOR', 'SWE', 'CHE', 'GBR','DNK','ITA','ESP'],
    'MENA': ['ARE', 'ZAF', 'EGY', 'JOR', 'IRN', 'ISR', 'OMN', 'QAT', 'KWT', 'LBN', 'BHR', 'QAT', 'YEM', 'SAU'],
    // 'EMEA': ['BEL', 'CHE', 'DEU', 'AUT', 'ESP', 'FRA', 'ATF', 'GBR', 'GGY', 'JEY', 'FLK', 'SGS', 'GRC', 'MLT', 'IRL', 'ITA', 'LUX', 'NLD', 'AND', 'POL', 'PRT', 'TUR', 'CYP', 'CYN', 'MON', 'ALD', 'IMN', 'LTU', 'LVA', 'EST', 'BLR', 'UKR', 'MDA', 'ROU', 'HUN', 'SVK', 'SVN', 'HRV', 'BIH', 'CZE', 'BGR', 'KOS', 'MKD', 'ALB', 'MNE', 'SRB', 'DNK', 'FRO', 'FIN', 'GRL', 'ISL', 'NOR', 'SWE'],
    'ANZ': [ 'NZL','AUS'],
    'Asia': ['CHN', 'IND', 'JPN', 'MYS', 'PHL', 'SGP']
  }
  regionMappingArr = Object.keys(this.regionMapping)
  selectedRegionsAsPerList = {};

  mapSelector = Date.now().toString();
  mapId = this.mapSelector;
  liveQuerySubscription = new Subscription();
  constructor(private dataService: DataService, private apiService: ApiService, private modalService: BsModalService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.liveQuerySubscription.add(liveQuery(() => this.apiService.getRegionsFromDb()).subscribe(result => {
      this.mapDataToD3(result)
      setTimeout(() => {
        if (this.isModal) {
          this.chartSelector = 'mapModal'
        }
        this.drawRegionChart();
        this.liveQuerySubscription.add(liveQuery(() => this.getLocations()).subscribe(locations => {
          this.setAlreadySelectedLocationRegionAsSelected(locations);
        }))
      }, 200)
    }));
    
    
  }

  async getLocations() {
    let area: AreaList[] = await this.dataService.getDataFromDb('areas', {}, true);
    if (area.length > 0) {
      return (await this.dataService.getDataFromDb('locations', {}, true)).filter(e => area[0].locationMappings.includes(e.id));
    }
    return [];
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  ngAfterViewInit() {

  }

  async mapDataToD3(data) {
    this.regions = data
  }

  drawRegionChart() {
    var self = this;
    var selectedRegion = null;
    var chart = d3v3.select(`#${this.chartSelector}`);
    var width = (chart.node().getBoundingClientRect().width) / 1.2;
    if(this.pdfExport){
      width = 800;
    }
    var mapRatio = 0.7;
    var height = width * mapRatio;

    var projection = d3v3['geo'].mercator().scale(width / 2 / Math.PI)
      .rotate([-11, 0])
      .translate([(width) / 2, height * 1.35 / 2])
      .precision(.1);

    var path = d3v3['geo'].path().projection(projection);
    // var div = d3v3.select("body").append("div")
    //   .attr("class", "tooltip")
    //   .style("opacity", 0);

    var svg = d3v3.select(`#${this.chartSelector}`)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin")
      .attr("width", width)
      .attr("height", height)
      .append("g");
    this.svgParent = svg;
    var regionMapping = this.regionMapping;
    var sets = [];

    Object.keys(regionMapping).forEach(k => {
      sets.push({
        name: k,
        set: d3v3.set(regionMapping[k])
      })
    });

    d3v3.json("assets/countries.json", (error, w) => {
      if (error) throw error;

      svg.append("path").datum(topojson.merge(w, w.objects.units.geometries.filter(function (d) {
        return d.id !== 'ATA'; // Sorry Antarctica
      })))
        .attr("class", "border")
        .style("fill", "#E8E8E8")
        .attr("d", path);

      for (var i = 0; i < sets.length; i++) {
        let element = sets[i];
        svg.append("path").datum(topojson.merge(w, w.objects.units.geometries.filter((d) => {
          return element.set.has(d.id);
        })))
          .attr('class', function(){
            return "regions selected "+ (element.name.replace(/ /g, '')).toLowerCase()
          } )
          .attr('id', function(){
            let selector = (element.name.replace(/ /g, '')).toLowerCase();
            self.selectedRegionsAsPerList[selector] = new Set();
            return selector;
          } )
          .attr("d", path)
          .attr({ 'data-name': element.name })
          .on('mouseover', function () {
            d3v3.select(this).attr('stroke','#000')
          }).on('mouseout', function () {
            d3v3.select(this).attr('stroke','')
          }).on('click', function () {
            var region = d3v3.select(this).attr('data-name')
            Array.from(svg[0][0].children).forEach(function (ele: any) {
              ele.classList.remove("selected-region");
            });
            d3v3.select(this).attr('class', "selected-region regions selected")
            self.selectLocation(region);
          })
      }

    });

    function resize() {
      width = document.querySelector(`#map`)['offsetWidth'];
      height = width * mapRatio;

      // update projection
      projection.scale(width / 2 / Math.PI)
        .translate([(width) / 2, height * 1.35 / 2])
        .precision(.1);
      document.querySelector('svg').setAttribute('width', width.toString());
      document.querySelector('svg').setAttribute('height', height.toString());

      // resize the map
      svg.selectAll('.regions, .border').attr('d', path);
    }
  }

  selectLocation(region) {
    this.countries = this.regions.filter(reg => { return reg.regionName == region })[0];
    this.showCountries = true;
  }

  modalClosedEventHandler(value) {
    this.showCountries = value;
  }

  selectLocationAsPerCheckbox(result){
    let selector = result.location.regionId;
    let selectedRegion = this.regions.filter(rg => { return rg.id == selector})
    let elementSelector = selectedRegion[0].regionName.replace(/ /g, '').toLowerCase();
    if(result.event.checked)
      this.selectedRegionsAsPerList[elementSelector].add(result.location.id);
    else
      this.selectedRegionsAsPerList[elementSelector].delete(result.location.id)
    if(this.selectedRegionsAsPerList[elementSelector].size > 0)
      d3v3.select(`.${elementSelector}`).attr('class', "selected-region")
    else
      d3v3.select(`.${elementSelector}`).classed('selected-region', false)
      this.deselectRegion()
  }

  // only used for modal selections
  selectMapRegion(event){
    let selector = event.location.regionId;
    let selectedRegion = this.regions.filter(rg => { return rg.id == selector})
    let elementSelector = selectedRegion[0].regionName.replace(/ /g, '').toLowerCase();
    if(event.selectedOption.checked)
      this.selectedRegionsAsPerList[elementSelector].add(event.location.id);
    else
      this.selectedRegionsAsPerList[elementSelector].delete(event.location.id)
    
    if(this.selectedRegionsAsPerList[elementSelector].size > 0)
      d3v3.select(`#mapModal .${elementSelector}`).classed("selected-region",  true)
    else
      d3v3.select(`#mapModal .${elementSelector}`).classed('selected-region', false)
  }

  setAlreadySelectedLocationRegionAsSelected(locations){
    locations.forEach(location => {
      let selector = location.regionId;
      let selectedRegion = this.regions.filter(rg => { return rg.id == selector})
      let elementSelector = selectedRegion[0].regionName.replace(/ /g, '').toLowerCase();
      this.selectedRegionsAsPerList[elementSelector].add(location['id']);
      
      if(this.selectedRegionsAsPerList[elementSelector].size > 0){
        d3v3.select(`#mapModal .${elementSelector}`).classed("selected-region",  true).style('fill', '#C5AB2E')
        d3v3.select(`#map .${elementSelector}`).classed("selected-region",  true).style('fill', '#C5AB2E')
      }
      else{
        d3v3.select(`#mapModal .${elementSelector}`).classed('selected-region', false)
        d3v3.select(`#map .${elementSelector}`).classed('selected-region', false)
      }
        
    })
    

  }

  deselectRegion() {
    // Array.from(this.svgParent[0][0].children).forEach(function (ele: any) {
    //   ele.classList.remove("selected-region");
    // });
    Object.keys(this.selectedRegionsAsPerList).forEach((key, k) => {
      if(this.selectedRegionsAsPerList[key].size > 0)
        d3v3.select(`#${key}`).attr('class', "selected-region regions selected")
      else
        d3v3.select(`#${key}`).classed('selected-region', false)
    })
    this.countries = [];
  }

  getClassName(region){
    return 'legend-'+ (region.replace(/ /g, '')).toLowerCase()
  }

}
