import { DatePipe, formatNumber } from "@angular/common";
import { HttpMethod } from "@auth0/auth0-angular";
import * as moment from "moment";

const config = {
  _dateFormat: 'dd MMM yyyy',
  _dateTimeFormat: 'dd MMM yyyy HH:mm',
  _decimalFormat: '2.1-4',
  _numberFormat: '1.2-2',
  prodEnvURL: 'https://www.hexindex.io',
  demoEnvURL: 'https://demo.hexindex.io',
  formatDate: (date: string) => {
    date = moment.utc(date).toLocaleString();
    return new DatePipe('en-US').transform(date, config._dateFormat)
  },
  formatDateTime: (date: string) => {
    date = moment.utc(date).toLocaleString();
    return new DatePipe('en-US').transform(date, config._dateTimeFormat)
  },
  formatDecimal: (number: number, format: string = undefined) => {
    return formatNumber(number, 'en-US', format ?? config._decimalFormat)
  },
  formatNumber: (number: any) => {
    if (number && number != '') {
      number = number.toString().replaceAll(',', '');
      return formatNumber(parseFloat(number), 'en-US', config._numberFormat)
    }
    return '0.00';
  },
  formatPercentage: (num: number) => {
    return !num ? '0%' : formatNumber(num, 'en-US', "1.2-2") + '%'
  },
  isNumber: (params: any) => {
    let value = params.newValue.replaceAll(' ', '');
    value = value.replaceAll(',', '');
    if (value == '' || isNaN(value) || parseFloat(value.toString()) < 0)
      return false;
    return true;
  },
  getNumber: (params: any) => {
    let value = params.newValue.replaceAll(' ', '');
    value = value.replaceAll(',', '');
    if (value == '' || isNaN(value) || parseFloat(value.toString()) < 0)
      return 0;
    return value;
  }
};

const commonConfig = {
  getAllowedList,
  indexDbVersion: 25,
  dbName: 'hexIndex_data_v4',
  // globalColorScheme: [
  //   '#1F477B', '#395982', '#536B8A', '#6C7C91', '#868E99', '#A0A0A0', '#979797', '#8E8E8E',
  //   '#858585', '#7C7C7C', '#737373', '#6D6D6D', '#676767', '#616161', '#5B5B5B', '#555555'
  // ],
  globalColorScheme: [
    '#c5ac2e', '#737373', '#fc7a36', '#1f477b', '#55c6aa', '#548cd4',
    '#798da5', '#c8c8c8', '#bfedff', '#ffc0a2', '#f5f0d5', '#dde3e8', '#b3e5d8', '#f4f4f4'
  ],
  sunburstColorSchema: [
    '#F9ECE5', '#CEE4FE', '#F9F4E4', '#E0F9FF', '#EBE6D4', '#C1E4F2', '#CBF3FF', '#ABD7EF',
    '#C4EFE4', '#CDF3FC', '#F2F0D9', '#F8F6E6'
  ],
  chartColorSchemaAltered: [
    '#1F477B', '#868E99', '#858585', '#676767', '#395982', '#A0A0A0', '#7C7C7C', '#616161',
    '#536B8A', '#979797', '#737373', '#5B5B5B', '#6C7C91', '#8E8E8E', '#6D6D6D', '#555555'
  ],
  newColorScheme: ['#737373', '#fc7a36', '#1f477b', '#55c6aa', '#548cd4', '#e1d07d', '#798da5',
    '#c8c8c8', '#bfedff', '#ffc0a2', '#f5f0d5', '#dde3e8', '#b3e5d8', '#f4f4f4'],
  encryptSecretKey: 'sdrg454gfg'
}


export { config, commonConfig }

function getAllowedList(apiEndpoint: string, audience: string = "hexindexapi") {
  return [
    {
      uri: apiEndpoint + 'api/admin/*',
      tokenOptions: {
        audience: audience,
        scope: 'read:admin'
      },
      httpMethod: HttpMethod.Get
    },
    {
      uri: apiEndpoint + 'api/*',
      tokenOptions: {
        audience: audience,
        scope: 'read:user'
      },
      httpMethod: HttpMethod.Get
    },
    {
      uri: apiEndpoint + 'api/*',
      tokenOptions: {
        audience: audience,
        scope: 'read:user'
      },
      httpMethod: HttpMethod.Post
    },
    {
      uri: apiEndpoint + 'api/*',
      tokenOptions: {
        audience: audience,
        scope: 'read:user'
      },
      httpMethod: HttpMethod.Put
    },
    {
      uri: apiEndpoint + 'api/*',
      tokenOptions: {
        audience: audience,
        scope: 'read:user'
      },
      httpMethod: HttpMethod.Delete
    }
  ];
}