<div mat-dialog-title>
  <div class="bg-heading bg-heading-blue">
    <span>Variance Spread </span>
    <div>
      <button mat-button (click)="startTour()"><i class="fa-solid fa-question"></i></button>
      <button mat-button mat-dialog-close>
        <i class="icons-image icon-cancel-white" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography scroll-window pt-3" style="padding: 10px;">
  <span class="error-text">&#9432; &nbsp;Compare the input rate variances for each role (in a specified
    location and
    for the
    selected provider type) to
    the benchmark rates for the corresponding roles, location, and provider type.</span>
  <div class="border rounded" style="min-height: 500px;">
    <div *ngIf="displayLoader" class="loader">
      <img src="../../assets/img/dark-loader.gif" style="width: 80%;" alt="">
      Loading..
    </div>

    <div class="disp-flex">
      <app-scatter-chart-new (dataLoaded)="displayLoader = !displayLoader" [type]="type" style="width: 100%;">
      </app-scatter-chart-new>
    </div>
  </div>
</mat-dialog-content>
