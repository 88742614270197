<div class="container-fluid">
  <div class="row">
    <div class="col-4">
      <div class="card hex-card">
        <div class="img-wrap">
          <div class="user-initials">
            <img class="img-fluid" alt="" *ngIf="auth.user$ | async as user"
              [src]="user.picture ? user.picture : 'assets/img/icon-user.svg'">
          </div>
        </div>
        <div class="other-wrap">
          <div class="user-name" *ngIf="auth.user$ | async as user">{{user.name}}</div>
          <div class="user-last-login" *ngIf="getUserLastLogin | async as lastLogin">Last login: {{lastLogin}}</div>
        </div>
        <i class="icons-image icon-bg-user" aria-hidden="true"></i>
      </div>
    </div>
    <div class="col-4">
      <!-- <div class="card hex-card pointer tour-ftebox" (click)="checkClick(1)" (mouseover)="tooltip.show()" (mouseout)="tooltip.hide()" matTooltip="Info about the action"> -->
      <div class="card hex-card pointer tour-ftebox" (click)="checkClick(1)">
        <div class="img-wrap">
          <i matTooltip="Open recent FTE benchmark scenario" class="icons-image icon-fte-benchmark-yellow"></i>
        </div>
        <div class="other-wrap">
          <div class="user-name">FTE Benchmark</div>
          <div class="user-last-login">Benchmark data for FTE based pricing</div>
        </div>
        <button matTooltip="Create new FTE benchmark scenario" aria-label="New Benchmark" mat-icon-button
          [matMenuTriggerFor]="menu" aria-label="New Benchmark" class="img-wrap btn-add"
          (click)="$event.stopPropagation()" [disabled]="this.loading">
          <i class="icons-image icon-add-white" aria-hidden="true"></i>
        </button>
        <!-- <button #tooltip="matTooltip" matTooltip="Info about the action from button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="New Benchmark" class="img-wrap btn-add" (click)="$event.stopPropagation()" [disabled]="this.loading">
            <i class="icons-image icon-add-white" aria-hidden="true"></i>
        </button> -->
        <mat-menu #menu="matMenu" class="toggle-benchmark-type">
          <button mat-menu-item (click)="selectedBenchmarkType(1,'role-level')">
            <span>Discrete Role Benchmarking</span>
          </button>
          <button mat-menu-item (click)="selectedBenchmarkType(1,'blended-rate')">
            <span>Blended Rate Benchmarking</span>
          </button>
        </mat-menu>
        <i class="icons-image icon-bg-fte" aria-hidden="true"></i>
      </div>
    </div>
    <div class="col-4 tour-rubox">
      <div class="card hex-card pointer position-relative ruBenchmarkCard" (click)="checkClick(2)">
        <div class="ruOverlay" *ngIf="!isRUEnabled">
          <span>RU Benchmarking</span>
        </div>
        <a href="javascript:void(0)" class="img-wrap">
          <i matTooltip="Open recent RU benchmark scenario" class="icons-image icon-ru-benchmark-yellow"></i>
        </a>
        <div class="other-wrap">
          <div class="user-name">RU Benchmark</div>
          <div class="user-last-login">Benchmark data for Resource Unit based pricing</div>
        </div>
        <button mat-icon-button matTooltip="Create new RU benchmark scenario" [matMenuTriggerFor]="menu2"
          aria-label="New Benchmark" class="img-wrap btn-add" (click)="$event.stopPropagation()"
          [disabled]="this.loading">
          <i class="icons-image icon-add-white" aria-hidden="true"></i>
        </button>
        <mat-menu #menu2="matMenu" class="toggle-benchmark-type">
          <button mat-menu-item (click)="selectedBenchmarkType(2,'')">
            <span>RU Rate Benchmarking</span>
          </button>
        </mat-menu>
        <i class="icons-image icon-bg-ru" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="row pt-15">
    <div class="col-5">
      <app-list-scenario></app-list-scenario>
      <app-recent-downloads></app-recent-downloads>
    </div>
    <div class="col-7">

      <carousel [isAnimated]="true" class="dashboard-carousel dashboard-carousel1">
        <slide *ngFor="let slide of slides1; let index = index">
          <div class="blue-bg-div dashboard-bg position-relative">
            <div class="banner-title">
              <div class="banner-count">{{ slide.title }}</div>
              <div class="banner-title-text">
                <span class="title-text-light">{{ slide.title_2 }}</span>
                <span class="title-highlight">{{ slide.title_3 }}</span>
                <span class="title-services">{{ slide.title_4 }}</span>
              </div>
            </div>
            <div class="banner-text">{{slide.text}}
            </div>
            <a class="banner-arrow-btn" href="{{slide.url}}" target="_blank">
              <img src="../../assets/image/banner-arrow-btn.svg" alt="">
            </a>
          </div>
        </slide>
      </carousel>

      <div class="row">
        <div class="col-5" *ngIf="hasAccessToHandbooks">
          <div class="brand-card">
            <div class="brand-cart-header">
              <img class="mt-1" src="../../assets/img/HEX-INDEX-TM.svg" alt="">
              <span class="adv-handbook-title mt-2">ADVISORY HANDBOOK</span>
            </div>
            <div class="brand-card-container">
              <ul *ngIf="entitlements">
                <li *ngIf="entitlementsFromApi?.itoPdf">
                  <span class="mb-3">Information Technology Outsourcing<br><span class="fw-bold">(ITO)</span></span>
                  <span (click)="download('ItoPdf')"><img
                      matTooltip="Download HEX Index&trade; Advisory Handbook on Information Technology Outsourcing"
                      src="../../assets/img/pdf_download.svg" alt=""></span>
                  <!-- <span *ngIf="isDemoEnvironment" (click)="openDemoSwal()"><img src="../../assets/img/pdf_download.svg" alt=""></span> -->
                </li>
                <li *ngIf="entitlementsFromApi?.bpoPdf">
                  <span class="mb-3">Business Process Outsourcing<br><span class="fw-bold">(BPO)</span></span>
                  <span (click)="download('BpoPdf')"><img
                      matTooltip="Download HEX Index&trade; Advisory Handbook on Business Process Outsourcing"
                      src="../../assets/img/pdf_download.svg" alt=""></span>
                  <!-- <span *ngIf="isDemoEnvironment" (click)="openDemoSwal()"><img src="../../assets/img/pdf_download.svg" alt=""></span> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="col-5">
            <div class="brand-card">
              <div class="brand-card-container">
                <i class="icons-image icon-brand-logo mb-1" aria-hidden="true"></i>
                <span class="adv-handbook-title mt-1">ADVISORY HANDBOOK</span>
                <span class="adv-handbook-download mb-1">Download</span>
                <img src="../../assets/image/handbook.svg" alt="">
                 <ul>
                  <li>BPO HEX IndexTM Product Brief</li>
                  <li>ITO HEX IndexTM Product Brief</li>
                  <li>Cost HEX IndexTM Product Brief</li>
                </ul> 
              </div>
            </div>
          </div> -->
        <div [ngClass]="{'col-12': !hasAccessToHandbooks, 'col-7': hasAccessToHandbooks}">
          <div class="benchmark">
            <carousel [isAnimated]="true" class="dashboard-carousel">
              <slide *ngFor="let slide of slides; let index = index" style="min-height:175px">
                <!-- <div class="dashboard-slider-bg"> -->
                <div class="carousel-center py-1">
                  <div class="title">{{ slide.title }}</div>
                  <div class="desc">{{ slide.text }}</div>
                  <a class="theme-btn theme-bg" href="{{slide.url}}" target="_blank">Read more...</a>
                </div>

                <!-- </div> -->
              </slide>
            </carousel>
            <!-- <div class="title">Accurate Benchmarks</div>
              <div class="desc">HEX Index is the foremost tool for accurate benchmarks in the sourcing industry. This tool is tightly curated by senior sourcing advisors and provides only contemporary benchmark data from IT, Business Process, and Global Capability Center transactions – no research, survey, or massaged secondary data...</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>