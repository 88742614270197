<div class="section">
    <div class="chart-wrap">
        <div class="row m0">
            <div class="col-5">
                <svg id="towerSpend"></svg>
            </div>
            <div class="col-7 plr-7">
                <div class="switchs-wrap">
                    <div class="switch switch--horizontal percentile-switch">
                        <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="benchmarkPoint" (change)="drawChart()">
                            <mat-button-toggle [disableRipple]="true" value="25">25%ile</mat-button-toggle>
                            <mat-button-toggle [disableRipple]="true" value="50">Median</mat-button-toggle>
                            <mat-button-toggle [disableRipple]="true" value="75">75%ile</mat-button-toggle>
                            <span class="toggle-outside">
                                <span class="toggle-inside"></span>
                            </span>
                        </mat-button-toggle-group>
                    </div>
                    <div class="switch switch--horizontal tower-location-switch">
                        <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="byLocation" (change)="drawChart()">
                          <mat-button-toggle value='tower' [disableRipple]="true">Tower</mat-button-toggle>
                          <mat-button-toggle value='location' [disableRipple]="true">Location</mat-button-toggle>
                          <span class="toggle-outside">
                            <span class="toggle-inside"></span>
                          </span>
                        </mat-button-toggle-group>
                      </div>
                </div>
                <div class="target-spent-label">Benchmark Spend</div>
                <div class="target-spent-value">{{targetSpend | million}}</div>
            </div>
        </div>
    </div>
</div>