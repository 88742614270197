import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ApiService } from '../api.service';
import annotationPlugin from 'chartjs-plugin-annotation';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
@Component({
  selector: 'app-scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.css']
})

export class ScatterChartComponent implements OnInit {
  @Input() type: string;
  public chart: Chart;
  skillFactor: number = 1;
  colorArray: any[] = ['#1F477B', '#6C7C91', '#A0A0A0', '#858585', '#616161',
    '#1F477B', '#6C7C91', '#A0A0A0', '#858585', '#616161',
    '#1F477B', '#6C7C91', '#A0A0A0', '#858585', '#616161',];

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dataService: DataService) {
    Chart.register(...registerables, annotationPlugin);
    Chart.defaults.font.family = "Raleway";
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if ('type' in params) {
        switch (params['type']) {
          case 'role-level':
            liveQuery(() => this.getRoles()).subscribe(async (data) => {
              let result = await this.getChartData(data);
              if (this.chart != undefined) {
                this.chart.data.datasets = result;
                this.chart.update();
              }
              else
                this.createChart(result);
            })

            break;
          case 'blended-rate':
            liveQuery(() => this.getTowers()).subscribe(async (data: any) => {
              let result = await this.getChartData(data);
              if (this.chart != undefined) {
                this.chart.data.datasets = result;
                this.chart.update();
              }
              else
                this.createChart(result);
            })
            break;
        }
      }
    })
  }

  async getChartData(data: any[]) {

    const selectedLocations =  await this.dataService.getDataFromDb('locations', {}, true);
    let locationIds = selectedLocations.map(loc => { return loc['id'] });
    let dataset = [];

    let locationDataset = {};
    selectedLocations.forEach(loc => {
      locationDataset[loc.id] = [];
    })

    const groupData = {};
    data.forEach(d => {
      let inputs = d.inputs;
      let rates = d.rates;
      Object.keys(inputs).forEach(location => {
        let rate = rates.filter(rate => rate['location'] == location && rate['benchmarkPoint'] == 50)[0]['value'] * this.skillFactor;
        let variance = ((inputs[location] - rate) / rate) * 100

        let dif = variance > 0 ? 1 : -1;
        let key = Math.trunc(variance / 5) + dif;
        if (!(key in groupData))
          groupData[key] = [];
        groupData[key].push(location);
        locationDataset[location].push({ x: variance, y: groupData[key].length });
      })
    })

    selectedLocations.forEach(loc => {
      dataset.push({
        label: loc.locationName,
        data: locationDataset[loc.id],
        backgroundColor: this.colorArray[dataset.length]
      })
    })

    return dataset;
  }

  async getRoles() {
    return await this.dataService.getRolesWithRatesInputPlot()
  }

  async getTowers() {
    return await this.dataService.getTowerWithRatesInputPlot();
  }

  async getSelectedTowers() {

    const selectedLocations =  await this.dataService.getDataFromDb('locations', {}, true);
    let locationIds = selectedLocations.map(loc => { return loc['id'] });
    let inputs = await db.input_plot.toArray();
    let rates = await db.rateList.where('location').anyOf(locationIds).toArray();
    let dataset = [];
    let locationDataset = {};
    selectedLocations.forEach(loc => {
      locationDataset[loc.id] = [];
    })

    const groupData = {};
    inputs.forEach(input => {
      let rate = parseFloat(rates.filter(rate => rate['role'] == input['role'] && rate['location'] == input['location'] && rate['benchmarkPoint'] == 50)[0]['intValue']);
      let variance = ((input['inputValue'] - rate) / rate) * 100

      let dif = variance > 0 ? 1 : -1;
      let key = Math.trunc(variance / 5) + dif;
      if (!(key in groupData))
        groupData[key] = [];
      groupData[key].push(input);
      locationDataset[input.location].push({ x: variance, y: groupData[key].length });
    })

    selectedLocations.forEach(loc => {
      dataset.push({
        label: loc.locationName,
        data: locationDataset[loc.id],
        backgroundColor: this.colorArray[dataset.length]
      })
    })

    return dataset;
  }

  createChart(result: any[]) {
    this.chart = new Chart("MyChart", {
      type: 'scatter', //this denotes the type of chart
      data: {
        datasets: result
      },
      options: {
        aspectRatio: 4,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true,
              boxWidth: 5,
              boxHeight: 5
            }
          }
        },
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return value + "%";
              }
            },
            grid: {
              display: false,
            }
          },
          y: {
            display: false,
            max: 17,
            min: 0,
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    });
  }

}
