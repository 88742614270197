<ng-container>
    <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
        <header class="header">
            <nav>
                <div class="header-logo">
                    <div class="hamburger bar-menu" (click)="toggleSidebar()">
                        <button mat-stroked-button>
                            <i class="icons-image icon-menu" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="logo">
                        <a [routerLink]="['/']">
                            <img alt="" src="assets/img/Hex_Indextm_Logo_horizontal.svg" height="32">
                        </a>
                    </div>
                </div>
                <div *ngIf="isDemoEnvironment" style="
                    color: #fff;
                    font-size: 18px;
                    text-shadow: 1px 2px 1px #000;
                    ">
                    * Demo Environment *
                </div>
                <div class="header-icon">
                    <ul>
                        <li class="user-icon">
                            <!-- {{user['clientname']}} -->
                            <!-- <button mat-raised-button (click)="adjustZoom()" color="primary">Adjust zoom</button>&nbsp; -->
                            <span class="user-name pe-0" *ngIf="clientName && clientURL == ''">{{clientName}} &nbsp;|&nbsp; </span>
                            <a *ngIf="clientURL != '' && clientName" href="{{clientURL}}" class="user-name pe-0" target="_blank" >{{clientName}} &nbsp;|&nbsp; </a>
                            <span class="user-name"
                                *ngIf="auth.user$ | async as user">{{user.name}}</span>
                            <button mat-button [matMenuTriggerFor]="menu" class="user-icon-button">
                                <img class="icons-image icon-user" alt="" *ngIf="auth.user$ | async as user"
                                    [src]="user.picture ? user.picture : 'assets/img/icon-user.svg'">
                            </button>
                            <div class="user-div">
                                <mat-menu #menu="matMenu" class="user-menu">
                                    <button mat-menu-item><a href="javascript:void(0)" (click)="takeATour()">Take a
                                            Tour</a></button>
                                    <!-- <button mat-menu-item><a href="javascript:void(0)" (click)="helpSupport()">Help &
                                            Support</a></button> -->
                                    <button mat-menu-item><a href="javascript:void(0)" (click)="feedback()">Support &
                                            Feedback</a></button>
                                    <!-- <button mat-menu-item><a href="javascript:void(0)" (click)="whatsNew()">What's
                                            New</a></button> -->
                                    <button *ngIf="entitlementsFromApi?.itoExcel" mat-menu-item><span (click)="download('ItoExcel')">Download ITO HEX Index</span></button>

                                    <button *ngIf="entitlementsFromApi?.bpoExcel" mat-menu-item><span (click)="download('BpoExcel')">Download BPO HEX Index</span></button>

                                    <button mat-menu-item *ngIf="!isDemoEnvironment && !production"><a
                                            href="javascript:void(0)" (click)="refreshIndexedDB()">Refresh
                                            DB</a></button>

                                    <button mat-menu-item id="logout-btn" (click)="logout()">
                                        <a href="javascript:void(0)">
                                            Log out
                                            <i class="icons-image icon-logout" aria-hidden="true"></i>
                                        </a>
                                    </button>
                                </mat-menu>
                            </div>
                        </li>
                        <li class="notifi-icon">
                            <button mat-button class="toggle-not">
                                <i class="icons-image icon-notification" aria-hidden="true"></i>
                            </button>
                            <!-- <div class="notific-div">
                                <mat-tab-group mat-align-tabs="start">
                                    <mat-tab label="Required Actions">Content 1</mat-tab>
                                    <mat-tab label="Notifications">
                                        <div class="notific-div-tab1-ul">
                                            <div class="img"> 
                                                <img src="./assets/img/circle1.svg">
                                                <img src="./assets/img/Ellipse-green.svg" class="green-img">
                                            </div>
                                            <div class="notific-div-tab1-content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    <br>
                                                    <span>1m ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="notific-div-tab1-ul">
                                            <div class="img"> <img src="./assets/img/circle1.svg"></div>
                                            <div class="notific-div-tab1-content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    <br>
                                                    <span>25m ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="notific-div-tab1-ul">
                                            <div class="img"> 
                                                <img src="./assets/img/circle1.svg">
                                            </div>
                                            <div class="notific-div-tab1-content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    <br>
                                                    <span>32m ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="notific-div-tab1-ul" style="margin-bottom: 10px;">
                                            <div class="img"> 
                                                <img src="./assets/img/circle1.svg">
                                            </div>
                                            <div class="notific-div-tab1-content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    <br>
                                                    <span>1h ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div> -->
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <div class="sidebar" *ngIf="!accessDenied" [ngClass]="isSidebarOpen? '': 'close'">
            <ul class="nav-links">
                <li class="nav-items">
                    <div class="dashboard-white item-fte tour-dashboard">
                        <a href="javascript:void(0)" href="/" [ngClass]="{'active-link': selectedBenchmark == null }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                            <span class="img-span">
                                <span class="icons-image icon-dashboard-white"></span>
                                <!-- <span class="icons-image icon-dashboard-yellow"></span> -->
                            </span>
                            <span class="link_name">Dashboard</span>
                        </a>
                    </div>
                </li>
                <li class="nav-items">
                    <div class="dashboard-white item-fte fte-tour">
                        <a href="javascript:void(0)" [ngClass]="{'active-link': selectedBenchmark == 'fte'}">
                            <span class="img-span">
                                <span class="icons-image icon-fte-benchmark-white"></span>
                                <!-- <span class="icons-image icon-fte-benchmark-yellow"></span> -->
                            </span>
                            <span class="link_name">
                                <ul>
                                    <li><a href="javascript:void(0)" class="link_name">FTE Benchmarking</a>
                                        <ul>
                                            <li><a class="link_name"
                                                    (click)="selectedBenchmarkType(1,'role-level', 'left-navbar')">&nbsp;
                                                    - Discrete
                                                    Role Benchmarking</a>
                                            </li>
                                            <li><a class="link_name"
                                                    (click)="selectedBenchmarkType(1,'blended-rate', 'left-navbar')">&nbsp;
                                                    - Blended
                                                    Rate Benchmarking</a>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                            </span>
                        </a>
                    </div>
                </li>
                <li class="nav-items" *ngIf="isRUEnabled">
                    <div class="dashboard-white item-fte ru-tour">
                        <!-- <a href="javascript:void(0)" (click)="selectedBenchmarkType(2,'ru')" routerLink="ru/step/ru" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"> -->
                        <a href="javascript:void(0)" [ngClass]="{'active-link': selectedBenchmark == 'ru'}">
                            <span class="img-span">
                                <span class="icons-image icon-ru-benchmark-white"></span>
                                <!-- <span class="icons-image icon-dashboard-yellow"></span> -->
                            </span>
                            <span class="link_name disabled" (click)="selectedBenchmarkType(2,'','left-navbar')">RU
                                Benchmarking </span>
                            <!-- routerLink="ru/step/ru" (click)="selectedBenchmarkType(2,'')" -->
                        </a>
                    </div>
                </li>

            </ul>
        </div>
        <div class="custom-overlay" *ngIf="showTour">
            <div class="tour-wrap dashboard-tour" *ngIf="showdashboard">
                <i class="icons-image icon-dashboard-yellow"></i>
                <div class="tour">
                    <div class="heading">Dashboard</div>
                    <div class="tour-desc">Explore our services and check your saved reports</div>
                    <div class="bg-heading">
                        <div class="ins-text">1/6</div>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="fteBenchmarking()">Next</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-1" (click)="takeATour()">Skip</button>
                    </div>
                </div>
            </div>
            <div class="tour-wrap fte-tour" *ngIf="showFte">
                <i class="icons-image icon-fte-benchmark-yellow"></i>
                <div class="tour">
                    <div class="heading">FTE Benchmarking</div>
                    <div class="tour-desc">Benchmark your hourly FTE rates</div>
                    <div class="bg-heading">
                        <div class="ins-text">2/6</div>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="dashboard()">Prev</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="ruBenchmarking()">Next</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-1" (click)="takeATour()">Skip</button>
                    </div>
                </div>
            </div>
            <div class="tour-wrap ru-tour" *ngIf="showRu">
                <i class="icons-image icon-ru-benchmark-yellow"></i>
                <div class="tour">
                    <div class="heading">RU Benchmarking</div>
                    <div class="tour-desc">Benchmark your resource unit</div>
                    <div class="bg-heading">
                        <div class="ins-text">3/6</div>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="fteBenchmarking()">Prev</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="fteTile()">Next</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-1" (click)="takeATour()">Skip</button>
                    </div>
                </div>
            </div>
            <div class="tour-wrap fte-tile-tour" *ngIf="showFteTile">
                <img src="../assets/img/fte-tile.svg" alt="fte-tile">
                <div class="tour">
                    <div class="heading">Options for FTE Benchmarking </div>
                    <div class="tour-desc">Available options for FTE Benchmarking</div>
                    <div class="bg-heading">
                        <div class="ins-text">4/6</div>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="ruBenchmarking()">Prev</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="ruTile()">Next</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-1" (click)="takeATour()">Skip</button>
                    </div>
                </div>
            </div>
            <div class="tour-wrap ru-tile-tour" *ngIf="showRuTile">
                <div class="tour">
                    <div class="heading">Resource Unit Benchmarking </div>
                    <div class="tour-desc">Benchmark your resource unit</div>
                    <div class="bg-heading">
                        <div class="ins-text">5/6</div>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="fteTile()">Prev</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="downloadTour()">Next</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-1" (click)="takeATour()">Skip</button>
                    </div>
                </div>
                <img src="../assets/img/ru-tile.svg" alt="ru-tile">
            </div>
            <div class="tour-wrap download-tour" *ngIf="showDownload">
                <img src="../assets/img/download.svg" alt="download">
                <div class="tour">
                    <div class="heading">Download Reports </div>
                    <div class="tour-desc">Download your saved reports here</div>
                    <div class="bg-heading">
                        <div class="ins-text">6/6</div>
                        <button class="cbtn-primary cbtn-secondary-2 ms-auto" (click)="ruTile()">Prev</button>
                        <button class="cbtn-primary cbtn-secondary-2 ms-1" (click)="takeATour()">Done</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-overlay" *ngIf="showFeedback">
            <app-feedback (toggleFeedback)="feedback()"></app-feedback>
        </div>
        <div class="custom-overlay" *ngIf="showHelp">
            <div class="help-section">
                <div class="bg-heading bg-heading-gray">
                    Help & Support
                    <a href="javascript:void(0)" (click)="helpSupport()"><i
                            class="icons-image icon-cancel-white"></i></a>
                </div>
                <div class="bg-heading bg-heading-left-align"><i class="icons-image icon-info"></i>Get to know Hex Index
                    in more detail</div>
                <div class="timeline help-timeline">
                    <div class="rightbox">
                        <div class="rb-container">
                            <ul class="rb scroll-window">
                                <li class="rb-item" ng-repeat="itembx">
                                    <div class="timestamp">How to Start</div>
                                    <div class="item-title">Please follow the following guide to understand the
                                        principles of Benchmarking and get started with the product</div>
                                    <!-- <button class="cbtn-primary cbtn-border"><i class="icons-image icon-download"></i>
                                        Download as</button> -->
                                </li>
                                <li class="rb-item" ng-repeat="itembx">
                                    <div class="timestamp">Know about FTE Benchmarking</div>
                                    <div class="item-title">A detailed guide on FTE (Full Time Equivalent) Benchmarking
                                        fundamentals</div>

                                </li>
                                <li class="rb-item" ng-repeat="itembx">
                                    <div class="timestamp">Know in detail about RU Benchmarking</div>
                                    <div class="item-title">A detailed guide on Benchmarking fundamentals based on RU
                                        (Resource Unit)</div>

                                </li>
                                <li class="rb-item" ng-repeat="itembx">
                                    <div class="timestamp">How to utilize Opportunity Assessment</div>
                                    <div class="item-title">Opportunity Assessment is a powerful tool. Please follow the
                                        guide to utilize the maximum potential of this feature</div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-overlay" *ngIf="showNew">
            <div class="help-section">
                <div class="bg-heading bg-heading-gray">
                    What's New
                    <a href="javascript:void(0)" (click)="whatsNew()"><i class="icons-image icon-cancel-white"></i></a>
                </div>
                <div class="bg-heading bg-heading-left-align"><i class="icons-image icon-info"></i>Our upcoming</div>
                <div class="timeline">
                    <div class="rightbox">
                        <div class="rb-container">
                            <ul class="rb scroll-window">
                                <li class="rb-item" ng-repeat="itembx">
                                    <div class="timestamp">Ver 0.0</div>
                                    <div class="item-title">● Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                        sed diam nonumy eirmod tempor invidunt ut labore et dolore magn
                                        <a href="javascript:vpod(0)">Know More</a>
                                    </div>
                                </li>
                                <li class="rb-item" ng-repeat="itembx">
                                    <div class="timestamp">Ver 0.1</div>
                                    <div class="item-title">● Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                        sed diam nonumy eirmod tempor invidunt ut labore et dolore magn
                                        <a href="javascript:vpod(0)">Know More</a>
                                    </div>
                                </li>
                                <li class="rb-item" ng-repeat="itembx">
                                    <div class="timestamp">Ver 0.1.1</div>
                                    <div class="item-title">● Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                        sed diam nonumy eirmod tempor invidunt ut labore et dolore magn
                                        <a href="javascript:vpod(0)">Know More</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-div scroll-window">
            <router-outlet></router-outlet>
        </div>
    </ng-container>
    <ng-template #loggedOut>
        <app-login *ngIf="(auth.isLoading$ | async) === false" (loginEvent)="login()"></app-login>
    </ng-template>
</ng-container>