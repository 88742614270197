<div class="section">
    <div class="oa-head oa-brb-head rounded-top">
        <div class="oa-selections">
            <div class="custom-toggle-btn">
                <div class="custom-select-filter">
                    <img  matTooltip="Select Location type" src="/assets/image/information-button.svg" class="me-1">
                    <label>Location </label>
                    <mat-form-field>
                        <mat-select (selectionChange)="locationChange($event)" [(value)]="allLocation">
                            <mat-option value="all">All</mat-option>
                            <mat-option value="split">Split</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="custom-select-filter">
                    <img matTooltip="Select spend type" src="/assets/image/information-button.svg" class="me-1">
                    <label>Spend Type</label>
                    <mat-form-field>
                        <mat-label>Spend Type</mat-label>
                        <mat-select (selectionChange)="spendChange($event)" [(value)]="selectedSpend">
                            <!-- <mat-select (selectionChange)="changeAreaSelection($event)" [(value)]="selectedArea.id"></mat-select> -->
                            <mat-option *ngFor="let spend of spendDropdown" [value]="spend.value">
                                {{spend.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="custom-select mw-150">
                    <img matTooltip="Select provider type" src="/assets/image/information-button.svg" class="me-1">
                    <label>Provider</label>
                    <mat-form-field *ngIf="spts$ | async as spts">
                        <mat-select (selectionChange)="changeSptFilter($event)" [(value)]="selectedSpt">
                            <mat-option *ngFor="let spt of spts" [value]="spt.id">
                                {{spt.spt}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <a href="javascript:void(0)" class="clear-btn mt-0 " (click)="clearData()" title="Clear Headcounts">
                    <i class="icons-image icon-reset"></i>
                </a>
                <!-- 
                    
                 -->
            </div>
        </div>
    </div>
    <!-- <ag-grid-angular
        class="ag-theme-alpine roles-table"
        [rowHeight]="rowHeight"
        [headerHeight]="headerHeight"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [animateRows]="true"
        [masterDetail]="true"
        (gridReady)="onGridReady($event)"
        [domLayout]="domLayout"
        [rowGroupPanelShow]="rowGroupPanelShow"
        [gridOptions]="gridOptions"
        [isExternalFilterPresent]="isExternalFilterPresent"
        [doesExternalFilterPass]="doesExternalFilterPass"
        (firstDataRendered)="onFirstDataRendered($event)"
        (cellValueChanged)="onCellValueChange($event)"
        [detailCellRenderer]="detailCellRenderer">
    </ag-grid-angular> -->
    <ag-grid-angular class="opportunity-ass ASSESSMENT ag-theme-alpine roles-table"
    [ngClass]="selectedSpend == 'total' ? 'totalSpendActive' : ''"
    [rowHeight]="rowHeight"
        [headerHeight]="headerHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
        (gridReady)="onGridReady($event)" [domLayout]="domLayout" [rowGroupPanelShow]="rowGroupPanelShow"
        [gridOptions]="gridOptions" [isExternalFilterPresent]="isExternalFilterPresent"
        [doesExternalFilterPass]="doesExternalFilterPass" (firstDataRendered)="onFirstDataRendered($event)"
        (cellValueChanged)="onCellValueChange($event)" [overlayNoRowsTemplate]="noRowsTemplate">
    </ag-grid-angular>
</div>