import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncyptDecryptService {

  constructor() { }  

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(data, environment.encryptSecretKey).toString();
    } catch (e) {
      console.error(e);
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
      if (bytes.toString()) {
        return bytes.toString(CryptoJS.enc.Utf8);
      }
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  encryptObject(data){
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), environment.encryptSecretKey).toString();
    } catch (e) {
      console.error(e);
    }
  }

  decryptObject(data){
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.error(e);
    }
  }
}
