<div mat-dialog-title>
    <div class="bg-heading bg-heading-blue">
        <span>Select Tower(s) & Sub-Tower(s)</span>
        <button mat-button mat-dialog-close>
            <i class="icons-image icon-cancel-white" aria-hidden="true"></i>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography scroll-window">
    <app-donut-chart-tower [isModal]="data.isModal" [isStepCaller]="false" [displayCategoryAndArea]="false"></app-donut-chart-tower>
</mat-dialog-content>
<div class="modal-footer">
    <button class="cbtn-primary cbtn-secondary" mat-stroked-button mat-dialog-close>Submit</button>
</div>
