import { Component, OnInit } from '@angular/core';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { config } from 'src/environments/config';
@Component({
  selector: 'app-recent-downloads',
  templateUrl: './recent-downloads.component.html',
  styleUrls: ['./recent-downloads.component.scss']
})
export class RecentDownloadsComponent implements OnInit {
  domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
  rowHeight = 30;
  headerHeight = 30;  
  public rowData$: Observable<any>;
  private gridApi: any = null;
  columnDefs: ColDef[] = [
    {
      field: 'name', headerName: 'Name',
      width: 350,  
      suppressSizeToFit: true,    
    },    
    { field: 'downloadedOn', headerName: 'Downloaded On', width:160, valueGetter: (params) => config.formatDateTime(params.data.downloadedOn) },
   
    // {
    //   field: 'downloadIcon',
    //   cellRenderer: function () {
    //     return '<a href="javascript:void(0)"><i class="icons-image icon-download" title="Cracker Replace"></i></a>'
    //   },
    //   width: 65,
    //   suppressSizeToFit: true
    // }
  ];
  // rowData = [
  //   { Name: 'Report 1', FileType: 'Celica', DownloadedOn: '09-May-2022 02:15 PM' },
  //   { Name: 'Report 1', FileType: 'Mondeo', DownloadedOn: '09-May-2022 02:15 PM' },
  //   { Name: 'Report 1', FileType: 'Boxster', DownloadedOn: '09-May-2022 02:15 PM' }
  // ];

  rowData: any[] = [];

  constructor(private apiService: ApiService, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.rowData$ = this.apiService.getRecentDownloads();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    // this.gridApi.sizeColumnsToFit()
  }

  onCellClicked(event: CellClickedEvent) {
    if (event.colDef.field == "name") {
      var link = document.createElement("a");
      link.setAttribute('name', event.data.name);
      link.href = event.data.fileUrl;
      document.body.appendChild(link);
      link.click();      
      // this.httpClient.get(event.data.fileUrl, { observe: 'response', responseType: 'blob' as 'json' }).subscribe({
      //   next: (resp: any) => {
      //     FileSaver.saveAs(resp.body, event.data.name);;
      //   }
      // })
    }
    // window.open(event.data.fileUrl);
  }

}
