import { Component } from '@angular/core';
import { _MatSelectBase } from '@angular/material/select';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { InputService } from '../input.service';
import * as _ from 'lodash';
import { M } from 'chart.js/dist/chunks/helpers.core';
import { MatDialog } from '@angular/material/dialog';
import { DetailedAnalysisModalComponent } from '../detailed-analysis-modal/detailed-analysis-modal.component';

@Component({
  selector: 'app-detail-cell-renderer',
  templateUrl: './detailed-custom-view.component.html',
  styleUrls: ['./detailed-custom-view.component.scss']
})
export class DetailedCustomViewComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  masterGridApi!: GridApi;
  rowId!: string;
  colDefs!: ColDef[];
  defaultColDef!: ColDef;
  rowData!: any[];

  constructor(private InputService: InputService, public dialog: MatDialog) { }

  public detailColDef: ColDef = {
    sortable: false,
    filter: false,
    editable: true,
    onCellValueChanged: (params) => {
      this.InputService.setDetailedAnalysisValue(params.data)
    }
  };

  openDialog(params): void {
    const dialogRef = this.dialog.open(DetailedAnalysisModalComponent, {
      data: { ...params },
      width: '80%',
      panelClass: 'detail-tower-modal'
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   ;
    // });
  }

  // called on init
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.openDialog(params)

    this.masterGridApi = params.api;
    this.rowId = params.node.id!;

    this.colDefs = [
      { field: 'name' },
      { field: 'level1', headerName: 'L1', ...this.detailColDef },
      { field: 'level2', headerName: 'L2', ...this.detailColDef },
      { field: 'level3', headerName: 'L3', ...this.detailColDef },
      { field: 'level4', headerName: 'L4', ...this.detailColDef },
      { field: 'level5', headerName: 'L5', ...this.detailColDef },
      { field: 'total', headerName: 'Total FTE', ...this.detailColDef },
      { field: 'spend', headerName: 'Total Spend', ...this.detailColDef }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 50,
    };

    this.rowData = params.data.detailedAnalysis;
  }

  // called when the cell is refreshed
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  onGridReady(params: GridReadyEvent) {
    var gridInfo = {
      id: this.rowId,
      api: params.api,
      columnApi: params.columnApi,
    };
    this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
  }

  ngOnDestroy(): void {
    this.masterGridApi.removeDetailGridInfo(this.rowId);
  }
}