<div class="pyr-main">
    <div class="custom-table">
        <div class="table-head">
            <div class="table-row">
                <div class="table-cell cell-location">Location</div>
                <div class="table-cell cell-25">25%ile</div>
                <div class="table-cell cell-50">50%ile</div>
                <div class="table-cell cell-75">75%ile</div>
                <div class="table-cell cell-your-rate">Your Rate($)</div>
            </div>
        </div>
        <div class="table-body">
            <div class="table-row"  *ngFor="let d of data">
                <div class="table-cell cell-location">{{d.locationName}}</div>
                <div class="table-cell cell-25">{{d.score[25] | number}}</div>
                <div class="table-cell cell-50">{{d.score[50] | number}}</div>
                <div class="table-cell cell-75">{{d.score[75] | number}}</div>
                <div class="table-cell cell-your-rate" [innerHTML]="getSign(d)"></div>
            </div>
        </div>
    </div>
    <div class="pyr-wrap" style="margin-top: 30px;">
        <a href="javascript:void(0)" class="btn-reset" (click)="reset()">
            <i class="icons-image icon-reset-small" aria-hidden="true"></i>
            <span>Reset</span>
        </a>
        <div *ngIf="identifier != null">
            <svg id="pyramid{{identifier}}"></svg>
        </div>
        <div>
            <div class="title">Span of Control <i class="icons-image icon-info-light-gray"></i></div>
            <div class="custom-slider" style="margin-top: 10px;">
                <div class="slider-wrap">
                    <span>L5</span>
                    <ngx-slider [(value)]="slider.level5.value" (userChangeEnd)="adjustPyramid($event, 'level5')"
                        [options]="slider.level5.options"></ngx-slider>
                </div>
                <div class="slider-wrap">
                    <span>L4</span>
                    <ngx-slider [(value)]="slider.level4.value" (userChangeEnd)="adjustPyramid($event, 'level4')"
                        [options]="slider.level4.options"></ngx-slider>
                </div>
                <div class="slider-wrap">
                    <span>L3</span>
                    <ngx-slider [(value)]="slider.level3.value" (userChangeEnd)="adjustPyramid($event, 'level3')"
                        [options]="slider.level3.options"></ngx-slider>
                </div>
                <div class="slider-wrap">
                    <span>L2</span>
                    <ngx-slider [(value)]="slider.level2.value" (userChangeEnd)="adjustPyramid($event, 'level2')"
                        [options]="slider.level2.options"></ngx-slider>
                </div>
                <div class="slider-wrap slider-disable">
                    <span>L1</span>
                    <ngx-slider [(value)]="slider.level1.value" [options]="{floor:0, ceil: 100, readOnly: true}">
                    </ngx-slider>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="onshore-slider">
        <div class="title">Onshoring</div>
            <div class="custom-slider-2">
                <span class="onshore-value" [ngStyle]="{'left': (((onshore.onShore) - 5)/2) + '%'}">{{onshore.onShore}}%</span>
                <span class="offshore-value" [ngStyle]="{'right': (((100 - onshore.onShore) - 10 )/2) + '%'}">{{100 - onshore.onShore}}%</span>
                <ngx-slider [(value)]="onshore.onShore" (userChangeEnd)="performOperation(onshore)" [options]="sliderOptions">
                </ngx-slider>
            </div>
    </div> -->
</div>