import LevelWiseData from "./LevelWiseData";

class LocationRateData {
    entityId: number | string = '';
    benchmarkRates: LevelWiseData = new LevelWiseData();
    currentHeadcount: LevelWiseData = new LevelWiseData();
    currentSpend: number = 0;
    totalFTE: number = 0;

    constructor() { }

    initializeShoreData(totalHeadcount, bmRates, benchmarkCost, numOfLocations, skillFactor, entityId, spend) {
        this.entityId = entityId;
        this.benchmarkRates.level1 = totalHeadcount.level1 == 0 ? (bmRates.level1 / numOfLocations) : (benchmarkCost.level1 * skillFactor) / totalHeadcount.level1;
        this.benchmarkRates.level2 = totalHeadcount.level2 == 0 ? (bmRates.level2 / numOfLocations) : (benchmarkCost.level2 * skillFactor) / totalHeadcount.level2;
        this.benchmarkRates.level3 = totalHeadcount.level3 == 0 ? (bmRates.level3 / numOfLocations) : (benchmarkCost.level3 * skillFactor) / totalHeadcount.level3;
        this.benchmarkRates.level4 = totalHeadcount.level4 == 0 ? (bmRates.level4 / numOfLocations) : (benchmarkCost.level4 * skillFactor) / totalHeadcount.level4;
        this.benchmarkRates.level5 = totalHeadcount.level5 == 0 ? (bmRates.level5 / numOfLocations) : (benchmarkCost.level5 * skillFactor) / totalHeadcount.level5;

        this.currentHeadcount.level1 = totalHeadcount.level1;
        this.currentHeadcount.level2 = totalHeadcount.level2;
        this.currentHeadcount.level3 = totalHeadcount.level3;
        this.currentHeadcount.level4 = totalHeadcount.level4;
        this.currentHeadcount.level5 = totalHeadcount.level5;

        this.currentSpend = spend;
    }

    get blendRate() { return this.currentHeadcount.total > 0 ? this.currentSpend / this.currentHeadcount.total / 1920 : 0 }

    //To calculate total tower rate using provided headcount ratio for each level
    getBenchmarkTowerRate(soc: LevelWiseData): LevelWiseData {

        let x = new LevelWiseData();

        x.level1 = this.benchmarkRates.level1 * soc.level1 / 100;
        x.level2 = this.benchmarkRates.level2 * soc.level2 / 100;
        x.level3 = this.benchmarkRates.level3 * soc.level3 / 100;
        x.level4 = this.benchmarkRates.level4 * soc.level4 / 100;
        x.level5 = this.benchmarkRates.level5 * soc.level5 / 100;

        return x;
    }

    getTargetSpend(soc: LevelWiseData, headcount: number) {
        let benchmarkLocationBlendedRate = this.getBenchmarkTowerRate(soc).total;
        return Math.floor(benchmarkLocationBlendedRate * headcount * 1920);
    }
}


export default LocationRateData;