<div class="heading-wrap">
<div class="headings" style="display: flex; justify-content: space-between;">Deal Parameters <i mattooltip="" class=" icons-image icon-info-light-gray"></i>
   
</div>
<i (click)="resetDefaults()" class="icons-image icon-reset" title="Reset to default"></i>
</div>
<div class="dealparameters scroll-window">
<div class="container p-0">    
    <form [formGroup]="dealParamFormGroup" *ngIf="dealParamFormGroup">
        <!-- <div class="row dealParameterSelection" *ngFor="let dealParam of dealParams">
            <div class="col-4 dealParameterName">
                {{dealParam.paramName}}
            </div>
            <div class="col-8 dealParameterSelect">
                <mat-form-field appearance="standard">                    
                    <mat-select placeholder="Choose one" [formControl]="getControl(dealParam.paramName)"
                        (selectionChange)="setSelectedValues($event, dealParam.paramName)">
                        <mat-option *ngFor="let value of dealParam.values" [value]="value">
                            {{value.displayText}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
        <div class="dealSelectedValue">
        <div class=" dealParameterSelection" *ngFor="let dealParam of dealParams" style="padding:0 5px;">
            <div class="dealParameterName">
                {{dealParam.paramName}}
            </div>
            <div class="dealParameterSelect">
                <mat-form-field appearance="standard">  
                    <mat-select [matTooltip]="getControl(dealParam.paramName).value.displayText" placeholder="Choose one" [formControl]="getControl(dealParam.paramName)"
                        (selectionChange)="setSelectedValues($event, dealParam.paramName)">
                        <mat-option [title]="value.displayText" *ngFor="let value of dealParam.values" [value]="value">
                            {{value.displayText}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        </div>
    </form>
</div>