<!-- <div class="section">
  <div class="switchs-wrap">
    <div class="switch switch--horizontal percentile-switch">
      <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="benchmarkPoint" (change)="refreshCalculations()">
        <mat-button-toggle [disableRipple]="true" value="25">25%ile</mat-button-toggle>
        <mat-button-toggle [disableRipple]="true" value="50">Median</mat-button-toggle>
        <mat-button-toggle [disableRipple]="true" value="75">75%ile</mat-button-toggle>
        <span class="toggle-outside">
          <span class="toggle-inside"></span>
        </span>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="chart-wrap">
    <div class="row m0">
      <div class="col-6 plr-7"  *ngIf="type!='total'">
        <div id="annualSpendTower" class="chart-inner-wrap"></div>
        <div class="headings">Tower</div>
      </div>
      <div class="col-6 plr-7">
        <span>Total Savings: </span>
        <strong>{{totalSavings|million}}</strong>
      </div>
    </div>
  </div>
</div> -->


<div class="section">
  <table width="100%">
    <tr>
      <td width="40%" style="text-align: center;">
        <div id="annualSpendTower" class="chart-inner-wrap"></div>
      </td>
      <td>
        <div class="switchs-wrap">
          <div class="switch switch--horizontal percentile-switch">
            <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="benchmarkPoint" (change)="getCalculatedData()">
              <mat-button-toggle [disableRipple]="true" value="25">25%ile</mat-button-toggle>
              <mat-button-toggle [disableRipple]="true" value="50">Median</mat-button-toggle>
              <mat-button-toggle [disableRipple]="true" value="75">75%ile</mat-button-toggle>
              <span class="toggle-outside">
                <span class="toggle-inside"></span>
              </span>
            </mat-button-toggle-group>
          </div>
        </div>
        <span>Total Savings: </span>
        <strong>{{totalSavings|million}}</strong>
        <div class="col-12"><div id="legends"></div></div>
      </td>
    </tr>
  </table>
</div>