<ng-template #box let-boxdata>
    <div class="row box-wrapper" style="max-width: 120px;">
        <span class="median">{{data[50]}}</span>
        <div class="col-6 first">&nbsp;</div>
        <div class="col-6 second">&nbsp;</div>
        <span class="quartile quartile1">{{data[25]}}</span>
        <span class="quartile quartile2">{{data[75]}}</span>
    </div>
 </ng-template>

 <div class="col-6 mx-auto">
    <!-- <ng-template *ngTemplateOutlet="box; context: { $implicit: role }"></ng-template> -->
    <ng-template *ngTemplateOutlet="box; context:{$implicit: data}"></ng-template>
</div>