import { Component, OnInit } from '@angular/core';
import { BarVerticalStackedComponent } from '@swimlane/ngx-charts';
import { M } from 'chart.js/dist/chunks/helpers.core';
import { db } from 'src/db';
import { ApiService } from '../api.service';
import { DataService } from '../data.service';
import { EncyptDecryptService } from '../encypt-decrypt.service';
import { LoaderService } from '../loader.service';
import { MatSelectChange } from '@angular/material/select';

export interface TableElement {
  type: string;
  value: string[];
}

@Component({
  selector: 'app-reset',
  host: { 'class': 'resetModal' },
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  constructor(private dataService: DataService, private loaderService: LoaderService, private apiService: ApiService, private encryptService: EncyptDecryptService) { }

  selectedChoice = null;
  selections: any;
  selectedData: any = '';
  displayedColumns: string[] = ['type', 'value'];
  dataSource: TableElement[];
  noOfRecords = 5;

  ngOnInit(): void {
    this.getData().then(data => {
      this.selections = data;
      // this.selectedData = data?.stepSelectionsRecords[0];
      // this.loadData(this.selectedData);
    })
  }

  loadData(stepSelection: any) {
    let { id, scenarioId } = stepSelection;
    if (scenarioId) {
      this.loadScenario(scenarioId);
      return;
    }
    this.createDataSource(stepSelection);
  }

  createDataSource(stepSelection: any) {
    let id = stepSelection.id;
    this.selectedChoice = { type: 'step', id: id };
    let data = [];
    Object.keys(stepSelection.data).filter(k => { return ['selectedBenchmarkType', 'role'].indexOf(k) == -1 }).forEach(key => {
      let value = { type: key, value: stepSelection.data[key].map(rec => rec[this.getFieldKey(key)]) }
      if (value.value.length > 0)
        data.push(value)
    })
    this.dataSource = data;
  }

  loadScenario(id) {
    let omittedKeys = ['selection_towerRates', 'selection_benchmarkType', 'meta', 'inputs', 'detailed_analysis', 'role_filter', 'input_plot', 'spends'];
    this.selectedChoice = { type: 'saved', id: id };
    this.apiService.getScenario(id).subscribe(async (res: any) => {
      let data = [];
      let resData = res.data;
      Object.keys(resData).filter(d => { return omittedKeys.indexOf(d) == -1 }).forEach(key => {
        let value = { type: key.replace("selection_", ''), value: resData[key].map(rec => rec[this.getScenarioFieldKey(key)]) };

        if (value.value.length > 0)
          data.push(value)
      })
      this.dataSource = data;
    });
  }

  async getData() {
    let savedScenarioRecord = (await db.reset_scenario_stat.toArray()).reverse().slice(0, this.noOfRecords).map(e => ({ ...e, type: 'saved' }));
    let stepSelectionsRecords = (await db.step_selection_stat.toArray()).reverse().slice(0, this.noOfRecords).map(e => ({ ...e, type: 'step' }));
    return { stepSelectionsRecords: [...savedScenarioRecord, ...stepSelectionsRecords] };
  }

  getScenarioFieldKey(key) {
    let returnKey = '';
    switch (key) {
      case 'selection_areas':
        returnKey = 'area';
        break;
      case 'selection_categories':
        returnKey = 'category';
        break;
      case 'selection_locations':
        returnKey = 'locationName';
        break;
      case 'selection_providerTypes':
        returnKey = 'spt';
        break;
      case 'selection_roles':
        returnKey = 'role';
        break;
      case 'selection_subtowers':
        returnKey = 'subTower';
        break;
      case 'selection_towers':
        returnKey = 'tower';
        break;
      default:
        returnKey = key;
    }
    return returnKey;
  }

  getFieldKey(key) {
    let returnKey = '';
    switch (key) {
      case 'location':
        returnKey = 'locationName';
        break;
      case 'provider':
        returnKey = 'spt';
        break;
      case 'subtower':
        returnKey = 'subTower';
        break;
      default:
        returnKey = key;
    }
    return returnKey;
  }

  async resetAsPerSelection() {
    this.loaderService.openDialog();
    let selections = await this.getData();
    if (this.selectedChoice.type == 'step') {
      await this.dataService.clearSelections();
      let dataToRestore = selections.stepSelectionsRecords.filter(ss => { return ss.id == this.selectedChoice.id })[0]['data'];
      await db.selection_areas.bulkAdd(dataToRestore['area']);
      await db.selection_categories.bulkAdd(dataToRestore['category']);
      await db.selection_locations.bulkAdd(dataToRestore['location']);
      await db.selection_providerTypes.bulkAdd(dataToRestore['provider']);
      await db.selection_subtowers.bulkAdd(dataToRestore['subtower']);
      await db.selection_towers.bulkAdd(dataToRestore['tower']);
      await db.selection_benchmarkType.bulkAdd(dataToRestore['selectedBenchmarkType']);

      (await this.apiService.getRatesAsPerSelections()).subscribe(async (data: any) => {
        data.forEach(d => {
          d['value'] = this.encryptService.encryptData(d['value'].toString());
        })
        await db.table('rateList').clear()
        await db.table('rateList').bulkPut(data);
        this.loaderService.closeDialog();
        window.location.reload();
      });
    }
    if (this.selectedChoice.type == 'saved') {
      //load scenario as per selections
      let clearOnly = false;
      await this.dataService.clearSelections();
      this.apiService.getScenario(this.selectedChoice.id).subscribe(async (res: any) => {
        let apiData = res.data;
        let dataKeys = Object.keys(apiData);
        let omittedKeys = ['meta']
        if (!clearOnly) {
          await Promise.all(dataKeys.map(async (key) => {
            if (omittedKeys.indexOf(key) == -1)
              await db.table(key).bulkAdd(apiData[key]);
          }));
          (await this.apiService.getRatesAsPerSelections()).subscribe(async (data: any) => {
            await db.table('rateList').bulkPut(data);
            this.loaderService.closeDialog();
            window.location.reload();
          });
        }
      })
    }
  }

}
