class VolumeInputFlat {
    towerId: number;
    towerName:string;
    roleId: number;
    roleName:string;
    spend:number;
    locationId:number;
    locationName:string;
    spt:number;
    volume:number;
    inputRate:number;
}

export default VolumeInputFlat;