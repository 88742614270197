import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { CreateScenarioComponent } from './scenario/create-scenario/create-scenario.component';
import { db } from 'src/db';
import { liveQuery } from 'dexie';
import { ApiService } from './api.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { LoaderService } from './loader.service';
import { EncyptDecryptService } from './encypt-decrypt.service';
import { TourService } from './tour.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { MessageService } from './services/Message.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isSidebarOpen: boolean = false;
  loading: boolean = false;
  isStepUrl: boolean = false;
  showTour: boolean = false;
  showFeedback: boolean = false;
  showHelp: boolean = false;
  showNew: boolean = false;
  showFte: boolean = false;
  showdashboard: boolean = true;
  showRu: boolean = false;
  showFteTile: boolean = false;
  showRuTile: boolean = false;
  showDownload: boolean = false;
  feedbackForm: FormGroup;
  isRUEnabled = environment.isRUEnabled;
  isDemoEnvironment: boolean = environment.isDemoEnvironment || false;
  production: boolean = environment.production || false;
  clientName: string = '';
  clientURL: string = '';
  accessDenied: boolean = false;
  shouldDisplayAlertBeforeDataDeletion = false;
  selectedBenchmark: any = '';
  entitlements: any;
  entitlementsFromApi: any = {}

  constructor(private actRoute: ActivatedRoute, private tourService: TourService, public auth: AuthService, private encDecService: EncyptDecryptService,
    private router: Router, private dialog: MatDialog, private dataService: DataService,
    private apiService: ApiService, private loader: LoaderService, private fb: FormBuilder, private messageService: MessageService) {
    if (router.url == '/') {
      this.isStepUrl = true;
    }
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.shouldDisplayAlertBeforeDataDeletion = false;
        if (event['url'] == '/ru/ru' || event['url'].indexOf('fte') > 0) {
          this.shouldDisplayAlertBeforeDataDeletion = true;

        }
        this.selectedBenchmark = (event['url'].indexOf('fte') > 0) ? 'fte' : (event['url'].indexOf('ru') > 0) ? 'ru' : null;
        this.auth.isAuthenticated$.subscribe(isLoggedIn => {
          if (isLoggedIn)
            this.auth.getUser().subscribe((user) => {
              if (user['HexIndex/roles'].includes('HexIndexUsers_Prod', 'HexIndexAdmins_UAT')) {
                // if(document.location.origin != config.prodEnvURL){
                //   document.location.href = config.prodEnvURL
                // }
              }
              else if (user['HexIndex/roles'].indexOf('HexIndexUsers_DEMO') != -1) {
                if (document.location.origin != config.demoEnvURL) {
                  document.location.href = config.demoEnvURL
                }
              }

              if (user['accountExpiry'] && user['accountExpiry'] != null) {
                try {
                  let expiryDate = new Date(user['accountExpiry']).getTime();
                  if (Date.now() > expiryDate) {
                    return this.router.navigate(['access-denied'])
                  }
                }
                catch (e) {

                }
              }
              if (!user['HexIndex/roles'].includes(environment.roleRequired)) {
                this.accessDenied = true;
                return this.router.navigate(['access-denied'])
              }
              this.getEntitlementsFromClientDetails();
              return true;
            })
        })
      }
    })

  }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      this.auth.getAccessTokenSilently().subscribe(token => {

        this.clientName = JSON.parse(atob(token.split('.')[1]))['clientname']
        this.clientURL = JSON.parse(atob(token.split('.')[1]))['clienturl']
      })

      if (isAuthenticated) {
        liveQuery(() => this.apiService.getDataSyncFromDb()).subscribe(result => {
          if (result.length == 0)
            this._getConstantDataFromAPI();
        })
        this.startFirstTimeTour();
        this.getEntitlementsFromClientDetails();
      }
    });

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        //location.reload()
      }
    });

    liveQuery(() => this.dataService.getEntitlements()).subscribe(result => {
      this.entitlements = result;

    })
  }

  async getEntitlementsFromClientDetails() {
    this.loader.openDialog()
    this.apiService.getClientDetails().subscribe(response => {
      this.messageService.sendMessage('clientDetailsEntitlements', response['data']);
      this.entitlementsFromApi = response['data'];
      this.loader.closeDialog()
    })
  }

  private startFirstTimeTour() {
    if (!localStorage.getItem('isTourStarted')) {
      localStorage.setItem('isTourStarted', 'true');
      setTimeout(() => { this.tourService.startTour() }, 1000)
    }
  }

  private _getConstantDataFromAPI() {
    this.loader.openDialog();
    let providers = this.apiService.getProviderTypes();
    let categories = this.apiService.getRoles();
    let regions = this.apiService.getRegions();
    // let groups = this.apiService.getGroups();
    let pyramid = this.apiService.getPyramid();
    let offshoreData = this.apiService.getOffShoreData();


    forkJoin([providers, categories, regions, pyramid, offshoreData]).subscribe((data: any[]) => {
      db.providerTypes.bulkAdd(data[0]);
      this.apiService.saveRelatedDataInDb(data[1], data[2]);
      // this.saveRegions(data[2]);
      this.savePyramidData(data[3]);
      this.saveOffshoreData(data[4]);
      // this.saveRoleFilterDefaults();
      db.dataSync.add({ isConstantDataSynced: true });
      this.loader.closeDialog();
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateScenarioComponent, {
      width: '40%'
    });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  login() {
    this.auth.loginWithRedirect();
  }

  logout() {
    this.auth.logout({ returnTo: window.location.origin })
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  refreshIndexedDB() {
    let req = indexedDB.deleteDatabase(environment.dbName);
    req.onsuccess = () => {
      // if(window.location.pathname == "/")
      //   this._getConstantDataFromAPI();
      // else
      window.location.href = window.location.origin;
    };
  }

  async selectedBenchmarkType(type, analysisType, origin = null) {
    let result = await db.selection_towers.count();
    if (result > 0 && this.shouldDisplayAlertBeforeDataDeletion) {
      Swal.fire({
        text: 'You have unsaved changes. Do you want to proceed?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed)
          this._proceedToSelections(type, analysisType, origin)
      })
    }
    else {
      this._proceedToSelections(type, analysisType, origin)
    }
  }

  private async _proceedToSelections(type, analysisType, origin) {

    await this.dataService.clearSelections();
    await db.selection_benchmarkType.clear();
    await db.selection_benchmarkType.add({ type: type, currentBenchmarkData: { analysisType: analysisType } });
    this.router.navigate([(type == 1) ? 'fte' : 'ru', 'step', (type == 1) ? analysisType : 'ru']).then(() => {
      if (origin != null)
        window.location.reload();
    })
  }

  takeATour() {
    this.tourService.startTour('interactive')
  }
  feedback() {

    this.showFeedback = !this.showFeedback;
  }
  helpSupport() {
    this.showHelp = !this.showHelp;
  }
  whatsNew() {
    this.showNew = !this.showNew;
  }
  dashboard() {
    this.showFte = false;
    this.showdashboard = true;
  }
  fteBenchmarking() {
    this.showFte = true;
    this.showdashboard = false;
    this.showRu = false;
  }
  ruBenchmarking() {
    this.showRu = true;
    this.showFte = false;
    this.showFteTile = false;
  }
  fteTile() {
    this.showFteTile = true;
    this.showRu = false;
    this.showRuTile = false;
  }
  ruTile() {
    this.showRuTile = true;
    this.showFteTile = false;
    this.showDownload = false;
  }
  downloadTour() {
    this.showDownload = true;
    this.showRuTile = false;
  }


  private savePyramidData(data) {
    let socs = []
    data.forEach(soc => {
      let socToPush = {
        towerid: soc.towerFTE.id,
        tower: soc.towerFTE.tower,
      }
      delete soc.towerFTE;
      socToPush['soc'] = this.encDecService.encryptObject(soc);
      socs.push(socToPush)
    })
    db.socs.bulkAdd(socs);
  }

  private saveOffshoreData(data) {
    let offshoreData = [];
    data.forEach(d => {
      delete d.towerFTE;
      offshoreData.push(d)
    })
    db.offshore.bulkAdd(offshoreData)
  }

  download(docName: string) {
    this.apiService.downloadFile(docName).subscribe(
      (data: any) => {
        var contentDisposition = data.headers.get('content-disposition');
        var filename = contentDisposition.split(';').find(n => n.includes('filename=')).replace('filename=', '').replace(/"/g, '').trim();
        const downloadedFile = new Blob([data.body], { type: data.body.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = filename;
        a.href = URL.createObjectURL(downloadedFile);
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      }
    )
  }

  adjustZoom() {
    document.body.style['zoom'] = '80%';
    //document.body.style['transform'] =  'scale(1, 0.9)'
  }

}
