<div class="allpages">
    <div class="mainContainer" #allpageContent>

        <div class="innerPage">
            <div class="shapeMask"></div>
            <div class="tocContent">
                <h4 class="selectionsHead"> <span class="numberList">01.</span> Selections </h4>
                <div class="selectionTitleContainer" id="firstPage">
                    <div class="selectionTitleBar">
                        <div class="selectionTitleBarHead">
                            <div class="selectionTitleBarNum"> <span>1.1</span>&nbsp; Towers & Sub Towers </div>
                            <div class="buttonGroup">
                                <button class="active">Basic</button>
                                <button>Premium</button>
                                <button>Niche</button>
                            </div>
                        </div>
                    </div>
                    <div class="imgContainer">
                        <app-tower-sunburst [pdfExport]=true [isModal]="false"
                            [size]="{ width: 625, height: 625 }"></app-tower-sunburst>
                    </div>
                </div>
                <br />
                <div>
                    <div class="selectionTitleContainer">
                        <div class="selectionTitleBar">
                            <div class="selectionTitleBarHead">
                                <div class="selectionTitleBarNum"> <span>1.2</span>&nbsp; Regions | Locations </div>
                            </div>
                        </div>
                        <div class="imgContainer" style="height: 600px;">
                            <app-region-chart [pdfExport]="true" [loadedPageType]="'fte'"
                                [isModal]="false"></app-region-chart>
                        </div>
                        <div class="graphSelectionSection">
                            <div class="graphSelectionTile">Locations</div>
                            <span class="selectionPills"
                                *ngFor="let location of masterSelections?.['location']">{{location.locationName}}</span>
                        </div>
                    </div>
                    <br />

                    <div class="selectionTitleContainer">
                        <div class="selectionTitleBar">
                            <div class="selectionTitleBarHead">
                                <div class="selectionTitleBarNum"> <span>1.3</span>&nbsp; Provider type </div>
                            </div>
                        </div>
                        <div class="graphSelectionSection">
                            <div class="graphSelectionTile">Provider Type</div>
                            <span class="selectionPills"
                                *ngFor="let provider of masterSelections?.['provider']">{{provider.spt}}</span>
                        </div>
                    </div>
                    <br />

                    <div class="selectionTitleContainer">
                        <div class="selectionTitleBar">
                            <div class="selectionTitleBarHead">
                                <div class="selectionTitleBarNum"> <span>2</span>&nbsp; Discrete Role Benchmarking
                                </div>
                            </div>
                        </div>
                        <div class="graphSelectionSection">
                            <!-- <app-price-bar-pdf-export [pdfExport]="true" *ngIf="loadedPageType != 'blended-rate'" class="role-level-price-bar" [type]="'role-level'" ></app-price-bar-pdf-export> -->

                        </div>
                    </div>
                    <br />

                    <h4 class="selectionsHead"> <span class="numberList">03.</span>Opportunity Sizing </h4>
                    <div class="selectionTitleContainer">
                        <div class="selectionTitleBar">
                            <div class="selectionTitleBarHead">
                                <div class="selectionTitleBarNum"> <span>3.1</span>&nbsp; Headcounts </div>
                            </div>
                        </div>
                        <div class="graphSelectionSection">
                            <!-- <app-savings-analysis-role></app-savings-analysis-role> -->
                            <!-- <app-roles-list> </app-roles-list> -->

                        </div>
                    </div>
                    <br />

                    <div class="selectionTitleContainer">
                        <div class="selectionTitleBar">
                            <div class="selectionTitleBarHead">
                                <div class="selectionTitleBarNum"> <span>3.2</span>&nbsp; Benchmarking Spend and Savings
                                    Analysis </div>
                            </div>
                        </div>
                        <div class="graphSelectionSection">
                            <!-- <app-savings-analysis-role></app-savings-analysis-role> -->
                            <app-savings-analysis-role (graphOptions)="SavingAnalysisGraphOptions($event, 'tower')"
                                [pdfExport]="true" selectionMode="tower" benchmarkPoint="50"
                                [isModal]="true"></app-savings-analysis-role>
                            <br />
                            <app-savings-analysis-role (graphOptions)="SavingAnalysisGraphOptions($event,'location')"
                                (emitCalculations)="bindCalculations($event)" [pdfExport]="true"
                                selectionMode="location" benchmarkPoint="50"
                                [isModal]="true"></app-savings-analysis-role>
                        </div>
                    </div>
                    <br />
                </div>

                <div class="innerFooter">
                    <span><img src="home_icon.svg"></span>
                    <span>Page No</span>
                    <span>© Copyright Hex Advisory Group</span>
                </div>
            </div>
        </div>




    </div>
</div>

<div *ngFor="let tower of towerSelections">
    <app-pyramid-chart-new-1 [soc]="tower" (emitFinalSOCData)="storeTowerData($event)"></app-pyramid-chart-new-1>
</div>

<app-savings-analysis-tower (calculations)="blendedCalculations($event)" ></app-savings-analysis-tower>

<!-- <div *ngFor="let tower of detailedAnalysisSavingsData">
    <app-bar-chart [pdfExport]="true" (graphOptions)="detailedAnalysisChart($event, tower.towerId)" [towerId]="tower.towerId" [savingsData]="tower"></app-bar-chart>
</div> -->