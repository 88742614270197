import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs';
import { MessageService } from '../services/Message.service';
import { DataUpdateService } from '../data-update.service';

declare let d3: any;
@Component({
  selector: 'app-tower-sunburst',
  templateUrl: './tower-sunburst.component.html',
  styleUrls: ['./tower-sunburst.component.scss']
})
export class TowerSunburstComponent implements OnInit, OnDestroy {

  liveQuerySubscription = new Subscription();

  constructor(private dataService: DataService, private messageService: MessageService, private dataUpdate: DataUpdateService) { }
  currentSelected = 'tower';
  areaName: string;
  @Input() size?= { width: null, height: null };
  @Input() variant?: string;
  @Input() isModal?: boolean = false;
  @Input() pdfExport?: boolean = false;

  towers: any;
  ngOnInit(): void {
    //window.addEventListener("resize", this.getSizes, false);

    this.liveQuerySubscription.add(liveQuery(() => this.getSelectedTowers())
      .subscribe(result => {
        this.towers = result;
        this.drawSunburst(result)
      }));

      this.dataUpdate.subscribeDataChangeEvent().subscribe(async (result) => {
        if(result == 'towerSubtowerListRefreshed'){
          let result = await this.getSelectedTowers();
          this.drawSunburst(result)
        }
      })
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  async getSelectedTowersFromDb() {
    return db.selection_towers.toArray();
  }

  async renderGraphAsPerSelection() {
    let result = await this.getSelectedTowers();
    //this.drawSunburst(result)
  }

  async getSelectedTowers() {
      let selectedArea = await this.dataService.getDataFromDb('areas', {}, true);
      let towers = await this.dataService.getDataFromDb('towers', { areaId: selectedArea[0]['id'] });
      let towerids = towers.map(tower => tower.id);
      let subtowers = await this.dataService.getDataFromDb('subtowers');
      subtowers = subtowers.filter(sub => towerids.includes(sub.towerId));
      this.areaName = selectedArea[0]['area'];
      let returnData = {
        name: "Towers",
        children: []
      }
      towers.forEach(tower => {
        let graphObj = {
          name: tower.tower,
          children: [],
          isSelected: tower.isSelected,
          shortCode: tower.shortCode,
          isEnabled: tower.isEnabled,
          id: tower.id,
          type:'tower'
        }
        subtowers.filter(subtower => subtower.towerId == tower.id).forEach(subtower => {
          //if (graphObj.children.length < 5)
            graphObj.children.push({
              name: subtower.subTower,
              isSelected: subtower.isSelected,
              shortCode: subtower.shortCode,
              id: subtower.id,
              isEnabled: tower.isEnabled,
              type: 'subtower',
              size: 10//numbers[Math.floor(Math.random() * numbers.length)]
            })
        })
        returnData.children.push(graphObj)
      })
      return returnData
  }

  drawSunburst(nodeData, zoom=100) {
    var self = this;
    let selector = '#towerSunburst';
    if (this.isModal) {
      selector = '#towerSunburstModal'
    }    
    d3.select(selector).html(null);
    //d3.select(selector).empty();
    var tooltip = d3.select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "1000")
      .style("visibility", "hidden")
      .style('padding', '0 10px')
      .style('border', '1px solid #ccc')
      .style("background", "#fff");
    var width = this.size.width || 400;
    var height = this.size.height || 400;

    var radius = Math.min(width, height) / 2;
    var color = d3.scaleOrdinal().domain(nodeData).range(environment.sunburstColorSchema)
    var g = d3.select(selector)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    var partition = d3.partition()
      .size([2 * Math.PI, radius]);

    var root = d3.hierarchy(nodeData)
      .sum(function (d) { return d.size });

    partition(root);
    var arc = d3.arc()
      .startAngle(function (d) {return d.x0 })
      .endAngle(function (d) { return d.x1 })
      .innerRadius(function (d) { return d.y0 })
      .outerRadius(function (d) { return d.y1 });

    g.selectAll('g')
      .data(root.descendants())
      .enter().append('g')
      .attr("class", 'node')
      .append('path')
      .attr("display", function (d) { return d.depth ? null : "none"; })
      .attr("d", arc)
      .style('stroke', '#f2f2f2')      
      .style("fill", (d) => {
        if(d.data.isEnabled == false){
          //return '#e9ecef';
        }
        return self.fillColorAsperSelection(d, color)
      })
      .on('click', function(d) {
        if(d.data.isEnabled == false){
          return false;
        }
        d.data.isSelected = !d.data.isSelected
        self.messageService.sendMessage('sunburstSelectionChanged', d)
        return tooltip.style("visibility", "hidden");
      })
      .on("mouseover", function (d) { 
        tooltip.text(d.data.name);
        if(d.data.isEnabled)
          d3.select(this).style("cursor", "pointer");
        return tooltip.style("visibility", "visible");
      })
      .on("mousemove", function () {
         return tooltip.style("top", (d3.event.pageY - 20) + "px").style("left", (d3.event.pageX + 20) + "px"); 
        })
      .on("mouseout", function () { 
        d3.select(this).style("cursor", 'default');
        return tooltip.style("visibility", "hidden"); })

    g.selectAll(".node")
      .append("text")
      .attr("transform",  (d) =>{
        if(this.variant == 'mini'){
          return "translate(" + arc.centroid(d) +50 + ")rotate(" + computeTextRotation(d) + ")";  
        }
        return "translate(" + arc.centroid(d) + ")rotate(" + computeTextRotation(d) + ")";
      })
      .style('font-size', (d) => {
        if(window.innerWidth < 1400){
            return 10;
        }        
        if(zoom > 100){
          return 10;
        }
        return 12;
      })
      .attr("dx", (d) => {
        return (this.variant == 'mini') ? -15 : -30
      })
      .attr('fill', (d) => {
        if(d.data.isEnabled == false){
          return '#ccc';
        }
        return '#000'
      })
      .attr("dy", ".2em")
      .attr("text-anchor", "start")
      .text((d) => { 
        if(this.variant == 'mini'){
          if(d.data.shortCode){
          let name =  (d.data.shortCode.length > 5)?  d.data.shortCode.substring(0,4) + '..' : d.data.shortCode ;
            return name;
          }
        }
        return d.parent ? (d.data.name.length>11 ? d.data.name.substring(0,8) + '..' :d.data.name) : "";
      })
      .attr('font-family', 'Open Sans', 'Inter','Roboto')
      .on("mouseover", function (d) { 
        if(d.data.isEnabled)
          d3.select(this).style("cursor", "pointer");      
        tooltip.text(d.data.name); return tooltip.style("visibility", "visible"); 
      })
      .on("mousemove", function () { return tooltip.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px"); })
      .on("mouseout", function () { return tooltip.style("visibility", "hidden"); })
      .on('click', function(d) {
        if(d.data.isEnabled == false){
          return false;
        }
        d.data.isSelected = !d.data.isSelected
        self.messageService.sendMessage('sunburstSelectionChanged', d)
        return tooltip.style("visibility", "hidden");
      })

    function computeTextRotation(d) {
      var angle = (d.x0 + d.x1) / Math.PI * 90;      
      return (angle < 180) ? angle - 90 : angle + 90;
    }

    function explode(d, index) {
      var offset = 10;
      var angle = (d.startAngle + d.endAngle) / 2;
      var xOff = Math.sin(angle) * offset;
      var yOff = -Math.cos(angle) * offset;
      return 'translate(' + xOff + ',' + yOff +')';
    }
  
  }

  fillColorAsperSelection(d, color, elem=null){
    if (d.data.isSelected) {
      if (d.children) {
        d.children.forEach(child => {
          if (child.isSelected) {
            return '#C5AB2E';
          }
          return color((d.children ? d : d.parent).data.name);
        })
      }
      if(elem == null){
      //if((d.children ? d : d.parent).data.isSelected){
      d3.select(this)
        .transition()
        .duration(500)
      return '#C5AB2E';
      }
      if(elem != null){
        
        d3.select(elem)
        .transition()
        .duration(500)
      return '#C5AB2E';
      }
    }
    return color((d.children ? d : d.parent).data.name);
  }

  getSizes() {
    let zoom = ((window.outerWidth - 10)
        / window.innerWidth) * 100;

    this.getSelectedTowers().then(result => {
      this.towers = result;
      this.drawSunburst(result, zoom)
    })
    return zoom.toFixed(2);
}

  

}