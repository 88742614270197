import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent, RowClassParams, RowNode, RowSelectedEvent, RowStyle, SelectionChangedEvent } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { DataService } from '../data.service';
import { InputService } from '../input.service';
import { ActivatedRoute } from '@angular/router';
import { AgGridEditDisplayComponent } from '../ag-grid-edit-display/ag-grid-edit-display.component';
import { DataUpdateService } from '../data-update.service';
import { config } from 'src/environments/config';
import { MillionPipe } from '../million.pipe';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  host: { 'class': 'roles-list-component' },
  styleUrls: ['./roles-list.component.scss'],
  providers: [MillionPipe]
})
export class RolesListComponent implements OnInit, OnDestroy {
  showSelectedOption: boolean = false;
  domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
  rowHeight = 30;
  headerHeight = 30;
  rowGroupPanelShow: any;
  rowData: any = [];
  private gridApi: GridApi = null;
  selectedRows: any;
  @Output() keyPressed = new EventEmitter<{}>();
  showSelectRow: boolean = false;
  selectedLocations = [];
  selectedRoles = [];
  gridParams;
  selectedRolesObservable: Subscription;
  selectedSpend: string = "discrete";
  allLocation: string = 'split';
  noRowsTemplate;
  selectedSpt: string;


  spts$ = liveQuery(() => this.getSPTFromDb())
  loadedPageType;

  spendDropdown: any = [{
    id: 1,
    text: "Discrete Spend",
    value: 'discrete'
  }, {
    id: 2,
    text: "Role Spend",
    value: 'role'
  }, {
    id: 3,
    text: "Total Spend",
    value: 'total'
  }
  ];

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    { headerName: 'Selected Roles', filter: false, field: 'role', checkboxSelection: false, width: 180, pinned: true, editable: false, headerClass: 'label-align-RU' },
    {
      headerName: 'Role spend ($)',
      field: 'totalSpend',
      checkboxSelection: false,
      width: 200, pinned: true,
      colId: 'totalSpend',
      cellClass: 'oat-total-spend',
      editable: true,
      hide: true,
      valueSetter: (param) => {
        param.data['totalSpend'] = config.getNumber(param);
        return true;
      },
      valueFormatter: (param) => this.millionPipe.transform(param.value)
    },
    {
      headerName: 'All Locations (#)',
      field: 'location_0',
      checkboxSelection: false,
      width: 150,
      colId: '0',
      hide: true,
      editable: true,
      valueFormatter: (params) => this.checkTotalSpendFunction(params),
      valueSetter: (param) => {
        param.data.location_0 = config.getNumber(param);
        return true;
      }
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: false,
    suppressMovable: true
  };

  public gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    getRowId: params => params.data.roleId,
    getRowStyle: (params: RowClassParams): RowStyle | undefined => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return {};
    },
  };

  constructor(private dataUpdateService: DataUpdateService,
    private _service: ApiService,
    private dataService: DataService,
    private inputService: InputService,
    private router: ActivatedRoute,
    private dataUpdate: DataUpdateService,
    private millionPipe: MillionPipe) {
    this.noRowsTemplate = `<span class="error-text">&#9432; Select atleast one role from FTE Rate Benchmarking table to perform opportunity sizing assessment.</span>`;
  }

  liveQuerySubscription = new Subscription();

  ngOnInit(): void {
    this.dataUpdate.subscribeDataChangeEvent().subscribe(event => {
      if (event == 'allRolesSelectionChanged') {
        this.getSelections().then(result => {

          this.selectedLocations = result.locations;
          this.addColumn();
          this._getRolesList();
        })
      }
    })

    this.liveQuerySubscription.add(liveQuery(() => this.getSelections()).subscribe(result => {
      if (result.towers.length == 0) {
        this.noRowsTemplate = `<span class="error-text">&#9432; Please select atleast one Tower/Sub-Tower to view the benchmark data.</span>`;
        this.removeTotalSpendInputRow();
      }
      this.selectedLocations = result.locations;
      this.addColumn();
      this._getRolesList();
    }));

    this.router.params.subscribe(par => {
      ;
      this.loadedPageType = par['type'];
      if (par['type'] == 'ru') {
        this.columnDefs[0].headerName = 'Selected RUs';
        this.columnDefs[1].headerName = 'RU spend';
        this.noRowsTemplate = '<span class="error-text">&#9432; Select atleast one RU from RU Rate Benchmarking table to perform opportunity sizing assessment.</span>'

        this.spendDropdown[1]['text'] = 'RU Spend';
        this.spendDropdown.splice(2, 0, {
          id: 4,
          text: "Location Spend",
          value: 'location'
        })
      }
    })

  }

  _getRolesList() {
    this.getRolesFromDB().then(roles => {
      //liveQuery(() => this.getRolesFromDB()).subscribe(roles => {
      this.selectedRoles = roles;
      let rows = [];
      if (!this.rowData || this.rowData.length == 0) {
        //this.rowData = roles;
        roles = roles.sort((a, b) => {
          if (a.tower != b.tower)
            return a.tower - b.tower;
          else if (a.subTowerId != b.subTowerId)
            return a.subTowerId - b.subTowerId;
          else
            return a.id - b.id;
        });
        roles.forEach((role: any) => {
          let locationsData = {}
          role.inputs.forEach(input => {
            // let val = input.headcount.toString().indexOf('.') >= 0 ? input.headcount.toFixed(2) : input.headcount.toFixed(0);
            let val = config.formatNumber(input.headcount);
            locationsData['location_' + input.location] = val;
          })

          if ('spend' in role)
            locationsData['totalSpend'] = config.formatNumber(role['spend']);
          rows.push({ roleId: role.id, role: `${role.role} (${role.subtowerShortcode})`, ...locationsData })
        });

        this.gridApi.setRowData([])
        this.gridApi.setRowData(rows)
        this.getRoleFiltersFromDb(); //Row pinning should happen after row data is initialized
      }
    })
  }

  async getSPTFromDb() {
    let spt = await (await db.selection_providerTypes.toArray()).filter(x => x.isSelected == true);
    let record = (await db.role_filter.toArray()).filter(rf => {
      return rf.type == 'spt';
    })
    if (record.length > 0) {
      this.selectedSpt = record[0].value;
    }
    else {
      this.selectedSpt = spt[0].id.toString();
    }
    return spt;
  }

  async changeSptFilter(event) {
    await this.inputService.updateFilterSelection('spt', event.value);
  }

  async clearData() {
    let ids = await db.inputs.where('role').notEqual('').primaryKeys();
    await this.dataService.deleteRecordFromTable('inputs', ids)
    let spendIds = await db.spends.where('role').notEqual('').primaryKeys();
    await this.dataService.deleteRecordFromTable('spends', spendIds);

    this._getRolesList();
  }

  async getRoleFiltersFromDb() {
    let filters = await db.role_filter.toArray();
    filters.forEach(async f => {
      if (f.type == 'role location') {
        this.allLocation = f.value;
        await this.locationChange(f)
      }
      if (f.type == 'role spend') {
        this.selectedSpend = f.value;
        await this.spendChange(f);
      }
    })
    if (this.selectedSpend == 'discrete') {
      this.dataUpdate.emitDataChangeEvent('discreteSelected')
    }
  }

  async spendChange(e) {
    this.columnDefs.forEach(col => {
      if (col.field == 'totalSpend')
        col.hide = this.selectedSpend == 'role' ? false : true;
    })

    if (this.allLocation != 'split') {
      this.gridApi.setColumnDefs(this.columnDefs);
    }
    else {
      this.addColumn();
    }

    await this.addTotalSpendInputRow();

    this.gridOptions.api!.redrawRows();

    await this.inputService.updateFilterSelection('role spend', e.value);
  }

  async addTotalSpendInputRow() {

    let totalSpendInputRow = {
      "roleId": 0,
      "role": "Total Spend",
      "label": "Total Spend"
    }
    let locationSpendInputRow = {
      roleId: 0,
      "role": "Location Spend",
      "label": "Location Spend"
    }

    switch (this.selectedSpend) {
      case 'total':
        let totalSpend = await this.dataService.getTotalSpend();
        totalSpendInputRow[this.getColNameForTotalSpend()] = config.formatNumber(totalSpend);
        this.gridApi!.setPinnedBottomRowData([totalSpendInputRow]);
        break;

      case 'location':
        let locationSpends = await db.spends.where('role').equals(0).toArray();
        this.selectedLocations.forEach(location => {
          let spend = locationSpends.filter(x => x.location == location.id && x['spendSelection'] == 'location');
          locationSpendInputRow['location_' + location.id] = spend.length > 0 ? spend[0]['spend'] : 0;
        })
        this.gridApi!.setPinnedBottomRowData([locationSpendInputRow])
        break;

      default:
        this.gridApi!.setPinnedBottomRowData([]);
    }

  }

  async removeTotalSpendInputRow() {
    await db.spends.clear();
    setTimeout(() => {
      this.gridApi!.setPinnedBottomRowData([]);
      this.gridOptions.api!.redrawRows();
    }, 100);
  }

  getColNameForTotalSpend() {
    if (this.allLocation == 'split')
      return 'location_' + this.selectedLocations[0].id;
    else
      return 'location_0';
  }

  async locationChange(e) {
    this.columnDefs.forEach(col => {
      if (col.field == 'location_0')
        col.hide = this.allLocation == 'split' ? true : false;
    })

    if (this.allLocation != 'split') {
      this.gridApi.setColumnDefs(this.columnDefs);
    }
    else {
      this.addColumn();
    }

    await this.addTotalSpendInputRow();

    this.gridOptions.api!.redrawRows();

    await this.inputService.updateFilterSelection('role location', e.value);
  }

  async getRolesFromDB() {
    let allRoles = await this.dataService.getRolesInputs();
    return allRoles.filter(role => role.isSelected == true);
  }

  async getInputsFromDB() {
    let roles = await db.selection_roles.toArray();
    let rolesIds = roles.map(role => { return role['roleId'] });
    return await db.inputs.where('role').anyOf(rolesIds).toArray();
  }

  async getSelections() {
    return await this.dataService.getByRoleSelectionChanges();
  }

  ngOnDestroy(): void {
    try {
      this.liveQuerySubscription.unsubscribe();
      this.selectedRolesObservable.unsubscribe();
    } catch (e) { }
  }

  showOnlySelected() {
    this.showSelectRow = !this.showSelectRow;
    Global_filterSelectedDocuments = this.showSelectRow;
    this.gridApi.onFilterChanged(); // nesseary notification to enable the filter
  }

  addColumn() {
    if (this.allLocation == 'split') {
      let columnDefs = [...this.columnDefs];
      this.selectedLocations.forEach((location, index) => {
        let obj = {
          colIndex: index,
          colId: location.id,
          field: 'location_' + location.id,
          columnGroupShow: 'open',
          headerName: location.locationName + ' (#)',
          width: 120,
          filter: false,
          //cellRenderer: AgGridEditDisplayComponent,
          cellClass: (params) => this.checkEditFunction(params) ? 'input-form-control' : "",
          editable: (params) => this.checkEditFunction(params),
          valueFormatter: (params) => this.checkTotalSpendFunction(params),
          valueSetter: (param) => {
            param.data['location_' + location.id] = config.getNumber(param);
            return true;
          }
        }
        columnDefs.push(obj);
      })

      this.gridApi.setColumnDefs(columnDefs);
    }
  }

  checkTotalSpendFunction(params) {
    let value;
    if (params.node.isRowPinned() && ((this.selectedSpend == 'total' && (params.colDef.colIndex == 0 || params.colDef.colId == '0')) || this.selectedSpend == 'location'))
      value = this.millionPipe.transform(params.value);
    else if (!params.node.isRowPinned())
      value = config.formatNumber(params.value);
    else
      value = '';
    return value;
  }

  checkEditFunction(params) {
    //params.node - for row identity
    //params.column - for column identity
    let editable = true;
    if (params.node.isRowPinned() && params.colDef.colIndex != 0 && this.selectedSpend == 'total')
      editable = false;
    return editable// - just as sample
  }

  onGridReady(params: GridReadyEvent) {
    this.gridParams = params;
    this.gridApi = params.api;
  }

  async onCellValueChange(e: any) {
    let roleId = e.data.roleId;
    let colId = e.colDef.colId;
    if (roleId == 0 && this.selectedSpend == 'location') {
      let params = {
        role: e.data.roleId,
        location: colId,
        spendSelection: this.selectedSpend,
        spend: parseFloat(e.value ? e.value : "0")
      }
      await this.inputService.setSpendValue(params);
    }
    else if (colId == 'totalSpend' || roleId == 0) {
      let params = {
        role: e.data.roleId,
        location: 0,
        spendSelection: this.selectedSpend,
        spend: parseFloat(e.value ? e.value : "0")
      }
      await this.inputService.setSpendValue(params);
    }
    else {
      let params = {
        location: colId,
        role: e.data.roleId,
        headcount: parseFloat(e.value ? e.value : "0"),
        locationSelection: this.allLocation
      }
      await this.inputService.setInputValue(params);
      if (colId == '0')
        this._getRolesList();
    }
  }

  isExternalFilterPresent() {
    return Global_filterSelectedDocuments; // Here we have no access to the 'this.' of the component. Use a Global Variable instead.
  }

  doesExternalFilterPass(node: any) {
    return node.isSelected();  // Show only selected nodes
  }

  onFirstDataRendered(params: any) {
    params.api.forEachNode((node) =>
      node.setSelected(node.data.isSelected)
    );
  }
}

// Used in isExternalFilterPresent() to detect if (external)filter is active or not
let Global_filterSelectedDocuments: boolean;