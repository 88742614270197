<!-- <table>
    <tr>
        <th>Name</th>
        <th>US tier1</th>
    </tr>
    <tr *ngFor="let tower of selectedTowers">
        <td>{{tower.tower}}</td>
        <app-detailed-tower-analysis></app-detailed-tower-analysis>
    </tr>
</table> -->
<table class="table">
    <tr>
        <td>Tower</td>
        <td style="width:100px;" *ngFor="let location of selectedLocations">{{location.locationName}}</td>
    </tr>
    <tr *ngFor="let tower of selectedTowers">
        <td [colSpan]="selectedLocations.length">
            <table style="width: 100%;">
                <tr>
                    <td>
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{tower.tower}}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        <table class="table">
                                            <tr>
                                                <td style="width: 100px;" *ngFor="let location of selectedLocations">{{location.locationName}}
                                                </td>
                                            </tr>
                                        </table>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <app-detailed-tower-analysis></app-detailed-tower-analysis>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </td>
                </tr>
            </table>

        </td>
    </tr>
</table>