import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ru-box-container',
  templateUrl: './ru-box-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./ru-box-container.component.scss']
})
export class RuBoxContainerComponent implements OnInit {
  @Input() locat: any;
  @Input() width: any;
  @Input() role: any;
  @Input() spt: any;
  @Input() minMaxRoles = {};
  constructor() { }

  ngOnInit(): void {
  }

  formatNumber(value) {
    return (value < 10)? value.toFixed(2) : value.toFixed(0);
  }

  canBeDisplayed(column){
    let buckets = this.minMaxRoles[this.locat.id][this.spt.id]['buckets']
    let valueToBeChecked = this.role['rateSPT'][this.locat['id']][this.spt['id']][75];
    let result = false;
    switch(column){
      case 1:
        result = valueToBeChecked < buckets[1];
        break;
      case 2:
        result = valueToBeChecked > buckets[1] && valueToBeChecked < buckets[2];
        break;
      case 3:
        result = valueToBeChecked > buckets[2];
        break;
    }
    return result;
  }

}
