import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tower-subtower-selector',
  templateUrl: './tower-subtower-selector.component.html',
  styleUrls: ['./tower-subtower-selector.component.scss']
})
export class TowerSubtowerSelectorComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }  
  ngOnInit(): void {
    console.warn(this.data)
  }

}
