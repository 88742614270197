<div class="login-main">
    <div class="form-div">
      <div class="loginpage-logo">
        <img src="assets/img/hex-logo.png" alt="logo">
      </div>
      <!-- loginpage-logo-end -->
  
      <div class="login-form">
        <h2>Reset Password</h2>
        <form class="example-form">
            <div class="form-email">
            <mat-label class="label">Old Password</mat-label>
            <mat-form-field class="example-full-width" appearance="fill">
              <input matInput placeholder="Enter old password" aria-autocomplete="off">
            </mat-form-field>
            </div>
             <!-- form-email-end -->

             <div class="form-email">
                <mat-label class="label">New Password</mat-label>
                <mat-form-field class="example-full-width" appearance="fill">
                  <input matInput placeholder="Enter password" aria-autocomplete="off">
                </mat-form-field>
                </div>
                 <!-- form-email-end -->

                     <div class="form-email">
                        <mat-label class="label">Confirm Password</mat-label>
                        <mat-form-field class="example-full-width" appearance="fill">
                          <input matInput placeholder="Enter password" aria-autocomplete="off">
                        </mat-form-field>
                        </div>
                         <!-- form-email-end -->


                   <div class="form-email login-btn res-btn">
                    <a mat-flat-button routerLink="/">Confirm</a>
                   </div>
                   <!-- form-email-end -->
          </form>
  
      </div>
      <!-- login-form-end -->
  
      <footer>
        <p>© 2022 Hex Advisory Group</p>
      </footer>
    </div>
    <!-- form-div-end -->
  
  
    <div class="blue-bg-div">
  
    </div>
    <!-- blue-bg-div -->
  </div>
  <!-- login-main-end -->



