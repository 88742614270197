import { Component, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataService } from '../data.service';
import * as ApexCharts from 'apexcharts';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { MillionPipe } from '../million.pipe';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SavingsModalComponent } from '../savings-modal/savings-modal.component';
import { environment } from 'src/environments/environment';
import { SavingsAnalysisService } from '../services/savings-analysis.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-savings-analysis-role',
  templateUrl: './savings-analysis-role.component.html',
  styleUrls: ['./savings-analysis-role.component.scss'],
  providers: [MillionPipe]
})
export class SavingsAnalysisRoleComponent implements OnInit, OnDestroy {

  constructor(private dataService: DataService,
    public dialog: MatDialog,
    private millionPipe: MillionPipe,
    private route: ActivatedRoute,
    private savingsService: SavingsAnalysisService) {
  }

  @Input() benchmarkPoint: string = '50';
  @Input() isModal: boolean = false;
  @Input() selectionMode?: string = 'tower';
  @Input() pdfExport?: boolean = false;
  @Output() emitCalculations = new EventEmitter<any>();
  @Output() graphOptions = new EventEmitter<any>();

  @Input() annualHours: number;
  @Input() loadedPageFromParent: string = '';
  byLocation = 'tower';
  type: string; //"discrete","role";
  spt: number;
  skillFactor: number = 1;
  public chartTower: ApexCharts;
  public chartLocation: ApexCharts;
  roles: any[] = [];
  totalSpend = 0;
  totalSavings = 0;
  locations = [];
  towers = [];
  targetSpend: number = 0;
  liveQuerySubscription = new Subscription();
  ratesMissing = false;
  isCurrent: boolean = false;
  displayLoader: boolean = false;
  errorMessage: string = '';
  loadedPage: string = '';
  tooltipMessage: any = '';

  ngOnInit(): void {

    if (this.isModal)
      this.displayLoader = true;
    this.savingsService.getBenchmarkPoint().subscribe(res => {
      this.benchmarkPoint = res;
      this.getCalculatedData();
    })
    if (!this.annualHours) {
      this.route.params.subscribe(param => {
        this.loadedPage = param['type']
        switch (param['type']) {
          case 'ru':
            this.annualHours = 12;
            break;
          default:
            this.annualHours = 1920;
        }

      })
    }
    this.liveQuerySubscription.add(liveQuery(() => this.getDataFromDB()).subscribe(async (data) => {
      this.roles = data;
      await this.getCalculatedData();
    }));

    this.liveQuerySubscription.add(liveQuery(() => { db.spends.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))

    this.liveQuerySubscription.add(liveQuery(() => { db.input_plot.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))

    this.liveQuerySubscription.add(liveQuery(() => { db.inputs.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))

    this.liveQuerySubscription.add(liveQuery(() => { db.role_filter.toArray() }).subscribe(data => {
      this.getErrorMessages()
    }))

  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
    if (this.chartTower != undefined)
      this.chartTower.destroy();
    if (this.chartLocation != undefined)
      this.chartLocation.destroy();
  }

  setBenchmark() {
    this.savingsService.setBenchmarkPoint(this.benchmarkPoint);
  }

  async getCalculatedData() {
    if (this.selectionMode != '' && this.pdfExport == true) {
      this.byLocation = this.selectionMode;
    }
    this.displayLoader = true;
    let result = {
      categories: [],
      series: []
    };
    let color = [];
    let targetSpendChartData = await this.getSelectedTowers();
    if (this.type == 'role') {

      if (this.byLocation == 'location') {
        result = targetSpendChartData;
      }
      else
        result = await this.getRoleSpendChartData(targetSpendChartData);
      color = this.getColorArray(this.locations.length, false);
    }
    else if (this.type == 'location') {
      result = await this.getLocationSpendChartData(targetSpendChartData);

      if (this.byLocation == 'tower') {
        result = targetSpendChartData;
      }
      color = this.getColorArray(this.towers.length, false);
    }
    else if (this.type == 'discrete' && this.byLocation == 'tower') {
      result = await this.getDiscreteData(targetSpendChartData);
      color = this.getColorArray(this.locations.length, true);
    }
    else if (this.type == 'discrete' && this.byLocation == 'location') {
      result = await this.getDiscreteDataByLocation(targetSpendChartData);
      color = this.getColorArray(this.towers.length, true);
    }
    else {
      result = targetSpendChartData;
      color = [...environment.newColorScheme];
    }

    this._checkIfCurrentPresent(result)
    
    this.displayLoader = false;
    if (this.isModal)
      this.createOptions(result, color);
    this._parseResultAndDeriveMessage()
    this.calculateTotalSavings();
  }

  private _checkIfCurrentPresent(result) {
    let currentGroupPresent = result.series.filter(ser => { return ser.group == 'current' })
    this.isCurrent = (currentGroupPresent.length == 0) ? false : _.flatten(currentGroupPresent.map(cg => { return cg.data })).some(el => el > 0);
  }

  calculateTotalSavings() {
      this.totalSavings = this.totalSpend - this.targetSpend;
    //this.totalSavings = this.totalSpend > this.targetSpend ? this.totalSpend - this.targetSpend : 0;
    this.emitCalculations.emit({
      raw: {
        totalSpend: this.totalSpend,
        targetSpend: this.targetSpend,
        totalSavings: this.totalSavings
      },
      totalSpend: this.millionPipe.transform(this.totalSpend), targetSpend: this.millionPipe.transform(this.targetSpend), totalSavings: this.millionPipe.transform(this.totalSavings)
    })
  }

  async getDiscreteData(targetSpendChartData) {
    return await this._getCurrentSpendBreakup(targetSpendChartData)
  }

  async getDiscreteDataByLocation(targetSpendChartData) {
    return await this._getCurrentSpendBreakup(targetSpendChartData, 'location')
  }

  private async _getCurrentSpendBreakupNew(targetSpendChartData, breakBy = 'tower') {

    let totalSpendArray = [];
    let inputs = await db.inputs.toArray();
    let annualHours = this.annualHours;
    let inputPlots = (await db.input_plot.toArray()).filter(ip => { return ip.serviceProvider == this.spt });
    let towers = await db.towers.toArray();
    let locations = await db.locations.toArray();
    towers = _.groupBy(towers, _.iteratee('id'))
    locations = _.groupBy(locations, _.iteratee('id'))
    let roles = _.groupBy(this.roles, _.iteratee('id'))
    inputs.forEach((ip: any) => {
      ip['tower'] = towers[roles[ip.role][0]['tower']][0]['tower']
      ip['locationName'] = locations[ip.location][0]['locationName']
    })
    inputPlots.forEach((ip: any) => {
      ip['tower'] = towers[roles[ip.role][0]['tower']][0]['tower']
      ip['locationName'] = locations[ip.location][0]['locationName']
    })
    let groupByArray = ['locationName', 'tower'];
    if (breakBy == 'tower') {
      groupByArray = ['tower', 'locationName']
    }
    let inputGrouped = this._deepGroup(inputs, groupByArray)
    let inputPlotrouped = this._deepGroup(inputPlots, groupByArray)

    _.forEach(inputGrouped, function (value, key) {
      let totalSpend = 0;
      _.forEach(value, function (value2, key2) {
        _.forEach(value2, function (input, key3) {
          try {
            let inputPlot = inputPlotrouped[key][key2][0]['inputValue']
            totalSpend = input.headcount * inputPlot
          } catch (e) { }
        })
        value['totalSpend'] = totalSpend * annualHours
      })
      let currentSpend = { name: `${key} (Your Spend)`, group: 'current', data: [] };
      currentSpend.data.push(value.totalSpend)
      targetSpendChartData.series.unshift(currentSpend);
      totalSpendArray.push(value['totalSpend'])
    })


    this.totalSpend = _.sum(totalSpendArray)

    return targetSpendChartData;
  }

  private async _getCurrentSpendBreakup(targetSpendChartData, breakBy = 'tower') {
    let categoriesMaster = targetSpendChartData.categories;
    let discreteTotalSpend = 0;
    let locations = await this.dataService.getDataFromDb('locations', {}, true);
    this.ratesMissing = false;
    let towers = await (await db.selection_towers.toArray()).filter(x => x.isSelected == true)
    let currentBreakup = {};
    let currentlocation = [];
    towers.sort().forEach(tower => {
      locations.sort().forEach(location => {
        let parentKey = location.locationName;
        let nestedKey = tower.id;
        if (breakBy == 'location') {
          parentKey = tower.tower;
          nestedKey = location.id;
        }
        let totalSpend = 0;
        if (typeof currentBreakup[parentKey] === 'undefined') {
          currentBreakup[parentKey] = {};

          currentlocation.push(parentKey);
        }
        currentBreakup[parentKey][nestedKey] = { tower: tower.id, towerName: tower.tower, location: location.id, location_name: location.locationName, totalSpend: 0 }

        this.roles.filter(role => role.tower == tower.id).forEach(role => {
          let inputs = role.inputs.filter(x => x.location == location.id);
          let inputPlot = (role.inputPlots[location.id] && role.inputPlots[location.id][this.spt]) ? role.inputPlots[location.id][this.spt] : 0;
          if (inputs.length > 0) {
            inputs = inputs[0];
            totalSpend = totalSpend + (inputs.headcount * inputPlot)
            currentBreakup[parentKey][nestedKey]['totalSpend'] = totalSpend * this.annualHours;
            if (inputPlot == 0)
              this.ratesMissing = true;
          }
        })
        let finalspend = totalSpend * this.annualHours;
        discreteTotalSpend += finalspend;
      })
    })
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    currentlocation.forEach(key => {
      let currentSpend = { name: `${key} (Your Spend)`, group: 'current', data: range(0, categoriesMaster.length - 1, 1).fill(0) };
      _.forEach(currentBreakup[key], (spends, key) => {
        if (breakBy == 'location')
          currentSpend.data[categoriesMaster.indexOf(spends.location_name)] = spends.totalSpend
        if (breakBy == 'tower')
          currentSpend.data[categoriesMaster.indexOf(spends.towerName)] = spends.totalSpend
      })
      // if (currentSpend.data.some(x => x > 0))
      targetSpendChartData.series.push(currentSpend);
    })
    this.totalSpend = discreteTotalSpend;
    return targetSpendChartData;
  }

  async getRoleSpendChartData(targetSpendChartData) {
    let rolesTotalSpend = 0;
    this.ratesMissing = false;
    let towers = await (await db.selection_towers.toArray()).filter(x => x.isSelected == true)
    let currentSpend = {
      name: 'Your Spend',
      group: 'current',
      data: []
    };
    towers.forEach(tower => {
      let totalSpend = 0;
      let rolelist = this.roles.filter(role => role.tower == tower.id)
      if (rolelist.length > 0) {
        rolelist.forEach(role => {
          totalSpend = totalSpend + role.spend;
          if (!role.spend || role.spend == 0)
            this.ratesMissing = true;
        })
        currentSpend.data.push(totalSpend);
      }
      rolesTotalSpend += totalSpend;
    })
    if (currentSpend.data.some(x => x > 0))
      targetSpendChartData.series.splice(0, 0, currentSpend);
    this.totalSpend = rolesTotalSpend;

    return targetSpendChartData;
  }

  async getLocationSpendChartData(targetSpendChartData) {
    let locationTotalSpend = 0;
    this.ratesMissing = false;
    let locations = await (await this.dataService.getDataFromDb('locations', {}, true)).filter(x => x.isSelected == true);
    let locationSpends = await db.spends.where('role').equals(0).toArray();
    let currentSpend = {
      name: 'Your Spend',
      group: 'current',
      data: []
    };
    locations.forEach(location => {
      let spend = locationSpends.filter(x => x.location == location.id && x['spendSelection'] == 'location');
      let locationSpend = spend.length > 0 ? spend[0]['spend'] : 0
      // if ((spend.length>0 && spend[0]['spend']) || spend == 0)
      // this.ratesMissing = true;
      currentSpend.data.push(locationSpend);
      locationTotalSpend += locationSpend;
    })
    if (this.byLocation == 'location' && currentSpend.data.some(x => x > 0))
      targetSpendChartData.series.splice(0, 0, currentSpend);
    this.totalSpend = locationTotalSpend;
    return targetSpendChartData;

  }

  createOptions(data, color) {
    data.series = _.sortBy(data.series, ['group'])

    if (this.pdfExport) {
      this.createOptionsForPdfExport(data, color);
    }
    var optimalColumnWidthPercent = 60;
    if (!this.isCurrent) {
      optimalColumnWidthPercent = 30;
    }
    var options = {
      zoom: {
        enabled: true,
        type: "xy"
      },
      series: data.series,
      chart: {
        type: 'bar',
        height: (this.pdfExport) ? 600 : 365,
        stacked: true,
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        formatter: (val) => this.millionPipe.transform(val)
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: optimalColumnWidthPercent + '%'
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return this.millionPipe.transform(val)
          }
        }
      },
      xaxis: {
        labels: {
          offsetY: 5,
        },
        categories: data.categories
      },
      fill: {
        opacity: 1,
      },
      colors: color,
      legend: this.getLegends(),
      annotations: {
        points: this._getAnnotations(data)
      },
      grid: {
        padding: {
          bottom: 40
        }
      }
    };

    if (this.byLocation == 'tower') {
      if (this.chartTower != undefined)
        this.chartTower.destroy()
      this.chartTower = new ApexCharts(document.querySelector("#savingsRoleTower"), options);
      this.chartTower.render();
    }
    else {
      if (this.chartLocation != undefined)
        this.chartLocation.destroy();
      this.chartLocation = new ApexCharts(document.querySelector("#savingsRoleLocation"), options);
      this.chartLocation.render();
    }
  }

  _formatter(value, decimals = 2) {
    value = value?.toString().replaceAll(',', '') ?? 0;
    if (value != '' && !isNaN(value)) {
      value = parseFloat(value)
    }
    else
      return '$0.00';

    let isDecimal = value.toString().indexOf('.') > -1;
    let digits = isDecimal ? value.toString().indexOf('.') : value.toString().length;

    if (digits < 4)
      return '$' + (isDecimal ? value.toFixed(decimals) : value);
    else if (digits >= 4 && digits < 7) {
      value = value / 1000;
      return '$' + (value.toFixed(decimals)) + 'K';
    }
    else if (digits >= 7 && digits < 10) {
      value = value / 1000000;
      return '$' + (value.toFixed(decimals)) + 'M';
    }
    else {
      value = value / 1000000000;
      return '$' + (value.toFixed(decimals)) + 'B';
    }
  }


  createOptionsForPdfExport(data, color) {
    var optimalColumnWidthPercent = 60;
    if (!this.isCurrent) {
      optimalColumnWidthPercent = 30;
    }
    var options = {
      zoom: {
        enabled: true,
        type: "xy"
      },
      series: data.series,
      chart: {
        type: 'bar',
        height: (this.pdfExport) ? 600 : 365,
        stacked: true,
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        formatter: "__CALLBACK_PLACEHOLDER__"
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: optimalColumnWidthPercent + '%'
        }
      },
      yaxis: {
        labels: {
          formatter: "__CALLBACK_PLACEHOLDER__"
        }
      },
      xaxis: {
        labels: {
          offsetY: 5,
        },
        categories: data.categories
      },
      fill: {
        opacity: 1,
      },
      colors: color,
      legend: this.getLegends(),
      annotations: {
        points: this._getAnnotations(data)
      },
      grid: {
        padding: {
          bottom: 40
        }
      }
    };
    this.graphOptions.emit(options)
  }

  getColorArray(noOfColorRequired, isCurrentSplit = false) {
    let color = [...environment.newColorScheme].slice(0, noOfColorRequired);
    let newColor = [];
    if (isCurrentSplit) {
      //for discrete we have to duplicate colors for current and benchmark data series
      newColor = [...color, ...color];
    }
    else {
      //for role level we use black color for current data series
      newColor = ['#000', ...color]
    }
    return newColor;

    /*Old Approach*/

    // let colorScheme = color;
    // if (this.isCurrent)
    //   colorScheme.splice(0, 0, '#000');
    // return colorScheme;
  }

  getLegends() {
    let arr = [];
    if (this.byLocation == 'tower')
      arr = this.locations.map(x => x.locationName)
    else
      arr = this.towers.map(x => x.tower);
    let color = [...environment.newColorScheme];

    let legend = {
      position: 'top',
      horizontalAlign: 'left',
      showForSingleSeries: true,
      customLegendItems: arr,
      markers: {
        fillColors: [...color.slice(0, arr.length)]
      }
    }
    return legend;
  }

  async getDataFromDB() {
    //let result = await db.spends.count();
    this.skillFactor = await this.dataService.getSkillFactor();
    let filter = await this.getFiltersFromDB();
    if (this.type == 'total')
      this.totalSpend = await this.dataService.getTotalSpend('role');

    let allData = await this.dataService.getRolesWithRatesInputPlot(true);
    return allData.filter(tower => tower.isSelected == true);
  }

  async getFiltersFromDB() {
    let filters = await db.role_filter.toArray();
    filters.forEach(f => {
      if (f.type == "role spend")
        this.type = f.value;
      else if (f.type == "spt")
        this.spt = f.value;
    })
    if (this.type == undefined)
      this.type = 'discrete';
    return filters;
  }

  private _getAnnotationConfig(category, type = 'Your Spend') {
    let options = {
      x: category,
      y: 0,
      marker: {
        size: 0,
        fillColor: "transparent",
        //strokeColor: "#2698FF",
        radius: 0
      },
      label: {
        borderColor: "transparent",
        offsetY: 25,
        offsetX: -50,
        style: {
          color: "#000000DE",
          //background: "#c5ac2e"
        },
        text: type
      }
    }
    if (type != 'Benchmark Spend') {
      options.label.offsetX = (this.isCurrent) ? 50 : 0;
    }
    return options;
  }

  private _getAnnotations(data) {
    let annotations = [];
    data.categories.forEach(category => {
      if (this.isCurrent)
        annotations.push(this._getAnnotationConfig(category))
      annotations.push(this._getAnnotationConfig(category, 'Benchmark Spend'))
    })
    return annotations;
  }

  async getSelectedTowers() {
    this.locations = await this.dataService.getDataFromDb('locations', {}, true);
    let inputs = await db.inputs.toArray();
    let roles = await (await db.selection_roles.toArray()).filter(x => x.isSelected == true);
    let rolesIds = roles.map(role => { return role['id'] });
    let rates = await db.rateList.where('role').anyOf(rolesIds).toArray();
    this.towers = await (await db.selection_towers.toArray()).filter(x => x.isSelected == true)
    if (this.byLocation == 'tower') {
      return this.calculateTargetSpendByTower(this.locations, roles, this.towers, this.benchmarkPoint);
    }
    else {
      return this.calculateTargetSpendByLocation(this.locations, roles, this.towers, rates, inputs, this.benchmarkPoint);
    }
  }

  calculateTargetSpendByTower(locations: any[], roles: any[], towers: any[], benchmarkPoint: string) {
    let returnData = {
      categories: [],
      series: []
    };
    let series = {};
    let targetSpend = 0;
    towers.forEach(tower => {
      returnData.categories.push(tower.tower);
      let rolelist = roles.filter(x => x.tower == tower.id);
      locations.forEach(location => {
        let value = 0;
        if (rolelist.length > 0) {
          rolelist.forEach(role => {
            let roleData = this.roles.filter(x => x.id == role.id)[0];
            if (roleData) {
              let input = roleData['inputs'].filter(x => x.location == location.id)[0];
              let rate = roleData['rateSPT'][location.id][this.spt][benchmarkPoint];
              if (input != undefined)
                value = value + (input.headcount * rate * this.annualHours);
            }
          })
          targetSpend = targetSpend + value;
        }
        if (!series.hasOwnProperty(location.locationName))
          series[location.locationName] = [];
        series[location.locationName].push(Math.round(value));
      })
    });
    this.targetSpend = targetSpend;
    Object.keys(series).forEach(key => {
      returnData.series.push({ label: key, name: `${key} (Benchmark)`, group: 'benchmark', data: series[key] });
    })
    // ;
    return returnData;
  }

  calculateTargetSpendByLocation(locations: any[], roles: any[], towers: any[], rates: any[], inputs: any[], benchmarkPoint: string) {
    let returnData = {
      categories: [],
      series: []
    };
    let series = {};
    let targetSpend = 0;
    locations.forEach(location => {
      returnData.categories.push(location.locationName);
      towers.forEach(tower => {
        let value = 0;
        let rolelist = roles.filter(x => x.tower == tower.id);
        if (rolelist.length > 0) {
          rolelist.forEach(role => {
            let roleData = this.roles.filter(x => x.id == role.id)[0];
            let input = roleData['inputs'].filter(x => x.location == location.id)[0];
            let rate = roleData['rateSPT'][location.id][this.spt][benchmarkPoint];
            if (input != undefined)
              value = value + (input.headcount * rate * this.annualHours);
          })
        }
        targetSpend += value;
        if (!series.hasOwnProperty(tower.tower))
          series[tower.tower] = [];
        series[tower.tower].push(value);
      })
    });

    this.targetSpend = targetSpend;

    Object.keys(series).forEach(key => {
      returnData.series.push({ name: `${key} (Benchmark)`, group: 'benchmark', data: series[key] });
    })
    return returnData;
  }

  openSavingsAnalysis() {

    const dialogRef = this.dialog.open(SavingsModalComponent, {
      width: '80%',
      panelClass: 'detail-tower-modal',
      data: {
        benchmarkPoint: this.benchmarkPoint,
        type: 'role',
        annualHours: this.annualHours,
        loadedPageFromParent: this.loadedPage.toString(),
        newparam: 122
      }
    });

    ;

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  getErrorMessages() {
    setTimeout(() => {
      this._parseResultAndDeriveMessage()
    }, 500)

    // liveQuery(() => this._getFilters()).subscribe(result => {
    //   //this._parseResultAndDeriveMessage(result)
    // })
  }

  private async _parseResultAndDeriveMessage() {
    this.errorMessage = '';
    let result = await db.role_filter.toArray();
    let data = result.filter(r => { return r.type == 'role spend' })[0]
    let spendData = await db.spends.toArray();
    let inputsData = await db.inputs.toArray();
    let inputGroup = _.groupBy(inputsData, _.iteratee('role'))
    let inputPlotData = await db.input_plot.toArray();
    let inputPlotGroup = _.groupBy(inputPlotData, _.iteratee('role'))

    let selections = await this.dataService.getSelections();
    let identifier = '';
    if (this.loadedPageFromParent != '')
      this.loadedPage = this.loadedPageFromParent;

    // Jugaad because loadedPageFromparent is not getting populated when initialized from Modal
    if (this.loadedPage == undefined || this.loadedPage == '') {
      if (this.annualHours == 12)
        this.loadedPage = 'ru';
      else if (this.annualHours == 1920) {
        this.loadedPage = 'role-level';
      }
    }

    switch (this.loadedPage) {
      case 'role-level':
        identifier = 'roles';
        break;
      case 'blended-rate':
        identifier = 'towers'
        break;
      case 'ru':
        identifier = 'RUs';
        break;
    }


    if (selections['role'].length == 0) {
      if (this.loadedPage == 'ru')
        this.errorMessage = `Select atleast one RU from RU Rate Benchmarking table to perform opportunity sizing assessment.`;
      this.errorMessage = `Select atleast one ${identifier.replace('s', '')} from ${identifier} Benchmarking table to perform opportunity sizing assessment.`
      return;
    }

    switch (data.value) {
      case 'total':
        let sp = spendData.filter(sp => { return sp.role == 0 && sp['spendSelection'] == 'total' });

        if (spendData.length == 0 || sp.length == 0 || sp[0]['spend'] == 0)
          this.errorMessage = 'Please provide total spend.';
        break;

      case 'role':
        let sprole = spendData.filter(sp => { return sp.role != 0 && sp['spendSelection'] == 'role' });
        if (spendData.length == 0 || sprole.length != selections['role'].length || !sprole.every(sp => sp.spend > 0))
          this.errorMessage = `Please provide ${identifier.replace('s', '')} spend for all ${identifier} that have an input headcount.`;
        break;

      case 'discrete':
        if (inputsData.length == 0 || Object.keys(inputGroup).length != Object.keys(inputPlotGroup).length || !inputsData.every(inp => inp['headcount'] > 0))
          this.errorMessage = `Please provide your rate for all ${identifier} that have an input headcount.`;
        break;

      case 'location':
        let locationSpend = spendData.filter(sp => { return sp['spendSelection'] == 'location' && sp.role == 0 })
        if (Object.keys(inputGroup).length != Object.keys(inputPlotGroup).length || locationSpend.length != selections['location'].length || !locationSpend.every(ls => ls.spend > 0))
          this.errorMessage = `Please provide location spend.`;
        break;
    }

  }

  calculatePercentage(total, value) {
    let percentage: any = ((value - total) / total) * 100;
    percentage = formatNumber(percentage, "en-US", "2.1-2").replace(',', '');
    if (value > total)
      this.tooltipMessage = `You spend is ${percentage}% higher than the benchmark spend`;
    else
      this.tooltipMessage = `Your spend is ${formatNumber(Math.abs(percentage), "en-US", "2.1-2")}% lower than the benchmark spend`
    return percentage;
    //return (value/ total) *100;
  }

  private _deepGroup = function (seq, keys) {
    if (!keys.length) return seq;
    var first = keys[0];
    var rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => { return this._deepGroup(value, rest) });
  };
}