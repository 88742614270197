<ng-container *ngIf="!isModal; else  modalDiv">
    <div class="section">
        <div class="region-wrap regionLocation-wrap" *ngIf="loadedPageType != 'ru'">
            <div class="row m0">
                <div class="col-lg-8 col-md-8 p0">
                    <div class="view" id="map" [ngStyle]="{margin: dashboardView? '' : '0 auto'}">
                    </div>
                    <!-- <div class="regionchart-legends">
                        <dl *ngFor="let i of regionMappingArr">
                            <dt [ngClass]="getClassName(i)"></dt>
                            <dd>{{i}}</dd>
                        </dl>
                    </div> -->
                </div>
                <div class="col-lg-4 col-md-4 p0 position-relative" *ngIf="pdfExport == false">
                    <div class="select-countries-wrap">
                        <div class="location-selection">
                            <div class="title selected-loc-title">
                                <span>
                                    Selected Location(s)
                                    <!-- <i class="icons-image icon-info"></i> -->
                                </span>
                                <!-- <div>Onshore/Offshore</div> -->
                            </div>
                        </div>
                    </div>
                    <app-select-countries [variant]="variant" (selectLocation)="selectLocationAsPerCheckbox($event)"
                        (deselectRegionEvent)="deselectRegion()" [countries]="countries"
                        (modalClosed)="modalClosedEventHandler($event)"></app-select-countries>
                </div>
            </div>
        </div>
        <div class="region-wrap regionLocation-wrap ruBenchmarkPage" *ngIf="loadedPageType == 'ru'">
            <div class="row m0">
                <div class="col-lg-12 col-md-12 p0 position-relative">
                    <div class="select-countries-wrap">
                        <div class="location-selection">
                            <div class="title selected-loc-title">
                                <span>
                                    Selected Location(s)
                                    <!-- <i class="icons-image icon-info"></i> -->
                                </span>
                                <!-- <div>Onshore/Offshore</div> -->
                            </div>
                        </div>
                    </div>
                    <app-select-countries *ngIf="pdfExport == false" [benchmarkType]="'ru'" [variant]="variant"
                        (selectLocation)="selectLocationAsPerCheckbox($event)" (deselectRegionEvent)="deselectRegion()"
                        [countries]="countries" (modalClosed)="modalClosedEventHandler($event)"></app-select-countries>
                </div>
            </div>
        </div>
    </div>

</ng-container>
<ng-template #modalDiv>
    <div class="section">
        <div class="region-wrap">
            <div class="row m0">
                <div class="col-8 p0 map-wrap">
                    <div class="view" id="mapModal"></div>
                    <!-- <div class="mapmodal-legends">
                        <dl *ngFor="let i of regionMappingArr">
                            <dt [ngClass]="getClassName(i)"></dt>
                            <dd>{{i}}</dd>
                        </dl>
                    </div> -->
                </div>
                <div class="col-4 p0 select-countries-wrap">
                    <app-select-countries *ngIf="pdfExport == false" (selectMapRegion)="selectMapRegion($event)"
                        (selectLocation)="selectLocationAsPerCheckbox($event)" [isModal]="isModal"
                        (deselectRegionEvent)="deselectRegion()" [countries]="countries"
                        (modalClosed)="modalClosedEventHandler($event)"></app-select-countries>
                </div>
            </div>
        </div>
    </div>
</ng-template>