<ul class="list-group list-group-flush" style="height:300px; overflow-y: auto;">
    <li class="list-group-item" *ngFor="let role of selectedRoles; let i = index;">
        <input class="form-check-input me-1" [(ngModel)]="role.isSelected" (change)="changeSelection(role)" type="checkbox">
        <label class="form-check-label" for="firstCheckbox">{{role.role}}</label>
    </li>
</ul>
<!-- <table mat-table [dataSource]="selectedRoles" class="mat-elevation-z8">

    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef> {{column}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table> -->