import { Component, OnDestroy, OnInit } from '@angular/core';
import { liveQuery } from 'dexie';
import { Subscription } from 'rxjs';
import { db } from 'src/db';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
declare let d3: any;
@Component({
  selector: 'app-annual-saving-tower',
  templateUrl: './annual-saving-tower.component.html',
  styleUrls: ['./annual-saving-tower.component.scss']
})
export class AnnualSavingTowerComponent implements OnInit, OnDestroy {

  constructor(private dataService: DataService) { }
  benchmarkPoint = '50'
  totalSpend = 0;
  totalSavings = 0;
  towers: any[] = [];
  spt: number;
  skillFactor: number = 1;
  type: string; //"discrete","role";
  annualHours: number = 1920;
  liveQuerySubscription = new Subscription();
  pieData = [];

  ngOnInit(): void {
    this.liveQuerySubscription.add(liveQuery(() => this.getTowersFromDB()).subscribe(async (towers) => {
      this.pieData = [];
      this.towers = towers;
      await this.getCalculatedData();
    }))
    
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  async getCalculatedData() {
    let result = [];
    if (this.type == "discrete")
      result = await this.getDiscreteData();
    else if (this.type == "total")
      result = await this.getTargetSpend();
    else
      result = await this.getResultData();
    this.calculateTotalSavings(result);
    this.pieData = result;
    this.drawPieChart(result, 'annualSpendTower');
  }

  calculateTotalSavings(result: any[]) {
    let totalSavings = 0;
    if (this.type == "total") {
      let targetSpend = result[0]['value'];
      let totalSpend = result[1]['value'];
      totalSavings = totalSpend - targetSpend;
    }
    else
      totalSavings = result.reduce<number>((accumulator, current) => {
        return accumulator + current.value;
      }, 0);
    this.totalSavings = totalSavings < 0 ? 0 : totalSavings;
  }

  async getDiscreteData() {
    let returnData = [];
    await Promise.all(this.towers.map(async (tower) => {
      let soc = await this.getBenchmarkSOCs(tower.id);
      let rates = tower.rates;
      let inputs = tower.inputs;
      let inputPlots = tower.inputPlots.filter(x=> x.serviceProvider == this.spt);
      let totalSpend = 0;
      let targetSpend = 0;
      inputs.forEach(input => {
        if (input.location != '0') {
          let towerRates = rates.filter(x => x.towerId == tower.id && x.serviceProviderType == this.spt)[0]['locationData'];
          let locationRate = towerRates.filter(x => x.locationId == input.location)[0].rates;
          let rate = this.getRateBySOC(soc, locationRate, this.benchmarkPoint);
          targetSpend = targetSpend + (input.headcount * rate);
          inputPlots.forEach(ip => {
            if (ip.location == input.location) {
              let inputRate = ip.inputValue == '' ? 0 : ip.inputValue;
              totalSpend = totalSpend + (input.headcount * inputRate * this.skillFactor);
            }
          });
        }
      })

      targetSpend = targetSpend * this.skillFactor;
      returnData.push({
        name: tower.tower,
        value: totalSpend > targetSpend ? Math.round((totalSpend - targetSpend) * this.annualHours) : 0
      })
    }));

    return returnData;
  }

  async getResultData() {
    let returnData = [];
    await Promise.all(this.towers.map(async (tower) => {
      let soc = await this.getBenchmarkSOCs(tower.id);
      let rates = tower.rates;
      let inputs = tower.inputs;
      let spend = tower.spend;
      let targetSpend = 0;
      inputs.forEach(input => {
        if (input.location != '0') {
          let towerRates = rates.filter(x => x.towerId == tower.id && x.serviceProviderType == this.spt)[0]['locationData'];
          let locationRate = towerRates.filter(x => x.locationId == input.location)[0].rates;
          let rate = this.getRateBySOC(soc, locationRate, this.benchmarkPoint);
          targetSpend = targetSpend + (input.headcount * rate);
        }
      })

      targetSpend = targetSpend * this.skillFactor;
      returnData.push({
        name: tower.tower,
        value: spend > targetSpend ? Math.round(spend - (targetSpend * this.annualHours)) : 0
      })
    }));

    return returnData;
  }

  async getTargetSpend() {
    
    let returnData = [];
    let targetSpend = 0;
    await Promise.all(this.towers.map(async (tower) => {
      let soc = await this.getBenchmarkSOCs(tower.id);
      let rates = tower.rates;
      let inputs = tower.inputs;
      inputs.forEach(input => {
        if (input.location != '0') {
          let towerRates = rates.filter(x => x.towerId == tower.id && x.serviceProviderType == this.spt)[0]['locationData'];
          let locationRate = towerRates.filter(x => x.locationId == input.location)[0].rates;
          let rate = this.getRateBySOC(soc, locationRate, this.benchmarkPoint);
          targetSpend = targetSpend + (input.headcount * rate);
        }
      })
    }));

    targetSpend = targetSpend * this.annualHours;

    this.totalSavings = this.totalSpend > targetSpend ? this.totalSpend - targetSpend : 0;

    returnData.push({
      name: "Target Spend",
      value: Math.round(targetSpend)
    })

    returnData.push({
      name: "Total Spend",
      value: Math.round(this.totalSpend)
    })

    return returnData;
  }

  async getTowersFromDB() {
    let allTowers = await this.dataService.getTowersInputs();

    this.skillFactor = await this.dataService.getSkillFactor();
    let filter = await this.getRoleFiltersFromDb();
    this.totalSpend = await this.dataService.getTotalSpend('tower');

    return allTowers.filter(tower => tower.isSelected == true);
  }

  async getRoleFiltersFromDb() {
    let filters = await db.role_filter.toArray();
    filters.forEach(f => {
      if (f.type == "blended spend")
        this.type = f.value;
      else if (f.type == "spt")
        this['spt'] = f.value;
    })
    return filters;
  }

  drawPieChart(data, selector) {
    d3.select(`#${selector}`).html(null);
    d3.select(`#legends`).html(null);
    var text = "";

    var width = 150;
    var height = 150;
    var thickness = 40;
    var duration = 750;
    var padding = 10;
    var opacity = .8;
    var opacityHover = 1;
    var otherOpacityOnHover = .8;
    var tooltipMargin = 13;

    var radius = Math.min(width - padding, height - padding) / 2;
    var color = d3.scaleOrdinal().domain(data).range(environment.chartColorSchemaAltered)

    var svg = d3.select(`#${selector}`)
      .append('svg')
      .attr('class', 'pie')
      .attr('width', width)
      .attr('height', height);

    var g = svg.append('g')
      .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')');

    var arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);

    var pie = d3.pie()
      .value(function (d) { return Math.round(d.value)})
      .sort(null);

    var path = g.selectAll('path')
      .data(pie(data))
      .enter()
      .append("g")
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => color(i))
      .style('opacity', opacity)
      .style('stroke', 'white')
      .on("mouseover", function (d) {
        d3.selectAll('path')
          .style("opacity", otherOpacityOnHover);
        d3.select(this)
          .style("opacity", opacityHover);

        let g = d3.select(`#${selector}`).select('svg')
          .style("cursor", "pointer")
          .append("g")
          .attr("class", "tooltip")
          .style("opacity", 0);

        g.append("text")
          .attr("class", "name-text")
          .text(`${d.data.name} (${d.data.value})`)
          .attr('font-family', 'Open Sans', 'Inter','Roboto')
          .attr('text-anchor', 'middle');

        let text = g.select("text");
        let bbox = text.node().getBBox();
        let padding = 2;
        g.insert("rect", "text")
          .attr("x", bbox.x - padding)
          .attr("y", bbox.y - padding)
          .attr("width", bbox.width + (padding * 2))
          .attr("height", bbox.height + (padding * 2))
          .style("fill", "white")
          .style("opacity", 0.75);
      })
      .on("mousemove", function (d) {
        let mousePosition = d3.mouse(this);
        let x = mousePosition[0] + width / 2;
        let y = mousePosition[1] + height / 2 - tooltipMargin;

        let text = d3.select('.tooltip text');
        let bbox = text.node().getBBox();
        if (x - bbox.width / 2 < 0) {
          x = bbox.width / 2;
        }
        else if (width - x - bbox.width / 2 < 0) {
          x = width - bbox.width / 2;
        }

        if (y - bbox.height / 2 < 0) {
          y = bbox.height + tooltipMargin * 2;
        }
        else if (height - y - bbox.height / 2 < 0) {
          y = height - bbox.height / 2;
        }

        d3.select('.tooltip')
          .style("opacity", 1)
          .attr('transform', `translate(${x}, ${y})`);
      })
      .on("mouseout", function (d) {
        d3.select(`#${selector}`).select('svg')
          .style("cursor", "none")
          .select(".tooltip").remove();
        d3.selectAll('path')
          .style("opacity", opacity);
      })
      .on("touchstart", function (d) {
        d3.select(`#${selector}`).select('svg')
          .style("cursor", "none");
      })
      .each(function (d, i) { this._current = i; });

    let legend = d3.select(`#legends`).append('div')
      .attr('class', 'legend')
      .style('font-size', '8px');

    let keys = legend.selectAll('.key')
      .data(data)
      .enter().append('div')
      .attr('class', 'key')
      .style('display', 'flex')
      .style('align-items', 'center');

    keys.append('div')
      .attr('class', 'symbol')
      .style('height', '7px')
      .style('width', '7px')
      .style('margin', '3px 3px')
      .style('background-color', (d, i) => color(i));

    keys.append('div')
      .attr('class', 'name')
      .text(d => `${d.name} (${d.value})`)
      .attr('font-family', 'Open Sans', 'Inter','Roboto');

    keys.exit().remove();
  }

  async getBenchmarkSOCs(towerId: number) {
    let soc = await db.socs.where('towerid').equals(towerId).first()
    return soc['soc'];
  }

  getRateBySOC(soc: any, locationRate: any, benchmarkPoint: string) {
    let level1 = (soc.level1 * locationRate.level1[benchmarkPoint]) / 100;
    let level2 = (soc.level2 * locationRate.level2[benchmarkPoint]) / 100;
    let level3 = (soc.level3 * locationRate.level3[benchmarkPoint]) / 100;
    let level4 = (soc.level4 * locationRate.level4[benchmarkPoint]) / 100;
    let level5 = (soc.level5 * locationRate.level5[benchmarkPoint]) / 100;

    let rate = (level1 + level2 + level3 + level4 + level5) * this.skillFactor;
    return rate;
  }


}
