import { ChangeDetectorRef, Component, Input, OnInit, ɵɵsetComponentScope } from '@angular/core';
import { ApiService } from '../api.service';
declare let d3v3: any;
import * as _ from 'lodash';
import * as ApexCharts from 'apexcharts';
import { Options, ChangeContext, PointerType } from '@angular-slider/ngx-slider';
import { difference } from 'd3-array';
import { db } from 'src/db';
import { liveQuery } from 'dexie';
import { DataService } from '../data.service';
import { DomSanitizer } from '@angular/platform-browser'
@Component({
    selector: 'app-pyramid-chart-new',
    templateUrl: './pyramid-chart-new.component.html',
    styleUrls: ['./pyramid-chart-new.component.scss']
})
export class PyramidChartNewComponent implements OnInit {
    spts: any[];
    locations: any[];

    constructor(private apiService: ApiService, private ref: ChangeDetectorRef, private dataService: DataService, private sanitizer: DomSanitizer) { }
    pyramidData = [];
    @Input() soc: any;
    data: any;
    apiData: any;
    inputData: any[];
    svg: any;
    width = 250;
    value = 10;
    height = 225;
    public chart: ApexCharts;
    difference = 0;
    identifier: any;
    sliderOptions = { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true };
    onshore: any = { onShore: 0, offShore: 0 };
    slider = {
        level5: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level4: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level3: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level2: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level1: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 }
    }

    ngOnInit(): void {
        liveQuery(() => this.getPyramidChanges()).subscribe(async (data) => {
            this.spts = data['spts'];
            this.locations = data['locations']
            await this.reset();
        })
    }

    async getPyramidChanges() {
        this.inputData = await db.input_plot.where('tower').notEqual('').toArray();
        return await this.dataService.getByTowerSelectionChanges();
    }

    async reset() {
        this.mapDataForD3(this.soc['socs']['soc']);
        this.onshore = this.soc['offshoreData'];
        await this.getRatesFromAPI();
    }

    adjustPyramid(changeCon: ChangeContext, level) {
        this.adjustPyramidValues(level);
    }

    mapDataForD3(data) {
        let arrMapping = [];
        let selectedKeys = ['level5', 'level4', 'level3', 'level2', 'level1'];
        selectedKeys.forEach(sk => {
            arrMapping.push({
                name: sk,
                levelValue: data[sk]
            });
            this.slider[sk]['pyramidValue'] = data[sk];
            this.slider[sk]['value'] = data[sk];
            this.slider[sk].options = this.setNewCeil(this.slider[sk], this.slider.level1.value + this.slider[sk].value);
        });
        this.identifier = this.soc['id'];
        this.pyramidData = _.create(arrMapping);
        setTimeout(() => {
            this.createChart()
        }, 1000);
    }

    drawPyramidChart() {
        this.svg = d3v3.select('#pyramid' + this.identifier)
            .attr("width", this.width)
            .attr("height", this.height)
            .append("g");
        this.constructPyramid()
    }

    adjustPyramidValues(changedLevel) {
        this.pyramidData = [];
        let dbStorageObject = { soc: {} };
        Object.keys(this.slider).forEach(slider => {
            // detect which level has been changed
            dbStorageObject['soc'][slider] = this.slider[slider]['value'];
            this.pyramidData.push({
                name: slider,
                levelValue: this.slider[slider]['value']
            });
            if (slider != 'level1')
                this.slider[slider] = this.setMaxValueForLevel(this.slider[slider], changedLevel);
        });
        this.getRatesFromAPI();
        //db.socs.update(this.soc['soc']['id'], dbStorageObject);
        // this.constructPyramid();
        this.createChart();
    }

    setMaxValueForLevel(slider, changedLevel) {
        let difference = this.slider[changedLevel]['value'] - this.slider[changedLevel]['pyramidValue'];
        if (slider.value != slider.pyramidValue && !('level1' in slider)) {
            this.slider.level1.value = this.slider.level1.value - difference;
            this.slider.level1.pyramidValue = this.slider.level1.value;
            slider.pyramidValue = slider.value;
        }
        slider.options = this.setNewCeil(slider, slider.value + this.slider.level1.value);
        return slider;
    }

    setNewCeil(slider, maxLimit) {
        const newOptions: Options = Object.assign({}, slider.options);
        newOptions['maxLimit'] = maxLimit;
        return newOptions;
    }

    constructPyramid() {
        let pyramidDataForFinalData = {};
        this.pyramidData.forEach(function (d) {
            pyramidDataForFinalData[d.name] = d.levelValue
        })
        //this.finalData['pyramid'] = pyramidDataForFinalData;
        this.svg.selectAll('*').remove();
        var color = d3v3.scale.ordinal().range(["#EEDD89", "#E0CD6B", "#D2BC4C", "#C5AC2E", "#C8C8C8", "rgb(144,197,345)", "rgb(165,218,366)"]);

        var pyramid = d3v3.pyramid()
            .size([this.width, this.height])
            .value(function (d) { return d.levelValue; });

        var line = d3v3.svg.line()
            .interpolate('linear-closed')
            .x(function (d, i) { return d.x; })
            .y(function (d, i) { return d.y; });

        var g = this.svg.selectAll(".pyramid-group")
            .data(pyramid(this.pyramidData))
            .enter().append("g")
            .attr("class", "pyramid-group")


        g.append("path")
            .attr("d", function (d) { return line(d.coordinates); })
            .style("fill", function (d) { return color(d.name); });

        g.append("text")
            .attr({
                "y": function (d, i) {
                    if (d.coordinates.length === 4) {
                        return (((d.coordinates[0].y - d.coordinates[1].y) / 2) + d.coordinates[1].y) + 5;
                    } else {
                        return (d.coordinates[0].y + d.coordinates[1].y) / 2 + 10;
                    }
                },
                "x": (d, i) => { return this.width / 2 }
            })
            .style("text-anchor", "end")
            .text(function (d) {
                return d.name.replace('level', 'L') + ': ' + d.value + '%'
            });

    }

    // blend rate calculations start here

    calculateBlendRates(dataFromAPI) {
        dataFromAPI.forEach(element => {
            element['locationData'].forEach(location => {
                let input = this.inputData.filter(input => input.tower == element['towerId'] && input.location == location['locationId'] && input['serviceProvider'] == element['serviceProviderType']);
                if (input.length > 0)
                    location['yourRate'] = input[0]['inputValue'];
                else
                    location['yourRate'] = '-na-';

                this.calculateFinalScoreAsPerRate(location, element['serviceProviderType']);
            })
        });
        this.mergeData(dataFromAPI);
    }

    mergeData(dataFromAPI: any) {
        let data = [];
        this.locations.forEach(location => {
            this.spts.forEach(spt => {
                let towerDataBySpt = dataFromAPI.filter(x => x.serviceProviderType == spt.id)[0]['locationData'];
                let locationData = towerDataBySpt.filter(x => x.locationId == location.id)[0];
                let dataToInsert = {
                    locationName: locationData.location + " (" + spt.spt.substring(0, 1) + ")",
                    locationId: locationData.locationId,
                    sptId: spt.id,
                    sptName: spt.spt,
                    score: locationData.finalScore,
                    yourRate: locationData.yourRate
                }
                data.push(dataToInsert);
            })
        })
        this.data = data;
    }

    calculateFinalScoreAsPerRate(location, spt) {
        // pickup data from onshore and offshore
        // pickup data from pyramid
        let scoreData = [];

        Object.keys(location.rates).forEach(r => {
            if ('finalScore' in location) {
                location.finalScore = {
                    25: location.finalScore[25] + this.calculateLevelScore(location.rates[r][25], r),
                    50: location.finalScore[50] + this.calculateLevelScore(location.rates[r][50], r),
                    75: location.finalScore[75] + this.calculateLevelScore(location.rates[r][75], r)
                };
            }
            else {
                location.finalScore = {
                    25: this.calculateLevelScore(location.rates[r][25], r),
                    50: this.calculateLevelScore(location.rates[r][50], r),
                    75: this.calculateLevelScore(location.rates[r][75], r)
                };
            }
        });
        return location;
    }

    calculateLevelScore(value, level) {
        return (value * (this.slider[level]['value'] / 100) * (this.onshore.onShore) / 100)
            + (value * (this.slider[level]['value'] / 100) * (this.onshore.offShore) / 100)
    }

    async getRatesFromAPI() {
        let towerData = await db.towerRates.where({ towerId: this.soc.id }).toArray();
        this.calculateBlendRates(towerData);
    }

    async getOffshoreFromDb() {
        return await db.offshore.where({ tower: this.soc.towerid }).first();
    }

    performOperation(shoreData) {
        shoreData.offShore = 100 - shoreData.onShore;
        db.offshore.update(shoreData.id, { onShore: shoreData.onShore, offShore: shoreData.offShore });
        this.getRatesFromAPI()
    }

    getSign(data) {
        let rateString = data.yourRate + '&nbsp;';
        let median = data.score[50];
        let yourRate = data.yourRate;
        if (yourRate && yourRate != undefined) {
            let percentage = ((yourRate - median) / median) * 100;
            if (percentage > 0)
                rateString = rateString + '<span style="color:#0EB787"><img src="assets/image/icon-trending up.svg">&nbsp;' + percentage.toFixed(0) + '%</span>';
            else if (percentage < 0)
                rateString = rateString + '<span style="color:red"><img src="assets/image/icon-trending down.svg">&nbsp;' + percentage.toFixed(0) + '%</span>';
        }
        return this.sanitizer.bypassSecurityTrustHtml(rateString);
        // {{d.yourRate}}&nbsp; {{getSign(d)}}
    }

    createChart() {
        let benchmark = this.soc['socs']['soc'];
        let current = this.pyramidData;
        var options = {
            series: [{
                name: 'Benchmark',
                data: [
                    -benchmark['level5'],
                    -benchmark['level4'],
                    -benchmark['level3'],
                    -benchmark['level2'],
                    -benchmark['level1'],
                ]
            },
            
            {
                name: 'Current',
                data: [
                    current[0].levelValue,
                    current[1].levelValue,
                    current[2].levelValue,
                    current[3].levelValue,
                    current[4].levelValue,
                ]
            }
            ],
            chart: {
                type: 'bar',
                height: 166,
                stacked: true,
            },
            legend: {
                position: 'top'
            },
            colors: ['#008FFB', '#FF4560'],
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '80%',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 1,
                colors: ["#fff"]
            },

            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                show:false,
                categories: ['L5', 'L4', 'L3', 'L2', 'L1'
                ],
                title: {
                    text: 'Percent'
                },
                labels: {
                    formatter: function (val) {
                        return Math.abs(Math.round(val)) + "%"
                    }
                }
            },
            yaxis:{
                show:true
            }
        };
        
        if (this.chart != undefined) {
            this.chart.updateOptions(options)
        }
        else {
            this.chart = new ApexCharts(document.querySelector("#chart"), options);
            this.chart.render();
        }


    }
}
