import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ApiService } from '../api.service';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { db } from 'src/db';
import * as ApexCharts from 'apexcharts';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { liveQuery } from 'dexie';
import { MillionPipe } from '../million.pipe';
@Component({
  selector: 'app-bar-chart-new',
  templateUrl: './bar-chart-new.component.html',
  styleUrls: ['./bar-chart-new.component.css'],
  providers: [MillionPipe]
})

export class BarChartNewComponent implements OnInit {
  @Input() towerId: number;
  public chart: ApexCharts;
  skillFactor: number = 1;
  showOverlay: boolean = true;
  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private millionPipe: MillionPipe,
    private dataService: DataService) {
      
  }

  ngOnInit(): void {
    liveQuery(() => this.getDetailedAnalysis()).subscribe((data: any) => {
      this.calculateValues();
    })
  }

  async getDetailedAnalysis() {
    this.skillFactor = await this.dataService.getSkillFactor();
    return await db.detailed_analysis.toArray();
  }

  createChart(data,annotation) {
    
    var options = {
      series: data,
      chart: {
        type: 'bar',
        height: 500,
        stacked: true,
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        formatter: (val) => this.millionPipe.transform(val)
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return this.millionPipe.transform(val)
          }
        }
      },
      xaxis: {
        categories: ["Current Spend", "Shoring", "Pyramid", "Rate", "Benchmark Spend"]
      },
      annotations:{
        yaxis:[annotation]
      },
      fill: {
        type: 'solid',
        opacity: 1,
        pattern: {
          style: ['', '', '', 'slantedLines'], // string or array of strings
      
        }
      },
      colors: ['#000', '#fff', '#C5AC2E','#737373'],
    };
    if (this.chart != undefined)
      this.chart.destroy()

    this.chart = new ApexCharts(document.querySelector("#MyChart"), options);
    this.chart.render();
  }

  async calculateValues() {
    let benchmarkSOCs = await this.getBenchmarkSOCs(this.towerId);
    let inputSOCs = await this.getInputSOCs(this.towerId);

    let benchmarkShoring = await this.getBenchmarkShoring(this.towerId);
    let inputShoring = await this.getInputShoring(this.towerId);

    let onShoreRates = await this.getShoreRates(this.towerId, false);
    let offShoreRates = await this.getShoreRates(this.towerId, true);

    let onShoreData = await this.getShoreData(this.towerId, false);
    let offShoreData = await this.getShoreData(this.towerId, true);

    // let singleOffshore = onShoreData['total'] == 0 || offShoreData['total'] == 0;

    let blendedRateUserInput = this.getBlendedRateUserInput(onShoreData, offShoreData);
    let blendedRateTargetBenchmark = this.getBlendedRateTargetBenchmark(benchmarkSOCs, onShoreRates, offShoreRates);
    let blendedRateTargetUserInput = this.getBlendedRateTargetUserInput(onShoreRates, offShoreRates, onShoreData, offShoreData);

    let currentFTECount = this.getFTECount(inputSOCs, inputShoring);
    let targetFTECount = this.getFTECount(inputSOCs, benchmarkShoring);

    let currentSpend = this.calculateSpend(currentFTECount, blendedRateUserInput);
    let targetSpend = this.calculateSpend(targetFTECount, blendedRateTargetBenchmark);

    let shoringSavings = this.calculateSavings(blendedRateUserInput, currentFTECount, targetFTECount);
    let rateSavings = this.calculateSavings(targetFTECount, blendedRateUserInput, blendedRateTargetUserInput);
    let socSavings = this.calculateSavings(targetFTECount, blendedRateTargetUserInput, blendedRateTargetBenchmark)
    // calculateSavings(FTECount: any, blendedRate1: any, blendedRate2: any) {
    //   let onShoreSpend = FTECount['onShore'] * (blendedRate1['onShore'] - blendedRate2['onShore']) * 1920;
    //   let offShoreSpend = FTECount['offShore'] * (blendedRate1['offShore'] - blendedRate2['offShore']) * 1920;

    //   return { onShore: onShoreSpend, offShore: offShoreSpend, total: onShoreSpend + offShoreSpend };
    // }
    if (currentSpend.total == 0) {
      this.showOverlay = true;
    }
    else {
      const annotation2 = {
        y: targetSpend.total,
        borderColor: '#C5AC2E'
      };

      let datasets = [
        {
          name: 'Current',
          data: [
            currentSpend.total,
            0,0,0,0
          ]//0,90,70,40,0
        },
        {
          name: 'Extra',
          data: [
            0,
            (currentSpend.total - Math.max(shoringSavings.total, 0)),
            (currentSpend.total - shoringSavings.total - Math.max(socSavings.total, 0)),
            (currentSpend.total - shoringSavings.total - socSavings.total - Math.max(rateSavings.total, 0)),
            0
          ]//0,90,70,40,0
        }, {
          name: 'Value',
          data: [
            0,
            shoringSavings.total >= 0 ? shoringSavings.total : 0,
            socSavings.total >= 0 ? socSavings.total : 0,
            rateSavings.total >= 0 ? rateSavings.total : 0,
            targetSpend.total
          ],//100,10,20,30,40
        }, {
          name: 'Value1',
          data: [
            0,
            shoringSavings.total < 0 ? Math.abs(shoringSavings.total) : 0,
            socSavings.total < 0 ? Math.abs(socSavings.total) : 0,
            rateSavings.total < 0 ? Math.abs(rateSavings.total) : 0,
            0
          ],//100,10,20,30,40
        }];
      this.createChart(datasets, annotation2);
    }
  }


  calculateSavings(FTECount: any, blendedRate1: any, blendedRate2: any) {
    let onShoreSpend = FTECount['onShore'] * (blendedRate1['onShore'] - blendedRate2['onShore']) * 1920;
    let offShoreSpend = FTECount['offShore'] * (blendedRate1['offShore'] - blendedRate2['offShore']) * 1920;

    return { onShore: onShoreSpend, offShore: offShoreSpend, total: onShoreSpend + offShoreSpend };
  }

  calculateSpend(FTECount: any, blendedRate: any) {
    let onShoreSpend = FTECount['onShore'] * blendedRate['onShore'] * 1920;
    let offShoreSpend = FTECount['offShore'] * blendedRate['offShore'] * 1920;

    return { onShore: onShoreSpend, offShore: offShoreSpend, total: onShoreSpend + offShoreSpend };
  }

  getFTECount(SOCs: any, shoring: any) {
    let fteCount = {
      onShore: SOCs['total'] * shoring['onShore'] / 100,
      offShore: SOCs['total'] * shoring['offShore'] / 100
    };

    return fteCount;
  }

  getBlendedRateTargetUserInput(onShoreRates: any, offShoreRates: any, onShoreData: {}, offShoreData: {}) {
    let onShore = (onShoreData['level1'] * onShoreRates['level1'])
      + (onShoreData['level2'] * onShoreRates['level2'])
      + (onShoreData['level3'] * onShoreRates['level3'])
      + (onShoreData['level4'] * onShoreRates['level4'])
      + (onShoreData['level5'] * onShoreRates['level5']);

    let offShore = (offShoreData['level1'] * offShoreRates['level1'])
      + (offShoreData['level2'] * offShoreRates['level2'])
      + (offShoreData['level3'] * offShoreRates['level3'])
      + (offShoreData['level4'] * offShoreRates['level4'])
      + (offShoreData['level5'] * offShoreRates['level5']);

    let result = {
      onShore: onShore / onShoreData['total'],
      offShore: offShore / offShoreData['total']
    };

    if (isNaN(result.onShore))
      result.onShore = 0;

    if (isNaN(result.offShore))
      result.offShore = 0;
    return result;
  }

  getBlendedRateTargetBenchmark(benchmarkSOCs: any, onShoreRates: any, offShoreRates: any) {
    let onShore = (benchmarkSOCs['level1'] * onShoreRates['level1'] / 100)
      + (benchmarkSOCs['level2'] * onShoreRates['level2'] / 100)
      + (benchmarkSOCs['level3'] * onShoreRates['level3'] / 100)
      + (benchmarkSOCs['level4'] * onShoreRates['level4'] / 100)
      + (benchmarkSOCs['level5'] * onShoreRates['level5'] / 100);

    let offShore = (benchmarkSOCs['level1'] * offShoreRates['level1'] / 100)
      + (benchmarkSOCs['level2'] * offShoreRates['level2'] / 100)
      + (benchmarkSOCs['level3'] * offShoreRates['level3'] / 100)
      + (benchmarkSOCs['level4'] * offShoreRates['level4'] / 100)
      + (benchmarkSOCs['level5'] * offShoreRates['level5'] / 100);

    return { onShore: onShore, offShore: offShore };
  }

  getBlendedRateUserInput(onShoreData: {}, offShoreData: {}) {
    let BlendRate = {
      onShore: onShoreData['spend'] / onShoreData['total'] / 1920,
      offShore: offShoreData['spend'] / offShoreData['total'] / 1920
    }

    if (isNaN(BlendRate.offShore))
      BlendRate.offShore = 0;

    if (isNaN(BlendRate.onShore))
      BlendRate.onShore = 0;
    return BlendRate;
  }

  async getShoreData(towerId: number, offShore: boolean) {
    let selectedLocations = await this.dataService.getDataFromDb('locations', {}, true)
    let shoreLocationIds = selectedLocations.filter(location => location['offshore'] == offShore).map(x => x.id);

    let detailedInputs = await (await db.detailed_analysis.where('tower').equals(towerId).toArray())
      .filter(x => shoreLocationIds.includes(x.location));

    let shoreData = {};
    let level1: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level1 ?? 0), 0);
    let level2: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level2 ?? 0), 0);
    let level3: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level3 ?? 0), 0);
    let level4: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level4 ?? 0), 0);
    let level5: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level5 ?? 0), 0);
    let spend: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.spend ?? 0), 0);

    let totalFTE = level1 + level2 + level3 + level4 + level5;

    shoreData['level1'] = level1;
    shoreData['level2'] = level2;
    shoreData['level3'] = level3;
    shoreData['level4'] = level4;
    shoreData['level5'] = level5;
    shoreData['total'] = totalFTE;
    shoreData['spend'] = spend;

    return shoreData;

  }

  async getShoreRates(towerId: number, offShore: boolean) {
    let selectedLocations = await this.dataService.getDataFromDb('locations', {}, true)
    let shoreLocationIds = selectedLocations.filter(location => location['offshore'] == offShore).map(x => x.id);

    let rates = await db.towerRates.where('towerId').equals(towerId).first();
    let inputs = await db.detailed_analysis.where('tower').equals(towerId).toArray();

    let benchmarkRates: any = {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }

    let totalHeadcount = {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }

    let bmRates = {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }


    shoreLocationIds.forEach((location) => {
      let detailedInput = inputs.filter(x => x.location == location)[0];
      let rate = rates['locationData'].filter(x => x.locationId == location)[0]['rates'];
      if (rate && detailedInput) {
        benchmarkRates.level1 += (detailedInput.level1 ?? 0) * rate.level1['50'];
        benchmarkRates.level2 += (detailedInput.level2 ?? 0) * rate.level2['50'];
        benchmarkRates.level3 += (detailedInput.level3 ?? 0) * rate.level3['50'];
        benchmarkRates.level4 += (detailedInput.level4 ?? 0) * rate.level4['50'];
        benchmarkRates.level5 += (detailedInput.level5 ?? 0) * rate.level5['50'];

        bmRates.level1 += rate.level1['50'];
        bmRates.level2 += rate.level2['50'];
        bmRates.level3 += rate.level3['50'];
        bmRates.level4 += rate.level4['50'];
        bmRates.level5 += rate.level5['50'];

        totalHeadcount.level1 += (detailedInput.level1 ?? 0);
        totalHeadcount.level2 += (detailedInput.level2 ?? 0);
        totalHeadcount.level3 += (detailedInput.level3 ?? 0);
        totalHeadcount.level4 += (detailedInput.level4 ?? 0);
        totalHeadcount.level5 += (detailedInput.level5 ?? 0);
      }
    });
    let shoreRates: any = {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }
    if (shoreLocationIds.length > 0) {
      shoreRates = {
        level1: totalHeadcount.level1 == 0 ? (bmRates.level1 / shoreLocationIds.length) : (benchmarkRates.level1 * this.skillFactor) / totalHeadcount.level1,
        level2: totalHeadcount.level2 == 0 ? (bmRates.level2 / shoreLocationIds.length) : (benchmarkRates.level2 * this.skillFactor) / totalHeadcount.level2,
        level3: totalHeadcount.level3 == 0 ? (bmRates.level3 / shoreLocationIds.length) : (benchmarkRates.level3 * this.skillFactor) / totalHeadcount.level3,
        level4: totalHeadcount.level4 == 0 ? (bmRates.level4 / shoreLocationIds.length) : (benchmarkRates.level4 * this.skillFactor) / totalHeadcount.level4,
        level5: totalHeadcount.level5 == 0 ? (bmRates.level5 / shoreLocationIds.length) : (benchmarkRates.level5 * this.skillFactor) / totalHeadcount.level5
      }
    }
    return shoreRates;
  }

  async getInputShoring(towerId: number) {
    let selectedLocations = await this.dataService.getDataFromDb('locations', {}, true);
    let detailedInputs = await (await db.detailed_analysis.where('tower').equals(towerId).toArray()).filter(x => x.location != 0);
    let offShore = 0;
    let onShore = 0;
    selectedLocations.forEach(location => {
      let isoffShore = location['offshore'];
      let inputs = detailedInputs.filter(x => x.location == location.id);
      if (inputs.length > 0) {
        if (isoffShore) {
          offShore += inputs[0]['total'];
        }
        else {
          onShore += inputs[0]['total'];
        }
      }
    })

    let total = offShore + onShore;

    let shoring = {
      offShore: (offShore / total) * 100,
      onShore: (onShore / total) * 100
    };

    return shoring;

  }

  async getBenchmarkShoring(towerId: number) {
    return await db.offshore.where('tower').equals(towerId).first();
  }

  async getInputSOCs(towerId: number) {
    let detailedInputs = await (await db.detailed_analysis.where('tower').equals(towerId).toArray()).filter(x => x.location != 0) ?? [];

    let SOCs = {};
    let level1: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level1 ?? 0), 0);
    let level2: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level2 ?? 0), 0);
    let level3: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level3 ?? 0), 0);
    let level4: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level4 ?? 0), 0);
    let level5: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level5 ?? 0), 0);

    let totalFTE = level1 + level2 + level3 + level4 + level5;

    SOCs['level1'] = (level1 / totalFTE) * 100;
    SOCs['level2'] = (level2 / totalFTE) * 100;
    SOCs['level3'] = (level3 / totalFTE) * 100;
    SOCs['level4'] = (level4 / totalFTE) * 100;
    SOCs['level5'] = (level5 / totalFTE) * 100;
    SOCs['total'] = totalFTE;

    return SOCs;
  }

  async getBenchmarkSOCs(towerId: number) {
    let soc = await db.socs.where('towerid').equals(towerId).first()
    return soc['soc'];
  }

  //   var options = {
  //   series: [{
  //     name: 'US Tier 1',
  //     data: [440000, 550000, 410000, 670000]
  //   }, {
  //     name: 'India',
  //     data: [130000, 230000, 200000, 80000]
  //   }, {
  //     name: 'Mexico',
  //     data: [110000, 170000, 150000, 150000]
  //   }, {
  //     name: 'Canada',
  //     data: [210000, 70000, 250000, 130000]
  //   }],
  //   chart: {
  //     type: 'bar',
  //     height: 350,
  //     stacked: true,
  //     toolbar: {
  //       show: true
  //     },
  //     zoom: {
  //       enabled: true
  //     }
  //   },
  //   responsive: [{
  //     breakpoint: 480,
  //     options: {
  //       legend: {
  //         position: 'bottom',
  //         offsetX: -10,
  //         offsetY: 0
  //       }
  //     }
  //   }],
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //     },
  //   },
  //   xaxis: {
  //     categories: ['Application Development', 'Testing', 'Product Engineering', 'Database'],
  //   },
  //   legend: {
  //     position: 'right',
  //     offsetY: 40
  //   },
  //   fill: {
  //     opacity: 1
  //   }
  // };

  // var options = {
  //   series: [
  //     {
  //       name: 'Current Spend',
  //       group: 'Current',
  //       data: [120000, 180000, 130000, 90000]
  //     },
  //     {
  //       name: 'US Tier 1',
  //       group: 'Benchmark',
  //       data: [48000, 50000, 40000, 65000]
  //     },
  //     {
  //       name: 'India',
  //       group: 'Benchmark',
  //       data: [13000, 36000, 20000, 8000]
  //     },
  //     {
  //       name: 'Mexico',
  //       group: 'Benchmark',
  //       data: [20000, 40000, 25000, 10000]
  //     },
  //     {
  //       name: 'Canada',
  //       group: 'Benchmark',
  //       data: [20000, 40000, 25000, 10000]
  //     }
  //   ],
  //   chart: {
  //     type: 'bar',
  //     height: 350,
  //     stacked: true,
  //   },
  //   stroke: {
  //     width: 1,
  //     colors: ['#fff']
  //   },
  //   dataLabels: {
  //     formatter: (val) => {
  //       return val / 1000 + 'K'
  //     }
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false
  //     }
  //   },
  //   yaxis: {
  //     labels: {
  //       formatter: (val) => {
  //         return val / 1000 + 'K'
  //       }
  //     }
  //   },
  //   xaxis: {
  //     categories: [
  //       'Application Development',
  //       'Testing',
  //       'Product Engineering',
  //       'Database'
  //     ]
  //   },

  //   fill: {
  //     opacity: 1,
  //   },
  //   colors: ['#80c7fd', '#008FFB', '#80f1cb', '#00E396'],
  //   legend: {
  //     position: 'top',
  //     horizontalAlign: 'left'
  //   }
  // };


}
