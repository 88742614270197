import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() loginEvent: EventEmitter<any> = new EventEmitter<any>();
  hide = true;
  checked = false;
  slides = [
    {
      backgroundClass: 'craousel_image_1',
      text: 'The authoritative benchmarking tool for global IT-BPS contracts. 100% sourcing advisory intelligence and insights. 0% survey or research data.',
      imageRight: 'assets/img/carousel-right-1.svg'
    },
    // {
    //   backgroundClass: 'craousel_image_2',
    //   text: 'Our Fairness Opinion Services help secure tangible improvement opportunities in sourcing RFPs, bids, and contacts',
    //   imageRight: 'assets/img/carousel-right-2.svg'
    // },
    // {
    //   backgroundClass: 'craousel_image_3',
    //   text: 'Our Benchmarking Advisory Services are solely mined from actual transactions by senior sourcing advisors.',
    //   imageRight: 'assets/img/carousel-right-3.svg'
    // },
    // {
    //   backgroundClass: 'craousel_image_4',
    //   text: 'Contract Analytics extracts key contractual terms and metrics in a structured format. Optimize spending by slicing and dicing against the contemporary market.',
    //   imageRight: 'assets/img/carousel-right-4.svg'
    // }
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

   login() {
     this.loginEvent.emit();
   }

}
