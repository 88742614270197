
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { liveQuery } from 'dexie';
import { AreaList, db, TowerRatesList } from 'src/db';
import { DataService } from '../data.service';
import { InputService } from '../input.service';
import * as _ from 'lodash';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { VarianceSpreadComponent } from '../variance-spread/variance-spread.component';
import { DataUpdateService } from '../data-update.service';
import { LoaderService } from '../loader.service';
import { EncyptDecryptService } from '../encypt-decrypt.service';
import { Subscription } from 'rxjs';
import { config } from 'src/environments/config';
import Swal from 'sweetalert2';
import { TowerParamsComponent } from '../tower-params/tower-params.component';
import { BlendedBenchmarkDetailComponent } from '../blended-benchmark-detail/blended-benchmark-detail.component';
@Component({
  selector: 'app-price-bar-pdf-export',
  templateUrl: './price-bar-pdf-export.component.html',
  styleUrls: ['./price-bar-pdf-export.component.scss']
})
export class PriceBarPdfExportComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() pdfExport?: boolean = false;
  rows = [];
  locations = [];
  isglobalEdit = false;
  loadedComponentType = 'role';
  selectedExperiences = [];
  experienceFilter = [];
  allRoleRows = [];
  routeParamType = '';
  searchText = '';
  selectedServiceProviders = [];
  skillFactor: any;
  spts: any[];
  minMaxRoles: any = undefined;
  locationsMaster: any[] = [];
  selectedLocation: any;
  locationSingle: any;
  typeNameSelector: string = 'Roles';
  liveQuerySubscription = new Subscription();
  showSelectedOnly: boolean = false;
  subscription = new Subscription();
  noRows = false;
  allRolesSelected: boolean = false;

  constructor(public dialog: MatDialog,
    private inputService: InputService, private dataService: DataService,
    private loaderService: LoaderService,
    private route: ActivatedRoute, private apiService: ApiService,
    private dataUpdate: DataUpdateService,
    private decryptService: EncyptDecryptService) { }

  ngOnInit(): void {
    this.loaderService.openDialog();
    this.route.params.subscribe(param => {
      switch (param['type']) {
        case 'ru':
          this.typeNameSelector = 'Services';
          break;
        case 'blended-rate':
          this.typeNameSelector = 'Towers';
          break;
        default:
          this.typeNameSelector = 'Roles';
      }

    })
    this.subscription = this.dataUpdate.subscribeDataChangeEvent().subscribe(event => {
      if (['locationSelectionsChanged', 'towerSubtowerUpdate', 'providerChanged', 'categoryChanged', 'areaUpdated', 'skillValueChanged', 'towerParamsUpdated', 'dealParamUpdated'].indexOf(event) != -1) {
        this.loaderService.openDialog();
        setTimeout(() => {
          this.getData();
          //this.loaderService.closeDialog();
        }, 1000)
      }
      if (['discreteSelected'].indexOf(event) != -1) {
        this.isglobalEdit = true;
      }
    })
    this.getData();
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  getData() {
    this.getSelectedLocations().then(data => {
      this.locationsMaster = data;
      this.locations = data;
      this.selectedLocation = data[0]['id'];
      this.getRowDatas();
    });
  }

  async getDealParamOptions() {
    return await db.selection_deal_tower_params_values.toArray();
  }

  async getSelectedLocations() {
    this.skillFactor = await this.dataService.getSkillFactor();
    this.selectedServiceProviders = (await db.selection_providerTypes.toArray()).filter(x => x.isSelected == true);
    let area: AreaList[] = await this.dataService.getDataFromDb('areas', {}, true);
    if (area.length > 0) {
      return (await this.dataService.getDataFromDb('locations', {}, true)).filter(e => area[0].locationMappings.includes(e.id));
    }
    return [];
  }

  cliPrice = [];

  refreshBoxPlot(isBoxPlot) {
    if (isBoxPlot)
      this.getRowDatas();
  }

  updateBoxPlot() {
    this.locationSingle = [];
    this.locationSingle = _.filter(this.locations, (loc) => {
      return loc.id == this.selectedLocation
    });

  }

  async getRowDatas() {
    switch (this.type) {
      case 'role-level':
        this.loadedComponentType = 'role';
        this.getRoles().then(async (data) => {

          this._getMinMaxRangeForLocationProvider(data);
          let locs = Array.from(new Set(data.flatMap(e => e["rates"]).map(e => e.location)));
          //this.locations = this.locationsMaster.filter(loc => locs.includes(loc.id));

          this.updateBoxPlot();
          this.transformData(data)
          let experiences = _.uniqBy(data, 'roleData.experience').map(role => { if (role.roleData) return role.roleData.experience });
          experiences = _.compact(experiences);
          this.experienceFilter = experiences;
          this.allRoleRows = data;
          this.allRoleRows = this.allRoleRows.filter(role => { return role.isSelected })
          this.allRolesSelected = this.allRoleRows.filter(role => { return role.isSelected }).length == this.allRoleRows.length;
          if (data.length == 0)
            this.noRows = true;
          else
            this.noRows = false;
          this.loaderService.closeDialog();
        })
        break;
      case 'blended-rate':
        this.loadedComponentType = 'tower';
        this.getTowers().then(data => {

          this._getMinMaxRangeForLocationProvider(data, 'towers')
          let locs = Array.from(new Set(data.flatMap(e => e["rates"]).map(e => e.location)));
          //this.locations = this.locationsMaster.filter(loc => locs.includes(loc.id));
          this.updateBoxPlot();
          this.transformData(data)
          if (data.length == 0)
            this.noRows = true;
          else
            this.noRows = false;
          this.loaderService.closeDialog();
        });
        break;
    }
  }

  async markAllRolesAsSelected(e: Event) {
    this.allRolesSelected = e.target['value'] == 'true';
    if (this.allRolesSelected) {
      let allRoles = this.allRoleRows.filter(role => { return role.isSelected });
      allRoles.forEach(role => {
        role.isSelected = false;
      })
      await db.selection_roles.clear();
      this.allRolesSelected = false;
    }
    else {
      let allRoles = this.allRoleRows.filter(role => { return !role.isSelected });
      allRoles.forEach(role => {
        role.isSelected = true;
      })
      await db.selection_roles.bulkAdd(allRoles);
      this.allRolesSelected = true;
    }
    this.dataUpdate.emitDataChangeEvent('allRolesSelectionChanged')
  }

  transformData(data) {
    data.forEach(d => {
      d['label'] = (this.loadedComponentType == 'role') ? d.role : d.tower;
      if (this.loadedComponentType == 'role' && this.typeNameSelector != 'Services') {
        d['roleData']['level'] = d['roleData']['level'].replace('Level ', 'L')
      }
    })

    this.rows = data.filter((role: any) => {
      return role.isSelected;
    })
  }

  async getTowers() {
    return await this.dataService.getTowerWithRatesInputPlot();
  }

  async getRoles() {
    return await this.dataService.getRolesWithRatesInputPlot()
  }

  getValue(obj, spid) {
    if (obj != undefined && obj[spid] != undefined) {
      return config.formatNumber(obj[spid]);
    }
    else return '';
  }

  async getLocationsFromDb() {
    this.skillFactor = await this.dataService.getSkillFactor();
    return await this.dataService.getDataFromDb('locations', {}, true);
  }

  async updateBoxPlotValue(id, location, spId, event) {
    if (event.target.value < 0) {
      //alert('Please enter Positive Values!!')
      Swal.fire({
        //title: 'Warning!',
        text: 'Please enter positive values only',
        icon: 'info',
        confirmButtonText: 'Close'
      });
      event.target.value = null;
      return false;
    }
    let data = {
      location: location,
      inputValue: isNaN(event.target.value) ? 0 : Math.round(event.target.value * 100) / 100,
      serviceProvider: spId
    }
    data[this.loadedComponentType] = id;
    await this.inputService.updateInputPlotValue(data)
    return true;
  }

  async onRowSelected(checked, roleData) {
    roleData.id = roleData.id;
    roleData.isSelected = checked;
    await this.dataService.updateRecord('roles', roleData);
  }

  async clearData() {
    await db.input_plot.clear();
    this.getRowDatas();
  }

  filterRolesByExperience() {
    if (this.selectedExperiences.length > 0) {
      this.rows = this.allRoleRows.filter((role: any) => {
        return this.selectedExperiences.indexOf(role.roleData['experience']) > -1;
      })
    }
    else
      this.rows = this.allRoleRows;
  }

  showOnlySelected(event) {
    if (event.target.checked) {
      this.rows = this.allRoleRows.filter((role: any) => {
        return role.isSelected;
      })
    }
    else
      this.rows = this.allRoleRows;
  }

  private _getMinMaxRangeForLocationProvider(data, mode = 'roles') {
    let minMaxRoles = {};
    let rates = {};
    _.forEach(data, (item) => {
      _.forEach(item.rateSPT, (rate, location) => {
        if (!rates[location])
          rates[location] = {}
        _.forEach(rate, (values, spid) => {
          if (!rates[location][spid])
            rates[location][spid] = {}
          _.forEach(values, (rate, bp) => {
            if (!rates[location][spid][bp])
              rates[location][spid][bp] = []
            rates[location][spid][bp].push(rate)
          })
        })
      })
    })
    _.forEach(rates, (rate, location) => {
      minMaxRoles[location] = {}
      _.forEach(rate, (values, sp) => {
        minMaxRoles[location][sp] = {
          min: _.min(values[25]) - 5,
          max: _.max(values[75]) + 5,
          buckets: (() => {
            let arr = [],
              min = _.min(values[25]) - 5,
              max = _.max(values[75]) + 5,
              i,
              buckets = 3;
            for (i = min; i <= max; i += (max - min) / buckets) {
              arr.push(i);
            }
            return arr;
          })()
        }
      })
    })
    this.minMaxRoles = minMaxRoles;
  }

  formatNumber(value) {
    if (value.toString().indexOf('.') > -1)
      return value.toFixed(0);
    else
      return value;
  }

  openVarianceDialog() {
    let type = this.type == 'blended-rate' ? 'tower' : 'role';
    const dialogRef = this.dialog.open(VarianceSpreadComponent, {
      width: '70%',
      panelClass: 'variance-spread-modal',
      data: {
        type: type
      }
    });
  }

  async openTowerParamsDialog() {
    this.dataService.getTowerParams().then(result => {
      if (Object.keys(result['grouped']).length > 0) {
        const dialogRef = this.dialog.open(TowerParamsComponent, {
          width: '1228.8px',
          maxWidth: 'inherit',
          panelClass: 'variance-spread-modal',
        });
      }
      else {
        Swal.fire({
          //title: 'Warning!',
          text: 'Please select atleast one Service from the list',
          icon: 'info',
          confirmButtonText: 'Close'
        });
      }
    })

  }

  html = `<ul>
  <li>Identifies and documents the current technical environment</li> 	<li>Participates in and documents interviews as a part of requirements gathering, high level scope definition, development of use cases</li>
  <li>Creates test scripts for functional verification and user acceptance testing</li>
  </ul>`;


  // getWidthAsPerSelections(){
  //   return this.selectedServiceProviders.length == 1 ? 400:250
  // }

  getWidthAsPerSelections() {
    let element = document.getElementsByClassName("sub-header-td")[0] as HTMLElement;
    return element.offsetWidth - 33;
  }

  openBenchmarkDetail(row) {
    const dialogRef = this.dialog.open(BlendedBenchmarkDetailComponent, {
      width: '70%',
      panelClass: 'variance-spread-modal',
      data: {
        row,
        location: this.selectedLocation
      }
    });
  }

  getTooltipText(row) {
    return row.selectedSubtowers.map(st => { return st.subTower }).join(' | ')
  }

}
