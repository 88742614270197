import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthModule, AuthHttpInterceptor, HttpMethod } from '@auth0/auth0-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AgGridModule } from 'ag-grid-angular';
// import 'ag-grid-enterprise';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PyramidChartComponent } from './pyramid-chart/pyramid-chart.component';
// import { BoxPlotComponent } from './box-plot/box-plot.component';
import { InteractiveDashboardComponent } from './interactive-dashboard/interactive-dashboard.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { ProviderTypesComponent } from './provider-types/provider-types.component';
import { RolesSelectorComponent } from './roles-selector/roles-selector.component';
import { YourPriceComponent } from './your-price/your-price.component';
import { BlendedRateComponent } from './blended-rate/blended-rate.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { environment } from 'src/environments/environment';
import { SelectSubtowersComponent } from './select-subtowers/select-subtowers.component';
import { TowerSelectionsComponent } from './tower-selections/tower-selections.component';
import { MessageService } from './services/Message.service';
import { RolesListComponent } from './roles-list/roles-list.component';
import { PriceBarComponent } from './price-bar/price-bar.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

import { TowerAnalysisComponent } from './tower-analysis/tower-analysis.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table'
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { LocationSelectorComponent } from './location-selector/location-selector.component';
import { SharedModule } from './shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ScenarioModule } from './scenario/scenario.module';
import { AnnualSavingRoleComponent } from './annual-saving-role/annual-saving-role.component';
import { ScatterChartComponent } from './scatter-chart/scatter-chart.component';
import { TargetSpendRoleComponent } from './target-spend-role/target-spend-role.component';
import { OpportunityAssessmentComponent } from './opportunity-assessment/opportunity-assessment.component';
import { DetailedTowerAnalysisComponent } from './detailed-tower-analysis/detailed-tower-analysis.component';
import { RoleListRateComponent } from './role-list-rate/role-list-rate.component';
import { GeneralPriceBarComponent } from './general-price-bar/general-price-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RecentDownloadsComponent } from './recent-downloads/recent-downloads.component';
import { OpportunityAssessmentNewComponent } from './opportunity-assessment-new/opportunity-assessment-new.component';
import { GeneralPriceBarPipe } from './general-price-bar.pipe';
import { DetailedCustomViewComponent } from './xxdetailed-custom-view/detailed-custom-view.component';
import { TowerSubtowerSelectorComponent } from './tower-subtower-selector/tower-subtower-selector.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { TargetSpendTowerComponent } from './target-spend-tower/target-spend-tower.component';
import { AnnualSavingTowerComponent } from './annual-saving-tower/annual-saving-tower.component';
import { MatCardModule } from '@angular/material/card';
import { DetailedAnalysisModalComponent } from './detailed-analysis-modal/detailed-analysis-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader.service';
import { ScatterChartNewComponent } from './scatter-chart-new/scatter-chart-new.component';
import { BoxPlotContainerComponent } from './box-plot-container/box-plot-container.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MillionPipe } from './million.pipe';
import { VarianceSpreadComponent } from './variance-spread/variance-spread.component';
import { DealParamsComponent } from './deal-params/deal-params.component';
import { SavingsAnalysisRoleComponent } from './savings-analysis-role/savings-analysis-role.component';
import { SavingsAnalysisTowerComponent } from './savings-analysis-tower/savings-analysis-tower.component';
import { PyramidChartNewComponent } from './pyramid-chart-new/pyramid-chart-new.component';
import { PyramidChartNew1Component } from './pyramid-chart-new-1/pyramid-chart-new-1.component';
import { SavingsModalComponent } from './savings-modal/savings-modal.component';
import { BarChartNewComponent } from './bar-chart-new/bar-chart-new.component';
import { AgGridEditDisplayComponent } from './ag-grid-edit-display/ag-grid-edit-display.component';
import { ResetComponent } from './reset/reset.component';
import {MatChipsModule} from '@angular/material/chips';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BoxSvgComponent } from './box-svg/box-svg.component';
import { TowerParamsComponent } from './tower-params/tower-params.component';
import { RuBoxContainerComponent } from './ru-box-container/ru-box-container.component';
import {MatSliderModule} from '@angular/material/slider';

import { BoxPlotContainerRUComponent } from './box-plot-container-ru/box-plot-container-ru.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { BlendedBenchmarkDetailComponent } from './blended-benchmark-detail/blended-benchmark-detail.component';
import { LevelProgressComponent } from './level-progress/level-progress.component';
import { BoxillustrationComponent } from './boxillustration/boxillustration.component';
import { PdfExportViewComponent } from './pdf-export-view/pdf-export-view.component';
import { PriceBarPdfExportComponent } from './price-bar-pdf-export/price-bar-pdf-export.component';
import { NetworkErrorInterceptor } from './network-error.interceptor';
import { DiscreteFiltersComponent } from './discrete-filters/discrete-filters.component';

@NgModule({
  declarations: [
    AppComponent,
    PyramidChartComponent,
    //BoxPlotComponent,
    InteractiveDashboardComponent,
    DonutChartComponent,
    ProviderTypesComponent,
    RolesSelectorComponent,
    YourPriceComponent,
    BlendedRateComponent,
    SelectSubtowersComponent,
    TowerSelectionsComponent,
    RolesListComponent,
    PriceBarComponent,
    ForgotpasswordComponent,
    LoginComponent,
    ResetpasswordComponent,
    TowerAnalysisComponent,
    LocationSelectorComponent,
    DashboardComponent,
    AnnualSavingRoleComponent,
    ScatterChartComponent,
    TargetSpendRoleComponent,
    OpportunityAssessmentComponent,
    DetailedTowerAnalysisComponent,
    RoleListRateComponent,
    GeneralPriceBarComponent,
    RecentDownloadsComponent,
    OpportunityAssessmentNewComponent,
    GeneralPriceBarPipe,
    DetailedCustomViewComponent,
    TowerSubtowerSelectorComponent,
    BarChartComponent,
    TargetSpendTowerComponent,
    AnnualSavingTowerComponent,
    DetailedAnalysisModalComponent,
    LoaderComponent,
    ScatterChartNewComponent,
    BoxPlotContainerComponent,
    MillionPipe,
    VarianceSpreadComponent,
    DealParamsComponent,
    SavingsAnalysisRoleComponent,
    PyramidChartNewComponent,
    PyramidChartNew1Component,
    SavingsModalComponent,
    BarChartNewComponent,
    SavingsAnalysisTowerComponent,
    AgGridEditDisplayComponent,
    ResetComponent,
    BoxSvgComponent,
    TowerParamsComponent,
    RuBoxContainerComponent,
    BoxPlotContainerRUComponent,
    FeedbackComponent,
    AccessDeniedComponent,
    BlendedBenchmarkDetailComponent,
    LevelProgressComponent,
    BoxillustrationComponent,
    PdfExportViewComponent,
    PriceBarPdfExportComponent,
    DiscreteFiltersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    FormsModule,
    NgxSliderModule,
    AgGridModule,
    ReactiveFormsModule,
    DragDropModule,
    MatIconModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatInputModule,
    MatExpansionModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTableModule,
    MatListModule,
    MatTooltipModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ScenarioModule,
    AuthModule.forRoot({ ...environment.auth0 }),
    SharedModule,
    CarouselModule,
    MatChipsModule,
    PopoverModule.forRoot(),
    MatSliderModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: NetworkErrorInterceptor, multi: true},
    MessageService,
    { provide: MatDialogRef, useValue: {} },
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
