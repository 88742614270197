import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-role-list-rate',
  templateUrl: './role-list-rate.component.html',
  styleUrls: ['./role-list-rate.component.scss']
})
export class RoleListRateComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

}
