import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { db } from 'src/db';
import LocationRateData from '../models/locationRateData';
import LevelWiseData from '../models/LevelWiseData';
import { EncyptDecryptService } from '../encypt-decrypt.service';
import { DataService } from '../data.service';
import ShoringData from '../models/ShoringData';
import { liveQuery } from 'dexie';

@Injectable({ providedIn: 'root' })

export class SavingsAnalysisService {
  private benchmarkPointObservable = new BehaviorSubject<any>('50');
  public benchmarkPoint = '50';
  private savingAnalysisData = new Subject<any>();
  //liveQuerySubscription: Subscription = new Subscription();

  setBenchmarkPoint(benchmarkPoint: any) {
    this.benchmarkPointObservable.next(benchmarkPoint);
    this.benchmarkPoint = benchmarkPoint;
  }

  getBenchmarkPoint(): Observable<any> {
    return this.benchmarkPointObservable.asObservable();
  }

  constructor(private encDecService: EncyptDecryptService, private dataService: DataService) {

    Map.prototype['map'] = function (callback) {
      let newMap = new Map();
      if (typeof (callback) == 'string')
        this.forEach((value, key) => newMap.set(key, value[callback]))
      else
        this.forEach((value, key) => newMap.set(key, callback(value)))
      return newMap;
    }

    Array.prototype['sum'] = function () {
      this.reduce((a, b) => a + b, 0);
    }

  }
  

  public async calculateValues(towerId, globalLocationId: number = 999999) {
    //Use Default Offshore location as INDIA in case no offshore location is selected
    //In case no onshore lcoation is selected, set values to 0
    //No shoring optimization to be applicable in either of the 1-0 cases

    let benchmarkSocPercent: LevelWiseData = await this.getBenchmarkSOCs(towerId);
    let userInputSocPercent = await this.getInputSOCs(towerId);

    let benchmarkShoringPercent = await this.getBenchmarkShoring(towerId);
    let inputShoringPercent = await this.getInputShoring(towerId);

    let onShoreData = await this.getShoreDataMap(towerId, false);
    let offShoreData = await this.getShoreDataMap(towerId, true);


    let totalFTE = Array.from(onShoreData.entries()).filter(e => e[0] < globalLocationId).reduce((acc, curr) => acc + curr[1].currentHeadcount.total, 0)
      + Array.from(offShoreData.entries()).filter(e => e[0] < globalLocationId).reduce((acc, curr) => acc + curr[1].currentHeadcount.total, 0)
    userInputSocPercent['totalFTE'] = totalFTE;

    //BenchmarkRate, Benchmark SOC. Used for final benchmark spend and Pyramid "To" rate
    let blendedRateTargetBenchmark: ShoringData = new ShoringData(
      onShoreData.get(globalLocationId)?.getBenchmarkTowerRate(benchmarkSocPercent).total ?? 0,
      offShoreData.get(globalLocationId).getBenchmarkTowerRate(benchmarkSocPercent).total
    );

    //Benchmark Rate, User Input SOC. Used for Rate Optimization "to" rate, Pyramid "from" rate 
    let blendedRateTargetUserInput: ShoringData = new ShoringData(
      onShoreData.get(globalLocationId)?.getBenchmarkTowerRate(userInputSocPercent).total ?? 0,
      offShoreData.get(globalLocationId).getBenchmarkTowerRate(userInputSocPercent).total,
    );

    //User Input Rate, User Soc. Only used in Rate Optimization "from"
    let blendedRateUserInput: ShoringData = new ShoringData(
      onShoreData.get(globalLocationId)?.blendRate ?? 0,
      offShoreData.get(globalLocationId).blendRate
    );

    //Used For Shoring optimization since only counts are changed. Rate remains unaffected
    let currentFTECount: ShoringData = this.getFTECount(totalFTE, inputShoringPercent);
    let targetFTECount: ShoringData = this.getFTECount(totalFTE, benchmarkShoringPercent);

    let onShoreHeadcounts = onShoreData['map']('currentHeadcount');
    let offShoreHeadcounts = offShoreData['map']('currentHeadcount');
    if (!onShoreHeadcounts.get(globalLocationId)
      || onShoreHeadcounts.get(globalLocationId).total == 0)
      targetFTECount = currentFTECount;
    if (offShoreHeadcounts.get(globalLocationId).total == 0) { //
      blendedRateUserInput.offShore = blendedRateTargetUserInput.offShore;
    }

    //To display in the grid. Not used in Chart
    let currentSpendByLocation: Map<number, number> = new Map([...onShoreData, ...offShoreData])['map']('currentSpend');
    let targetSpendByLocation: Map<number, number> = new Map([
      ...onShoreData['map']((value: LocationRateData) => value.getTargetSpend(benchmarkSocPercent, targetFTECount.onShore / (onShoreData.size - 1))),
      ...offShoreData['map']((value: LocationRateData) => value.getTargetSpend(benchmarkSocPercent, targetFTECount.offShore / (offShoreData.size - 1)))
    ]);


    let currentSpend: ShoringData = new ShoringData(
      onShoreData.get(globalLocationId)?.currentSpend ?? 0,
      offShoreData.get(globalLocationId).currentSpend
    );

    let targetSpend: ShoringData = new ShoringData(
      blendedRateTargetBenchmark.onShore * targetFTECount.onShore * 1920,
      blendedRateTargetBenchmark.offShore * targetFTECount.offShore * 1920
    );

    //Just change the FTE counts. =0 in case of 1-0 cases
    let shoringSavings: ShoringData = this.calculateSavings(blendedRateUserInput, currentFTECount, targetFTECount);

    //Apply Benchmark Rates
    let rateSavings: ShoringData = this.calculateSavings(targetFTECount, blendedRateUserInput, blendedRateTargetUserInput);

    //Apply benchmark SOC
    let socSavings: ShoringData = this.calculateSavings(targetFTECount, blendedRateTargetUserInput, blendedRateTargetBenchmark)

    currentSpendByLocation.delete(globalLocationId);
    targetSpendByLocation.delete(globalLocationId);

    return { currentSpend, shoringSavings, socSavings, rateSavings, targetSpend, currentSpendByLocation, targetSpendByLocation, towerId }
  }

  private calculateSavings(FTECount: ShoringData, blendedRate1: ShoringData, blendedRate2: ShoringData) {
    let onShoreSpend = FTECount.onShore * (blendedRate1.onShore - blendedRate2.onShore) * 1920;
    let offShoreSpend = FTECount.offShore * (blendedRate1.offShore - blendedRate2.offShore) * 1920;

    return new ShoringData(Math.floor(onShoreSpend), Math.floor(offShoreSpend));
  }

  private getFTECount(totalFTE: any, shoring: any): ShoringData {
    let fteCount = new ShoringData(
      totalFTE * shoring['onShore'] / 100,
      totalFTE * shoring['offShore'] / 100
    );

    return fteCount;
  }

  private async getShoreDataMap(towerId: number, isOffShore: boolean, globalLocationId: number = 999999) {
    let selectedLocationIds = ( await this.dataService.getDataFromDb('locations', {}, true)).filter(location => location['offshore'] == isOffShore).map(x => x.id);

    let allinputs = [];
    let skillFactor = await this.dataService.getSkillFactor();
    let sptFilterForOA = await this.dataService.sptFilterForOA();
    let allrates = await (await db.towerRates.where('towerId').equals(towerId).toArray()).filter(x => x['serviceProviderType'] == sptFilterForOA)[0];
    allrates['locationData'] = this.encDecService.decryptObject(allrates['locationData']);

    if (selectedLocationIds.length == 0) { //Default offshore location set to INDIA. Ingore INDIA inputs in default case
      if (isOffShore)
        selectedLocationIds = (await this.dataService.getDefaultOffshoreLocation()).map(e => e.id);
    } else {
      allinputs = await db.detailed_analysis.where('tower').equals(towerId).toArray(); //Get all inputs only if not in default offshore location case
    }

    let globalBenchmarkCost: LevelWiseData = new LevelWiseData();
    let globalTotalHeadcount: LevelWiseData = new LevelWiseData();
    let globalBmRates: LevelWiseData = new LevelWiseData();
    let globalSpend: number = 0;

    let LocationData: Map<number, LocationRateData> = new Map<number, LocationRateData>();

    selectedLocationIds.forEach((locationId) => {
      let locbenchmarkCost: LevelWiseData = new LevelWiseData();
      let loctotalHeadcount: LevelWiseData = new LevelWiseData();
      let locbmRates: LevelWiseData = new LevelWiseData();
      let locationSpend: number = 0;

      let detailedInput: LevelWiseData = new LevelWiseData(allinputs.filter(x => x.location == locationId)[0]);
      let rate = allrates['locationData'].filter(x => x.locationId == locationId)[0]['rates'];

      if (rate && detailedInput) {
        locationSpend = allinputs.filter(x => x.location == locationId).length > 0 ? allinputs.filter(x => x.location == locationId)[0]['spend'] : 0;
        locbenchmarkCost.level1 = detailedInput.level1 * rate.level1['50'];
        locbenchmarkCost.level2 = detailedInput.level2 * rate.level2['50'];
        locbenchmarkCost.level3 = detailedInput.level3 * rate.level3['50'];
        locbenchmarkCost.level4 = detailedInput.level4 * rate.level4['50'];
        locbenchmarkCost.level5 = detailedInput.level5 * rate.level5['50'];

        locbmRates.level1 = rate.level1['50'];
        locbmRates.level2 = rate.level2['50'];
        locbmRates.level3 = rate.level3['50'];
        locbmRates.level4 = rate.level4['50'];
        locbmRates.level5 = rate.level5['50'];

        loctotalHeadcount.level1 = detailedInput.level1;
        loctotalHeadcount.level2 = detailedInput.level2;
        loctotalHeadcount.level3 = detailedInput.level3;
        loctotalHeadcount.level4 = detailedInput.level4;
        loctotalHeadcount.level5 = detailedInput.level5;

        globalSpend += locationSpend;
        globalBenchmarkCost.level1 += locbenchmarkCost.level1;
        globalBenchmarkCost.level2 += locbenchmarkCost.level2;
        globalBenchmarkCost.level3 += locbenchmarkCost.level3;
        globalBenchmarkCost.level4 += locbenchmarkCost.level4;
        globalBenchmarkCost.level5 += locbenchmarkCost.level5;

        globalBmRates.level1 += locbmRates.level1;
        globalBmRates.level2 += locbmRates.level2;
        globalBmRates.level3 += locbmRates.level3;
        globalBmRates.level4 += locbmRates.level4;
        globalBmRates.level5 += locbmRates.level5;

        globalTotalHeadcount.level1 += loctotalHeadcount.level1;
        globalTotalHeadcount.level2 += loctotalHeadcount.level2;
        globalTotalHeadcount.level3 += loctotalHeadcount.level3;
        globalTotalHeadcount.level4 += loctotalHeadcount.level4;
        globalTotalHeadcount.level5 += loctotalHeadcount.level5;
      }
      let shoreData = new LocationRateData();
      shoreData.initializeShoreData(loctotalHeadcount, locbmRates, locbenchmarkCost, 1, skillFactor, locationId, locationSpend);
      LocationData.set(locationId, shoreData);
    });

    if (selectedLocationIds.length > 0) {
      let globalShoreData = new LocationRateData();
      globalShoreData.initializeShoreData(globalTotalHeadcount, globalBmRates, globalBenchmarkCost, selectedLocationIds.length, skillFactor, globalLocationId, globalSpend);
      LocationData.set(globalLocationId, globalShoreData);
    }

    return LocationData;
  }

  private async getInputShoring(towerId: number) {
    let selectedLocations =  await this.dataService.getDataFromDb('locations', {}, true);
    let detailedInputs = await (await db.detailed_analysis.where('tower').equals(towerId).toArray()).filter(x => x.location != 0);
    let offShore = 0;
    let onShore = 0;
    selectedLocations.forEach(location => {
      let isoffShore = location['offshore'];
      let inputs = detailedInputs.filter(x => x.location == location.id);
      if (inputs.length > 0) {
        if (isoffShore) {
          offShore += inputs[0]['total'];
        }
        else {
          onShore += inputs[0]['total'];
        }
      }
    })

    let total = offShore + onShore;

    let shoring = {
      offShore: (offShore / total) * 100,
      onShore: (onShore / total) * 100
    };

    return shoring;

  }

  private async getBenchmarkShoring(towerId: number) {
    return await db.offshore.where('tower').equals(towerId).first();
  }

  private async getInputSOCs(towerId: number) {
    let detailedInputs = await (await db.detailed_analysis.where('tower').equals(towerId).toArray()).filter(x => x.location != 0) ?? [];

    let SOCs = new LevelWiseData();
    let level1: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level1 ?? 0), 0);
    let level2: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level2 ?? 0), 0);
    let level3: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level3 ?? 0), 0);
    let level4: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level4 ?? 0), 0);
    let level5: number = detailedInputs.reduce<number>((acc, current) => acc + Number(current.level5 ?? 0), 0);

    let totalFTE = level1 + level2 + level3 + level4 + level5;

    SOCs.level1 = (level1 / totalFTE) * 100;
    SOCs.level2 = (level2 / totalFTE) * 100;
    SOCs.level3 = (level3 / totalFTE) * 100;
    SOCs.level4 = (level4 / totalFTE) * 100;
    SOCs.level5 = (level5 / totalFTE) * 100;

    return SOCs;
  }

  private async getBenchmarkSOCs(towerId: number) {
    let soc = await db.socs.where('towerid').equals(towerId).first()
    return soc['soc'];
  }

  //savings analysis roles functions

  

}

