import { Component, Input, OnInit } from '@angular/core';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { ApiService } from '../api.service';
import { DataService } from '../data.service';
import { DataUpdateService } from '../data-update.service';

@Component({
  selector: 'app-tower-analysis',
  host: { 'class': 'tower-analysis' },
  templateUrl: './tower-analysis.component.html',
  styleUrls: ['./tower-analysis.component.css']
})
export class TowerAnalysisComponent implements OnInit {

  constructor(private apiService: ApiService, private dataService: DataService, private dataUpdateService: DataUpdateService) { }
  sliderOptions = { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true };
  onshore: any = { onShore: 0, offShore: 0 };
  selectedTowers = [];
  noData = false;
  @Input() modal?: boolean = false;
  @Input() tower?: object;
  @Input() pdfExport?: boolean = false;

  ngOnInit(): void {
    liveQuery(() => this.getOffshoreFromDb()).subscribe(result => {
      if (this.modal) {
        this.selectedTowers = result.filter(t => t.id == this.tower['row']['id']);
      }
      else {
        this.selectedTowers = result;

      }

      this.noData = result.length == 0;
    })
    this.dataUpdateService.subscribeDataChangeEvent().subscribe(event => {
      if (['towerSubtowerUpdate'].indexOf(event) != -1) {
        this.getOffshoreFromDb().then(result => {
          if (this.modal) {
            this.selectedTowers = result.filter(t => t.id == this.tower['row']['id']);
          }
          else {
            this.selectedTowers = result;
          }

          this.noData = result.length == 0;
        })
      }
    });

  }

  async getOffshoreFromDb() {
    return await this.dataService.getPyramidChartData()
  }

  async performOperation(shoreData) {
    shoreData.offShore = 100 - shoreData.onShore;
    await db.offshore.update(shoreData.id, { onShore: shoreData.onShore, offShore: shoreData.offShore });
  }

}
