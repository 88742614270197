import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from './loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<LoaderComponent>) { }

  openDialog(text = "Loading"): void {
    if (!this.dialog.getDialogById('loader'))
      this.dialog.open(LoaderComponent, { data: { text: text }, disableClose: true, hasBackdrop: true, panelClass: 'loader-modal', id: 'loader' })
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
