import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';
import { db } from 'src/db';
import { EncyptDecryptService } from '../encypt-decrypt.service';
import * as _ from 'lodash';
import { DataUpdateService } from '../data-update.service';
import { liveQuery } from 'dexie';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-deal-params',
  templateUrl: './deal-params.component.html',
  styleUrls: ['./deal-params.component.scss']
})
export class DealParamsComponent implements OnInit, OnDestroy {

  constructor(private apiService: ApiService, private dataUpdate: DataUpdateService, private dataService: DataService, private encDecService: EncyptDecryptService) { }
  dealParamFormGroup: FormGroup;
  selectedValues = [];
  querySub = new Subscription();
  dealParams: any[] = [];
  factorValue: number=1;
  factorValueOffshore: any = {
    "US TIER 2": 1.0,
    "US TIER 1": 1.0
  };

  ngOnInit(): void {
    this._storeDealTowerParams();
    this.querySub.add(liveQuery(() => this.getCategory()).subscribe(result => {
      this.getDealParams();
    }))
  }

  ngOnDestroy(){
    this.querySub.unsubscribe();
  }

  async getCategory(){
    return await db.selection_categories.toArray();
  }

  private async _storeDealTowerParams(){
    if(await db.deal_params.count() == 0){
      this.apiService.getDealParams().subscribe(async(result) => {
        await db.deal_params.bulkAdd(result);
      });
      this.apiService.getTowerParams().subscribe(async(result) => {
        await db.tower_params.bulkAdd(result);
        await this.getDealParams();
      })
    }
    else{
      await this.getDealParams()
    }    
  }

  async getDealParams(){
    let dealParamsData = await this.dataService.getDataFromDb('deal_params',{});
    let selectedCategory = await db.selection_categories.toArray();
    dealParamsData = dealParamsData.filter(dp => { return dp.category == selectedCategory[0]['id'] });
    let params = _.groupBy(dealParamsData, _.iteratee('parameter'));
    let resultArray = [];
    _.forEach(params, (value, key) => {
      resultArray.push({
        paramName: key,
        values: value
      });
    });

    resultArray.forEach((item, key) =>{
      if(item['paramName'] == 'Offshore Leverage'){
      const arrayUniqueByKey = [...new Map(item.values.map(op =>[op['displayText'], op])).values()];
        resultArray.push({
          paramName: item['paramName'],
          values: arrayUniqueByKey
        })
        resultArray.splice(key, 1)
      }
    })
    this.dealParams = resultArray;
    this.dealParamFormGroup = this.toFormGroup(this.dealParams);
  }

  toFormGroup(params: any[]): FormGroup {
    const group: any = {};
    _.forEach(params, (values, key) => {
      let selections = values.values.filter(x => x.isSelected)
      if(selections.length == 0){
        selections = values.values.filter(x => x.isDefault)
      }
      group[values.paramName] = new FormControl(selections[0]);
    })
    return new FormGroup(group);
  }

  async setSelectedValues(event, dealId) {
    let data = event.value;
    data['isSelected'] = true;
    let result = await db.selection_deal_params.filter(x => {
      return x.category == data.category && x.parameter == data.parameter
    }).first()
    if(result){
      await db.selection_deal_params.delete(result.id)
    }    
    await this.dataService.updateRecord('deal_params', data);
    this.dataUpdate.emitDataChangeEvent('dealParamUpdated')
  }

  getControl(controlName: string) {
    return this.dealParamFormGroup.controls[controlName] as FormControl;
  }

  getControls() {
    return Object.keys(this.dealParamFormGroup.controls);
  }

  async resetDefaults(){
    await db.selection_deal_params.clear();
    await this.getDealParams();
    this.dataUpdate.emitDataChangeEvent('dealParamUpdated')
  }

}
