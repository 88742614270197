import { Component, OnDestroy, OnInit } from '@angular/core';
import { liveQuery } from 'dexie';
import { Subscription } from 'rxjs';
import { db } from 'src/db';
import { DataService } from '../data.service';

@Component({
  selector: 'app-detailed-tower-analysis',
  templateUrl: './detailed-tower-analysis.component.html',
  styleUrls: ['./detailed-tower-analysis.component.scss']
})
export class DetailedTowerAnalysisComponent implements OnInit, OnDestroy {

  constructor(private dataService:DataService) { }
  selectedLocations = [];
  liveQuerySubscription = new Subscription();

  ngOnInit(): void {
    this.liveQuerySubscription.add(liveQuery(() => this.getSelectedLocations()).subscribe((locations:any) => {
      this.selectedLocations = locations;
    }))
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  async getSelectedLocations(){
    return  await this.dataService.getDataFromDb('locations', {}, true);

  }

}
