import { Component, Input, OnInit } from '@angular/core';
import { thresholdScott } from 'd3-array';
import { ApiService } from '../api.service';
declare let d3: any;
declare let donut: any;
@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.css']
})
export class DonutChartComponent implements OnInit {
  @Input() finalData: any;
  constructor(private apiService: ApiService) { }
  donutData = {onshore: 0, offshore: 0};
  ngOnInit(): void {

    // this.apiService.getOffShoreData().subscribe(data => {
    //   this.donutData = {
    //     offshore: data[0]['offShore'],
    //     onshore: data[0]['onShore']
    //   }
    //   this.finalData['donut'] = this.donutData;
    //   this.renderDonut();
    // })
  }

  renderDonut(){
    d3.select('#chart').selectAll("*").remove();
    var chart = donut()
              .$el(d3.select("#chart"))
              .data(this.donutData)
              .render();
  } 

  performOperation(type){
    if(type == 'minus'){
      this.donutData.onshore = this.donutData.onshore-1; 
      this.donutData.offshore = this.donutData.offshore+1; 
    }
    if(type == 'plus'){
      this.donutData.onshore = this.donutData.onshore+1; 
      this.donutData.offshore = this.donutData.offshore-1; 
    }
    this.finalData['donut'] = this.donutData;
    this.renderDonut()
  }

}
