<!-- <h1 mat-dialog-title>Please pick your option</h1>
<div mat-dialog-content>
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
        [(ngModel)]="selectedChoice">
        <mat-radio-button [matTooltip]="season.tooltipText" [disabled]="season.disabled" class="example-radio-button"
            *ngFor="let season of choices" [value]="season.type">
            {{season.text}}
        </mat-radio-button>
    </mat-radio-group>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="resetAsPerSelection()" cdkFocusInitial>Reset</button>
</mat-dialog-actions> -->

<h1 mat-dialog-title class="saveSenarioTitle">Reset Selections to:</h1>
<div class="saveSenarioSelectLabel">To reset recent activity state, select it from the given dropdown list</div>
<mat-dialog-content class="mat-typography">

    <mat-form-field>
        <mat-select (selectionChange)="loadData($event.value)" [(value)]="selectedData">
            <mat-option disabled value="">--Select One--</mat-option>
            <mat-option *ngFor="let stepSelection of selections?.stepSelectionsRecords" [value]="stepSelection">
                <span *ngIf="stepSelection.type=='step'">
                    Selection at {{stepSelection.created | date:'medium'}}
                </span>
                <span *ngIf="stepSelection.type=='saved'">
                    Saved Scenario ({{stepSelection.data.name}})
                </span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="tscroll" *ngIf="dataSource && dataSource.length>0">
        <table fixedLayout mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Selection</th>
                <td mat-cell *matCellDef="let element"> {{element.type | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Values</th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip-list>
                        <span *ngFor="let value of element.value">
                            <mat-chip disabled>{{value}}</mat-chip>
                        </span>
                    </mat-chip-list>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end" class="saveSenariobtn">
    <button [disabled]="selectedData.length == 0" class="themebg ms-3" mat-button (click)="resetAsPerSelection()" mat-raised-button
        color="primary">Reset</button>
        <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>