import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { timeHours } from 'd3';
import { config } from 'src/environments/config';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';
import { db } from 'src/db';

@Component({
  selector: 'app-list-scenario',
  templateUrl: './list-scenario.component.html',
  styleUrls: ['./list-scenario.component.scss']
})
export class ListScenarioComponent implements OnInit {
  domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
  rowHeight = 30;
  headerHeight = 30;
  public rowData$: Observable<any>;
  private gridApi: GridApi | undefined;
  columnDefs: ColDef[] = [
    { field: 'name' },
    {
      field: 'type',
      headerName: 'Type',
      valueGetter: (params) => params.data.type == 1 ? "FTE" : "RU",
      width: 75,
      suppressSizeToFit: true
    },
    {
      field: 'catName.category',
      headerName: 'Category',
      valueGetter: (params) => params.data.catName.category,
      width: 95,
      suppressSizeToFit: true,//cellRenderer: (params)=> {} 
    },

    { headerName: 'Last Modified', field: 'createdOn', valueGetter: (params) => config.formatDateTime(params.data.createdOn) },
    {
      field: 'delete',
      headerName: '',
      headerTooltip:'Delete saved scenario',
      //valueGetter: (params) => params.data.catName.category,
      width: 50,
      cellRenderer: function () {
        //if (params.data.expand != 1)
        return '<span class="delete-row"><i title="Delete saved scenario" class="fa-regular fa-trash-can"></i></span>'

      },
      //suppressSizeToFit: true,//cellRenderer: (params)=> {} 
    },
  ];

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
    this.getScenarios();
  }

  getScenarios() {
    this.rowData$ = this.apiService.getScenariosList();
    // this.gridApi.sizeColumnsToFit()
  }

  onCellClicked(event: CellClickedEvent) {
    if (event.colDef.field == "delete") {
      this.apiService.deleteScenario(event.data.id).subscribe(e => {
        this.rowData$.subscribe(rows => {
          rows = rows.filter(row => { return row.id != event.data.id});
          this.rowData$ = of(rows);
        });
      });
    }
    else
      this._applyScenarioAndRedirect(event.data);

  }

  async saveToTable(tableName, data) {
    return db.table(tableName).bulkAdd(data);
  }

  private async _applyScenarioAndRedirect(data) {
    localStorage.removeItem('showOnlySelected')
    await db.reset_scenario_stat.clear();
    await db.reset_scenario_stat.add({ scenarioId: data.id, data: data, created: Date.now() });
    this.openBlockingProgressDialog();
    let clearOnly = false;
    await this.dataService.clearSelections();
    await this.dataService.clearPreviousSpends();
    await db.spends.clear();
    await db.rateList.clear();
    await db.selection_deal_params.clear();
    await db.selection_tower_params.clear();
    this.apiService.getScenario(data.id).subscribe(async (res: any) => {
      let apiData = res.data;
      let dataKeys = Object.keys(apiData);
      let omittedKeys = ['meta']
      if (!clearOnly) {
        await Promise.all(dataKeys.map(async (key) => {
          if (omittedKeys.indexOf(key) == -1)
            await db.table(key).bulkAdd(apiData[key]);
        }));
        (await this.apiService.getRatesAsPerSelections()).subscribe(async (data: any) => {
          await db.table('rateList').bulkPut(data);
          this.router.navigate([(apiData.meta['loadedPage'] == 'ru')? "/ru": '/fte', apiData.meta['loadedPage']]).then(d => {
            this.dialog.closeAll();
          })
        });
      }
    })

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit()
  }

  openBlockingProgressDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {});
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `<div mat-dialog-content class="p-3">Please wait while data is being prepared!</div>`,
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
