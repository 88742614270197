<div class="selection" *ngIf="countryList">
    <div *ngIf="!isModal && countryList.length > 0" class="country-list">
        <div class="bg-heading customFlex">
            <div class="title d-flex align-items-center" style="position: relative;">
                <img *ngIf="regionName!='Select Location(s)'" class="me-2 cursor-pointer" (click)="deselectRegion()"
                    style="margin-top: 2px !important; width: 15px; height: 15px;"
                    src="./assets/image/arrow-return-left.svg" />
                <!-- <button *ngIf="regionName!='All Locations'" (click)="deselectRegion()" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                <!- <mat-icon>undo</mat-icon> ->                
              </button>  -->
                <span class="truncate-regionName-main" style="line-height: 23px; width: auto;"
                    matTooltip="{{ regionName }}">{{
                    regionName
                    }}</span>
            </div>
            <span *ngIf="locationLimitEnabled" style="line-height: 1.2;">*Max 4 locations allowed to select</span>
            <span *ngIf="displayWarningMessage" class="popup-slecting-more" matTooltip="(*Selecting more locations will
            increase computation time.)" style="background: #fff;
            position: absolute;
            left: 6px;
            top: 36px;
            z-index: 1;
            "> &nbsp; (*Selecting more locations will
                increase computation time.)</span>
        </div>
        <ul class="select-options scroll-window" [ngClass]="{'select-options-for-margin':displayWarningMessage}">
            <li *ngFor="let location of countryList; let i = index;">
                <mat-checkbox (change)="updateSelection($event, location)" [checked]="location.isSelected"
                    color="primary">
                    {{location.locationName}}
                </mat-checkbox>
            </li>
        </ul>
    </div>

    <div *ngIf="isModal">
        <form [formGroup]="selectionCountriesForm">
            <div class="bg-heading customFlex" style="position: relative;
            align-items: start;">
                <!-- <button *ngIf="regionName!='All Locations'" (click)="deselectRegion()" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                    <mat-icon>undo</mat-icon>
                  </button> -->
                <div class="title d-flex align-items-center">
                    <img *ngIf="regionName!='Select Location(s)'" class="me-2 cursor-pointer" (click)="deselectRegion()"
                        style="margin-top: 2px !important; width: 15px; height: 15px;"
                        src="./assets/image/arrow-return-left.svg" />

                    <span class="title truncate-regionName" style="line-height: 23px; width: auto;"
                        matTooltip="{{ regionName }}"> {{
                        regionName }}</span>
                </div>
                <span *ngIf="locationLimitEnabled" style="line-height: 1.2;">*Max 4 locations allowed to select</span>
                <span *ngIf="displayWarningMessage" class="popup-slecting-more" matTooltip="(*Selecting more locations will
                increase computation time.)" style="background: #fff;
                position: absolute;
                left: 6px;
                top: 35px;
                z-index: 1;"> &nbsp;(*Selecting more locations will
                    increase computation time.)</span>
            </div>
            <ul class="select-options scroll-window " [ngClass]="{'select-options-for-margin': displayWarningMessage}">
                <li *ngFor="let location of countryList; let i = index;">
                    <mat-checkbox
                        [disabled]="locationLimitEnabled && selectionCountriesForm.get('countries').value.length == 4 && selectionCountriesForm.get('countries').value.indexOf(location.id) == -1"
                        (change)="updateSelectionCheckbox($event, location.id, location)"
                        [checked]="location.isSelected" color="primary">
                        {{location.locationName}}
                    </mat-checkbox>
                </li>
            </ul>
        </form>
    </div>

    <mat-accordion multi>
        <mat-expansion-panel [expanded]="true" [disabled]="true">
            <mat-expansion-panel-header class="bg-heading">
                <mat-panel-title class="title selected-loc-title">
                    <span>
                        Selected Location(s)
                    </span>
                    <!-- <div class="offshore-title">Onshore/Offshore</div> -->
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field class="tags-list">
                    <mat-chip-list #chipList aria-label="Countries selection">
                        <div *ngFor="let location of selectedCountries">
                            <mat-chip class="tags">
                                {{location.locationName}}
                                <!-- <span class="offshore-flag" *ngIf="location.offshore">
                                    <img src="../../../assets/image/icon_flag.svg" alt="Offshore Location"></span> -->
                            </mat-chip>
                            <!-- <mat-label ngClass="labelBeforeSlide">Off</mat-label> -->
                            <!-- <mat-slide-toggle disabled class="custom-toggle" color="primary"
                                (change)="markAsOffshoreLocation($event,location)"
                                [checked]="location.offshore"></mat-slide-toggle> -->
                            <span *ngIf="benchmarkType != 'ru' && variant != 'mini'">{{location.offshore? 'Offshore':
                                'Onshore' }}</span>
                        </div>
                    </mat-chip-list>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

</div>
<div *ngIf="isModal" class="modal-footer" style="margin: 10px 10px;">
    <span class="me-5" style="color:orangered" *ngIf="checkIfLimitEnabled()">You can select upto only 4 Locations!
    </span>
    <button [disabled]="checkIfLimitEnabled()" class="cbtn-primary cbtn-secondary" mat-stroked-button mat-dialog-close
        (click)="submitLocations()">Submit</button>
</div>