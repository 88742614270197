import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonutChartTowerComponent } from './donut-chart-tower/donut-chart-tower.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TowerSubtowerListComponent } from './tower-subtower-list/tower-subtower-list.component';
import { MatTreeModule } from '@angular/material/tree';
import { RegionChartComponent } from './region-chart/region-chart.component';
import { SelectCountriesComponent } from './select-countries/select-countries.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips'
import { MatTooltipModule} from '@angular/material/tooltip';
import { TowerSunburstComponent } from '../tower-sunburst/tower-sunburst.component';
@NgModule({
  declarations: [
    DonutChartTowerComponent,
    TowerSubtowerListComponent,
    SelectCountriesComponent,
    RegionChartComponent,
    TowerSunburstComponent
  ],
  imports: [
    FormsModule,
    NgxSliderModule,    
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatInputModule,
    MatExpansionModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTableModule,
    MatListModule,
    CommonModule,
    MatTreeModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  exports:[
    DonutChartTowerComponent,
    RegionChartComponent,
    SelectCountriesComponent,
    TowerSunburstComponent
  ]
})
export class SharedModule { }
