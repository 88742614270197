<div class="scatter-chart-section" style="height: 100%;">
  <div class="chart-container tour-variance">
    <div id="scatterChart"></div>
    <ng-container *ngIf="showOverlay">
      <div class="region-overlay opc-1" style="border: 0;">
        <span href="javascript:void(0)" class="error-text">&#9432; Input your rates for one or more roles (and for one
          or
          more selected locations and provider types) to analyze how your input rate variance compares to the benchmark
          rates.</span>
      </div>
    </ng-container>
  </div>
</div>
