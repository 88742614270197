import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';
import { liveQuery } from 'dexie';
import { db, InputPlot, ProviderTypeList, SelectionLocationList } from 'src/db';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import * as ApexCharts from 'apexcharts';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { EncyptDecryptService } from '../encypt-decrypt.service';
import { config } from 'src/environments/config';
@Component({
  selector: 'app-scatter-chart-new',
  templateUrl: './scatter-chart-new.component.html',
  styleUrls: ['./scatter-chart-new.component.css']
})

export class ScatterChartNewComponent implements OnInit {
  @Input() type: string;
  public chart: ApexCharts;
  skillFactor: number = 1;
  showOverlay: boolean = false;
  liveQuerySubscription: Subscription = new Subscription();
  @Output() dataLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public roles: any = {};

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dataService: DataService, private encryptDecryptService: EncyptDecryptService) {

  }

  ngOnInit(): void {

    let callback = async (data) => {
      let result = this.type == 'tower' ? await this.getChartDataTower(this.skillFactor) : await this.getChartDataRole(this.skillFactor);

      this.showOverlay = result.length == 0;
      let options = this.createOptions(result);
      this.dataLoaded.emit(false);
      if (this.chart != undefined) {
        this.chart.updateOptions(options)
      }
      else
        this.createChart(options);
    };

    if (this.type == 'role')
      this.getRoles().then(callback.bind(this))
    else if (this.type == 'tower')
      this.getTowers().then(callback.bind(this))

  }

  async getRoles() {
    this.skillFactor = await this.dataService.getSkillFactor();

    //return await this.dataService.getRolesWithRatesInputPlot();
  }

  async getTowers() {
    this.skillFactor = await this.dataService.getSkillFactor();
    //return await this.dataService.getTowerWithRatesInputPlot();
  }

  async getChartDataRole(skillFactor: number) {

    let data = await this.dataService.getRolesWithRatesInputPlot();
    const selectedLocations = await this.getSelectedLocations();
    let locationIds = selectedLocations.map(loc => { return loc['id'] });

    const selectedSPT = await this.getSelectedSPT();
    let selectedArea = await this.dataService.getDataFromDb('areas', {}, true);

    const inputs = await this.getSelectedInputs('role');
    const towersDetail = await this.dataService.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true); // await db.selection_towers.toArray();

    const roles = [...new Set(inputs.map(item => item.role))];
    const rolesDetail = await db.roles.where('id').anyOf(roles).toArray();
    this.roles = rolesDetail.map(role => `${role.role} (${towersDetail.find(tower => tower.id == role.tower)?.['shortCode']})`);
    const rates = await this.getRates(roles, 'role');

    const towers = Object.fromEntries(towersDetail.map(({ id, tower }) => ([id, tower])));

    let dataset = [];

    selectedLocations.forEach(location => {
      selectedSPT.forEach(spt => {
        let series = {
          name: location.locationName + "(" + spt.spt.substring(0, 1) + ")",
          data: [],
          metadata: []
        }
        let flag = 0;
        roles.forEach(async role => {
          let input = inputs.filter(input => input.role == role && input.serviceProvider == spt.id && input.location == location.id);
          let obj = {};
          obj['location'] = location.locationName;
          obj['spt'] = spt.spt;
          obj['role'] = this.roles[role];
          obj['tower'] = towers[rolesDetail.filter(x => x.id == role)[0].tower];
          obj['x'] = flag++;
          if (input.length > 0) {
            obj['input'] = config.formatNumber(input[0].inputValue);
            // let encrptedRate = rates.filter(rate => rate['role'] == role && rate['serviceProviderType'] == spt.id && rate['location'] == location.id && rate['benchmarkPoint'] == 50)[0]['value'];
            // let rate = parseFloat(this.encryptDecryptService.decryptData(encrptedRate)) * skillFactor;
            let roleRate = data.filter(x => x.id == role)[0]?.['rateSPT'][location.id][spt.id][50];

            let variance = ((input[0].inputValue - roleRate) / roleRate) * 100;
            obj['median'] = config.formatNumber(roleRate);
            obj['y'] = parseInt(variance.toFixed(0));
            obj['variance'] = config.formatNumber(variance);
          }
          else
            obj['y'] = '';
          series.metadata.push(obj);
          series.data.push(obj['y'])
        });
        if (series.metadata.filter(x => x.y != '').length > 0)
          dataset.push(series);
      })
    })

    return dataset;
  }

  async getChartDataTower(skillFactor: number) {

    let data = await this.dataService.getTowerWithRatesInputPlot();
    const selectedLocations = await this.getSelectedLocations();
    let locationIds = selectedLocations.map(loc => { return loc['id'] });

    const selectedSPT = await this.getSelectedSPT();

    const inputs = await this.getSelectedInputs('tower');

    const towerIds = [...new Set(inputs.map(item => item.tower))];
    const towersDetail = await db.towers.where('id').anyOf(towerIds).toArray();

    const towersList = Object.fromEntries(towersDetail.map(({ id, tower }) => ([id, tower])));
    this.roles = towersDetail.map((tower) => tower.tower);

    const rates = await this.getRates(towerIds, 'tower');

    let dataset = [];

    selectedLocations.forEach(location => {
      selectedSPT.forEach(spt => {
        let series = {
          name: location.locationName + "(" + spt.spt.substring(0, 1) + ")",
          data: [],
          metadata: []
        }
        let flag = 0;
        towerIds.forEach(async tower => {
          let input = inputs.filter(input => input.tower == tower && input.serviceProvider == spt.id && input.location == location.id);
          let obj = {};
          obj['location'] = location.locationName;
          obj['spt'] = spt.spt;
          obj['tower'] = towersList[tower];
          obj['x'] = flag++;
          if (input.length > 0) {
            obj['input'] = config.formatNumber(input[0].inputValue);
            // let towerRates = rates.filter(rate => rate['location'] == location.id && rate['tower'] == tower && rate['benchmarkPoint'] == 50).map(x => parseFloat(x['intValue']));
            // const average = towerRates.reduce((prev, curr) => prev + curr) / towerRates.length;
            let towerRate = data.filter(x => x.id == tower)[0]['rateSPT'][location.id][spt.id][50];
            // let rate = average * skillFactor;
            let variance = ((input[0].inputValue - towerRate) / towerRate) * 100;
            obj['median'] = config.formatNumber(towerRate);
            obj['y'] = variance.toFixed(0);
            obj['variance'] = config.formatNumber(variance);
          }
          else
            obj['y'] = '';
          series.metadata.push(obj);
          series.data.push(obj['y'])
        });
        if (series.metadata.filter(x => x.y != '').length > 0)
          dataset.push(series);
      })
    })

    return dataset;
  }

  async getSelectedLocations() {
    return this.dataService.getDataFromDb('locations', {}, true);
  }

  async getSelectedSPT() {
    return this.dataService.getDataFromDb('providerTypes', {}, true);
  }

  async getSelectedInputs(col) {
    let selections = await this.dataService.getSelections();
    if(col == 'role'){
      let roles = selections.role.map(r => r.id)
      return await db.input_plot.where('role').anyOf(roles).toArray();
    }
    return await db.input_plot.where(col).notEqual('').toArray();
  }

  async getRates(ids, col) {
    return await db.rateList.where(col).anyOf(ids).toArray();
  }

  createOptions(data: any[]) {
    ;
    ;
    let options = {
      colors: environment.newColorScheme,
      chart: {
        fontFamily: 'Open Sans',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
          type: "xy"
        },
        height: 450,
        type: "scatter",
      },
      series: data,
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        showForSingleSeries: true
      },
      xaxis: {
        type: "category",
        tickPlacement: 'between',
        tickAmount: this.roles.length,
        categories: this.roles.map(e => e.split(' '))
      },
      yaxis: {
        labels: {
          minHeight: 30,
          maxHeight: 30,
          formatter: (value) => { return value.toFixed(0) + '%' },
          offsetX: -15
        }
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var data = w.globals.initialSeries[seriesIndex].metadata[dataPointIndex];
          var tooltip = `<ul class="scatter-tooltip"> 
          <li><b>Location</b>:${data.location} (${data.spt})</li>
          <li><b>Median</b>: $ ${data.median} &emsp;|&emsp; <b>Input</b>: $ ${data.input}</li>
          <li><b>Variance</b>: ${data.y}%</li></ul>`;
          return tooltip;
        }
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: '#D0B60F'
          }
        ]
      },
      grid: {
        padding: {
          left: 0,
          right: 40,
          bottom: 0
        }
      }
    };
    ;
    return options;
  }

  createChart(options) {
    this.chart = new ApexCharts(document.querySelector("#scatterChart"), options);
    this.chart.render();
  }

}
