<table *ngFor="let d of data" class="table table-striped">
    <tr>
        <td>{{d.towerName}}</td>
    </tr>
    <tr>
        <th>Location</th>
        <th>25%</th>
        <th>50%</th>
        <th>75%</th>
    </tr>
    <tr *ngFor="let location of d.locationData">
        <td>{{location.location}}</td>
        <td>{{location?.finalScore[25] | number}}</td>
        <td>{{location?.finalScore[50] | number }}</td>
        <td>{{location?.finalScore[75] | number}}</td>
    </tr>
</table>
<!-- <button (click)="getRatesFromAPI()">Caculate</button>
<div class="custom-table">
    <div class="table-head">
        <div class="table-row">
            <div class="table-cell cell-location">Location</div>
            <div class="table-cell cell-25">25%</div>
            <div class="table-cell cell-50">50%</div>
            <div class="table-cell cell-75">75%</div>
        </div>
    </div>
    <div class="table-body">
        <div class="table-row">
            <div class="table-cell cell-location">India</div>
            <div class="table-cell cell-25">10</div>
            <div class="table-cell cell-50">20</div>
            <div class="table-cell cell-75">25</div>
        </div>
        <div class="table-row">
            <div class="table-cell cell-location">India</div>
            <div class="table-cell cell-25">10</div>
            <div class="table-cell cell-50">20</div>
            <div class="table-cell cell-75">25</div>
        </div>
    </div>
</div> -->
