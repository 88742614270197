import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, switchAll } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-scenario',
  templateUrl: './create-scenario.component.html',
  styleUrls: ['./create-scenario.component.scss']
})
export class CreateScenarioComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CreateScenarioComponent>, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService) { }

  currentUrl = '';
  scenarios: any = [];
  scenarioForm = new FormGroup({
    name: new FormControl(''),
    selectionType: new FormControl('new'),
    scenario: new FormControl()
  });
  public rowData: any;

  ngOnInit(): void {
    this.apiService.getScenariosList().subscribe(async data => {
      let selectedCategory = await this.dataService.getDataFromDb('categories', {}, true);
      this.rowData = data.filter(x => x.type == selectedCategory[0].type);
      this.apiService.getScenariosList().subscribe(result => {
        this.scenarios = result;
      })
    });
  }

  changeCurrentSelection(e, d = null) {
    this.scenarioForm.get('name').clearValidators();
    this.scenarioForm.get('scenario').clearValidators();

    if (e.value == 'new')
      this.scenarioForm.get('name').setValidators(Validators.required)

    if (e.value == 'update')
      this.scenarioForm.get('scenario').setValidators(Validators.required);

  }

  onNoClick(): void {
    ;
    this.dialogRef.close();
  }

  async saveScenario() {
    let requestData = {};
    if (this.scenarioForm.value['selectionType'] == 'update') {
      requestData['id'] = this.scenarioForm.value['scenario']['id']
      requestData['name'] = this.scenarioForm.value['scenario']['name']
      requestData['createdOn'] = this.scenarioForm.value['scenario']['createdOn']
      requestData['user'] = this.scenarioForm.value['scenario']['user']
    }
    else {
      let existing = this.scenarios.map(s => { return s.name.toLowerCase(); })
      if (existing.indexOf(this.scenarioForm.value['name'].toLowerCase()) > -1) {
        Swal.fire('Error', 'Scenario with that name already exists. Please try different name!', 'error');
        return false;
      }
      requestData['name'] = this.scenarioForm.value['name']
    }
    this.apiService.saveScenario(requestData, this.data, this.scenarioForm.value['selectionType']).then(data => {
      this.dialogRef.close();
      Swal.fire('Thank you...', 'Scenario saved successfully.', 'success');
      //alert("Saved Sucessfully");
    })
    return true;
  }

  openSnackBar(message: string, action: string) {
    // this._snackBar.open(message, action, {
    //   horizontalPosition: 'end',
    //   verticalPosition: 'top',
    // });
  }

  submitButton() {
    if (this.scenarioForm.get('selectionType').value == 'new')
      return this.scenarioForm.get('name').value == '';
    else
      return this.scenarioForm.get('scenario').value == null;
  }

}
