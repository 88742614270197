
<div class="custom-tree scroll-window"> 
  <div class="header-section mb-2">
    <div class="selectAllTower">
      <div class="selectAllTowerOne">
        <mat-checkbox style="margin-left: -10px; margin-top: 2px; position: relative !important;"
                  [checked]="allTowersSelected"
                  color="primary"
                  [indeterminate]="checkTowerSelections()"
                  (change)="setAllTowerAsSelected($event.checked)">
    </mat-checkbox>
      </div>
      <div class="selectAllTowerTwo">
        <div class="title">Select Tower(s)/Sub-Tower(s)</div>
      </div>
      
    </div>
    
  </div> 
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node  *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox [disabled]="isNodeDisbled(node)" class="checklist-leaf-node" color="primary" [checked]="checklistSelection.isSelected(node)"
        (change)="updateSelection($event.checked, node)">{{node.name}}</mat-checkbox>
    </mat-tree-node>

    <mat-tree-node  *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-checkbox [disabled]="isNodeDisbled(node)" [checked]="descendantsAllSelected(node)" color="primary"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="todoItemSelectionToggle(node); setAll($event.checked, node)">{{node.name}}</mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</div>