<div class="savings-main position-relative" *ngIf="!isModal">
  <div class="flex-box-col" style="height:100%">
    <div class="switchs-wrap">
      <span class="headings px-0 py-1 pe-4">Benchmark Spend Analysis</span>
      <div class="switch switch--horizontal percentile-switch" *ngIf="targetSpend>0">
        <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="benchmarkPoint" (change)="setBenchmark()">
          <mat-button-toggle [disableRipple]="true" value="25">Quartile 1</mat-button-toggle>
          <mat-button-toggle [disableRipple]="true" value="50">Median</mat-button-toggle>
          <mat-button-toggle [disableRipple]="true" value="75">Quartile 3</mat-button-toggle>
          <span class="toggle-outside">
            <span class="toggle-inside"></span>
          </span>
        </mat-button-toggle-group>
      </div>

    </div>
    <div class="bechmark-spend-saving-box" *ngIf="targetSpend > 0">
      <div class="blur-bg">
        <div class="flex-box-row">
          <div [ngClass]="{'displayLoader': displayLoader}" class="flex-box-col bechmark-spend-saving">
            <h6>
              <img src="assets/image/becnhamark.svg" alt="Benchmark Spend">
              <span>Benchmark Spend</span>
            </h6>
            <h4>{{targetSpend|million}}</h4>
          </div>
          <div [ngClass]="{'displayLoader': displayLoader}" class="flex-box-col bechmark-spend-saving">
            <h6>
              <img src="assets/image/current-spend.svg" alt="Current Spend">
              <span>Your Spend</span>
            </h6>
            <h4>{{totalSpend|million}}{{totalSpend==0 || ratesMissing?'*':''}}</h4>
          </div>
          <div [ngClass]="{'displayLoader': displayLoader}" class="flex-box-col bechmark-spend-saving">
            <h6>
              <img src="assets/image/total-saving.svg" alt="Total Saving">
              <span>Spend Variance</span>
            </h6>
            <h4>
              <ng-container *ngIf="totalSavings > 0">
                {{totalSavings|million}}{{totalSavings==0?'**':''}}
                <mat-icon class="material-symbols-outlined" style="color: #C5AC2E">trending_up</mat-icon>&nbsp;
                <span [matTooltip]="tooltipMessage"
                  style="font-size: 12px; display: inline-block; margin-top: 5px; position: absolute;">
                  {{calculatePercentage(targetSpend, totalSpend) | number:'2.1-2'}}%</span>
              </ng-container>
              <ng-container *ngIf="totalSavings < 0">
                ({{totalSavings * -1|million}}) {{totalSavings==0?'**':''}}
                <mat-icon class="material-symbols-outlined" style="color: #C5AC2E">trending_down</mat-icon>&nbsp;
                <span [matTooltip]="tooltipMessage"
                  style="font-size: 12px; display: inline-block; margin-top: 5px; position: absolute;">
                  {{calculatePercentage(targetSpend, totalSpend) | number:'2.1-2' }}%</span>
              </ng-container>
            </h4>
          </div>
        </div>
        <button (click)="openSavingsAnalysis()"
          style="padding: 7px 15px;border-radius: 15px;background-color: #C5AC2E;border: 0px;">
          <h6
            style="text-align: center;font-size: 11px;font-family: 'Open Sans', 'Inter','Roboto';font-weight: 600;margin: 0px;color:white;">
            Explore more <i class="fa fa-arrows"></i></h6>
        </button>
      </div>
    </div>

  </div>

  <div class="savings-overlay flex-box-col" style="height:200px" *ngIf="targetSpend==0">
    <div class="blur-bg">
      <a href="javascript:void(0)" class="btn-model">&#9432; Provide your
        {{loadedPage == 'ru' ? 'volume' : 'headcount'}} and spend
        data in the panel above to view the <strong>Benchmark spend analysis</strong> 
      </a>
      <button [disabled]="true" (click)="openSavingsAnalysis()"
        style="padding: 7px 15px;border-radius: 15px;border: 0px;">
        <h6
          style="text-align: center;font-size: 11px;font-family: 'Open Sans', 'Inter','Roboto';font-weight: 600;margin: 0px;color:white;">
          Explore more <i class="fa fa-arrows"></i></h6>
      </button>
    </div>
  </div>
  <div class="notetext">
    <div class="red-text" *ngIf="errorMessage && targetSpend>0">* {{errorMessage }}</div>
    <!-- <div class="red-text" *ngIf="targetSpend>0 && totalSpend==0">* {{errorMessage }}</div>
    <div class="red-text" *ngIf="targetSpend>0 && totalSpend>0 && ratesMissing">* Spend value for all selected roles not
      provided</div>
    <div class="red-text" *ngIf="targetSpend>0 && totalSavings==0 && false">** Benchmark Spend is greater than Current
      Spend </div>
  </div> -->
  </div>
</div>

<div class="section bechmark-spend-saving-modal-box" *ngIf="isModal">
  <div class="bechmark-spend-saving-box bechmark-spend-saving-modal" *ngIf="selectionMode != 'location'">
    <div class="flex-box-row">
      <div class="flex-box-col bechmark-spend-saving">
        <img _ngcontent-qmu-c205="" src="assets/image/becnhamark.svg" alt="Benchmark Spend">
        <div>
          <h6>
            <span>Benchmark
              Spend</span>
          </h6>
          <h4>{{targetSpend|million}}</h4>
        </div>
      </div>
      <div class="flex-box-col bechmark-spend-saving">
        <img _ngcontent-qmu-c205="" src="assets/image/current-spend.svg" alt="Current Spend">
        <div>
          <h6><span>Your Spend</span>
          </h6>
          <h4>{{totalSpend|million}}{{totalSpend==0?'*':''}}</h4>
        </div>
      </div>
      <div class="flex-box-col bechmark-spend-saving">
        <img _ngcontent-qmu-c205="" src="assets/image/total-saving.svg" alt="Total Saving">
        <div>
          <h6><span>Spend Variance</span>
          </h6>
          <h4>
            <ng-container *ngIf="totalSavings > 0">
              {{totalSavings|million}}{{totalSavings==0?'**':''}}
              <mat-icon class="material-symbols-outlined" style="color: #C5AC2E">trending_up</mat-icon> &nbsp;
              <span [matTooltip]="tooltipMessage"
                style="font-size: 12px; display: inline-block; margin-top: 0px; position: absolute;">
                {{calculatePercentage(targetSpend, totalSpend) | number:'2.1-2'}}%</span>
            </ng-container>
            <ng-container *ngIf="totalSavings < 0">
              ({{totalSavings * -1|million}}) {{totalSavings==0?'**':''}}
              <mat-icon class="material-symbols-outlined" style="color: #C5AC2E">trending_down</mat-icon> &nbsp;
              <span [matTooltip]="tooltipMessage"
                style="font-size: 12px; display: inline-block; margin-top: 0px; position: absolute;">
                {{calculatePercentage(targetSpend, totalSpend) | number:'2.1-2' }}%</span>
            </ng-container>
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div style="width: 100%; height: 100px; position: absolute; top:36%; z-index: 99; left:0%;" *ngIf="displayLoader"
    [ngClass]="{'displayLoader': displayLoader}"></div>

  <div class="quartilePanel">
    <div class="quartilePanelText">
      <div class="red-text" *ngIf="errorMessage">* {{errorMessage }}</div>
      <!-- <div class="red-text" *ngIf="totalSpend==0">* Rates for all selected roles not provided<br></div>
      <div class="red-text" *ngIf="targetSpend>0 && totalSpend>0 && ratesMissing">* Spend value for all selected roles
        not provided<br></div>
      <div class="red-text" *ngIf="totalSavings==0 && false">** Benchmark Spend is greater than Current Spend</div> -->
    </div>
    <div class="switchs-wrap-box">
      <div class="switchs-wrap" *ngIf="pdfExport == false">
        <div class="switch switch--horizontal percentile-switch">
          <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="benchmarkPoint" (change)="setBenchmark()">
            <mat-button-toggle [disableRipple]="true" value="25">Quartile 1</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="50">Median</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="75">Quartile 3</mat-button-toggle>
            <span class="toggle-outside">
              <span class="toggle-inside"></span>
            </span>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="switchs-wrap">
        <div class="switch switch--horizontal percentile-switch">
          <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="byLocation" (change)="getCalculatedData()">
            <mat-button-toggle [disableRipple]="true" value="tower">Tower</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="location">Location</mat-button-toggle>
            <span class="toggle-outside">
              <span class="toggle-inside"></span>
            </span>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </div>

  <div class="savingsRoleChart" id="savingsRoleTower" *ngIf="byLocation == 'tower'"></div>
  <div class="savingsRoleChart" id="savingsRoleLocation" *ngIf="byLocation == 'location'"></div>


</div>
