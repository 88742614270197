import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
@Component({
  selector: 'app-select-subtowers',
  templateUrl: './select-subtowers.component.html',
  styleUrls: ['./select-subtowers.component.css']
})
export class SelectSubtowersComponent implements OnInit {

  constructor(private bsModal: BsModalRef) { }
  @Input() subTowers: any;
  @Output() towerSelected: EventEmitter<boolean> = new EventEmitter<boolean>()
  
  ngOnInit(): void {
    
  }

  closeModal(){
    this.bsModal.hide();
  }

  clickButton() {
    this.towerSelected.emit(false);
    this.subTowers = ''
  }

}
