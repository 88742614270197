<button class="parent-dropdown" mat-menu-item (click)="$event.stopPropagation();">
  <mat-checkbox [indeterminate]="someComplete()" color="primary" (change)="setAll($event.checked)">Show Experience
    Levels</mat-checkbox>
</button>

<section class="example-section">
  <span class="example-list-section">
    <ul style="padding-left:0px;">
      <li *ngFor="let filter of filters">
        <button mat-menu-item (click)="$event.stopPropagation();" style="padding-left: 20px;">
          <mat-checkbox color="primary" (click)="$event.stopPropagation();"
            [checked]="selectedFilters.indexOf(filter) > -1" (change)="updateAllComplete($event)" [value]="filter">
            {{filter}}
          </mat-checkbox>
        </button>
      </li>
    </ul>
  </span>
</section>