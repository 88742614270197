import { Component, OnDestroy, OnInit } from '@angular/core';
import { liveQuery } from 'dexie';
import { Subscription } from 'rxjs';
import { db } from 'src/db';
import { DataService } from '../data.service';

@Component({
  selector: 'app-opportunity-assessment',
  templateUrl: './opportunity-assessment.component.html',
  styleUrls: ['./opportunity-assessment.component.scss']
})
export class OpportunityAssessmentComponent implements OnInit, OnDestroy {

  constructor(private dataService:DataService) { }
  selectedTowers = [];
  selectedLocations = [];
  panelOpenState = false;
  liveQuerySubscription = new Subscription();

  ngOnInit(): void {
    this.liveQuerySubscription.add(liveQuery(() => this.getSelectedLocations()).subscribe((data:any) => {
      this.selectedTowers = data.towers;
      this.selectedLocations = data.locations;
    }))
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  async getSelectedLocations(){
    let locations =  await this.dataService.getDataFromDb('locations', {}, true);
    let towers = await db.selection_towers.toArray();
    return {locations, towers};
    
  }

}
