<!-- <div class="section">
  <div class="switchs-wrap">
    <div class="switch switch--horizontal percentile-switch">
      <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="benchmarkPoint" (change)="refreshCalculations()">
        <mat-button-toggle [disableRipple]="true" value="25">25%ile</mat-button-toggle>
        <mat-button-toggle [disableRipple]="true" value="50">Median</mat-button-toggle>
        <mat-button-toggle [disableRipple]="true" value="75">75%ile</mat-button-toggle>
        <span class="toggle-outside">
          <span class="toggle-inside"></span>
        </span>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="chart-wrap">
    <div class="row m0">
      <div class="col-6 plr-7" *ngIf="type!='total'">
        <div class="row">
          <div class="col-6"><div id="annualSpendRole" class="chart-inner-wrap"></div></div>
          <div class="col-6"><div id="legends"></div></div>
        </div>
        <div class="headings">Tower</div>
      </div>
      <div class="col-6 plr-7">
        <span>Total Savings: </span>
      <strong>{{totalSavings|million}}</strong>
      <div class="col-12"><div id="legends"></div></div>
      </div>
    </div>
  </div>
</div> -->
<div class="section">
<table width="100%">
  <tr>
    <td width="40%" style="text-align: center;">
      <div id="annualSpendRole" class="chart-inner-wrap"></div>
    </td>
    <td>
      <div class="switchs-wrap">
        <div class="switch switch--horizontal percentile-switch">
          <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="benchmarkPoint" (change)="getCalculatedData()">
            <mat-button-toggle [disableRipple]="true" value="25">25%ile</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="50">Median</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="75">75%ile</mat-button-toggle>
            <span class="toggle-outside">
              <span class="toggle-inside"></span>
            </span>
          </mat-button-toggle-group>
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td width="80%" style="text-align: center;">
      <div id="annualSpendRole" class="chart-inner-wrap"></div>
      <table class="table1">
        <tr class="tr1">
          <th class="th1">Name</th>
          <th>Value</th>
        </tr>
        <tr class="tr1" *ngFor="let row of pieData">
          <td class="td1">{{row.name}}</td>
          <td class="td1">{{row.value | million}}</td>
        </tr>
      </table>
    </td>
    <td>
      <span>Total Savings: </span>
      <strong>{{totalSavings|million}}</strong>
      <div class="col-12"><div id="legends"></div></div>
    </td>
  </tr>
</table>