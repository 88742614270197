import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { LoaderService } from './loader.service';
import Swal from 'sweetalert2';

@Injectable()
export class NetworkErrorInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.loaderService.closeDialog();
        Swal.fire({
          icon:'error',
          text: 'Please try again or reload the page as there appears to be a problem with the current request. Kindly get in touch with help if the issue continues to arise'
        })
        console.error('error occurred:', error);
        return throwError(error);
      })
    );
  }
}
