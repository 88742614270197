import { ChangeDetectionStrategy } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-plot-container',
  templateUrl: './box-plot-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./box-plot-container.component.scss']
})
export class BoxPlotContainerComponent implements OnInit {

  constructor() { }
  @Input() locat: any;
  @Input() width: any;
  @Input() role: any;
  @Input() spt: any;
  @Input() minMaxRoles = {};
  min;
  max;
  ngOnInit(): void {
    try {
      this.min = this.minMaxRoles[this.locat['id']][this.spt['id']]['min'];
      this.max = this.minMaxRoles[this.locat['id']][this.spt['id']]['max'];
    } catch (e) {

    }
  }

  getClassForUserValue(role, location, serviceProviderType) {
    let className = '';
    var value = role['rateSPT'][location['id']][serviceProviderType['id']];
    if ('inputPlots' in role) {
      if (role.inputPlots[location.id] != undefined && role.inputPlots[location.id][serviceProviderType.id] != undefined) {
        let userInputValue = role.inputPlots[location.id][serviceProviderType.id];
        if (userInputValue >= 0 && userInputValue < (((value[50]) * 95) / 100)) {
          className = 'bg-green';
        }
        if (userInputValue >= (((value[50]) * 95) / 100) && userInputValue <= (((value[50]) * 105) / 100)) {
          className = 'bg-amber';
        }
        if (userInputValue > (((value[50]) * 105) / 100)) {
          className = 'bg-red';
        }
      }
    }
    return className;
  }

  getColorForInput(role, location, serviceProviderType) {
    var value = role['rateSPT'][location['id']][serviceProviderType['id']];
    let className = 'primary';
    if ('inputPlots' in role) {
      if (role.inputPlots[location.id] != undefined && role.inputPlots[location.id][serviceProviderType.id] != undefined) {
        let userInputValue = role.inputPlots[location.id][serviceProviderType.id];

        if (userInputValue >= (((value[50]) * 95) / 100) && userInputValue <= (((value[50]) * 105) / 100)) {
          className = 'accent';
        }
        if (userInputValue > (((value[50]) * 105) / 100)) {
          className = 'warn';
        }
      }
    }
    return className;
  }

  checkIfDataAvailable(): any {
    if (this.role != undefined
      && this.locat != undefined
      && this.role['rateSPT']
      && this.locat['id']
      && this.role['rateSPT'][this.locat['id']] != undefined
      && this.role['rateSPT'][this.locat['id']][this.spt['id']])
      return true;
    return false;
  }

  formatNumber(value) {
    if (value.toString().indexOf('.') > -1)
      return value.toFixed(0);
    else
      return value;
  }

  calculateScatterPositionOld(): any {
    let input = this.role.inputPlots[this.locat.id][this.spt.id]
    let unitSize = (this.width / (this.max - this.min));

    if (input > this.max)
      input = this.max;
    else if (input < this.min)
      input = this.min;

    let val = (this.max - input) * unitSize;
    if (val >= this.width) val -= 5;
    return val;
  }
  calculateScatterPosition(percentile25, percentile75): any {
    let input = this.role.inputPlots[this.locat.id][this.spt.id]
    let unitSize = (this.width / (percentile75 - percentile25));
    if (input > percentile75)
      input = percentile75;
    else if (input < percentile25)
      input = percentile25;
    let val = (percentile75 - input) * unitSize;
    if (val >= this.width) {
      val -= 20;
    }
    return val;
  }

  getRate(benchmarkPoint) {
    return this.role['rateSPT'][this.locat['id']][this.spt['id']][benchmarkPoint];
  }

}
