<div class="pyr-main">
    <div class="custom-table" *ngIf="!modal">
        <div class="table-head">
            <div class="table-row">
                <div class="table-cell cell-location">Location</div>
                <div class="table-cell cell-25">Quartile 1</div>
                <div class="table-cell cell-50">Median</div>
                <div class="table-cell cell-75">Quartile 3</div>
                <div class="table-cell cell-your-rate">Your Rate ($)</div>
            </div>
        </div>
        <div class="table-body  scroll-window">
            <div class="table-row" *ngFor="let d of data">
                <div class="table-cell cell-location">{{d.locationName}}
                    <!-- <span class="offshore-flag" *ngIf="d.offshore"><img src="../../../assets/image/icon_flag-dark.svg"></span> -->
                </div>
                <div class="table-cell cell-25">{{d.score[25] | number: '1.2-2'}}</div>
                <div class="table-cell cell-50">{{d.score[50] | number: '1.2-2'}}</div>
                <div class="table-cell cell-75">{{d.score[75] | number: '1.2-2'}}</div>
                <div class="table-cell cell-your-rate"
                    [ngClass]="d.yourRate=='-na-' ? '' : (d.yourRate > d.score[50] ? 'red':'green')">
                    {{d.yourRate}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <span style="font-size: 10.5px;position: absolute;z-index: 99;" class="pl-3 d-block pt-2 ms-3">
                <ng-container *ngIf="!isDefaultValuesChanged; else rateChanged"><span class="headings">Span of Control :
                    </span> Change the input SOC to find the
                    adjusted benchmark rates.</ng-container>
                <ng-template #rateChanged><span class="headings">Span of Control:</span> The table above shows the
                    adjusted benchmark rates based on input
                    SOC</ng-template>
            </span>
        </div>
        <div class="col-4">
            <a href="javascript:void(0)" class="btn-reset mt-2" (click)="reset()"
                style="top:unset !important;z-index: 999;">
                <i class="icons-image icon-reset-small" aria-hidden="true"></i>
                <span matTooltip="Reset Input SOC">Reset</span>
            </a>
        </div>
    </div>
    <div class="pyr-wrap mt-2">

        <div *ngIf="identifier != null" class="flex-box-row" style="margin:0 5px;">
            <div [id]="'chart1_'+identifier" style="width:50%"></div>
            <div [id]="'chart2_'+identifier" style="width:50%;margin-left: -20px;"></div>
        </div>
        <div>
            <div class="custom-slider">
                <div class="slider-wrap">
                    <span>L5</span>
                    <ngx-slider class="ngx-slider_level1" [(value)]="slider.level5.value"
                        (userChangeEnd)="adjustPyramid($event, 'level5')"
                        [options]="slider.level5.options"></ngx-slider>
                </div>
                <div class="slider-wrap">
                    <span>L4</span>
                    <ngx-slider class="ngx-slider_level2" [(value)]="slider.level4.value"
                        (userChangeEnd)="adjustPyramid($event, 'level4')"
                        [options]="slider.level4.options"></ngx-slider>
                </div>
                <div class="slider-wrap">
                    <span>L3</span>
                    <ngx-slider class="ngx-slider_level3" [(value)]="slider.level3.value"
                        (userChangeEnd)="adjustPyramid($event, 'level3')"
                        [options]="slider.level3.options"></ngx-slider>
                </div>
                <div class="slider-wrap">
                    <span>L2</span>
                    <ngx-slider class="ngx-slider_level4" [(value)]="slider.level2.value"
                        (userChangeEnd)="adjustPyramid($event, 'level2')"
                        [options]="slider.level2.options"></ngx-slider>
                </div>
                <div class="slider-wrap slider-disable">
                    <span>L1</span>
                    <ngx-slider [(value)]="slider.level1.value" [options]="{floor:0, ceil: 100, readOnly: true}">
                    </ngx-slider>
                </div>
            </div>
            <div class="title" style="margin-top:15px;  ">*Change Input SOC
                <i class="icons-image icon-info-light-gray"
                    matTooltip="Change the levels of the Input SOC to simulate the breakup of the different levels of hierarchy in your Delivery Team."></i>
            </div>

        </div>
    </div>
</div>