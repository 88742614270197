import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-svg',
  templateUrl: './box-svg.component.svg',
  styleUrls: ['./box-svg.component.scss']
})
export class BoxSvgComponent implements OnInit {

  constructor() { }
  width = 100;
  height = 30;
  orientation: 'horizontal';
  min: any;
  max: any;
  stats: any;
  className: any;  
  style: { strokeOpacity: 1, fillOpacity: 0.75 };
  // tickStyle: { stroke: 'black', strokeDasharray: '2,2' };
  tickStyle: { stroke: 'black' };
  whiskerStrokeWidth: 1;
  // whiskerStyle: { stroke: 'black', strokeDasharray: '2,2' };
  whiskerStyle: { stroke: 'black' };
  boxStyle: { stroke: 'black', fill: 'black' };
  medianStrokeWidth: 2;
  medianStyle: { stroke: 'white' };
  outlierRadius: 2.5;
  outlierStyle: { stroke: 'black', fill: 'black' };
  ngOnInit(): void {
  }

}
