import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new Subject<any>();
  private disableStep = new Subject<Boolean>();

  sendMessage(message: any, params:any = null) {
    this.subject.next({ data: message, params: params });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  setStepAsDisabled(status = false){
    this.disableStep.next(status);
  }

  isStepDisabled(): Observable<Boolean>{
    return this.disableStep.asObservable();
  }

}

