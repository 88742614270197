import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit {

  constructor(private apiService: ApiService,private loaderService: LoaderService) { }

  ngOnInit(): void {
  }

  async submit(){
    (await this.apiService.getAllRatesAsPerSelections()).subscribe(data => {
     this.loaderService.closeDialog();
    })
  }

}
