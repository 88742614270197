
class LevelWiseData {
    level1: number = 0;
    level2: number = 0;
    level3: number = 0;
    level4: number = 0;
    level5: number = 0;

    constructor(obj = undefined) {
      this.level1 = obj?.level1 ?? 0;
      this.level2 = obj?.level2 ?? 0;
      this.level3 = obj?.level3 ?? 0;
      this.level4 = obj?.level4 ?? 0;
      this.level5 = obj?.level5 ?? 0;
    }
  
    get total() { return this.level1 + this.level2 + this.level3 + this.level4 + this.level5 }
  
  }

  export default LevelWiseData;