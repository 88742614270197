<div class="section">
    <div class="oa-head rounded-top">
        <!-- <div class="oa-text"># Headcounts</div> -->
        <div class="oa-selections">
            <div class="custom-toggle-btn">
                <!-- <span style="color:#fff; font: italic normal normal 11px/18px Raleway;">Location</span> -->
                <div class="custom-select-filter" *ngIf="loadedPageType!='ru'">
                    <img src="/assets/image/information-button.svg" class="me-1" matTooltip="Select location type">
                    <label>Location </label>
                    <mat-form-field class="tour-locationselect">
                        <mat-select (selectionChange)="locationChange($event)" [(value)]="allLocation">
                            <mat-option value="all">All</mat-option>
                            <mat-option value="split">Split</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="custom-select-filter">
                    <img matTooltip="Select spend type" src="/assets/image/information-button.svg" class="me-1">
                    <label>Spend Type</label>
                    <mat-form-field class="tour-spendtype">
                        <mat-select (selectionChange)="spendChange($event)" [(value)]="selectedSpend">
                            <mat-option *ngFor="let spend of spendDropdown" [value]="spend.value">
                                {{spend.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="custom-select mw-150">
                    <img src="/assets/image/information-button.svg" class="me-1" matTooltip="Select provider type">
                    <label>Provider</label>
                    <mat-form-field *ngIf="spts$ | async as spts" class="tour-roleprovider">
                        <mat-select (selectionChange)="changeSptFilter($event)" [(value)]="selectedSpt">
                            <mat-option *ngFor="let spt of spts" [value]="spt.id">
                                {{spt.spt}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <!-- <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="locationChange($event)"
                    [(ngModel)]="allLocation">
                    <mat-button-toggle value="all">All locations</mat-button-toggle>
                    <mat-button-toggle value="split">Split locations</mat-button-toggle>
                </mat-button-toggle-group> -->
                <a href="javascript:void(0)" title="Clear Headcounts" class="clear-btn" style="top: -24px; right: 4px;"
                    (click)="clearData()">
                    <i class="icons-image icon-reset"></i>
                </a>
            </div>
        </div>
    </div>
    <ag-grid-angular class="ag-theme-alpine roles-table" [ngClass]="selectedSpend == 'total' ? 'totalSpendActive' :''"
        [headerHeight]="headerHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
        [animateRows]="true" (gridReady)="onGridReady($event)" [domLayout]="domLayout"
        [rowGroupPanelShow]="rowGroupPanelShow" [gridOptions]="gridOptions"
        [isExternalFilterPresent]="isExternalFilterPresent" [doesExternalFilterPass]="doesExternalFilterPass"
        (firstDataRendered)="onFirstDataRendered($event)" (cellValueChanged)="onCellValueChange($event)"
        [overlayNoRowsTemplate]="noRowsTemplate">
    </ag-grid-angular>
</div>