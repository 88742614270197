
<div class="benchmark-price">
  <div class="bm-price-header scroll-window scroll-sections" #scrollOne>
    <div class="location-wrap">Role</div>
    <div class="plot-wrap">
      <div class="d-flex">
        <div class="plots" *ngFor="let locat of requestData.locations">
          <div class="location">{{ locat.locationName }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="role-wrap-outer scroll-window">
  <div class="role-wrap scroll-window scroll-sections" #scrollTwo>    
  <div class="role" *ngFor="let role of selectedRolesbenchmarkPrices">
    <div class="location-wrap role-name">
      <!-- <i class="icons-image icon-placeholder" aria-hidden="true"></i> -->
      <mat-checkbox [color]="'primary'" [checked]="role.isSelected"
                  (change)="onRowSelected($event.checked, role)">
                  {{ role.role }} 
      </mat-checkbox>      
      <a href="javascript:void(0)" class="btn-actions">
        <i class="icons-image icon-info" aria-hidden="true"></i>
      </a>
      <a [hidden]="role.isBarChart" href="javascript:void(0)" (click)="toggleBarChart(role)" class="btn-actions">
        <i class="icons-image icon-edit" aria-hidden="true"></i>
      </a>
      <a [hidden]="!role.isBarChart" href="javascript:void(0)" (click)="toggleBarChart(role)" class="btn-actions">
        <i class="icons-image icon-check-green" aria-hidden="true"></i>
      </a>
    </div>
    <div [hidden]="role.isBarChart" class="form-field-wrap">
      <div class="form-field-cell" *ngFor="let locat of requestData.locations">
        <input class="form-control" type="text" (keyup)="drawScatter($event, role.roleId, locat['id'])">
      </div>
    </div>
    <div class="plot-wrap" [hidden]="!role.isBarChart">
      <div class="d-flex">
        <div class="plots" *ngFor="let locat of requestData.locations">
          <div class="price-bar"
            [ngStyle]="{
              'width.px': (locat.max - locat.min) * (100/(locat.max - locat.min))}">
            <div class="bar">
              <div class="first" 
                [ngStyle]="{
                  'left.px': (role['finalRates'][locat['id']]['benchmark']['25'] - locat.min) * (100/(locat.max - locat.min)), 
                  'width.px': (role['finalRates'][locat['id']]['benchmark']['50']! - role['finalRates'][locat['id']]['benchmark']['25']!) * (100/(locat.max - locat.min))}">
              </div>
              <div 
                class="second" 
                [ngStyle]="{
                  'left.px': (role['finalRates'][locat['id']]['benchmark']['50'] - locat.min) * (100/(locat.max - locat.min)), 
                  'width.px': (role['finalRates'][locat['id']]['benchmark']['75']! - role['finalRates'][locat['id']]['benchmark']['50']!) * (100/(locat.max - locat.min))}">
              </div>
              <span 
                class="numbers" 
                [ngStyle]="{
                  'left.px': ((role['finalRates'][locat['id']]['benchmark']['25'] - locat.min) * (100/(locat.max - locat.min))) - 5}">
                  {{role['finalRates'][locat['id']]['benchmark']['25']}}
              </span>
              <span 
                class="numbers" 
                [ngStyle]="{
                  'left.px': ((role['finalRates'][locat['id']]['benchmark']['50'] - locat.min) * (100/(locat.max - locat.min))) - 5}">
                  {{role['finalRates'][locat['id']]['benchmark']['50']}}
              </span>
              <span 
                class="numbers" 
                [ngStyle]="{
                  'right.px': ((locat.max - role['finalRates'][locat['id']]['benchmark']['75']) * (100/(locat.max - locat.min))) - 5}">
                  {{role['finalRates'][locat['id']]['benchmark']['75']}}
              </span>
              <div *ngFor="let price of cliPrice">
                <span 
                  class="numbers cp" 
                  *ngIf="price['roleId'] == role.roleId && price['location'] == role.data[locat['id']]?.location && price['cp'] <= locat.max && price['cp'] >= locat.min" 
                  [ngStyle]="{
                    'right.px': ((locat.max - price['cp']) * (100/(locat.max - locat.min))) - 4
                  }"
                  [ngClass]="{
                    'bg-green': price['cp'] >= 0 && price['cp'] < (((role['finalRates'][locat['id']]['benchmark']['50'])*95)/100),
                    'bg-amber': price['cp'] >= (((role['finalRates'][locat['id']]['benchmark']['50'])*95)/100) && price['cp'] <= (((role['finalRates'][locat['id']]['benchmark']['50'])*105)/100),
                    'bg-red': price['cp'] > (((role['finalRates'][locat['id']]['benchmark']['50'])*105)/100)
                  }">
                </span>
                <span 
                  class="numbers cp" 
                  *ngIf="price['roleId'] == role.roleId && price['location'] == role.data[locat['id']]?.location && price['cp'] < locat.min" 
                  [ngClass]="{
                    'leftmost': price['cp'] <= locat.min
                  }">
                </span>
                <span 
                  class="numbers cp" 
                  *ngIf="price['roleId'] == role.roleId && price['location'] == role.data[locat['id']]?.location && price['cp'] > locat.max" 
                  [ngClass]="{
                    'rightmost': price['cp'] >= locat.max
                  }">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
  <div class="scale-bar-wrap scroll-window scroll-sections" *ngIf="locationRateRange" (scroll)="syncScroll()" #scrollThree>
    <div class="location-wrap"></div>
    <div class="plot-wrap">
      <div class="d-flex">
        <div class="plots" *ngFor="let locat of requestData.locations">
            <div 
              class="scale-bar" 
              [ngStyle]="{
                'width.px': (locat?.max - locat?.min) * (100/(locat?.max - locat?.min))}">
                  <span 
                    class="numbers" 
                    [ngStyle]="{'left.px': -5}">{{locat?.min}}
                  </span>
                  <span 
                    class="numbers" 
                    [ngStyle]="{'right.px': -5}">{{locat?.max}}
                  </span>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>