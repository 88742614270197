import { Component, Input, OnInit } from '@angular/core';
import { liveQuery } from 'dexie';
import { db } from 'src/db';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
declare let d3: any;
@Component({
  selector: 'app-target-spend-tower',
  templateUrl: './target-spend-tower.component.html',
  styleUrls: ['./target-spend-tower.component.scss']
})
export class TargetSpendTowerComponent implements OnInit {

  @Input() spendBy: string;
  benchmarkPoint: string = '50';
  onshore = 0;
  offshore = 0;
  totalSpend = false;
  targetSpend = 0;
  spt: number;
  byLocation = 'tower';
  skillFactor: number = 1

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this._getTowersList();
  }

  drawSunburst(nodeData, selector) {
    d3.select(`#${selector}`).empty();
    var Tooltip = d3.select("#div_customContent")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")
    var width = 150;
    var height = 150;
    var radius = Math.min(width, height) / 2;

    nodeData.children = nodeData.children.filter(E => {
      E.children = E.children.filter(e => e.size != 0);
      return E.children.length > 0;
    });

    var color = d3.scaleOrdinal().domain(nodeData).range(environment.globalColorScheme)

    var g = d3.select(`#${selector}`)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    var partition = d3.partition()
      .size([2 * Math.PI, radius]);

    var root = d3.hierarchy(nodeData)
      .sum(function (d) { return d.size });

    partition(root);
    var arc = d3.arc()
      .startAngle(function (d) { return d.x0 })
      .endAngle(function (d) { return d.x1 })
      .innerRadius(function (d) { return d.y0 })
      .outerRadius(function (d) { return d.y1 });

    g.selectAll('g')
      .data(root.descendants())
      .enter().append('g').attr("class", "node").append('path')
      .attr("display", function (d) { return d.depth ? null : "none"; })
      .attr("d", arc)
      .style('stroke', '#fff')
      .style("fill", function (d) { return color(d.data.name); })
      .on('mouseover', function (d) {
        Tooltip
          .html("The exact value of<br>this cell is: " + d.size)
          .style("left", (d3.mouse(this)[0] + 70) + "px")
          .style("top", (d3.mouse(this)[1]) + "px")
      })

    g.selectAll(".node")
      .append("text")
      .attr("transform", function (d) {
        return "translate(" + arc.centroid(d) + ")rotate(" + computeTextRotation(d) + ")";
      })
      .style('font-size', 9)
      .style('font-weight', 'bold')
      .attr('font-family', 'Open Sans', 'Inter','Roboto')
      .attr("dx", "-20")
      .attr('fill', '#fff')
      .attr("dy", ".5em")
      .text(function (d) { return d.parent ? d.data.name : "" })
      .on('mouseover', function (d) {
        Tooltip
          .html("The exact value of<br>this cell is: " + d.size)
          .style("left", (d3.mouse(this)[0] + 70) + "px")
          .style("top", (d3.mouse(this)[1]) + "px")
      })

    function computeTextRotation(d) {
      var angle = (d.x0 + d.x1) / Math.PI * 90;
      return (angle < 120 || angle > 270) ? angle : angle + 180;
    }

  }

  _getTowersList() {
    liveQuery(() => this.getTowersFromDB()).subscribe((towers) => {
      this.drawChart();
    })
  }

  async drawChart() {
    let result = await this.getSelectedTowers();
    this.drawSunburst(result, 'towerSpend');
  }

  async getTowersFromDB() {
    let allTowers = await this.dataService.getTowersInputs();

    this.skillFactor = await this.dataService.getSkillFactor();

    await this.getRoleFiltersFromDb();

    return allTowers.filter(tower => tower.isSelected == true);
  }

  async getSelectedTowers() {
    let locations =  await this.dataService.getDataFromDb('locations', {}, true);
    let inputs = await db.inputs.toArray();
    let towers = await (await db.selection_towers.toArray()).filter(x => x.isSelected == true);
    let towersIds = towers.map(tower => { return tower['id'] });
    let rates = await db.towerRates.where('towerId').anyOf(towersIds).toArray();
    if (this.byLocation == 'tower') {
      let result = await this.calculateTargetSpendByTower(locations, towers, rates, inputs, this.benchmarkPoint);
      return result;
    }
    else {
      let result = await this.calculateTargetSpendByLocation(locations, towers, rates, inputs, this.benchmarkPoint);
      return result;
    }
  }

  async getRoleFiltersFromDb() {
    let filters = await db.role_filter.toArray();
    filters.forEach(f => {
      if (f.type == "spt")
        this.spt = f.value;
    })
    return filters;
  }

  async calculateTargetSpendByTower(locations: any[], towers: any[], rates: any[], inputs: any[], benchmarkPoint: string) {
    let returnData = {
      name: "Towers",
      children: []
    };

    let targetSpend = 0;
    await Promise.all(towers.map(async (tower) => {
      let graphObj = {
        name: tower.tower,
        children: []
      };
      let soc = await this.getBenchmarkSOCs(tower.id);
      locations.forEach(location => {
        let value = 0;
        let input = inputs.filter(x => x.tower == tower.id && x.location == location.id)[0];
        let towerRates = rates.filter(x => x.towerId == tower.id && x.serviceProviderType == this.spt)[0]['locationData'];
        let locationRate = towerRates.filter(x => x.locationId == location.id)[0].rates;
        let rate = this.getRateBySOC(soc, locationRate, benchmarkPoint);
        if (input != undefined)
          value = value + (input.headcount * rate * 1920);

        targetSpend = targetSpend + value;
        graphObj.children.push({
          name: location.locationName,
          size: value
        })
      })
      returnData.children.push(graphObj)
    }));
    this.targetSpend = targetSpend;
    return returnData;
  }

  async calculateTargetSpendByLocation(locations: any[], towers: any[], rates: any[], inputs: any[], benchmarkPoint: string) {
    let returnData = {
      name: "Locations",
      children: []
    };
    let targetSpend = 0;
    await Promise.all(locations.map(async (location) => {
      let graphObj = {
        name: location.locationName,
        children: []
      };
      await Promise.all(towers.map(async (tower) => {
        let value = 0;
        let soc = await this.getBenchmarkSOCs(tower.id);
        let input = inputs.filter(x => x.tower == tower.id && x.location == location.id)[0];
        let towerRates = rates.filter(x => x.towerId == tower.id && x.serviceProviderType == this.spt)[0]['locationData'];
        let locationRate = towerRates.filter(x => x.locationId == location.id)[0].rates;
        let rate = this.getRateBySOC(soc, locationRate, benchmarkPoint);
        if (input != undefined)
          value = value + (input.headcount * rate * 1920);
        targetSpend = targetSpend + value;
        graphObj.children.push({
          name: tower.tower,
          size: value
        })
      }))
      returnData.children.push(graphObj)
    }));
    this.targetSpend = targetSpend;
    return returnData;
  }

  async getBenchmarkSOCs(towerId: number) {
    let soc = await db.socs.where('towerid').equals(towerId).first()
    return soc['soc'];
  }

  getRateBySOC(soc: any, locationRate: any, benchmarkPoint: string) {
    let level1 = (soc.level1 * locationRate.level1[benchmarkPoint]) / 100;
    let level2 = (soc.level2 * locationRate.level2[benchmarkPoint]) / 100;
    let level3 = (soc.level3 * locationRate.level3[benchmarkPoint]) / 100;
    let level4 = (soc.level4 * locationRate.level4[benchmarkPoint]) / 100;
    let level5 = (soc.level5 * locationRate.level5[benchmarkPoint]) / 100;

    let rate = (level1 + level2 + level3 + level4 + level5) * this.skillFactor;
    return rate;
  }

}
