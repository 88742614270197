import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  constructor(private auth: AuthService) { }
  isDemoEnvironment = environment.isDemoEnvironment;
  show: boolean = false;

  ngOnInit(): void {
    setTimeout(() => this.show = true, 2500);
  }

  goBack() {
    this.auth.logout({ returnTo: window.location.origin });
  }

}
