<div class="">
    <div class="headings">Tower Based Benchmark Spend Analysis</div>
    <div class="custom-table">
        <div class="custom-table-head">
            <div class="custom-table-row">
                <div class="custom-table-cell os-location"></div>
                <div class="custom-table-cell os-input">L1</div>
                <div class="custom-table-cell os-input">L2</div>
                <div class="custom-table-cell os-input">L3</div>
                <div class="custom-table-cell os-input">L4</div>
                <div class="custom-table-cell os-input">L5</div>
                <div class="custom-table-cell os-input">Total FTE's</div>
                <div class="custom-table-cell os-input">Current Spend($)</div>
            </div>
        </div>
        <div class="custom-table-body">
            <div class="custom-table-row" *ngFor="let location of selectedLocations">
                <div class="custom-table-cell os-location">{{location.locationName}}</div>
                <div class="custom-table-cell os-input"><input type="number" /></div>
                <div class="custom-table-cell os-input"><input type="number" /></div>
                <div class="custom-table-cell os-input"><input type="number" /></div>
                <div class="custom-table-cell os-input"><input type="number" /></div>
                <div class="custom-table-cell os-input"><input type="number" /></div>
                <div class="custom-table-cell os-input"><input type="number" /></div>
                <div class="custom-table-cell os-input"><input type="number" /></div>
            </div>
        </div>
    </div>
    <!-- <table class="table">
        <thead>
            <tr>
                <th>Location</th>
                <th>L1</th>
                <th>L2</th>
                <th>L3</th>
                <th>L4</th>
                <th>Total FTE's</th>
                <th>Total Spend($)</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let location of selectedLocations">
                <td>{{location.locationName}}</td>
                <td><input type="number" /></td>
                <td><input type="number" /></td>
                <td><input type="number" /></td>
                <td><input type="number" /></td>
                <td><input type="number" /></td>
                <td><input type="number" /></td>
            </tr>
        </tbody>
    </table> -->
</div>