<div class="row hex-role-table">

  <div class="col-12 pe-0 ps-0">

    <div class="bar-section editbutton-col">
      <div class="headings hex-role-table">{{typeNameSelector}} <i *ngIf="!hideInfoIcon"
          class="icons-image icon-info-light-gray" matTooltip="{{getHeadingTooltipText()}}"
          matTooltipClass="only-break-word-tooltip"></i></div>
      <div class="m">
        <div class="m-content">
          <span class="price-hex" *ngIf="loadedBenchmarkType=='ru'">Quartile Rates ($/month)</span>
          <span class="price-hex" *ngIf="loadedBenchmarkType!='ru'">Quartile Rates ($/hr)</span>
          <div class="scatter-hex">
            <img src="/assets/image/scatter_plot_icon.svg">
            <!-- Scatterplot
        <span class="circle red"></span>
        <span class="circle green"></span>
        <span class="circle yellow"></span> -->
            <div class="custom-popover-legend">
              <img src="../../assets/img/IQR-ICON-V2.svg" alt="" class="src">
              <ul style="list-style-type: none;">
                <li><span class="circle red"></span> 5% or higher than MEDIAN</li>
                <li><span class="circle green"></span> 5% or lower than MEDIAN</li>
                <li><span class="circle yellow"></span>
                  Within 5% range of MEDIAN</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="r">
        <div class="r-content">
          <ng-container *ngIf="!noRows">
            <button [disabled]="rows.length == 0" style="margin:2px 0 0 5px !important"
              *ngIf="loadedBenchmarkType == 'ru'" (click)="openTowerParamsDialog()"
              matTooltip="Update Tower Parameters">
              <img src="/assets/img/tower-parameters.svg" class="tour-variance">
              <!-- <i class="icons-image icon-more"></i> -->
            </button>
            <button [disabled]="rows.length == 0" (click)="isglobalEdit = !isglobalEdit;">
              <i *ngIf="!isglobalEdit" (click)="refreshBoxPlot(isglobalEdit)" class="icons-image icon-table-edit"
                matTooltip="Input your rates"></i>
              <i *ngIf="isglobalEdit" (click)="refreshBoxPlot(isglobalEdit)" class="icons-image icon-box-plot"
                matTooltip="Show benchmark rates"></i>
            </button>
            <button [disabled]="rows.length == 0" (click)="openVarianceDialog()" matTooltip="Show Variance spread">
              <img src="/assets/image/variance_spread.svg" class="tour-variance">
              <!-- <i class="icons-image icon-more"></i> -->
            </button>

            <button style="position: relative; bottom: -3px;" [disabled]="rows.length == 0"
              href="javascript:void(0)" class="clear-btn" (click)="clearData()" matTooltip="Clear input rates">
              <i class="icons-image icon-reset"></i>
            </button>
            <!-- <a href="javascript:void(0)" class="clear-btn" (click)="clearData()" matTooltip="Clear input rates">
            <i class="icons-image icon-reset"></i>
          </a> -->
          </ng-container>
        </div>
      </div>
    </div>


  </div>
</div>

<div class="row dark m-0 py-1 rounded-top">
  <div class="col-12">
    <div class="roleSearch">
      <div class="row btn-more-wrap justify-content-center lh-1" *ngIf="loadedComponentType == 'role'">
        <!-- <div class="col-2 pe-1"><input matTooltip="Select/ Deselect all Roles" style="vertical-align: middle;"
              type="checkbox" (change)="markAllRolesAsSelected($event)" [value]="allRolesSelected" /></div> -->
        <div class="col-9 d-flex px-1">
          <img src="assets/image/search.svg" alt="Search" />
          <input type="text" [(ngModel)]="searchText" placeholder="Search here"
            style="border-bottom: 1px solid #fff !important; width:100%; background: transparent; color: #fff; border:0; padding:2px;" />

        </div>
        <div class="col-2 px-1">
          <a href="javascript:void(0)" class="btn-more" mat-button [matMenuTriggerFor]="rolesDropdown">
            <i class="icons-image icon-role-grid-dropdown" aria-hidden="true"></i>
          </a>
          <mat-menu #rolesDropdown="matMenu" class="roles-dropdown">
            <button mat-menu-item (click)="$event.stopPropagation();">
              <mat-checkbox color="primary" (click)="$event.stopPropagation();" [(ngModel)]="showSelectedOnly"
                (change)="showOnlySelected($event)">
                <span *ngIf="loadedBenchmarkType == 'ru'">Show Selected RUs</span>
                <span *ngIf="loadedBenchmarkType == 'fte'">Show Selected Roles</span>
              </mat-checkbox>
              <!-- <input [(ngModel)]="showSelectedOnly" type="checkbox" (change)="showOnlySelected($event)">Show Selected Roles -->
            </button>

            <app-discrete-filters *ngIf="loadedBenchmarkType == 'fte'" (filtersOut)="filterRolesByExperience($event)"
              [filters]="experienceFilter"></app-discrete-filters>

            <!-- <button mat-menu-item (click)="$event.stopPropagation();"
              *ngIf="typeNameSelector != 'Services' && !hideRemoveTransform">
              <mat-form-field class="remove-transform">
                <mat-select #select (selectionChange)="filterRolesByExperience()" placeholder="Experience Level"
                  [(value)]="selectedExperiences" multiple class="exp-select">
                  <div class="select-all" style="margin-left: 15px;">
                    <mat-checkbox class="deselect-checkbox " [(ngModel)]="allSelected"
                      [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()">
                      <!- <span *ngIf="allSelected">Deselect All</span> ->
                      <span>Select All</span>
                    </mat-checkbox>
                  </div>
                  <!- <mat-option value="selectAll">Select/ Deselect all</mat-option> ->
                  <mat-option class="removed-bg-color mat-select" *ngFor=" let experience of experienceFilter"
                    [value]="experience">{{experience}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </button> -->
          </mat-menu>
        </div>
      </div>
      <div class="custom-select-filter float-right" style="float:right">
        <img class="me-1" src="../../assets/image/information-button.svg"
          matTooltip="Select the relevant delivery location" alt="">
        <span style="font-size: 10px; font-weight: 500;color: #fff; margin-right: 5px;">Location&nbsp;</span>
        <mat-form-field class="tour-boxplotlocation">
          <mat-label>Location</mat-label>

          <mat-select (selectionChange)="updateBoxPlot();refreshBoxPlot(isglobalEdit)" [(value)]="selectedLocation">
            <mat-option *ngFor="let locat of locations;let i = index" [value]="locat.id">{{locat.locationName}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<div class="tscroll tour-boxplot" style="background-image: url('assets/img/Hex_Indextm_Logo_horizontal-new.svg');">
  <div class="card border-light text-center m-3 align-middle" *ngIf="noRows"
    style="height: 100%;align-items: center;display: flex;justify-content: center;">
    <div class="error-text">&#9432; Please select atleast one Tower/ Sub-Tower</div>
  </div>
  <table class="table table-striped-columns custom-table"
    *ngIf="rows.length > 0 || (rows.length==0 && showOnlySelected)">
    <tbody>
      <tr class="loc-row">
        <td class="search-content fixed-col" style="background-color: #F5F5F5; color: #565656">
          <span class="towerNameHead">{{typeNameSelectorTableHeader}}</span>
        </td>
        <td *ngFor="let locat of locationSingle;let i = index"
          [ngClass]="selectedServiceProviders.length == 1 ? (i%2==0 ? 'loc-gray' : 'loc-white') : ''">
          <table>
            <tr style="background-color: #F5F5F5; color: #2B2B2B">
              <td class="sub-header-td" *ngFor="let sp of selectedServiceProviders" style="min-width: 250px;"
                [ngClass]="selectedServiceProviders.length > 1 ? 'color-' + sp.spt : ''">{{sp.spt}}</td>
            </tr>
          </table>
        </td>
      </tr>
      <ng-container *ngIf="displayBoxPlot">
        <tr *ngFor="let row of rows | generalPriceBar:searchText">
          <td class="search-content fixed-col">
            <ng-container *ngIf="loadedComponentType == 'role'">
              <mat-checkbox [color]="'primary'" [checked]="row.isSelected"
                (change)="onRowSelected($event.checked, row)">
                <span class="role-label"> <span>{{ row.label }}</span>
                  <ng-template #popTemplate>
                    <h6 style="font-size: 14px;font-weight: 600;">Role Description</h6>
                    <div [innerHtml]="row['roleData']['description']" style="font-size: 12px;"></div>
                  </ng-template>
                  <mat-icon *ngIf="row['roleData'] && row['roleData']['description']" [popover]="popTemplate"
                    [popoverTitle]="row['roleData']['level']+': '+ row.label + ' ('+row['roleData']['experience'] + ' years)'"
                    [outsideClick]="true" triggers="mouseenter:mouseleave" placement="right" [adaptivePosition]="true">
                    <i class="icons-image icon-info-light-gray"></i>
                  </mat-icon>
                  <mat-icon
                    *ngIf="loadedBenchmarkType=='ru' && row['roleData'] && row['roleData']['experience']"
                    [matTooltip]="row['roleData']['experience']">
                    <i class="icons-image icon-info-light-gray"></i>
                  </mat-icon>
                </span>
                <span *ngIf="loadedComponentType == 'role'"
                  [matTooltip]="row.subtowerName">({{row.subtowerShortcode}})</span>
              </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="loadedComponentType != 'role'">
              <div class="d-flex justify-content-start align-items-center" (click)="openBenchmarkDetail(row)">
                <div class="align-self-start" style="line-height: 0;">
                  <i class="icons-image icon-expand" title="Blended benchmark rate across levels"></i>
                </div>
                <div class=" px-1 text-truncate align-self-center">{{row.label}}</div>

              </div>
              <div style="padding-left:30px !important" class=" px-1 d-flex  justify-content-start align-items-center"
                [matTooltip]="getTooltipText(row)">
                <span class="badge rounded-pill text-light text-bg-secondary">{{ row.selectedSubtowers[0]['shortCode']
                  }}</span>
                <span class="badge rounded-pill text-light text-bg-secondary" *ngIf="row.selectedSubtowers.length > 1">{{
                  row.selectedSubtowers[1]['shortCode'] }}</span>
                <span class="badge rounded-pill text-light text-bg-secondary"
                  *ngIf="row.selectedSubtowers.length > 2">+{{row.selectedSubtowers.length - 2}}</span>
              </div>
            </ng-container>
          </td>
          <td *ngFor="let locat of locationSingle;let i = index"
            [ngClass]="selectedServiceProviders.length == 1 ? (i%2==0 ? 'loc-gray' : 'loc-white') : ''">
            <table>
              <tr class="table-inline">
                <td *ngFor="let sp of selectedServiceProviders"
                  style="max-width: 250px;min-width:250px;height: 25px;padding-left: 13px;">
                  <ng-container *ngIf="!isglobalEdit else inputBoxes">
                    <div *ngIf="row && minMaxRoles">
                      <app-box-plot-container-ru *ngIf="loadedBenchmarkType=='ru'" [minMaxRoles]="minMaxRoles"
                        [width]="getWidthAsPerSelections()" [role]="row" [locat]="locat" [spt]="sp">
                      </app-box-plot-container-ru>
                      <app-box-plot-container *ngIf="loadedBenchmarkType!='ru'" [minMaxRoles]="minMaxRoles"
                        [width]="getWidthAsPerSelections()" [role]="row" [locat]="locat" [spt]="sp">
                      </app-box-plot-container>
                      <!-- <app-box-plot-container-ru *ngIf="loadedComponentType != 'role'"  [minMaxRoles]="minMaxRoles" [width]="getWidthAsPerSelections()" [role]="row" [locat]="locat" [spt]="sp">
                    </app-box-plot-container-ru>  -->
                      <!-- <app-box-plot-container *ngIf="typeNameSelector != 'Services'"  [minMaxRoles]="minMaxRoles" [width]="selectedServiceProviders.length == 1 ? 400:250" [role]="row" [locat]="locat" [spt]="sp">
                    </app-box-plot-container>  -->
                      <!-- <app-ru-box-container *ngIf="typeNameSelector == 'Services'" [minMaxRoles]="minMaxRoles" [width]="selectedServiceProviders.length == 1 ? 400:250" [role]="row" [locat]="locat" [spt]="sp"></app-ru-box-container>   -->
                    </div>
                  </ng-container>
                  <ng-template #inputBoxes>
                    <div style="display: flex; justify-content: center;">
                      <input type="number" min="0" [placeholder]="'0.00'" class=" form-control form-control-md"
                        style="width:auto" [value]="getValue(row.inputPlots[locat.id],sp.id)"
                        (change)="updateBoxPlotValue(row.id, locat.id, sp.id, $event)" autocomplete="false" />
                    </div>
                  </ng-template>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </ng-container>
      <!-- <tr class="fix-bottom">
        <td class="search-content"></td>
        <td *ngFor="let locat of locationSingle;let i = index"
          [ngClass]="selectedServiceProviders.length == 1 ? (i%2==0 ? 'loc-gray' : 'loc-white') : ''">
          <table>
            <tr>
              <td *ngFor="let sp of selectedServiceProviders" style="min-width: 250px; max-width: 250px;"
                [ngClass]="selectedServiceProviders.length > 1 ? 'color-' + sp.spt : ''">
                <div class="bar">
                  <span class="numbers"
                    [ngStyle]="{'left.px': 5}">{{formatNumber(minMaxRoles[locat.id][sp.id]['min'])}}</span>
                  <span class="numbers"
                    [ngStyle]="{'right.px': 5}">{{formatNumber(minMaxRoles[locat.id][sp.id]['max'])}}</span>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr> -->
    </tbody>

  </table>

</div>
