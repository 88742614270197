<div class="help-section">
    <div class="bg-heading bg-heading-gray">
        Feedback
        <a href="javascript:void(0)" (click)="toggle()"><i class="icons-image icon-cancel-white"></i></a>
    </div>
    <form class="feedback-form" [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()">
        <div class="form-body">
            <div class="form-group">
                <select formControlName="subject">
                    <option value="">-- Select --</option>
                    <option *ngFor="let subject of subjects" [ngValue]="subject">{{subject}}</option>
                </select>
            </div>
            <div class="form-group">
                <textarea class="form-control"
                    placeholder="We would love to hear back from you! Please mention here." formControlName="body"></textarea>
            </div>
            <div class="form-group attachment-group">
                <label for="">Attachments</label>
                <input #fileInput accept=".pdf,.doc,.csv,.xlsx,.xls,image/*" type="file" (change)="onFilechange($event)" class="form-control">
                <a *ngIf="isFileChosen" href="javascript:void(0)" (click)="resetFileSelection()"><i class="icons-image icon-cancel"></i></a>
            </div>
        </div>
        <div class="bg-heading form-footer">            
            <button class="cbtn-primary cbtn-secondary-2" [disabled]="isSubmitted || !feedbackForm.valid" type="submit">Submit Feedback <img *ngIf="isSubmitted" src="../../assets/img/dark-loader.gif" style="width:19px;" alt="" class="loader"></button>
        </div>
    </form>
</div>