import { Injectable, ɵɵsetComponentScope } from '@angular/core';
import { db, DetailedAnalysis } from 'src/db';
import * as _ from 'lodash';
import { SelectionList } from 'src/selection'
import { ActivatedRoute } from '@angular/router';
import { InputService } from './input.service';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { EncyptDecryptService } from './encypt-decrypt.service';
import VolumeInput from './models/VolumeInput';
import VolumeInputFlat from './models/VolumeInputFlat';
import SpendInputFlat from './models/SpendInputFlat';
import deepGroup from './tower-params/deepgroup';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private loaderService: LoaderService, private route: ActivatedRoute, private InputService: InputService, private http: HttpClient,
    private decryptService: EncyptDecryptService) { }
  identifier: string = 'selection';
  rateInputPlot = [];
  dealParams: object = {};

  async updateRecord(tableName, dataToUpdate) {
    let updateTableName = this._getUpdateTableName(tableName);
    //find if existing record
    let record = await db.table(updateTableName).where({ id: dataToUpdate.id }).toArray();
    if (record.length > 0)
      return await db.table(updateTableName).update(dataToUpdate.id, dataToUpdate)
    else
      return await db.table(updateTableName).add(dataToUpdate);
  }

  async updateBulkRecords(tableName, dataToUpdate = []) {
    let updateTableName = this._getUpdateTableName(tableName);
    db.table(updateTableName).bulkPut(dataToUpdate).then(result => {
    })
  }

  async getDataFromDb(tableName, query = {}, returnOnlySelected = false) {
    let updateTableName = this._getUpdateTableName(tableName);

    let data = [];
    var updatedData = [];

    if (Object.keys(query).length == 0) {
      data = await db.table(tableName).toArray();
      updatedData = await db.table(updateTableName).toArray();
    }
    else {
      data = await db.table(tableName).where(query).toArray();
      updatedData = await db.table(updateTableName).where(query).toArray();
    }
    let upData = updatedData

    data.forEach(d => {
      let updatedData = upData.filter(ud => { return d.id == ud.id })
      if (updatedData.length > 0) {
        _.merge(d, updatedData[0]);
      }
    })

    if (returnOnlySelected) {
      data = data.filter(data => {
        return data.isSelected == true;
      })
    }
    if (data.length > 0 && data[0].hasOwnProperty('sortOrder'))
      data.sort((a, b) => a.sortOrder - b.sortOrder);
    return data
  }

  private _getUpdateTableName(tableName) {
    return [this.identifier, tableName].join('_');
  }

  async deleteRecordFromSelection(tableName, ids = []) {
    let updateTableName = this._getUpdateTableName(tableName);
    await db.table(updateTableName).bulkDelete(ids);
  }

  async deleteRecordFromTable(tableName, ids = []) {
    await db.table(tableName).bulkDelete(ids);
  }

  async clearRecordFromTable(tableName, ids = []) {
    let updateTableName = this._getUpdateTableName(tableName);
    await db.table(updateTableName).clear();
  }

  //used in detailed tower analysis to fetch data for levelwise with country
  async getTowerWithDetailedAnalysis() {
    let detailedAnalysis: DetailedAnalysis;
    let selectedLocations = await this.getDataFromDb('locations', {}, true);
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let selectedTowersId = selectedTowers.map(sc => { return sc.id });
    let savedData = await db.selection_towers.toArray();
    let inputValues = await db.inputs.where('tower').anyOf(selectedTowersId).toArray();
    let detailedData = await db.detailed_analysis.where('tower').anyOf(selectedTowersId).toArray();

    let spendValues = await db.spends.where('tower').anyOf(selectedTowersId).toArray();
    selectedTowers.forEach(tower => {
      let updatedData = savedData.filter(ud => { return tower.id == ud.id })
      let inputs = inputValues.filter(iv => { return tower.id == iv.tower });
      let spends = spendValues.filter(sv => { return tower.id == sv.tower });
      let finalInputs = {}
      if (updatedData.length > 0) {
        _.merge(tower, updatedData[0]);
      }
      inputs.forEach(i => {
        finalInputs[i.location] = i.headcount
      })
      tower['inputs'] = inputs;
      tower['detailedAnalysis'] = [];
      tower['spend'] = spends.length > 0 ? spends[0]['spend'] : 0
      selectedLocations.forEach(location => {
        let locationData = detailedData.filter(dd => { return dd.location == location.id && dd.tower == tower.id });
        let analysisData = {};
        if (locationData.length > 0) {
          analysisData = Object.assign({}, locationData[0]);
        }
        else
          analysisData = Object.assign({}, detailedAnalysis);

        analysisData['location'] = location.id
        analysisData['name'] = location.locationName;
        analysisData['tower'] = tower.id;
        tower.detailedAnalysis.push(analysisData)
      });
    })
    return selectedTowers;
  }

  //used in pyramid chart to fetch SOC data and offshore data as per selected towers
  async getPyramidChartData() {
    let selectedTowers = await db.selection_towers.toArray();
    let selectedTowersId = selectedTowers.map(st => { return st.id })
    let socs = await db.socs.where('towerid').anyOf(selectedTowersId).toArray();
    let offshoreData = await db.offshore.where('tower').anyOf(selectedTowersId).toArray();
    selectedTowers.forEach(tower => {
      tower['socs'] = socs.filter(soc => { return tower.id == soc.towerid })[0];
      tower['offshoreData'] = offshoreData.filter(soc => { return tower.id == soc.tower })[0]
    })
    return selectedTowers;
  }

  async getByTowerSelectionChanges() {
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let selectedTowerIds = selectedTowers.map(sc => { return sc.id });
    let selectedSubTowers = await (await db.selection_subtowers.where('towerId').anyOf(selectedTowerIds).toArray()).filter(x => x.isSelected == true);
    let selectedLocations = await this.getDataFromDb('locations', {}, true)
    let selectedSPTs = await this.getDataFromDb('providerTypes', {}, true)
    let data = {
      towers: selectedTowers,
      locations: selectedLocations,
      spts: selectedSPTs
    }
    return data;
  }

  async getByRoleSelectionChanges() {
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let selectedTowerIds = selectedTowers.map(sc => { return sc.id });
    let allsubtowers = await db.subtowers.toArray();
    let selectedSubTowers = await (await db.selection_subtowers.where('towerId').anyOf(selectedTowerIds).toArray()).filter(x => x.isSelected == true);
    let selectedSubTowersIds = selectedSubTowers.map(sc => { return sc.id });
    let roles = await (await db.selection_roles.where('subTowerId').anyOf(selectedSubTowersIds).toArray()).filter(x => x.isSelected == true);
    let selectedLocations = await this.getDataFromDb('locations', {}, true)
    let selectedSPTs = await this.getDataFromDb('providerTypes', {}, true)
    roles.forEach(r => {
      r['shortCode'] = allsubtowers.filter(st => { return st.id == r.subTowerId })[0]['subTower']
    })
    let data = {
      roles: roles,
      towers: selectedTowers,
      locations: selectedLocations,
      spts: selectedSPTs
    }
    return data;
  }

  async getTowerWithRatesInputPlot() {
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let selectedTowersId = selectedTowers.map(sc => { return sc.id });
    let savedData = await db.selection_towers.toArray();
    let inputValues = await db.input_plot.where('tower').anyOf(selectedTowersId).toArray();
    let ratesData = await db.rateList.where('tower').anyOf(selectedTowersId).toArray();
    ratesData = _.groupBy(ratesData, _.iteratee('tower'))
    let skillFactor = await this.getSkillFactor('tower');
    let socs = await db.socs.filter(x => selectedTowersId.indexOf(x.towerid) != -1).toArray();
    let towerRates = await db.towerRates.filter(x => selectedTowersId.indexOf(x.towerId) != -1).toArray();
    let selectedSubTowers = await db.selection_subtowers.where('towerId').anyOf(selectedTowersId).toArray();
    selectedSubTowers = _.groupBy(selectedSubTowers, _.iteratee('towerId'));
    let newRates = {};
    _.forEach(socs, (soc) => {
      let rates = {};
      _.forEach(soc.soc, (value, key) => {
        if (['level1', 'level2', 'level3', 'level4', 'level5'].indexOf(key) != -1)
          rates[key] = value / 100;
      })
      newRates[soc.towerid] = rates
    });

    let finalRates = {};
    _.forEach(towerRates, (tower) => {

      finalRates[tower.towerId] = (finalRates[tower.towerId] || {});
      _.forEach(tower.locationData, (locations) => {
        let computedSOC = {};
        _.forEach(locations.rates, (rate, key) => {
          if (rate[50] != 0)
            computedSOC[key] = newRates[tower.towerId][key];
          else
            computedSOC[key] = 0;
        })

        let totalSOC = 0;
        _.forEach(computedSOC, (item, key) => {
          totalSOC += item;
        })

        _.forEach(computedSOC, (item, key) => {
          computedSOC[key] = item / totalSOC;
        })

        if (finalRates[tower.towerId][locations.locationId]) {

        } else
          finalRates[tower.towerId][locations.locationId] = {};
        _.forEach(locations.rates, (rate, key) => {
          _.forEach(rate, (rate, rateKey) => {
            if (finalRates[tower.towerId][locations.locationId][tower.serviceProviderType]) {

            } else
              finalRates[tower.towerId][locations.locationId][tower.serviceProviderType] = {};
            if (finalRates[tower.towerId][locations.locationId][tower.serviceProviderType][rateKey]) {

            } else
              finalRates[tower.towerId][locations.locationId][tower.serviceProviderType][rateKey] = []
            finalRates[tower.towerId][locations.locationId][tower.serviceProviderType][rateKey].push(rate * computedSOC[key])
          })
        })
        _.forEach(finalRates[tower.towerId][locations.locationId][tower.serviceProviderType], (item, key) => {
          finalRates[tower.towerId][locations.locationId][tower.serviceProviderType][key] = _.sum(item) * skillFactor
        })
      })
    })
    selectedTowers.forEach(tower => {
      let updatedData = savedData.filter(ud => { return tower.id == ud.id })
      let rate = ratesData[tower.id];
      let inputs = inputValues.filter(iv => { return tower.id == iv.tower });
      let finalInputs = _.groupBy(inputs, 'location');
      _.forEach(finalInputs, function (location, locKey) {
        let sps = _.groupBy(location, _.iteratee('serviceProvider'))
        Object.keys(sps).forEach(x => {
          sps[x] = sps[x][0].inputValue;
        })
        finalInputs[locKey] = sps;
      })
      if (updatedData.length > 0) {
        _.merge(tower, updatedData[0]);
      }
      tower['inputPlots'] = finalInputs;
      tower['rates'] = rate;
      tower['rateSPT'] = finalRates[tower.id];
      tower['selectedSubtowers'] = selectedSubTowers[tower.id]
    })
    return selectedTowers;
  }

  async getTowerWithRatesInputPlotOld() {
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let selectedTowersId = selectedTowers.map(sc => { return sc.id });
    let savedData = await db.selection_towers.toArray();
    let ratesData = await db.rateList.where('tower').anyOf(selectedTowersId).toArray();
    let inputValues = await db.input_plot.where('tower').anyOf(selectedTowersId).toArray();
    let skillFactor = await this.getSkillFactor();
    let socs = await db.socs.filter(x => selectedTowersId.indexOf(x.towerid) != -1).toArray();
    let towerRates = await db.towerRates.filter(x => selectedTowersId.indexOf(x.towerId) != -1).toArray();
    let rateServiceProvider = _.groupBy(ratesData, _.iteratee('tower'));
    _.forEach(rateServiceProvider, function (value, key) {
      let locations = _.groupBy(value, 'location');
      _.forEach(locations, function (location, locKey) {
        let sps = _.groupBy(location, _.iteratee('serviceProviderType'))
        _.forEach(sps, function (sp, spKey) {
          let bps = _.groupBy(sp, _.iteratee('benchmarkPoint'))
          bps[25] = (bps[25].map(x => x.intValue).reduce<number>((accumulator, current) => {
            return accumulator + current;
          }, 0) / bps[25].length) * skillFactor;
          bps[50] = (bps[50].map(x => x.intValue).reduce<number>((accumulator, current) => {
            return accumulator + current;
          }, 0) / bps[50].length) * skillFactor;
          bps[75] = (bps[75].map(x => x.intValue).reduce<number>((accumulator, current) => {
            return accumulator + current;
          }, 0) / bps[75].length) * skillFactor;
          sps[spKey] = bps;
        })
        locations[locKey] = sps;
      })
      rateServiceProvider[key] = locations
    })
    selectedTowers.forEach(tower => {
      let updatedData = savedData.filter(ud => { return tower.id == ud.id })
      let rate = ratesData.filter(rd => { return tower.id == rd.tower });
      let inputs = inputValues.filter(iv => { return tower.id == iv.tower });
      let finalInputs = _.groupBy(inputs, 'location');
      _.forEach(finalInputs, function (location, locKey) {
        let sps = _.groupBy(location, _.iteratee('serviceProvider'))
        Object.keys(sps).forEach(x => {
          sps[x] = sps[x][0].inputValue;
        })
        finalInputs[locKey] = sps;
      })
      if (updatedData.length > 0) {
        _.merge(tower, updatedData[0]);
      }
      tower['inputs'] = finalInputs;
      tower['rates'] = rate;
      tower['rateSPT'] = rateServiceProvider[tower.id];
    })

    return selectedTowers;
  }

  // async getTowerWithRates() {
  //   let selectedArea = await this.getDataFromDb('areas', {}, true);
  //   let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
  //   let selectedTowersId = selectedTowers.map(sc => { return sc.id });
  //   let savedData = await db.selection_towers.toArray();
  //   let ratesData = await db.rateList.where('tower').anyOf(selectedTowersId).toArray();
  //   let ratesTowerIds = ratesData.map(rate => { return rate.tower });
  //   let inputValues = await db.input_plot.where('tower').anyOf(selectedTowersId).toArray();
  //   // if against tower no ratelist fetch it
  //   let difference = _.isEqual(selectedTowersId, _.uniq(ratesTowerIds))
  //   if (!difference) {
  //     this.loaderService.openDialog();
  //     (await this.getRatesAsPerSelections()).subscribe(async (data: any) => {
  //       await db.table('rateList').bulkPut(data);
  //       ratesData = await db.rateList.where('tower').anyOf(selectedTowersId).toArray();
  //       this.loaderService.closeDialog();
  //       return this._formatData(selectedTowers, savedData, ratesData, inputValues);
  //     });
  //   } else {
  //     return this._formatData(selectedTowers, savedData, ratesData, inputValues);
  //   }


  // }

  private _formatData(selectedTowers, savedData, ratesData, inputValues) {
    selectedTowers.forEach(tower => {
      let updatedData = savedData.filter(ud => { return tower.id == ud.id })
      let rate = ratesData.filter(rd => { return tower.id == rd.tower });
      let inputs = inputValues.filter(iv => { return tower.id == iv.tower });
      let finalInputs = {}
      if (updatedData.length > 0) {
        _.merge(tower, updatedData[0]);
      }
      inputs.forEach(i => {
        finalInputs[i.location] = i.inputValue
      })
      tower['inputs'] = finalInputs;
      tower['rates'] = rate
    })
    return selectedTowers;
  }

  async getRolesWithRatesInputPlot(considerOnlySelected = false) {
    let towerFactor = await this.getTowerParamFactorRoleWise();
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true);
    let selectedTowersIds = selectedTowers.map(sc => { return sc.id });
    let selectedSubTowers = await db.selection_subtowers.where('towerId').anyOf(selectedTowersIds).primaryKeys()
    let subTowers = await db.subtowers.toArray();
    let roles = [];
    if (considerOnlySelected) {
      roles = await db.selection_roles.where('subTowerId').anyOf(selectedSubTowers).toArray()
    }
    else
      roles = await db.roles.where('subTowerId').anyOf(selectedSubTowers).toArray()
    let rolesIds = roles.map(role => { return role.id });
    let savedData = await db.selection_roles.toArray();
    let ratesData = await db.rateList.where('role').anyOf(rolesIds).toArray();
    let inputPlotValues = await db.input_plot.where('role').anyOf(rolesIds).toArray();
    let spendValues = await db.spends.where('role').anyOf(rolesIds).toArray();
    let locationSpends = await db.spends.where('role').equals(0).toArray();
    ratesData.map(rate => {
      rate['value'] = this.decryptService.decryptData(rate['value']);
    })
    let inputValues = await db.inputs.where('role').anyOf(rolesIds).toArray();
    let skillFactor = await this.getSkillFactor();
    //let rateServiceProvider = _.sortBy(ratesData, ['role', 'location','serviceProviderType']);
    let rateServiceProvider = _.groupBy(ratesData, _.iteratee('role'));

    _.forEach(rateServiceProvider, (value, key) => {
      let locations = _.groupBy(value, 'location');
      _.forEach(locations, (location, locKey) => {
        let sps = _.groupBy(location, _.iteratee('serviceProviderType'))
        _.forEach(sps, (sp, spKey) => {
          let bps = _.groupBy(sp, _.iteratee('benchmarkPoint'))
          bps[25] = this._getValueByMultipleFactors(bps[25][0]['value'], skillFactor, towerFactor, key, location);
          bps[50] = this._getValueByMultipleFactors(bps[50][0]['value'], skillFactor, towerFactor, key, location);
          bps[75] = this._getValueByMultipleFactors(bps[75][0]['value'], skillFactor, towerFactor, key, location);
          sps[spKey] = bps;
        })
        locations[locKey] = sps;
      })
      rateServiceProvider[key] = locations
    })
    roles.forEach(role => {
      let updatedData = savedData.filter(ud => { return role.id == ud.id })
      let rate = ratesData.filter(rd => { return role.id == rd.role });
      let inputs = inputValues.filter(iv => { return role.id == iv.role });
      let inputPlots = inputPlotValues.filter(iv => { return role.id == iv.role });
      let finalInputs = _.groupBy(inputPlots, 'location');
      _.forEach(finalInputs, function (location, locKey) {
        let sps = _.groupBy(location, _.iteratee('serviceProvider'))
        Object.keys(sps).forEach(x => {
          sps[x] = sps[x][0].inputValue;
        })
        finalInputs[locKey] = sps;
      })
      if (updatedData.length > 0) {
        _.merge(role, updatedData[0]);
      }
      let spends = spendValues.filter(sv => { return role.id == sv.role });
      role['inputPlots'] = finalInputs;
      role['spend'] = spends.length > 0 ? spends[0]['spend'] : 0
      role['inputs'] = inputs;
      role['rates'] = rate;
      role['subtowerShortcode'] = subTowers.filter(st => { return st.id == role.subTowerId }).map(st => { return st['shortCode'] })[0];
      role['subtowerName'] = subTowers.filter(st => { return st.id == role.subTowerId }).map(st => { return st['subTower'] })[0];
      role['towerShortcode'] = selectedTowers.filter(st => { return st.id == role.tower }).map(st => { return st['shortCode'] })[0];
      role['rateSPT'] = rateServiceProvider[role.id];
    })
    return roles;
  }

  private _getValueByMultipleFactors(bps, skillFactor, towerFactors, key, location) {
    let multipliers = [parseFloat(bps), skillFactor];
    if (towerFactors.hasOwnProperty(key)) {
      multipliers.push(towerFactors[key])
    }
    if (_.size(this.dealParams) > 0) {
      multipliers.push(this.dealParams[location[0]['location']]);
    }
    return multipliers.reduce((a, b) => a * b, 1);
  }

  async getRolesInputs() {
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let selectedTowerIds = selectedTowers.map(sc => { return sc.id });
    let selectedSubTowers = await db.selection_subtowers.where('towerId').anyOf(selectedTowerIds).primaryKeys()
    let roles = await this.getDataFromDb('roles', {}, true)

    roles = roles.filter(role => { return selectedSubTowers.indexOf(role.subTowerId) > -1 });
    let rolesIds = roles.map(role => { return role.id });
    let ratesData = await db.rateList.where('role').anyOf(rolesIds).toArray();
    let inputValues = await db.inputs.where('role').anyOf(rolesIds).toArray();
    let inputPlotValues = await db.input_plot.where('role').anyOf(rolesIds).toArray();
    let spendValues = await db.spends.where('role').anyOf(rolesIds).toArray();
    let allsubtowers = await db.subtowers.toArray();
    roles.forEach(role => {
      try {
        let rate = ratesData.filter(rd => { return role.id == rd.role });
        let inputs = inputValues.filter(iv => { return role.id == iv.role });
        let inputPlots = inputPlotValues.filter(ipv => { return role.id == ipv.role });
        let spends = spendValues.filter(sv => { return role.id == sv.role });
        role['inputs'] = inputs;
        role['inputPlots'] = inputPlots;
        role['spend'] = spends.length > 0 ? spends[0]['spend'] : 0
        role['rates'] = rate
        role['subtowerShortcode'] = allsubtowers.filter(st => { return st.id == role.subTowerId })[0]['shortCode']
        role['towerShortCode'] = selectedTowers.filter(st => { return st.id == role.tower })[0]['shortCode']
      } catch (e) { }
    })
    return roles;
  }

  async getRolesVolumes() {
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let roles = await this.getDataFromDb('roles', {}, true)
    let rolesIds = roles.map(role => { return role.id });
    let inputValues = await db.inputs.where('role').anyOf(rolesIds).toArray();
    let inputPlotValues = await db.input_plot.where('role').anyOf(rolesIds).toArray();
    let selectedLocations = await this.getDataFromDb('locations', {}, true);
    let spendValues = await db.spends.where('role').anyOf(rolesIds).toArray();
    let returnData = [];
    roles.forEach(role => {
      let obj = new VolumeInput();
      obj.towerId = role.tower;
      obj.towerName = selectedTowers.filter(x => x.id == role.tower)[0].tower;
      obj.roleId = role.id;
      obj.roleName = role.role;
      obj.inputs = [];
      selectedLocations.forEach(location => {
        let volume = inputValues.filter(iv => iv.location == location.id && iv.role == role.id);
        let inputRate = inputPlotValues.filter(ipv => ipv.location == location.id && ipv.role == role.id);
        let volumeObj = {
          locationId: location.id,
          locationName: location.locationName,
          volume: volume.length > 0 ? volume[0]['headcount'] : 0,
          inputRate: inputRate.length > 0 ? inputRate[0]['inputValue'] : 0
        }
        obj.inputs.push(volumeObj);
      })
      let spends = spendValues.filter(sv => { return role.id == sv.role });
      obj.spend = spends.length > 0 ? spends[0]['spend'] : 0
      returnData.push(obj);
    })
    return returnData;
  }

  async getRolesVolumesFlat() {
    let selectedSPTs = await this.getDataFromDb('providerTypes', {}, true);
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let roles = await this.getDataFromDb('roles', {}, true)
    let rolesIds = roles.map(role => { return role.id });
    let inputValues = await db.inputs.where('role').anyOf(rolesIds).toArray();
    let inputPlotValues = await db.input_plot.where('role').anyOf(rolesIds).toArray();
    ;
    let selectedLocations = await this.getDataFromDb('locations', {}, true);
    let spendValues = await db.spends.where('role').anyOf(rolesIds).toArray();
    let returnData = [];
    selectedSPTs.forEach(spt => {
      roles.forEach(role => {
        selectedLocations.forEach(location => {
          let obj = new VolumeInputFlat();
          obj.towerId = role.tower;
          obj.towerName = selectedTowers.filter(x => x.id == role.tower)[0].tower;
          obj.roleId = role.id;
          obj.roleName = role.role;
          obj.spt = spt.id;
          let volume = inputValues.filter(iv => iv.location == location.id && iv.role == role.id);
          let inputRate = inputPlotValues.filter(ipv => ipv.location == location.id && ipv.role == role.id && ipv.serviceProvider == spt.id);
          ;
          obj.locationId = location.id;
          obj.locationName = location.locationName;
          obj.volume = volume.length > 0 ? volume[0]['headcount'] : 0;
          obj.inputRate = inputRate.length > 0 ? inputRate[0]['inputValue'] : 0;
          let spends = spendValues.filter(sv => { return role.id == sv.role });
          obj.spend = spends.length > 0 ? spends[0]['spend'] : 0
          returnData.push(obj);
        })
      })
    })

    return returnData;
  }

  async getRolesSpendsFlat() {
    let roles = await this.getDataFromDb('roles', {}, true)
    let spendValues = await db.spends.toArray();
    let returnData = [];
    roles.forEach(role => {
      let obj = new SpendInputFlat();
      obj.roleId = role.id;
      obj.towerId = role.tower;
      let spends = spendValues.filter(sv => { return role.id == sv.role });
      obj.spend = spends.length > 0 ? spends[0]['spend'] : 0
      returnData.push(obj);
    })
    let spends = spendValues.filter(sv => sv.role == 0 && sv.location > 0);
    ;
    spends.forEach(spend => {
      let obj = new SpendInputFlat();
      obj.roleId = 0;
      obj.towerId = 0;
      obj.locationId = spend.location;
      obj.spend = spend.spend;
      returnData.push(obj);
    })
      ;

    spends = spendValues.filter(sv => sv.role == 0 && sv.location == 0);
    let totalSpend = 0;
    if (spends.length > 0) {
      totalSpend = spends[0].spend;
    }
    let obj = new SpendInputFlat();
    obj.roleId = 0;
    obj.towerId = 0;
    obj.locationId = 0;
    obj.spend = totalSpend;
    returnData.push(obj);

    return returnData;
  }

  async getTowersSpendsFlat() {
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let towers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let towersIds = towers.map(tower => { return tower.id });
    towersIds.push(0);
    let spendValues = await db.spends.where('tower').anyOf(towersIds).toArray();
    let returnData = [];
    towers.forEach(tower => {
      let obj = new SpendInputFlat();
      obj.towerId = tower.id;
      let spends = spendValues.filter(sv => { return tower.id == sv.tower });
      obj.spend = spends.length > 0 ? spends[0]['spend'] : 0
      returnData.push(obj);
    })
    let obj = new SpendInputFlat();
    obj.towerId = 0;
    let spends = spendValues.filter(sv => { return sv.tower == 0 });
    obj.spend = spends.length > 0 ? spends[0]['spend'] : 0
    returnData.push(obj);
    return returnData;
  }

  async getTowersVolumesFlat() {
    let selectedSPTs = await this.getDataFromDb('providerTypes', {}, true);
    let selectedArea = await this.getDataFromDb('areas', {}, true);
    let towers = await this.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true)
    let towersIds = towers.map(role => { return role.id });
    let inputValues = await db.inputs.where('tower').anyOf(towersIds).toArray();
    let inputPlotValues = await db.input_plot.where('tower').anyOf(towersIds).toArray();
    let selectedLocations = await this.getDataFromDb('locations', {}, true);
    let spendValues = await db.spends.where('tower').anyOf(towersIds).toArray();
    let returnData = [];
    selectedSPTs.forEach(spt => {
      towers.forEach(tower => {
        selectedLocations.forEach(location => {
          let obj = new VolumeInputFlat();
          obj.towerId = tower.id;
          obj.towerName = tower.tower;
          let volume = inputValues.filter(iv => iv.location == location.id && iv.tower == tower.id);
          let inputRate = inputPlotValues.filter(ipv => ipv.location == location.id && ipv.tower == tower.id && ipv.serviceProvider == spt.id);
          obj.locationId = location.id;
          obj.locationName = location.locationName;
          obj.spt = spt.id;
          obj.volume = volume.length > 0 ? volume[0]['headcount'] : 0;
          obj.inputRate = inputRate.length > 0 ? inputRate[0]['inputValue'] : 0;
          let spends = spendValues.filter(sv => { return tower.id == sv.tower });
          obj.spend = spends.length > 0 ? spends[0]['spend'] : 0
          returnData.push(obj);
        })
      })
    })

    return returnData;
  }

  async getSkillFactor(type = null) {
    let skillFactor = 1;
    let selectedArea = await (await db.selection_areas.toArray()).filter(x => x.isSelected == true)[0];

    if (selectedArea.type == 1) {
      skillFactor = selectedArea['skillFactor'];
    }
    if (selectedArea.type == 2) {
      let selections = await db.selection_deal_params.toArray();
      selections = selections.filter(s => { return s.category == selectedArea.categoryId })
      let omitOffshoreselections = selections.filter(s => { return s.parameter != 'Offshore Leverage' });
      omitOffshoreselections.forEach(deal => {
        skillFactor = skillFactor * deal.value;
      });
      let offshore = selections.filter(s => { return s.parameter == 'Offshore Leverage' })
      if (offshore.length > 0) {
        let allLocationDeals = await db.deal_params.filter(dp => { return dp.displayText == offshore[0]['displayText'] }).toArray();
        let result = _.groupBy(allLocationDeals, _.iteratee('location'))
        _.forEach(result, (value, key) => {
          result[key] = value.map(v => { return v.value })[0];
        })
        this.dealParams = result;
      }
    }
    return skillFactor;
  }

  async sptFilterForOA() {
    let filters = await db.role_filter.toArray();
    return filters.filter(f => f.type == "spt")[0].value;
  }

  public async getDefaultOffshoreLocation() {
    //Default offshore location is INDIA
    let defaultLoc = await this.getDataFromDb('locations', { locationName: 'INDIA' }, false);
    return defaultLoc;
  }

  async getTotalSpend(type = 'role') {
    let spend = await (await db.spends.toArray()).filter(x => x[type] == 0 && x['spendSelection'] == 'total');
    if (spend.length > 0)
      return spend[0]['spend'];
    else
      return 0;
  }

  async getTowersInputs() {
    let towers = await db.selection_towers.toArray();
    let towersIds = towers.map(role => { return role.id });
    let ratesData = await db.towerRates.where('towerId').anyOf(towersIds).toArray();
    let inputValues = await db.inputs.where('tower').anyOf(towersIds).toArray();
    let inputPlotValues = await db.input_plot.where('tower').anyOf(towersIds).toArray();
    let spendValues = await db.spends.where('tower').anyOf(towersIds).toArray();
    towers.forEach(tower => {
      let rate = ratesData.filter(td => { return tower.id == td.towerId });
      let inputs = inputValues.filter(iv => { return tower.id == iv.tower });
      let inputPlots = inputPlotValues.filter(ipv => { return tower.id == ipv.tower });
      let spends = spendValues.filter(sv => { return tower.id == sv.tower });
      tower['inputs'] = inputs;
      tower['inputPlots'] = inputPlots;
      tower['spend'] = spends.length > 0 ? spends[0]['spend'] : 0
      tower['rates'] = rate
    })
    return towers;
  }

  async getSelections(getDefaultOffshoreLocation = true): Promise<SelectionList> {
    let selectedCategory = await this.getDataFromDb('categories', {}, true);
    let selectedArea = await this.getDataFromDb('areas', { categoryId: selectedCategory[0].id }, true);
    let selectedTowers = await this.getDataFromDb('towers', { areaId: selectedArea[0].id }, true);
    let selectedSubTowers = (await this.getDataFromDb('subtowers', {}, true)).filter(e => selectedTowers.map(t => t.id).includes(e.towerId));
    let selectedRoles = (await this.getDataFromDb('roles', {}, true)).filter(e => selectedSubTowers.map(t => t.id).includes(e.subTowerId));
    let selectedLocations = await this.getDataFromDb('locations', {}, true);

    if(getDefaultOffshoreLocation){
    if (selectedLocations.filter(e => e.offshore).length == 0)
      selectedLocations.push((await this.getDefaultOffshoreLocation())[0]);
    }

    let selectedProvider = await this.getDataFromDb('providerTypes', {}, true);
    let selectedBenchmarkType = await db.selection_benchmarkType.toArray();
    let selectionObj: SelectionList = {
      category: selectedCategory,
      area: selectedArea,
      tower: selectedTowers,
      subtower: selectedSubTowers,
      role: selectedRoles,
      provider: selectedProvider,
      location: selectedLocations,
      selectedBenchmarkType
    }

    return selectionObj;
  }

  async clearSelections() {
    let tableNames = [
      'selection_areas', 'selection_categories', 'selection_roles',
      'selection_subtowers', 'selection_towers', 'selection_benchmarkType',
      'selection_providerTypes', 'selection_locations', 'inputs', 'input_plot',
      'role_filter', 'detailed_analysis', 'selection_deal_params', 'selection_tower_params',
      'selection_deal_params'
    ]
    tableNames.forEach(async (table) => {
      await db.table(table).clear();
    });
  }

  async getDealTowerParameters(type) {
    let dealTowerParams = await db.deal_tower_params.where('type').equals(type).toArray();
    let dealTowerParamsValues = await db.deal_tower_params_values.toArray();
    let selectedDealTowerParams = await db.selection_deal_tower_params_values.toArray();
    dealTowerParams.forEach(dp => {
      dp['options'] = dealTowerParamsValues.filter(dpv => dpv.dealParamId == dp.paramId);
      dp['options'].forEach(opt => {
        let selectionOptions = selectedDealTowerParams.filter(sdt => sdt.id == opt.id)
        _.merge(opt, selectionOptions[0]);
      });
    })
    return dealTowerParams;
  }

  async getTowerParams() {
    let selectedAreas = (await db.selection_areas.toCollection().primaryKeys())
    let selectedTowers = await db.selection_towers.toCollection().toArray()
    let seletectedTowersById = _.keyBy(selectedTowers, 'id')
    let selectedSubTowers = await db.selection_subtowers.toCollection().toArray()
    let selectedSubTowersById = _.keyBy(selectedSubTowers, 'id')
    let selectedCategory = await db.selection_categories.toCollection().toArray()
    let roles = await db.selection_roles.toCollection().filter(role => {
      return selectedTowers.map(st => { return st.id }).indexOf(role.tower) > -1
        && selectedAreas.indexOf(role.area) > -1
        && selectedSubTowers.map(st => { return st.id }).indexOf(role.subTowerId) > -1
        && selectedCategory[0].type == role.type
        && role.isSelected
    })
    let rolesIds = await roles.primaryKeys();
    let rolesByIds = _.keyBy(await roles.toArray(), 'id');
    //let towerParamsData = await (await db.tower_params.toArray()).filter(tp => {return rolesIds.indexOf(tp.resourceUnit) > -1 })
    let towerParamsData = await (await this.getDataFromDb('tower_params', {})).filter(tp => { return rolesIds.indexOf(tp.resourceUnit) > -1 })
    towerParamsData = _.sortBy(towerParamsData, ['parameter']);
    let uniqueParams = _.keysIn(_.groupBy(towerParamsData, 'parameter'));
    towerParamsData.forEach(tp => {
      let resource = rolesByIds[tp.resourceUnit]
      tp['roleName'] = rolesByIds[tp.resourceUnit]['role']
      tp['tower'] = seletectedTowersById[resource['tower']]['tower']
      tp['subtower'] = selectedSubTowersById[resource['subTowerId']]['subTower']
    })
    let roleParamGrouped = _.groupBy(towerParamsData, (item) => {
      return [item.roleName, item.parameter].join('-')
    })
    let grouped = deepGroup(towerParamsData, ['tower', 'subtower', 'roleName', 'parameter'])
    return { grouped, uniqueParams, roleParamGrouped };
  }

  async getTowerParamFactorRoleWise() {
    let selectedAreas = (await db.selection_areas.toCollection().primaryKeys())
    let selectedTowers = await db.selection_towers.toCollection().toArray()
    let seletectedTowersById = _.keyBy(selectedTowers, 'id')
    let selectedSubTowers = await db.selection_subtowers.toCollection().toArray()
    let selectedSubTowersById = _.keyBy(selectedSubTowers, 'id')
    let selectedCategory = await db.selection_categories.toCollection().toArray()
    let roles = await db.selection_roles.toCollection().filter(role => {
      return selectedTowers.map(st => { return st.id }).indexOf(role.tower) > -1
        && selectedAreas.indexOf(role.area) > -1
        && selectedSubTowers.map(st => { return st.id }).indexOf(role.subTowerId) > -1
        && selectedCategory[0].type == role.type
    })
    let rolesIds = await roles.primaryKeys();
    let towerParamsData = await (await this.getDataFromDb('tower_params', {})).filter(tp => { return rolesIds.indexOf(tp.resourceUnit) > -1 })
    let roleWise = _.groupBy(towerParamsData, (item) => {
      return [item.resourceUnit, item.parameter].join('-')
    })
    let roleTowerParam = {};
    _.forEach(roleWise, (values, key) => {
      if (roleTowerParam[values[0]['resourceUnit']]) {

      } else {
        roleTowerParam[values[0]['resourceUnit']] = [];
      }
      let selections = values.filter(x => x.isSelected)
      if (selections.length == 0) {
        selections = values.filter(x => x.isDefault)
      }
      roleTowerParam[values[0]['resourceUnit']].push(selections[0]['value'])
    })
    _.forEach(roleTowerParam, (item, key) => {
      //roleTowerParam[key] = _.sum(item);
      roleTowerParam[key] = item.reduce((a, b) => a * b, 1)
    })
    return roleTowerParam;
  }

  getDealParams() {

  }

  async getEntitlements() {
    let categories = await db.categories.toArray();
    categories = _.chain(categories)
      .filter(c => c['isEnabled'])
      .groupBy(_.iteratee('category'))
      .value();
    categories = Object.assign({ ITO: [], BPO: [] }, categories)
    return { categories };
  }

  async clearPreviousSpends() {
    await db.spends.clear();
    await db.input_plot.clear();
    await db.inputs.clear();
  }

}
