import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { db } from 'src/db';
import * as _ from 'lodash';
import { DataService } from '../data.service';

@Component({
  selector: 'app-blended-benchmark-detail',
  templateUrl: './blended-benchmark-detail.component.html',
  styleUrls: ['./blended-benchmark-detail.component.scss']
})
export class BlendedBenchmarkDetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BlendedBenchmarkDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
  ) { }


  isMaximised: boolean = false;
  leveldrilldata = {};
  subtowers = [];
  leveldata = [];
  providers = [];
  maximisedElem = {};
  masterData = {};
  rates =[];
  roles = {};
  displayLoader: boolean = false;

  ngOnInit(): void {
    this.displayLoader = true;
    this.getProviders().then(providers => {
      this.providers = providers;
    })
    this.getRates().then(rates => {
      this.rates = rates;
      this.getLevelData(rates).then(() =>{
        this.getLevels(this.data.row.id).then(d => {
          let data = _.pick(d[0]['soc'], ['level1', 'level2', 'level3', 'level4', 'level5']);
          _.forEach(data, (value, key) => {
            this.leveldata.push({
              text: key.replace('level', 'L'),
              level: key,
              rateKey: key.replace('level', 'Level '),
              percentage: value
            })
          })
          this.displayLoader = false;
        })
      })
    })
  }

  async getLevels(id) {
    return await db.socs.where('towerid').equals(id).toArray();
  }

  async getProviders() {
    return await db.selection_providerTypes.toArray();
  }

  async maximiseLevel(level) {
    if(typeof this.masterData[level.rateKey] === 'undefined'){
      return false;
    }
    this.isMaximised = true;
    this.maximisedElem = level;
    this.leveldrilldata = {};
    let rates = this.rates.filter(r => { return  r['level'] == level.rateKey })    
    let final = this._deepGroup(rates, ['subTower', 'roleFTE.role','serviceProviderType'])
    _.forEach(final, (value, role) => {
      _.forEach(value, (valu2, sp) => {
        _.forEach(valu2, (rates, sp2) => {
          let r = {};
          _.forEach(rates, (rate, k) => {
            r[rate.benchmarkPoint] = rate.intValue.toFixed(0);
          });
          final[role][sp][sp2] = r;
        })
      })
    });
    this.leveldrilldata = final;
    return true;
  }

  async getRates(){
    let sf = await this.dataService.getSkillFactor();
    let subtowers = await db.selection_subtowers.where('towerId').equals(this.data.row.id).toArray();
    let rates = await db.rateList.where('subTower').anyOf(subtowers.map(st => { return st.id })).toArray();
    this.roles = _.groupBy(rates, _.iteratee('roleFTE.role'));
    subtowers = _.groupBy(subtowers, _.iteratee('id'))
    this.subtowers = subtowers;
    return rates.filter(r => { return r.location == this.data['location'] }).map(r => { r['intValue'] *= sf; return r;});
  }

  async getLevelData(rates) {    
    let final = this._deepGroup(rates, ['level', 'serviceProviderType','benchmarkPoint'])
    _.forEach(final, (value, level) => {
      _.forEach(value, (valu2, sp) => {
        _.forEach(valu2, (bp, sp2) => {
          final[level][sp][sp2] = _.mean(bp.map(b=>{ return b.intValue})).toFixed(0)
        })
      })
    });
    this.masterData = final;
    return final;
  }

  private _deepGroup = function (seq, keys) {
    if (!keys.length) return seq;
    var first = keys[0];
    var rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => { return this._deepGroup(value, rest) });
  };

  getExperience(role){
    return this.roles[role.key][0]['experience'];
  }

  checkIfRatesPresent(data, provider){
    return typeof data !== 'undefined';
  }

}
