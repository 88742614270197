import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RoleList, db, TowerRatesList } from './../db';
import buildQuery from 'odata-query';
import { DataService } from './data.service';
import { liveQuery, Observable } from 'dexie';
import { forkJoin, map, Observable as rxjsObservable, shareReplay, tap } from 'rxjs';
import { LoaderService } from './loader.service';
import { EncyptDecryptService } from './encypt-decrypt.service';
import { DataUpdateService } from './data-update.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private encDecService: EncyptDecryptService, private dataService: DataService, private loaderService: LoaderService) {
  }

  getRegions() {
    return this.http.get(`${environment.apiEndpoint}Metadata/Geography`)
  }

  getGroups() {
    return this.http.get(`${environment.apiEndpoint}Metadata/Group`)
  }

  getPyramid() {
    return this.http.get(`${environment.apiEndpoint}SOC?$top=200`)
  }

  getOffShoreData() {
    //return this.http.get(`${environment.apiEndpoint}OffShore?$filter=tower in(${towerIds})`)
    return this.http.get(`${environment.apiEndpoint}OffShore?$top=200`)
  }

  getProviderTypes() {
    return this.http.get(`${environment.apiEndpoint}Metadata/SPT`)
  }

  getRoles() {
    return this.http.get(`${environment.apiEndpoint}Metadata/CategoryV2`)
  }

  getBenchmarkPrice(data) {
    return this.http.post(`${environment.apiEndpoint}BenchmarkPrice`, data)
  }

  getBlendedRates(data) {
    return this.http.post(`${environment.apiEndpoint}Rate/by-tower`, data)
  }

  getRates(urlParams) {
    return this.http.get(`${environment.apiEndpoint}Rate${urlParams}`);
  }

  getScenariosList(count: number = 20): rxjsObservable<any> {
    return this.http.get(`${environment.apiEndpoint}SaveScenario?$top=${count}&$select=id,name,type,createdOn,user&$expand=catName($select=category)&$orderby=createdOn desc`)
  }

  deleteScenario(id: number) {
    return this.http.delete(`${environment.apiEndpoint}SaveScenario\\${id}`)
  }

  getScenario(id): rxjsObservable<any> {
    return this.http.get(`${environment.apiEndpoint}SaveScenario\\${id}`)
  }

  getRecentDownloads(): rxjsObservable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}Download?$top=20&$orderby=downloadedOn desc`)
  }

  getDocDownload(docName: string): rxjsObservable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}Download/docs?docName=${docName}`)
  }

  getDealParams(): rxjsObservable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}Metadata/DealParams`)
  }

  getTowerParams(): rxjsObservable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}Metadata/TowerParams`)
  }

  getExcelReport(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }),
      observe: 'response' as const,
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${environment.apiEndpoint}Export`, data, httpOptions)
  }

  sendFeedback(postData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
      })
    };
    let formParams = new FormData();
    formParams.append('file', postData.file);
    formParams.append('subject', postData.formData.subject);
    formParams.append('body', postData.formData.body);
    ;
    return this.http.post(`${environment.apiEndpoint}Email/feedback`, formParams)
  }

  downloadFile(docName: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }),
      observe: 'response' as const,
      responseType: 'blob' as 'json'
    };
    return this.http.get(
      `${environment.apiEndpoint}Download/DownloadDocFile?docName=${docName}`, httpOptions);
  }

  /*db queries here */


  private saveRegions(regions) {
    let regionsToSave = []
    let locationsToSave = []
    regions.forEach(region => {
      locationsToSave.push(...region.locations)
      delete region.locations;
      regionsToSave.push(region);
    })
    db.regions.bulkAdd(regionsToSave);
    locationsToSave.sort((a, b) => a.sortOrder - b.sortOrder);
    db.locations.bulkAdd(locationsToSave)
  }


  // private saveRegions(regions, locationIdsToSave: Set<number>) {
  //   let regionsToSave = []
  //   let locationsToSave = []
  //   regions.forEach(region => {
  //     locationsToSave.push(...region.locations.filter(e => locationIdsToSave.has(e.Id)))
  //     delete region.locations;
  //     regionsToSave.push(region);
  //   })
  //   db.regions.bulkAdd(regionsToSave);
  //   db.locations.bulkAdd(locationsToSave)
  // }

  async saveRelatedDataInDb(categories, regions) {
    let rolesToSave = [];
    let areasToSave = [];
    let towerToSave = [];
    let locationIdsToSave = [];
    let subtowersToSave = [];

    categories.forEach(elem => {

      elem.areaDetails.forEach(areas => {
        locationIdsToSave.push(areas.locationMappings);

        areas.towerDetails.forEach(tower => {
          tower['subtowerCount'] = 0;

          tower.subTowerDetails.forEach(subtower => {
            tower['subtowerCount']++;
            subtower.roleDetails.forEach(role => {
              role['type'] = elem.type;
              role['area'] = areas.id;
              role['tower'] = tower.id;
              rolesToSave.push(role)
            })

            delete subtower.roleDetails
            subtower['type'] = elem.type
            subtowersToSave.push(subtower)
          });

          delete tower.subTowerDetails;
          tower['type'] = elem.type
          towerToSave.push(tower)
        })


        let skillFactor = 1;
        if ('data' in areas) {
          if (areas['data'] == "")
            delete areas['data'];
          else {
            areas['data'] = JSON.parse(areas.data);
            let skillFactors = areas['data']['skillFactor'];
            skillFactor = skillFactors.filter(x => x['isDefault'])[0]['Value'];
          }
        }

        areas['skillFactor'] = skillFactor;
        areas.locationMappings = Array.from(new Set(areas.locationMappings.map(e => e.locationId)));

        delete areas.towerDetails;
        delete areas.locationDetails;
        areas['type'] = elem.type
        areasToSave.push(areas);
      })
      delete elem.areaDetails;
      db.categories.add(elem);
    })

    // await this.saveRegions(regions, new Set(locationIdsToSave.flat().map(e => e.locationId)));
    await this.saveRegions(regions);

    await db.areas.bulkAdd(areasToSave);
    await db.towers.bulkAdd(towerToSave);
    await db.roles.bulkAdd(rolesToSave);
    await db.subtowers.bulkAdd(subtowersToSave)
  }


  async getSOCFromDb() {
    let area = await db.selection_areas.filter(area => { return area.isSelected }).first();
    let towers = await db.selection_towers.where({ areaId: area.id }).primaryKeys();
    return await db.socs.where('towerid').anyOf(towers).toArray();
  }

  async getDataSyncFromDb() {
    return await db.dataSync.toArray();
  }

  async getRegionsFromDb() {
    let regions = await db.regions.toArray();
    regions.forEach(region => {
      liveQuery(() => this.getLocationsFromDb(region.id)).subscribe(result => {
        region['locations'] = result;
      })
    })
    return regions;
  }

  async getLocationsFromDb(regionId) {
    let area = await db.selection_areas.filter(area => { return area.isSelected }).first()
    return await db.locations.where({ regionId: regionId }).filter(e => area.locationMappings.includes(e.id)).toArray();
  }

  async saveScenario(postData, navigationData, updateOrNew) {
    let selection_towers = await db.selection_towers.toArray();
    let selection_subtowers = await db.selection_subtowers.toArray();
    let selection_areas = await db.selection_areas.toArray();
    let selection_roles = await db.selection_roles.toArray();
    let selection_towerRates = await db.selection_towerRates.toArray();
    let selection_categories = await db.selection_categories.toArray();
    let selection_providerTypes = await db.selection_providerTypes.toArray();
    let selection_benchmarkType = await db.selection_benchmarkType.toArray();
    let selection_locations = await db.selection_locations.toArray();
    let selection_deal_params = await db.selection_deal_params.toArray();
    let selection_deal_tower_params_values = await db.selection_deal_tower_params_values.toArray();
    let selection_tower_params = await db.selection_tower_params.toArray();
    let inputs = await db.inputs.toArray();
    let input_plot = await db.input_plot.toArray();
    let detailed_analysis = await db.detailed_analysis.toArray();
    let spends = await db.spends.toArray();
    let role_filter = await db.role_filter.toArray();
    let requestData = {
      type: selection_benchmarkType[0]['type'],
      category: selection_categories[0]['id'],
      data: {
        selection_categories,
        selection_areas,
        selection_towers,
        selection_subtowers,
        selection_roles,
        selection_towerRates,
        selection_providerTypes,
        selection_benchmarkType,
        inputs,
        input_plot,
        detailed_analysis,
        selection_locations,
        spends,
        role_filter,
        selection_deal_params,
        selection_deal_tower_params_values,
        selection_tower_params,
        meta: {
          ...navigationData
        }
      }
    }

    if (updateOrNew == 'update') {
      requestData['id'] = postData.id;
      requestData['name'] = postData['name']
      requestData['createdOn'] = postData['createdOn']
      requestData['user'] = postData['user']
      return this.http.put(`${environment.apiEndpoint}SaveScenario`, requestData).subscribe(data => { })
    }
    requestData['name'] = postData.name;
    return this.http.post(`${environment.apiEndpoint}SaveScenario`, requestData).subscribe(data => { })
  }

  async getAllRatesAsPerSelections() {
    //this.loaderService.openDialog();
    let selections = await this.dataService.getSelections();
    let towers = selections.tower.map(tower => { return tower.id });
    let subtowers = selections.subtower.map(tower => { return tower.id });
    let locations = selections.location.map(tower => { return tower.id });
    let providerTypes = selections.provider.map(tower => { return tower.id });

    const filter = { tower: { in: towers }, subtower: { in: subtowers }, location: { in: locations }, serviceProviderType: { in: providerTypes } };
    let filterQuery = buildQuery({ filter });
    let blendedObj = await this.getBlendedObject();
    let rates = this.getRates(filterQuery);
    let blendedRates = this.getBlendedRates(blendedObj);
    return forkJoin([rates, blendedRates])
      .pipe(map(async (data: any[]) => {
        await db.rateList.clear();
        await db.towerRates.clear();
        let towerRatesData = data[1];
        towerRatesData.forEach(rate => {
          rate['locationData'] = this.encDecService.encryptObject(rate['locationData']);
        })
        let ratesData = data[0];
        towerRatesData.forEach(rate => {
          rate['value'] = this.encDecService.encryptData(rate['value']);
        })
        await db.towerRates.bulkAdd(towerRatesData);
        await db.rateList.bulkAdd(ratesData)
      }));
  }

  async getRatesAsPerSelections() {
    let selections = await this.dataService.getSelections();

    let towers = selections.tower.map(tower => { return tower.id })
    let subtowers = selections.subtower.map(tower => { return tower.id })
    let locations = selections.location.map(tower => { return tower.id })
    let providerTypes = selections.provider.map(tower => { return tower.id })

    const filter = { tower: { in: towers }, subtower: { in: subtowers }, location: { in: locations }, serviceProviderType: { in: providerTypes } };
    let filterQuery = buildQuery({ filter });
    return this.getRates(filterQuery)
  }

  async getBlendedRatesFromAPI() {
    let blendedObj = await this.getBlendedObject();
    this.getBlendedRates(blendedObj).subscribe(async (data: TowerRatesList[]) => {
      db.towerRates.clear();
      let towerRatesData = data;
      towerRatesData.forEach(rate => {
        rate['locationData'] = this.encDecService.encryptObject(rate['locationData']);
      })
      await db.towerRates.bulkAdd(towerRatesData)
    });
  }

  async getBlendedObject() {
    //returns tower, location, serviceProviderType in  single object
    let data = await this.dataService.getSelections();
    let type = data['category'][0]['type'];
    let categoryName = data['category'][0]['category'];
    let areaName = data['area'][0]['area'];
    let towerIds = data['tower'].map(e => e.id)
    let subTowerIds = data['subtower'].map(e => e.id)
    let locations = data['location'].map(e => e.id)
    let serviceProviders = data['provider'].map(sp => { return sp.id })
    let requestData = {
      "serviceProviderTypes": serviceProviders,
      "towers": towerIds,
      "subTowers": subTowerIds,
      "locations": locations,
      "areaName": areaName,
      "category": categoryName,
      "type": type
    }
    return requestData;
  }


  async downloadPDFReport(requestData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Accept': 'application/pdf'
      }),
      //observe: 'response' as const,
      responseType: 'blob' as 'blob'
    };

    this.http.post(environment.pdfExportService, requestData, httpOptions).pipe(map((data: any) => {
      let blob = new Blob([data], {
        type: 'application/pdf' // must match the Accept type
      });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let area = requestData['selections']['area'][0]['area']
      link.download = `HEX_PDF_${area}_${Date.now()}.pdf`;
      link.click();
      window.URL.revokeObjectURL(link.href);
      db.file_download.add({ isDownloaded: true });
    })).subscribe((result: any) => {
    }, (err: HttpErrorResponse) => {
      Swal.fire({icon:'info', text:'Something went Wrong'})
      db.file_download.add({ isDownloaded: false });
    })
  }

  getClientDetails() {
    return this.http.get(`${environment.apiEndpoint}/Client/GetClientDetails`);
  }

}
