<div class="login-main">
  <carousel [isAnimated]="true" data-interval="10">
    <slide *ngFor="let slide of slides; let index = index">
        <div class="slider-bg" [ngClass]="slide.backgroundClass">
          <div class="container">
            <div class="row">
              <div class="col-md-7 col-left">
                <div class="carousel-left">
                  <div class="logo">
                    <a href="https://hexadvisory.com/" target="_blank"><img src="../../assets/img/hex-logo-white.svg" alt="logo"></a>
                  </div>
                  <img class="mb-2" src="../../assets/img/HEX-INDEX-TM.svg" alt="logo" width="25%">
                  <!-- <h2>HEX INDEX&#8482;</h2> -->
                  <p>{{ slide.text }}</p>
                  <a mat-flat-button class="cbtn-primary cbtn-secondary login-btn" (click)="login()">Login</a>
                </div>
              </div>
              <div class="col-md-5 col-right">
                <div class="carousel-right">
                  <img [src]="slide.imageRight" alt="image" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <!-- <a href="javascript:void(0)" class="copyright">hexadvisory.com</a> -->
        </div>
      </slide>
  </carousel>
</div>
