import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { liveQuery } from 'dexie';
import { db, ProviderTypeList, TowerRatesList } from 'src/db';
import { ApiService } from '../api.service';
import { DataService } from '../data.service';
import { InputService } from '../input.service';
import { LocationSelectorComponent } from '../location-selector/location-selector.component';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateScenarioComponent } from '../scenario/create-scenario/create-scenario.component';
import { TowerSubtowerSelectorComponent } from '../tower-subtower-selector/tower-subtower-selector.component';
import * as FileSaver from 'file-saver';
import { HttpResponse } from '@angular/common/http';
import { LoaderService } from '../loader.service';
import { Subscription } from 'rxjs';
import { ResetComponent } from '../reset/reset.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataUpdateService } from '../data-update.service';
import { SavingsAnalysisService } from '../services/savings-analysis.service';

@Component({
  selector: 'app-interactive-dashboard',
  templateUrl: './interactive-dashboard.component_new.html',
  styleUrls: ['./interactive-dashboard.component.scss']
})

export class InteractiveDashboardComponent implements OnInit, OnDestroy {
  finalData = {};
  step = 0;
  panelOpenState = false;
  experienceFilter = [];
  analysisType: string = 'role-level';
  selectedExperiences = [];
  toppings = new FormControl('');
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  public keyPressedHandler;
  loadedPageType: string = '';
  isTwoColumn: Boolean = true;
  isOneColumn: Boolean = false;
  isThreeColumn: Boolean = false;
  oAPane = false;
  sPane = true;
  targetRoleOverlay: boolean = true;
  annualRoleOverlay: boolean = true;
  targetTowerOverlay: boolean = true;
  annualTowerOverlay: boolean = true;
  selectedSpt: string;
  selectedRolesSavingsIndex: number = 0;
  selectedBlendedSavingsIndex: number = 0;
  isDownloadInProgress: boolean = false;
  isRuEnabled: boolean = true;
  safeUrl: SafeResourceUrl;
  tooltipContentInputSOC: string;
  setStep(index: number) {
    this.step = index;
  }
  liveQuerySubscription = new Subscription();
  spts$ = liveQuery(() => this.getSPTFromDb())

  towerWiseSOC = [];

  constructor(private router: Router, private apiService: ApiService, public dialog: MatDialog, private dataService: DataService,
    private inputService: InputService, private route: ActivatedRoute, private loaderService: LoaderService,
    private savingsAnalysisService: SavingsAnalysisService,
    private sanitizer: DomSanitizer) {
    //this.tooltipContentInputSOC = 'Find Blended FTE Benchmark Rates for your delivery team by changing the input span of control for each tower.'+ ' Blended FTE Rate = Average Role Based Benchmark FTE Rate per Level * Input SOC.';
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadedPageType = params['type'];
      this.analysisType = params['type'];
      if (this.loadedPageType != 'ru')
        this.isRuEnabled = false
      else
        this.isRuEnabled = environment.isRUEnabled && environment.production;
    })
    this.liveQuerySubscription.add(liveQuery(() => this.apiService.getSOCFromDb()).subscribe(result => {
      this.towerWiseSOC = result;
    }));
    this.listenInputsToPlotChart();
    this.apiService.getBlendedRatesFromAPI();
    this.getExperienceLevel();
    this.getSPTFromDb();

    this.liveQuerySubscription.add(liveQuery(() => this.getDownloadStatus()).subscribe(result => {
      this.isDownloadInProgress = false;
    }));

  }

  getTooltip() {
    let tooltip = ['Find Blended FTE Benchmark Rates for your delivery team by changing the input span of control for each tower.', 'Blended FTE Rate = Average Role Based Benchmark FTE Rate per Level * Input SOC.'];
    return `${tooltip[0]}
    ${tooltip[1]}`
  }

  async getDownloadStatus() {
    return await db.file_download.toArray();
  }

  ngOnDestroy(): void {
    this.liveQuerySubscription.unsubscribe();
  }

  listenToSubTowerLocationSPT() {

  }

  listenInputsToPlotChart() {
    this.liveQuerySubscription.add(liveQuery(() => this.getTargetSpendRole()).subscribe(result => {
      this.targetRoleOverlay = result.length == 0;
    }));

    this.liveQuerySubscription.add(liveQuery(() => this.getTargetSpendTower()).subscribe(result => {
      this.targetTowerOverlay = result.length == 0;
    }));

    this.liveQuerySubscription.add(liveQuery(() => this.getAnnualSavingsRole()).subscribe(result => {
      if (result.headcounts.length > 0 && result.spends.length > 0) {
        this.annualRoleOverlay = false;
      }
      else
        this.annualRoleOverlay = true;
    }));

    this.liveQuerySubscription.add(liveQuery(() => this.getAnnualSavingsTower()).subscribe(result => {
      if (result.headcounts.length > 0 && result.spends.length > 0) {
        this.annualTowerOverlay = false;
      }
      else
        this.annualTowerOverlay = true;
    }));
  }

  onTabChanged() {

  }

  openResetDialog(): void {
    const dialogRef = this.dialog.open(ResetComponent, {
      width: '50%',
    });

    // dialogRef.afterClosed().subscribe(result => {});
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateScenarioComponent, {
      width: '40%',
      data: {
        loadedPage: this.loadedPageType
      }
    });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  async getSPTFromDb() {
    let spt = await (await db.selection_providerTypes.toArray()).filter(x => x.isSelected == true);
    let record = (await db.role_filter.toArray()).filter(rf => {
      return rf.type == 'spt';
    })
    if (record.length > 0) {
      this.selectedSpt = record[0].value;
    }
    else {
      await this.inputService.updateFilterSelection('spt', spt[0].id);
      this.selectedSpt = spt[0].id.toString();
    }
    return spt;
  }

  async changeSptFilter(event) {
    await this.inputService.updateFilterSelection('spt', event.value);
  }

  async getTargetSpendRole() {
    return await (await db.inputs.where('role').notEqual('').toArray()).filter(x => x.headcount > 0);
  }

  async getTargetSpendTower() {
    return await (await db.inputs.where('tower').notEqual('').toArray()).filter(x => x.headcount > 0);
  }

  async getAnnualSavingsRole() {
    let resultData = {
      headcounts: [],
      spends: []
    }
    resultData.headcounts = await this.getTargetSpendRole();
    resultData.spends = await (await db.spends.where('role').notEqual('').toArray()).filter(x => x.spend > 0);
    return resultData;
  }

  async getAnnualSavingsTower() {
    let resultData = {
      headcounts: [],
      spends: []
    }
    resultData.headcounts = await this.getTargetSpendTower();
    resultData.spends = await (await db.spends.where('tower').notEqual('').toArray()).filter(x => x.spend > 0);
    return resultData;
  }

  plotScatter(cliPrice) {
    //this.scatterData = [...cliPrice];
  }

  openLocationSelectorDialog() {
    const dialogRef = this.dialog.open(LocationSelectorComponent, {
      width: '80%',
      panelClass: 'region-modal',
      data: {
        isModal: true
      }
    });
  }

  openTSSelectorDialog() {
    const dialogRef = this.dialog.open(TowerSubtowerSelectorComponent, {
      width: '80%',
      panelClass: 'tower-subtower-modal',
      data: {
        isModal: true
      }
    });
  }

  async getExperienceLevel() {
    let roles = await db.roles.toArray();
    let experiences = _.uniqBy(roles, 'data.experience').map(role => { if (role.data) return role.data.experience })
    experiences = _.compact(experiences);
    this.experienceFilter = experiences
  }

  togglePaneS() {
    this.sPane = !this.sPane;
    if (this.sPane) {
      this.oAPane = false;
      this.isTwoColumn = true;
      this.isOneColumn = false;
    }
    else {
      this.isOneColumn = true;
      this.isTwoColumn = false;
    }
  }
  togglePaneOA() {
    this.oAPane = !this.oAPane;
    if (this.oAPane) {
      this.sPane = false;
      this.isTwoColumn = true;
      this.isOneColumn = false;
    }
    else {
      this.isOneColumn = true;
      this.isTwoColumn = false;
    }
  }

  private async checkIfSelectionsMade() {
    let selections = await this.dataService.getSelections();
    if (selections.tower.length == 0 || selections.subtower.length == 0) {
      Swal.fire({
        icon: 'warning',
        text: "Select at least one Tower/Sub-Tower from the Selections pane to view and download the benchmark data."
      });
      return false;
    }
    return true;
  }

  async download() {
    this._downloadNotify();
    if (await this.checkIfSelectionsMade() == false) {
      return;
    }
    this.isDownloadInProgress = true;
    await this.getSPTFromDb();
    let ratesObj = await this.apiService.getBlendedObject();
    let volumeInputsRole = await this.dataService.getRolesVolumesFlat();
    let spendInputsRole = await this.dataService.getRolesSpendsFlat();
    let volumeInputsTower = ratesObj.type == 1 ? await this.dataService.getTowersVolumesFlat() : null;
    let spendInputsTower = ratesObj.type == 1 ? await this.dataService.getTowersSpendsFlat() : null;
    let towerParams = ratesObj.type == 2 ? await this.getTowerParams() : null;
    let dealParams = ratesObj.type == 2 ? await this.getDealParams() : null;
    let skillFactor = await this.dataService.getSkillFactor();
    let obj = {
      rolesVolumeInputs: volumeInputsRole,
      rolesSpendInputs: spendInputsRole,
      towersVolumeInputs: volumeInputsTower,
      towersSpendInputs: spendInputsTower,
      blendedObj: ratesObj,
      towerParam: towerParams,
      dealParam: dealParams,
      benchmarkPoint: this.savingsAnalysisService.benchmarkPoint,
      skillFactor: skillFactor
    }

    this.apiService.getExcelReport(obj).subscribe({
      next: (data: HttpResponse<Blob>) => {
        this.isDownloadInProgress = false;
        if (data) {
          var contentDisposition = data.headers.get('content-disposition') ?? "file.xlsm";
          var filename = contentDisposition.split(';')[1].split('filename=')[1].replace(/\"/g, "").trim();
          FileSaver.saveAs(data.body, filename);
        }
      },
      error: (error) => {
        this.isDownloadInProgress = false;
        Swal.fire({
          icon: 'error',
          title: "Download error",
          text: "Some error occured while trying to download. Please retry or reach out to support for further help"
        });
        console.error(error);
      },
    });
  }

  async getTowerParams() {
    let returnData = [];
    let groupedTowerParams = await this.dataService.getTowerParams();
    _.forEach(groupedTowerParams['roleParamGrouped'], (values, key) => {
      let selections = values.filter(x => x.isSelected)
      if (selections.length == 0) {
        selections = values.filter(x => x.isDefault)[0]
      }
      else
        selections = selections[0];
      let data = {
        resourceUnit: selections['resourceUnit'],
        parameter: selections['parameter'],
        displayText: selections['displayText'],
        value: selections['value']
      }
      returnData.push(data);
    })
    return returnData;
  }

  async getDealParams() {
    let dealParamsData = await this.dataService.getDataFromDb('deal_params', {});
    let selectedCategory = await db.selection_categories.toArray();
    dealParamsData = dealParamsData.filter(dp => { return dp.category == selectedCategory[0]['id'] });
    let params = _.groupBy(dealParamsData, _.iteratee('parameter'));
    let resultArray = [];
    let returnData = [];
    _.forEach(params, (value, key) => {
      let selections = value.filter(x => x.isSelected)
      if (selections.length == 0) {
        selections = value.filter(x => x.isDefault);
      }
      resultArray.push({
        paramName: key,
        values: selections[0]
      });
      returnData.push(selections[0]);
    });
    return returnData;
  }

  downloadPDFReport() {
    this._downloadNotify();
    this.isDownloadInProgress = true;
    let page = (this.loadedPageType == 'ru') ? 'ru' : 'fte';
    let host = new URL(window.location.href)
    let url = `${host.origin}/pdf_export/${page}/${this.loadedPageType}`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  _downloadNotify() {
    Swal.fire({
      text: 'We will notify you once the benchmark report has been prepared and is ready to be downloaded.',
      icon: 'info'
    })
  }

  showButton: boolean = false;
  showButtonSelections: boolean = false;
}
