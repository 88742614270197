import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { liveQuery } from 'dexie';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/api.service';
import { DataUpdateService } from 'src/app/data-update.service';
import { DataService } from 'src/app/data.service';
import { MessageService } from 'src/app/services/Message.service';
import { TowerSubtowerSelectorComponent } from 'src/app/tower-subtower-selector/tower-subtower-selector.component';
import { AreaList, db } from 'src/db';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-donut-chart-tower',
  templateUrl: './donut-chart-tower.component.html',
  styleUrls: ['./donut-chart-tower.component.scss']
})
export class DonutChartTowerComponent implements OnInit {


  //@Input() towers: any;
  @Input() displayCategoryAndArea: boolean = true;
  @Input() isStepCaller: boolean = false;
  @Input() isTwoColumn;
  @Input() variant?: string = "full";
  @Input() isModal?: boolean = false;
  
  category: any[];
  view: [number, number] = [250, 250];
  tower: any[];
  finalTower: any[];
  subTower = [];
  bsModalRef?: BsModalRef;
  categoryModel: any;
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  showSubtowers: boolean = false;
  activeEntries: any[];
  selectedArea: AreaList;
  selectedAreaId: number;
  scoreFactor: any;
  skillFactors = [];
  donutSize: any|undefined ;//= { width: 260, height: 260 }
  laptopMedia: any = window.matchMedia("(max-width: 1600px)")
  constructor(private dataUpdateService: DataUpdateService, public dialog: MatDialog, private apiService: ApiService, private modalService: BsModalService, private messageService: MessageService, private dataService: DataService) {
  }

  getDonutSize() {
    if (this.laptopMedia.matches) {
      this.donutSize = this.variant == 'full' ? { width: 400, height: 400 } : { width: 210, height: 210 };
    } else{
      this.donutSize = this.variant == 'full' ? { width: 625, height: 625 } : { width: 280, height: 280 };
    }
    if (this.isModal){
      this.donutSize = { width: this.donutSize.width -30 , height: this.donutSize.height - 30 }
    }
  }

  areas$ = liveQuery(() => this.getAreasFromDb())

  ngOnInit(): void {
    this.getDonutSize();

    liveQuery(() => this.getTowersFromDb()).subscribe(data => {
      this.tower = data;
      this.finalTower = data;
    });
    this.getCategories();
  }

  async onSelect(data) {
    let selectedTower = this.finalTower.filter(x => x["name"] == data["name"])[0];
    this.subTower = await this.getSubTowersFromDb(selectedTower.id);
    // this.showSubtowers = true;
  }

  onEventFired() {

  }

  async changeAreaSelection(event) {
    let area = await db.areas.where('id').equals(event.value).first()
    await db.selection_areas.clear();
    area['isSelected'] = true;
    await this.dataService.updateRecord('areas', area);
    await db.selection_subtowers.clear();
    await db.selection_towers.clear();
    this.dataUpdateService.emitDataChangeEvent('areaUpdated')
  }

  updateSelectionForSubTower(subtower, event) {
    db.subtowers.update(subtower.id, { isSelected: event.checked })
  }

  updateSelectionForTower(tower, event) {
    this.dataService.updateRecord('towers', Object.assign(tower, { isSelected: event.checked }));
    db.towers.update(tower.id, { isSelected: event.checked })
  }

  reset() {
    this.activeEntries = [];
    this.finalTower.forEach(x => x.subTowerDetails.forEach(e => e.isChecked = false));
    this.onEventFired();
  }

  async getAreasFromDb() {
    try{
      let selectedType = await db.selection_benchmarkType.toArray();
      let category = await this.dataService.getDataFromDb('categories', { type: selectedType[0]['type'] }, true);
      return await this.dataService.getDataFromDb('areas',{ categoryId: category[0]['id']});
    }
    catch(e){
      return []
    }
  }

  async selectCategory(category) {
    await db.selection_categories.clear();
    let cat = await db.categories.where({ id: category.id }).first();
    cat['isSelected'] = true;    
    await this.dataService.updateRecord('categories', cat);
    await this.setDefaultsUponCategoryChange(category);
    this.dataUpdateService.emitDataChangeEvent('categoryChanged');
  }

  async setDefaultsUponCategoryChange(category){
    let defaultArea = await db.areas.where({categoryId: category.id}).toArray();
    defaultArea = defaultArea.filter(da => { return da.isDefault && da.isEnabled });
    if(defaultArea.length > 0){
      let area = defaultArea[0];
      this.changeAreaSelection({value: area.id});
    }
  }

  async getCategories() {
    this.getCategoriesFromDb().then(categories => {
      this.category = categories;
      categories.forEach(category => {
        if (category.isSelected)
          this.categoryModel = category.id
      })
    })
  }

  async getTowersFromDb() {
    let selectedArea = await this.dataService.getDataFromDb('areas', {}, true);
    if (selectedArea.length > 0) {
      if (this.selectedArea == undefined || this.selectedArea.id != selectedArea[0].id) {
        this.selectedArea = selectedArea[0];
        this.selectedAreaId = selectedArea[0].id;
        this.scoreFactor = this.selectedArea['skillFactor'];
      }
    }

    this.skillFactors = this.selectedArea['data'] ? this.selectedArea['data']['skillFactor'] : [];

    let towers = await db.towers.where({ areaId: this.selectedArea['id'] }).toArray();
    towers.forEach(tower => {
      tower['name'] = tower.tower
      tower['text'] = tower.tower;
      tower['isSelected'] = tower.isSelected;
      tower['value'] = tower['subtowerCount'];
    })
    return towers;
  }

  async getCategoriesFromDb() {
    let selectedType = await db.selection_benchmarkType.toArray();
    return await this.dataService.getDataFromDb('categories', { type: selectedType[0]['type'] });
  }

  async getSubTowersFromDb(towerid) {
    return await db.subtowers.where({ towerId: towerid }).toArray();
  }
  openTSSelectorDialog() {
    const dialogRef = this.dialog.open(TowerSubtowerSelectorComponent, {
      width: '80%',
      panelClass: 'tower-subtower-modal',
      data: {
        isModal: true
      }
    });
  }


  async onSkillValChange(value) {
    this.selectedArea['skillFactor'] = value;
    await this.dataService.updateRecord('areas', { ...this.selectedArea });
    this.dataUpdateService.emitDataChangeEvent('skillValueChanged')
  }

}
