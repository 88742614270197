import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { liveQuery } from 'dexie';
import { forkJoin, map, Observable, Subscription } from 'rxjs';
import { db } from 'src/db';
import { ApiService } from '../api.service';
import { DataService } from '../data.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { select } from 'd3';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { MessageService } from '../services/Message.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  loading: boolean;
  isRUEnabled = environment.isRUEnabled;
  isDemoEnvironment = environment.isDemoEnvironment || false;
  hasAccessToHandbooks: boolean = false;
  entitlementsFromApi: any = {};
  
  slides = [
    {
      title: "2023 Sourcing Pricing trends Manifesto (An Advisor's POV)",
      text: "Avoid tacos at 7-Eleven and coffee at Taco Bell Source matters in everything you consume and that applies to Sourcing and Price Benchmarking trends too. Don't ask ...",
      url: 'https://hexadvisory.com/blog/2023-sourcing-pricing-trends-manifesto-an-advisors-pov/',
    },
    {
      title: 'Are your metrics measuring the business impact?',
      text: 'What you control is governed by the metrics you measure and report on. But are the right metrics being measured? Traditionally. The function and back-office metrics ...',
      url: 'https://hexadvisory.com/blog/measuring-the-business-impact/',
    },
    {
      title: 'Be Mindful of Muddying the Waters',
      text: "Contracts with remit ranging from managed services to supporting Digital Transformation need to clearly delineate the solution and the underlying people and commercial construct ...",
      url: 'https://hexadvisory.com/blog/muddying-waters/',
    },
    {
      title: 'Choose your COLA Wisely',
      text: 'In this hyperinflationary environment, COLA. once a standard provision, is gaining increase prominence. And rightly so, as a typical managed services contracts spans 5 years and robust COLA mechanism/cluse that ...',
      url: 'https://hexadvisory.com/blog/choose-cola/',
    },
    {
      title: 'Productivity Gains in Field Service / Onsite Support',
      text: 'Field Service productivity is impacted by SLAs and the underlying support model. The primary driver for productivity is strong emphasis on ticket elimination ...',
      url: 'https://hexadvisory.com/blog/productivity-gains/',
    },
    {
      title: 'COLA in the Time of Great Resignation',
      text: "In managed services contacts. We continue to draft up and get equitable agreement for 3.0%-3.5% global COLA i.e. applicable to the managed services delivery model ...",
      url: 'https://hexadvisory.com/blog/muddying-waters/',
    }
  ];
  slides1 = [
    {
      title: 1,
      title_2: 'COST & RUN',
      title_3: 'OPTIMIZATION',
      title_4: 'SERVICES',
      text: 'Large enterprises under shareholder scrutiny and mid-market companies financing growth via retained earnings or private equity funding have one agenda in common—optimizing the overall cost to run the business. Third-party spend can account for 30–70 percent of an organization’s cost structure. This means that its equally important to contain vendor spend as it is to manage retained costs.',
      url: 'https://hexadvisory.com/cost-run-optimization/#more',
    },
    {
      title: 2,
      title_2: 'FAIRNESS',
      title_3: 'OPINION',
      title_4: 'SERVICES',
      text: 'The ether is perpetually fraught with spiel about next generation solutions while outsourcing contracts are still stuck in the past. Scores of outsourcing clients are yet to realize tangible gains from basic service delivery automation and several first-generation outsourcers are not geared to manage contemporary RFPs, bids solutions and contracts. Our fairness opinion services portfolio offers point solutions to pointed asks ranging from RFP and Bid advisory to comprehensive Contract Health Checks.',
      url: 'https://hexadvisory.com/fairness-opinion-services/#more',
    },
    {
      title: 3,
      title_2: 'BENCHMARKING',
      title_3: 'ADVISORY',
      title_4: 'SERVICES',
      text: 'Benchmarking is a key mechanism to obtain fair-market assurance on the solution, cost, quality, and/or terms of engagement with third-party or inhouse sourcing models. It is baked-in as an intervention point in almost all third-party contracts and inhouse shared services governance. Despite the importance of benchmarking in the shifting sands of technology, talent, and terms, it is the most marginalized agenda–undertaken almost as a check-box item with barely any rigor given to proofing the efficacy of the benchmarks.',
      url: 'https://hexadvisory.com/benchmarking-advisory/#more',
    },
    {
      title: 4,
      title_2: 'GLOBAL',
      title_3: 'CAPABILITY CENTER',
      title_4: 'ADVISORY',
      text: 'Over the past three decades, Global Capability Centers (GCCs, formerly called Captives and GICs) have thrived by delivering solid value proposition on capacity and capability augmentation charters modelled mainly on cost arbitrage. Some mismanaged GCCs perished while some outliers evolved by becoming integrated business partners in Digital engineering, R&D, and Product Design & Development – these outliers were primarily deep-pocketed Hi-Tech, Semiconductors, and Banking corporations. The pandemic changed this asymmetry in GCC evolution. The GCC model is seeing a point of inflection as businesses of all sizes and types are now exposed to the viability of the globally distributed workforce, the inevitability of the talent-anywhere model, and the practicality of zero-capex pay-as-you-grow operations.',
      url: 'https://hexadvisory.com/gcc-advisory/#more',
    },
    {
      title: 5,
      title_2: 'PROGRAM',
      title_3: 'EFFECTIVENESS ',
      title_4: 'ADVISORY',
      text: 'Nearly three-quarters of enterprise transformation programs do not meet their intended objectives, timelines, and / or budgets and the key reason for this high failure rate is the lack of focal program orchestration. HEX’s program managers ensure that a detailed program plan is in place and straddles all underlying project workstreams pertaining to target operating model, personnel, process, data, technology, and knowledge broken down further as sequential and concurrent activities. Each activity has its specific milestones and timelines which is aggregated up to the milestones and timelines for each project workstream. Timelines and milestones for each project coalesce to form the overall program roadmap.',
      url: 'https://hexadvisory.com/program-effectiveness/#more',
    },
    {
      title: 6,
      title_2: 'CONTRACT',
      title_3: 'ANALYTICS',
      title_4: '',
      text: 'Enterprises are relying more and more on third-party suppliers, resulting in increased operational complexity and effort to manage contracts. They are spending significant time and effort in crafting third-party contracts, however, the rigor in properly managing these contracts is usually missing. The inability to have an effective contract management process can result in value leakage up to 40% of the contract value. The significance of contract analytics goes beyond the cost management to support one or more of the key strategic objectives including risk minimization, improved regulatory compliance and increased speed to market.',
      url: 'https://hexadvisory.com/contract-analytics/#more',
    }
  ];
  isITO = false;
  isBPO = false;
  dialogListner: Subscription | undefined;
  ito = "https://devhexindex.blob.core.windows.net/devreports/TEMPLATES/Advisory-Handbook_ITO%20Hex%20Index.pdf?sv=2021-10-04&st=2023-06-16T09%3A41%3A57Z&se=2027-06-17T09%3A41%3A00Z&sr=b&sp=r&sig=uxD9szO%2FSYbXm%2BkwbKpEQ8tI%2F8eGRVIAE9gRAuayvzs%3D";
  bpo = "https://devhexindex.blob.core.windows.net/devreports/TEMPLATES/Advisory-Handbook_BPO%20Hex%20Index.pdf?sv=2021-10-04&st=2023-06-16T09%3A41%3A38Z&se=2027-06-17T09%3A41%3A00Z&sr=b&sp=r&sig=Js5mDOuN3F1nmkh6QIhC%2BplZfnRQaT9s3jMYZFxdjsY%3D";
  entitlements: any;

  constructor(private dataService: DataService, private router: Router, private apiService: ApiService, public auth: AuthService, private dialog: MatDialog,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.dialogListner = this.dialog.getDialogById("loader")?.afterClosed().subscribe({
      next: () => {
        this.loading == false
      }
    });
    liveQuery(() => this.dataService.getEntitlements()).subscribe(result => {
      this.entitlements = result;
    })
    this.messageService.getMessage().subscribe(event=> {
      if('clientDetailsEntitlements' == event.data ){
        this.entitlementsFromApi = event.params;
        this.hasAccessToHandbooks  = ('bpoPdf' in event.params ||  'itoPdf' in event.params)
      }
    })
  }

  getUserLastLogin: any = this.auth.user$.pipe(map((e: any) => moment(e.last_login).fromNow()));

  async selectedBenchmarkType(type, analysisType) {
    this._proceedToSelections(type, analysisType)
    // let result = await db.selection_towers.count();
    // if(result > 0){
    //   Swal.fire({
    //     title: 'You have unsaved changes. Do you want to proceed?',        
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes'
    //   }).then((result) => {
    //     if(result.isConfirmed)
    //       this._proceedToSelections(type, analysisType)
    //   })
    // }
    // else{
    //   this._proceedToSelections(type, analysisType)
    // }
  }

  private async _proceedToSelections(type, analysisType) {
    await this.dataService.clearSelections();
    await db.selection_benchmarkType.clear();
    await db.selection_benchmarkType.add({ type: type, currentBenchmarkData: { analysisType: analysisType } });
    this.router.navigate([(type == 1) ? 'fte' : 'ru', 'step', (type == 1) ? analysisType : 'ru']);//fte/role-level
  }

  // private saveRoleFilterDefaults() {
  //   db.role_filter.add({ type: 'location', value: 'split' })
  //   db.role_filter.add({ type: 'spend', value: 'role' })
  // }

  async checkClick(type) {
    let typeText = (type == 1)? 'FTE': 'RU';
    let selectedBenchmarkType = await db.selection_benchmarkType.toArray();
    let isProviderSelected = await (await db.selection_providerTypes.toArray()).length > 0;
    if (selectedBenchmarkType.length > 0) {
      let data = selectedBenchmarkType[0];
      if (data.type == type && isProviderSelected)
        this.router.navigate([(data.type == 1) ? 'fte' : 'ru', (data.type == 1) ? data.currentBenchmarkData['analysisType'] : 'ru']);
      else{
        if (type == 1)
          this._proceedToSelections(type,'role-level')
        if(type ==2)
          this._proceedToSelections(type,'role-level')
      }
        
        //alert(`Selections not found for current Type.`)
      // Swal.fire({
      //   title: 'Error!',
      //   text: 'Selections not found for current Type.',
      //   icon: 'error',
      //   confirmButtonText: 'Close'
      // })
    }
    // let selectionProviderType = await db.selection_providerTypes.toArray();
    // if (selectionProviderType.length > 0)
    //   this.router.navigate(['fte/role-level']);
  }

  ngOnDestroy(): void {
    this.dialogListner?.unsubscribe();
  }

  showDownloadSwal() {
    setTimeout(() => {
      this.isDemoEnvironment = false
    }, 100)
  }

  openDemoSwal() {
    Swal.fire({
      text: "Download isn't available in Demo version",
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok'
    })
  }

  download(docName: string) {
    this.apiService.downloadFile(docName).subscribe(
      (data: any) => {
        var contentDisposition = data.headers.get('content-disposition');
        var filename = contentDisposition.split(';').find(n => n.includes('filename=')).replace('filename=', '').replace(/"/g, '').trim();

        const downloadedFile = new Blob([data.body], { type: data.body.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = filename;
        a.href = URL.createObjectURL(downloadedFile);
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      }
    )
  }

}
