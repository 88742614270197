import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-grid-edit-display',
  template: `<input type="text" [value]="this.displayValue" tabindex="2" />`
})

export class AgGridEditDisplayComponent implements ICellRendererAngularComp {
  public displayValue: any;

  agInit(params: ICellRendererParams<any, number>): void {
    // 
    this.displayValue = params.valueFormatted ? params.valueFormatted : params.value;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
