<div mat-dialog-title>
    <div class="bg-heading bg-heading-blue">
        <div class="w-100 d-flex align-items-center justify-content-between">
            <span>Blended Benchmark Rate Across Levels</span>
            <span class="title text-light text-wrap pe-2 d-none">Click on levels L1, L2, L3, L4 and L5 to see further in
                detail</span>
        </div>
        <button mat-button mat-dialog-close>
            <i class="icons-image icon-cancel-white" aria-hidden="true"></i>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography scroll-window">

    <div class="col-12 bg-heading bg-heading-yellow ps-0 ml-2">
        <div class="container pl-16">{{data.row.label}}</div>
    </div>
    <div class="ml-2 py-2 pl-20 pr-20">Blended rate takes into account the breakup of the Delivery Team across different
        levels
        of the hierarchy. Select a level to see the benchmark rates for roles in each level.</div>

    <div *ngIf="displayLoader" class="loader" style="left: 50%; position: relative;">
        <img src="../../assets/img/dark-loader.gif" style="width: 80%;" alt="">
        Loading..
    </div>
    <div class="container p-2">
        <div class="fixTableHead">
            <table class="table table-bordered table-striped-columns px-5 p-0 mb-0">
                <thead class="rounded-top">
                    <tr class="rounded-top">
                        <th class="py-1 ps-5" width="425px">Level</th>
                        <th class="py-1 text-center" *ngFor="let provider of providers">{{provider.spt}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let level of leveldata">
                        <td (click)="maximiseLevel(level)"
                            [ngClass]="{'active': level.level == maximisedElem['level'] }" class="py-0 align-middle">
                            <app-level-progress [data]="level"></app-level-progress>
                        </td>
                        <td *ngFor="let provider of providers" class="py-0">
                            <ng-container *ngIf="masterData[level.rateKey]; else notavail">
                                <app-boxillustration [data]="masterData[level.rateKey][provider.id]"
                                    style="padding: 0px;"></app-boxillustration>
                            </ng-container>
                            <ng-template #notavail>
                                <div style="min-height: 60px; width: 50px; margin: 0 auto; padding:18px;">NA</div>
                            </ng-template>
                        </td>
                    </tr>

                    <tr>
                        <td style="border-width: 0;" [attr.colspan]="providers.length + 2">&nbsp;</td>
                    </tr>

                    <ng-container *ngIf="isMaximised && leveldrilldata; else loading">
                        <tr id="drillDown" style="color: #555;font-weight:bold;
                        background-color:#d5c98e;">
                            <td class="py-0 align-middle" [attr.colspan]="providers.length + 2">
                                {{maximisedElem['text']}}</td>
                        </tr>
                        <tr>
                            <td class="drillDown-table-data p-0 align-middle" [attr.colspan]="providers.length + 2">
                                <table width="100%" class="table table-bordered  p-0 mb-0">
                                    <thead>
                                        <tr style="background-color: #f1f1f1;">
                                            <th class="py-1 align-middle">Sub-Tower</th>
                                            <th class="py-1 align-middle">Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of leveldrilldata | keyvalue">
                                            <td class="py-0 align-middle" style="width: 200px;max-width:200px;">
                                                {{subtowers[item.key][0]['subTower']}}</td>
                                            <td class="py-0 align-middle">
                                                <table width="100%" class="table table-striped-columns p-0 mb-0">
                                                    <tr *ngFor="let role of item.value | keyvalue">
                                                        <td class="py-0 align-middle"
                                                            style="border-right:1px solid #ccc; width: 225px;max-width:225px;">
                                                            {{role?.key}} ({{ getExperience(role) }} years)</td>
                                                        <td class="py-0 align-middle"
                                                            *ngFor="let sp of role.value | keyvalue">
                                                            <app-boxillustration *ngIf="sp" style="padding: 0px;"
                                                                [data]="sp.value"></app-boxillustration>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>

                        </tr>
                    </ng-container>


                    <ng-template #loading>
                        <!-- <tr>
                            <td [attr.colspan]="providers.length + 2" style="border: none;border-bottom: 1px solid #dee2e6 ;">&nbsp;</td>
                        </tr>                      -->
                        <tr id="drillDown">
                            <td style="
                            height: 115px;
                            font-size: 14px;
                            font-weight: 300;
                            cursor: default;
                            color: #555555;
                            text-align: center;
                            padding: 20px 40px;" class="py-0 align-middle" [attr.colspan]="providers.length + 2">
                                ⓘ Select a level to see the benchmark rates for roles in each level.
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
    </div>
</mat-dialog-content>