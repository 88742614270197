import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { liveQuery } from 'dexie';
import { debounce, map, Observable, Subject } from 'rxjs';
import { db } from 'src/db';
import { ApiService } from '../api.service';
import { DataService } from '../data.service';
import { interval, take } from 'rxjs';
import { InputService } from '../input.service';
@Component({
  selector: 'app-price-bar',
  templateUrl: './price-bar.component.html',
  styleUrls: ['./price-bar.component.scss']
})
export class PriceBarComponent implements OnInit, OnChanges {
  selectedRolesbenchmarkPrices: any[];
  locationRateRange: dynamicObj = {};
  cliPrice: any = [];
  allRoles = [];
  isBarChart: boolean = true;
  allBenchmark = [];
  @Input() experienceFilter = [];
  @Input() displaySelectedOnly = false;
  @Output() actionEvent = new EventEmitter<any[]>();
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;
  @ViewChild('scrollThree') scrollThree: ElementRef;

  updateResult$: Observable<any>;
  updateSubject = new Subject();


  requestData = {
    roles: [],
    locations: [],
    serviceProviderType: null
  };
  @Input() set clientPriceEvent(event) {
    if (event) {
      this.setCliPrice(event)
    }
  }
  selectedExperiences = [];
  // experienceFilter = [];

  ngOnChanges(SimpleChange: SimpleChanges) {
    if (SimpleChange['experienceFilter'].currentValue != null && SimpleChange['experienceFilter'].previousValue != SimpleChange['experienceFilter'].currentValue) {
      this.filterRolesByExperience(SimpleChange['experienceFilter'].currentValue)
    }
  }

  plotScatter() {
    this.actionEvent.emit(this.cliPrice);
  }

  filterRolesByExperience(experieces: any) {
    if (experieces.length > 0) {
      this.selectedRolesbenchmarkPrices = this.allBenchmark.filter((role: any) => {
        return experieces.indexOf(role['experience_level']) > -1;
      })
    }
    else
      this.selectedRolesbenchmarkPrices = this.allBenchmark;
  }

  constructor(private _service: ApiService, private dataService: DataService, private InputService: InputService) { }

  ngOnInit(): void {
    liveQuery(() => this.getRoles()).subscribe(data => {
      this.transformData(data)
    })

    this.getData();
  }

  async getRoles() {
    return await this.dataService.getRolesWithRatesInputPlot()
  }

  transformData(data) {
    data.forEach(d => {
      d['finalRates'] = {};
      d['roleId'] = d.id;
      d.rates.forEach(r => {
        if (!d.finalRates[r['location']]) {
          d.finalRates[r['location']] = {
            rateid: r['id'],
            input: null,
            headcount: 0,
            savings: 0,
            benchmark: {},
            min: r['value'],
            max: r['value'],
            isBarChart: false,
          };
        }
        d.finalRates[r['location']]['benchmark'][r['benchmarkPoint']] = r['value'];
        if (r['value'] < d.finalRates[r['location']]['min'])
          d.finalRates[r['location']]['min'] = r['value']
        if (r['value'] > d.finalRates[r['location']]['max'])
          d.finalRates[r['location']]['max'] = r['value']
      })
      delete d.rates
    })
    this.selectedRolesbenchmarkPrices = data;
  }

  getData() {
    liveQuery(() => this.getLocationsFromDb()).subscribe(locations => {

      locations.forEach(location => {
        location['max'] = 30;
        location['min'] = 20;
      })
      this.requestData.locations = locations;
    })
    liveQuery(() => this.getProviderFromDb()).subscribe(provider => {
      this.requestData.serviceProviderType = provider.id;
      //this.getBenchmarkPrice();
    })
  }

  async getLocationsFromDb() {
    return  await this.dataService.getDataFromDb('locations', {}, true);
  }

  async getProviderFromDb() {
    return await db.selection_providerTypes.filter(pt => { return pt.isSelected == true }).first()
  }

  async getRolesFromDb() {
    return await this.dataService.getDataFromDb('roles',{},false);
  }

  async getSelectedRolesFromDb() {
    let roles = await db.selection_roles.toArray();
    return roles.filter(role => { return role.isSelected == true }).map(role => { return role.id })
  }

  onRowSelected(checked, roleData) {
    roleData.id = roleData.roleId;
    roleData.isSelected = checked;
    this.dataService.updateRecord('roles', roleData);
    this.InsertRolesDataInInput(roleData);
  }

  async InsertRolesDataInInput(roleData: any) {
    let selectionLocations = await this.getLocationsFromDb();
    let records = [];
    selectionLocations.forEach(location => {
      let data = {
        role: roleData.roleId,
        location: location.id
      }
      records.push(data);
    })
    await db.inputs.bulkAdd(records);
  }

  drawScatter(e, id, location) {
    this.InputService.setInputValue({
      role: id,
      location,
      // inputValue: parseInt(e.target.value)
    });
  }

  setCliPrice(data) {
    let loc = data.colDef.field;
    let roleid = data.data.id;
    let cp = data.event.target.value;
    let cpArr = { roleId: roleid, location: loc, cp: cp };
    this.cliPrice.forEach((item, index) => {
      if (item.roleId == cpArr.roleId && item.location == cpArr.location) {
        this.cliPrice.splice(index, 1);
      }
    })
    if (cpArr.cp != '') {
      this.cliPrice.push(cpArr)
    }
    this.plotScatter();
  }

  getBenchmarkPrice() {
    this._service.getBenchmarkPrice(this.requestData).subscribe(
      (bmprice: any) => {
        this.refineData(bmprice);
      }
    )
  }
  async refineData(bmprice: any) {
    let selectedRoles = await this.getSelectedRolesFromDb();
    this.locationRateRange = bmprice.locationRateRange;
    let obj: dynamicObj = {};
    bmprice.locationRateRange.forEach((loc: any) => {
      obj[loc.locationId] = { ...loc, min: loc.min - 10, max: loc.max + 10 };
    })
    this.locationRateRange = obj;
    bmprice.benchmarkPrice.forEach((role: any) => {
      let obj: dynamicObj = {};
      role.data.forEach((locationData: any) => {
        obj[locationData.locationId] = locationData;
      })
      role.data = obj;
    })
    this.selectedRolesbenchmarkPrices = bmprice.benchmarkPrice;
    this.selectedRolesbenchmarkPrices.forEach(role => {
      role["isBarChart"] = true;
      role['isSelected'] = selectedRoles.indexOf(role.roleId) > -1;
      role['experience_level'] = this.allRoles.filter(aRole => { return aRole.id == role.roleId })[0]['data']['experience'];
    });
    this.allBenchmark = this.selectedRolesbenchmarkPrices;
  }

  syncScroll() {
    const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    const scrollTwo = this.scrollTwo.nativeElement as HTMLElement;
    const scrollThree = this.scrollThree.nativeElement as HTMLElement;
    scrollOne.scrollLeft = scrollThree.scrollLeft;
    scrollTwo.scrollLeft = scrollThree.scrollLeft;
  }

  toggleBarChart(role) {
    this.selectedRolesbenchmarkPrices.forEach(ele => {
      if (ele.roleId == role.roleId)
        ele.isBarChart = !ele.isBarChart;
    });
  }

}
interface dynamicObj {
  [key: string]: any;
}