import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, GridOptions, GridReadyEvent, GridApi } from 'ag-grid-community';
import { db } from 'src/db';
import { InputService } from '../input.service';
import { MillionPipe } from '../million.pipe';
import { config } from 'src/environments/config';
import { TourService } from '../tour.service';
import { liveQuery } from 'dexie';

@Component({
  selector: 'app-detailed-analysis-modal',
  templateUrl: './detailed-analysis-modal.component.html',
  styleUrls: ['./detailed-analysis-modal.component.scss'],
  host: { 'class': 'app-detailed-analysis-modal' },
  providers: [MillionPipe]
})
export class DetailedAnalysisModalComponent implements OnInit {
  colDefs!: ColDef[];
  defaultColDef!: ColDef;
  rowData: any[] = [];
  rowHeight = 30;
  headerHeight = 30;
  domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
  previousDetailedAnalysis = []
  isValueChanged: boolean = false;
  displayLoader = true;
  private gridApi: GridApi = null;
  private dataCopy: any;

  constructor(private tourService: TourService, private InputService: InputService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DetailedAnalysisModalComponent>,
    private millionPipe: MillionPipe,
  ) { }
  ngOnInit(): void {
    this.colDefs = [
      { headerName: 'Location', field: 'name', headerClass: 'ig-location', cellClass: 'ig-location', width: 200, editable: false },
      {
        headerName: 'L1', field: 'level1', ...this.detailColDef, width: 100, maxWidth: 100,
        cellClass: 'number-align-right',
        valueFormatter: (param) => config.formatNumber(param.value),
        valueSetter: (param) => {
          param.data['level1'] = config.getNumber(param);
          return true;
        },
      },
      {
        headerName: 'L2', field: 'level2', ...this.detailColDef, width: 100, maxWidth: 100,
        cellClass: 'number-align-right',
        valueFormatter: (param) => config.formatNumber(param.value),
        valueSetter: (param) => {
          param.data['level2'] = config.getNumber(param);
          return true;
        },
      },
      {
        headerName: 'L3', field: 'level3', ...this.detailColDef, width: 100, maxWidth: 100, cellClass: 'number-align-right',
        valueFormatter: (param) => config.formatNumber(param.value),
        valueSetter: (param) => {
          param.data['level3'] = config.getNumber(param);
          return true;
        },
      },
      {
        headerName: 'L4', field: 'level4', ...this.detailColDef, width: 100, maxWidth: 100, cellClass: 'number-align-right',
        valueFormatter: (param) => config.formatNumber(param.value),
        valueSetter: (param) => {
          param.data['level4'] = config.getNumber(param);
          return true;
        },
      },
      {
        headerName: 'L5', field: 'level5', ...this.detailColDef, width: 100, maxWidth: 100, cellClass: 'number-align-right',
        valueFormatter: (param) => config.formatNumber(param.value),
        valueSetter: (param) => {
          param.data['level5'] = config.getNumber(param);
          return true;
        },
      },
      {
        headerName: 'Total FTEs',
        valueGetter: params => this.performSumOfColumns(params),
        ...this.detailColDef, width: 100, headerClass: 'ig-total-fte', cellClass: 'number-align-right ig-total-fte', editable: false,

        valueFormatter: (param) => config.formatNumber(param.value)
      },
      {
        headerName: 'Benchmark Spend ($)', field: 'benchmarkSpend', ...this.detailColDef, headerClass: 'ig-total-spent',
        cellClass: 'ig-total-spent number-align-right', width: 200, editable: false,
        valueFormatter: (param) => {console.log(param); return this.millionPipe.transform(param.value)}
      },
      {
        headerName: 'Your Spend ($)', field: 'spend', ...this.detailColDef, headerClass: 'ig-total-spent',
        cellClass: 'ig-total-spent number-align-right', width: 200,
        valueFormatter: (param) => this.millionPipe.transform(param.value),
        valueSetter: (param) => {
          param.data['spend'] = config.getNumber(param);
          return true;
        },
      }
    ];
    this.defaultColDef = {
      flex: 1,
      minWidth: 50,
      filter: false,
      menuTabs: [],
    };

    liveQuery(() => this._listenForResetAnalysisChanges()).subscribe(result => {
      //   

      this.displayLoader = false
      //this.rowData = result;
    })

  }

  public updateInjectedData(data) {
    this.data = data;
    this.rowData = this.data.gridData.detailedAnalysis;
    //this.previousDetailedAnalysis = JSON.parse(JSON.stringify(this.data.gridData.detailedAnalysis))
  }

  private async _listenForResetAnalysisChanges() {
    return await db.detailed_analysis.where('tower').equals(this.data.data.towerId).toArray();
  }

  public async reset() {
    let records = await db.detailed_analysis_reset.toArray();
    await db.detailed_analysis.bulkPut(records);
    this.gridApi.setRowData([])
    this.gridApi.setRowData(records);
    this.isValueChanged = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  startTour() {
    this.tourService.startCustomTour('towerAnanlysis')
  }

  public detailColDef: ColDef = {
    sortable: false,
    filter: false,
    editable: true,
    onCellValueChanged: async (params) => {
      this.isValueChanged = true;
      params.data.total = this.performSumOfColumns(params);
      this.InputService.setDetailedAnalysisValue(params.data)
      let input: any = await (await db.inputs.where('tower').equals(params.data.tower).toArray()).filter(x => x.location == params.data.location)[0] ?? {};
      input['headcount'] = params.data.total;
      this.InputService.setInputValue(input);
    }
  };

  performSumOfColumns(params) {
    let total: number = 0;
    for (const [key, value] of Object.entries<any>(params.data)) {
      if (['level1', 'level2', 'level3', 'level4', 'level5'].indexOf(key) != -1) {
        if (!isNaN(value)) {
          total += this.formatNumber(value);
        }
      }
    }
    return this.formatNumber(total);
  }

  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
    this.gridApi = params.api;
  }

  formatNumber(value) {
    //!isNaN(value) && value.toString().indexOf('.') != -1
    if (!isNaN(value))
      value = Math.round(value * 100) / 100;
    else
      value = 0;
    return value;
  }

  resetOld() {
    this.InputService.resetDetailedDataByTower(this.data.data.towerId)
  }

}
