import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CellClickedEvent, ColDef, FirstDataRenderedEvent, GridOptions, GridReadyEvent, IDetailCellRendererParams, RowClassParams, RowNode, RowSelectedEvent, RowStyle, SelectionChangedEvent } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { liveQuery, Observable } from 'dexie';
import { db, ProviderTypeList } from 'src/db';
import { DataService } from '../data.service';
import { InputService } from '../input.service';
import { DetailedCustomViewComponent } from '../xxdetailed-custom-view/detailed-custom-view.component';
import { DetailedAnalysisModalComponent } from '../detailed-analysis-modal/detailed-analysis-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SavingsAnalysisService } from '../services/savings-analysis.service';
import { MillionPipe } from '../million.pipe';
import { config } from 'src/environments/config';
import Swal from 'sweetalert2';
import { DataUpdateService } from '../data-update.service';
@Component({
  selector: 'app-opportunity-assessment-new',
  host: { 'class': 'app-opportunity-assessment-new' },
  templateUrl: './opportunity-assessment-new.component.html',
  styleUrls: ['./opportunity-assessment-new.component.scss'],
  providers: [MillionPipe]
})
export class OpportunityAssessmentNewComponent implements OnInit, OnDestroy {
  @Output() keyPressed = new EventEmitter<{}>();

  public detailCellRenderer: any = DetailedCustomViewComponent;
  showSelectedOption: boolean = false;
  domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
  rowHeight = 30;
  headerHeight = 30;
  rowGroupPanelShow: any;
  rowData: any;
  private gridApi: any = null;
  selectedRows: any;
  showSelectRow: boolean = false;
  selectedLocations = [];
  selectedTowers = [];
  gridParams;
  selectedTowersObservable: Subscription;
  total = true;
  selectedSpend: string = "discrete";
  allLocation: string = 'split';
  selectedSpt: string;
  liveQuerySubscription = new Subscription();
  spendDropdown: any = [{
    id: 1,
    text: "Discrete Spend",
    value: 'discrete'
  }, {
    id: 2,
    text: "Tower Spend",
    value: 'tower'
  }, {
    id: 3,
    text: "Total Spend",
    value: 'total'
  },
    // {
    //   id: 4,
    //   text: "Resource Spend",
    //   value: 'resource'
    // }
  ];


  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      headerName: '',
      field: 'expand',
      width: 30,
      pinned: true,
      editable: false,
      onCellClicked: (params) => (this.onTowerExpandClicked(params)),
      cellRenderer: function (params) {
        if (params.data.expand != 1)
          return '<a href="javascript:void(0)"><i class="icons-image icon-expand" title="Tower Based Benchmark Spend Analysis"></i></a>'
        return '';
      },
      menuTabs: []
    },
    {
      headerName: 'Tower',
      field: 'tower',
      checkboxSelection: false,
      width: 150,
      pinned: true,
      editable: false,
      headerClass: 'label-align-now',
    },
    {
      headerName: 'Tower spend ($)',
      field: 'totalSpend',
      checkboxSelection: false,
      width: 200, pinned: true,
      colId: 'totalSpend',
      cellClass: 'oat-total-spend',
      headerClass: 'label-align-now',
      hide: true,
      valueFormatter: (param) => this.millionPipe.transform(param.value),
      valueSetter: (param) => {
        param.data['totalSpend'] = config.getNumber(param);
        return true;
      }
    },
    {
      headerName: 'All Locations (#)',
      field: 'location_0',
      checkboxSelection: false,
      width: 150,
      colId: '0',
      hide: true,
      cellClass: 'input-form-control',
      valueFormatter: (params) => this.checkTotalSpendFunction(params),
      valueSetter: (param) => {
        param.data['location_0'] = config.getNumber(param);
        return true;
      }
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: false,
    filter: false,
    editable: true,
    suppressMovable: true
  };
  noRowsTemplate: string;


  spts$: Observable<ProviderTypeList[]>;

  constructor(private _service: ApiService,
    private dataService: DataService,
    private inputService: InputService,
    public dialog: MatDialog,
    private savingAnalysisService: SavingsAnalysisService,
    private millionPipe: MillionPipe,
    private dataUpdate: DataUpdateService) {
    this.noRowsTemplate = `<span class="error-text">&#9432; Select Towers to start assessment</span>`;
  }

  ngOnInit(): void {
    this.spts$ = liveQuery(() => this.getSPTFromDb());
    this.liveQuerySubscription.add(liveQuery(() => this.getSelections()).subscribe(result => {
      this.selectedLocations = result.locations;
      this.addColumn();
      this._getTowersList();
    }));
    if (this.selectedSpend == 'discrete') {
      this.dataUpdate.emitDataChangeEvent('discreteSelected')
    }
  }

  _getTowersList() {
    //liveQuery(() => this.getTowers()).subscribe(towers => {
    this.getTowers().then(towers => {

      this.selectedTowers = towers;
      let rows = [];
      if (!this.rowData || this.rowData.length == 0) {
        towers.forEach(tower => {
          let locationsData = {}
          if ('inputs' in tower && tower['inputs'].length > 0) {
            tower['inputs'].forEach(input => {
              let val = this.formatNumber(input.headcount);
              locationsData['location_' + input.location] = val;
            })
          }
          if ('spend' in tower)
            locationsData['totalSpend'] = tower['spend'];
          rows.push({ towerId: tower.id, tower: tower.tower, ...locationsData, detailedAnalysis: tower.detailedAnalysis })
        });

        this.gridApi.setRowData([])
        this.gridApi.setRowData(rows)

        this.getBlendedFiltersFromDb();
      }
    })
  }

  async getSPTFromDb() {
    let spt = await (await db.selection_providerTypes.toArray()).filter(x => x.isSelected == true);
    let record = (await db.role_filter.toArray()).filter(rf => {
      return rf.type == 'spt';
    })
    if (record.length > 0) {
      this.selectedSpt = record[0].value;
    }
    else {
      this.selectedSpt = spt[0].id.toString();
    }
    return spt;
  }

  async changeSptFilter(event) {
    await this.inputService.updateFilterSelection('spt', event.value);
  }

  async getBlendedFiltersFromDb() {
    let filters = await db.role_filter.toArray();
    filters.forEach(async f => {
      if (f.type == 'blended location') {
        this.allLocation = f.value;
        await this.locationChange(f)
      }
      if (f.type == 'blended spend') {
        this.selectedSpend = f.value;
        await this.spendChange(f);
      }
    })
  }

  async getTowers() {
    return await this.dataService.getTowerWithDetailedAnalysis();
  }

  async locationChange(e) {
    this.columnDefs.forEach(col => {
      if (col.field == 'location_0')
        col.hide = this.allLocation == 'split' ? true : false;
    })

    if (this.allLocation != 'split') {
      this.gridApi.setColumnDefs(this.columnDefs);
    }
    else {
      this.addColumn();
    }

    await this.addTotalSpendInputRow();

    this.gridOptions.api!.redrawRows();

    await this.inputService.updateFilterSelection('blended location', e.value);
  }

  async addTotalSpendInputRow() {

    let totalSpendInputRow =
    {
      "expand": 1,
      "towerId": 0,
      "tower": "Total Spend",
      "label": "Total Spend"
    }

    if (this.selectedSpend == 'total') {
      let totalSpend = await this.dataService.getTotalSpend('tower');
      totalSpendInputRow[this.getColNameForTotalSpend()] = totalSpend;
      this.gridApi!.setPinnedBottomRowData([totalSpendInputRow]);
    }
    else {
      this.gridApi!.setPinnedBottomRowData([]);
    }
  }

  getColNameForTotalSpend() {
    if (this.allLocation == 'split')
      return 'location_' + this.selectedLocations[0].id;
    else
      return 'location_0';
  }

  async getSelectedLocations() {
    return await this.dataService.getDataFromDb('locations', {}, true);
  }

  async getSelections() {
    return await this.dataService.getByTowerSelectionChanges();
  }

  ngOnDestroy(): void {
    try {
      this.liveQuerySubscription.unsubscribe();
      this.selectedTowersObservable.unsubscribe();
    } catch (e) { }
  }

  addColumn() {
    if (this.allLocation == 'split') {
      let columnDefs = [...this.columnDefs];
      this.selectedLocations.forEach((location, index) => {
        let obj = {
          colIndex: index,
          colId: location.id,
          field: 'location_' + location.id,
          columnGroupShow: 'open',
          headerName: location.locationName + ' (#)',
          width: 120,
          cellClass: (params) => this.checkEditFunction(params) ? 'input-form-control' : "",
          editable: (params) => this.checkEditFunction(params),
          valueFormatter: (params) => this.checkTotalSpendFunction(params),
          valueSetter: (param) => {
            param.data['location_' + location.id] = config.getNumber(param);
            return true;
          }
        }
        columnDefs.push(obj);
      })

      this.gridApi.setColumnDefs(columnDefs);
    }
  }

  async clearData() {
    await db.detailed_analysis.clear();
    let ids = await db.inputs.where('tower').notEqual('').primaryKeys();
    await this.dataService.deleteRecordFromTable('inputs', ids)
    let spendIds = await db.spends.where('tower').notEqual('').primaryKeys();
    await this.dataService.deleteRecordFromTable('spends', spendIds);

    this._getTowersList();
  }

  checkTotalSpendFunction(params) {
    let value;
    if (params.node.isRowPinned() && (params.colDef.colIndex == 0 || params.colDef.colId == '0'))
      value = this.millionPipe.transform(params.value);
    else if (!params.node.isRowPinned())
      value = config.formatNumber(params.value);
    else
      value = '';
    return value;
  }

  checkEditFunction(params) {
    //params.node - for row identity
    //params.column - for column identity
    let editable = true;
    if (params.node.isRowPinned() && params.colDef.colIndex != 0)
      editable = false;
    return editable// - just as sample
  }

  onGridReady(params: GridReadyEvent) {
    this.gridParams = params;
    this.gridApi = params.api;
  }

  async onCellValueChange(e: any) {
    let towerId = e.data.towerId;
    let colId = e.colDef.colId == '0' ? 0 : e.colDef.colId;
    if (colId == 'totalSpend' || towerId == 0) {
      let params = {
        tower: e.data.towerId,
        spendSelection: this.selectedSpend,
        spend: parseFloat(e.value)
      }
      await this.inputService.setSpendValue(params);
    }
    else {
      let params = {
        location: colId,
        tower: e.data.towerId,
        headcount: isNaN(e.value) ? 0 : Math.round(e.value * 100) / 100,
        locationSelection: this.allLocation
      }
      await this.inputService.setInputValue(params);
      if (colId == 0)
        this._getTowersList();

    }
  }

  isExternalFilterPresent() {
    return Global_filterSelectedDocuments; // Here we have no access to the 'this.' of the component. Use a Global Variable instead.
  }

  doesExternalFilterPass(node: any) {
    return node.isSelected();  // Show only selected nodes
  }

  onFirstDataRendered(params: any) {
    params.api.forEachNode((node) =>
      node.setSelected(node.data.isSelected)
    );
  }

  async openDetailedAnalysisDialog(params) {
    let totalFTEs = params.data.detailedAnalysis.reduce((acc, currLoc) => {
      currLoc
      return acc + Number(params.data["location_" + currLoc.location] ?? 0);
    }, 0)

    if (totalFTEs < 1) {
      Swal.fire({
        //title: 'Warning!',
        text: 'Please input headcount for atleast one location',
        icon: 'info',
        confirmButtonText: 'Close'
      });
      return;
    }

    let towers = await this.getTowers();
    let tower = towers.filter(e => e.id == params.data.towerId).map(tower => ({ towerId: tower.id, tower: tower.tower, detailedAnalysis: tower.detailedAnalysis }))[0];

    //let detailedAnalysisData = await db.detailed_analysis.toArray();
    let savingsData = await this.savingAnalysisService.calculateValues(params.data.towerId);
    tower.detailedAnalysis.forEach(ele => ele['benchmarkSpend'] = savingsData['targetSpendByLocation'].get(ele.location));
    let gridDataResetState = Object.assign({}, tower);
    await db.detailed_analysis_reset.clear();
    await db.detailed_analysis_reset.bulkAdd(tower.detailedAnalysis)

    let dialogRef = this.dialog.open(DetailedAnalysisModalComponent, {
      data: { ...params, gridData: tower },
      width: '60%',
      height: '75%',
      panelClass: 'detail-tower-modal'
    });

    let detailedAnalysisLiveQuery = liveQuery(() => db.detailed_analysis.toArray())
      .subscribe((data: any) => {
        this.savingAnalysisService.calculateValues(params.data.towerId).then(savingsData => {
          tower.detailedAnalysis.forEach(ele => ele['benchmarkSpend'] = savingsData.targetSpendByLocation.get(ele.location));
          setTimeout(() => dialogRef.componentInstance.updateInjectedData({ ...params, gridDataResetState, gridData: tower, savingsData: savingsData }), 100);
        });
      });

    this.liveQuerySubscription.add(
      dialogRef.afterClosed().subscribe(result => {
        detailedAnalysisLiveQuery.unsubscribe();
        this._getTowersList();
      })
    );
  }

  public gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    getRowId: params => params.data.id,
    getRowStyle: (params: RowClassParams): RowStyle | undefined => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return {};
    },
  };

  async spendChange(e) {
    this.columnDefs.forEach(col => {
      if (col.field == 'totalSpend')
        col.hide = this.selectedSpend == 'tower' ? false : true;
    })

    if (this.allLocation != 'split') {
      this.gridApi.setColumnDefs(this.columnDefs);
    }
    else {
      this.addColumn();
    }

    await this.addTotalSpendInputRow();

    this.gridOptions.api!.redrawRows();

    await this.inputService.updateFilterSelection('blended spend', e.value);
  }

  onTowerExpandClicked(params: CellClickedEvent<any, any>): void {
    this.openDetailedAnalysisDialog(params);
  }

  formatNumber(value) {
    if (value != '' && parseFloat(value).toString() == value)
      value = parseFloat(value);
    else
      value = 0;
    if (value.toString().indexOf('.') > -1)
      return value.toFixed(2);
    else
      return value;
  }

}

// Used in isExternalFilterPresent() to detect if (external)filter is active or not
let Global_filterSelectedDocuments: boolean;